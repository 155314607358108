import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { ChangeEvent, useEffect, useState, Dispatch } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useMany } from '../../../hooks/api';
import { getDataPortalAccountAmdList } from '../../../common/api/ApiDataPortal';
import { KEY_DATAPORTAL_ACCOUNT_AMD_LIST } from '../../../common/key';

interface Props {
  title: string;
  items: string[];
  open: boolean;
  onClose: () => void;
}

export const SwMissionRecordingDetail: React.FC<Props> = ({
  title,
  items,
  open,
  onClose,
}) => {
  const [step, setStep] = useState<number>(0);

  useEffect(() => {}, [open]);

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          height: '35vh',
          width: '40vh',
        },
      }}
    >
      <DialogTitle>
        <Box className='flex_between'>
          <span>{title}</span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogContent sx={{ p: '1rem 2rem' }}>
        <List>
          {items.map((item: string) => (
            <ListItem key={`record_${item}`}>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize='small' />
              </ListItemIcon>
              <ListItemText primary={`${item}`} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogActions>
        <Box>
          <Button
            variant='contained'
            color='info'
            fullWidth
            size='small'
            onClick={onClose}
          >
            확인
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
