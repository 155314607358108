import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ChangeEvent, useEffect, useState, Dispatch } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useMany } from '../../hooks/api';
import { getDataPortalAccountAmdList } from '../../common/api/ApiDataPortal';
import { KEY_DATAPORTAL_ACCOUNT_AMD_LIST } from '../../common/key';

interface Props {
  target: number;
  setTarget: Dispatch<React.SetStateAction<number>>;
  maxValue: number;
}

export const SwIncDecWithText: React.FC<Props> = ({
  target,
  setTarget,
  maxValue,
}) => {
  const plusValue = () => {
    const newValue = target + 1 <= maxValue ? target + 1 : maxValue;
    setTarget(newValue);
  };

  const minusValue = () => {
    const newValue = target - 1 >= 0 ? target - 1 : 0;
    setTarget(newValue);
  };

  return (
    <Box>
      <Box>
        <Button variant='outlined' color='info' onClick={minusValue}>
          -
        </Button>
        <TextField
          sx={{
            ml: '.2rem',
            mr: '.2rem',
            width: '8rem',
            '& .MuiInputBase-input': {
              textAlign: 'center', // 수평 중앙 정렬
              display: 'flex',
              alignItems: 'center', // 수직 중앙 정렬
              height: '100%', // 높이를 채워야 제대로 작동
            },
          }}
          value={target}
          onChange={(
            evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => setTarget(Number(evt.target.value))}
        />
        <Button variant='outlined' color='info' onClick={plusValue}>
          +
        </Button>
      </Box>
    </Box>
  );
};
