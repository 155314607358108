import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import CloseIcon from '@mui/icons-material/Close';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { SelectSearchBox } from '../../commonComponent/SelectSearchBox';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwSwitch } from '../../styles/Styles';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { AppReleaseNote } from './AppReleaseNote';
import { KEY_APP_OS_RECENT_VERSION } from '../../../common/key';
import { getAppOsRecentVersion } from '../../../common/api/ApiUser';
import { SwDatesPickerWithScroll } from '../../commonComponent/dateSetting/SwDatesPickerWithScroll';
import { RESULT_OK } from '../../../common/resultCode';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { postAppUpdateReleaseNote } from '../../../common/api/ApiAppUpdateManage';

interface Props {
  releaseNote: any;
  open: boolean;
  onclose: () => void;
  onReload: () => void;
}

export const AppReleaseNoteDrawUpDialog: React.FC<Props> = ({
  releaseNote,
  open,
  onclose,
  onReload,
}) => {
  const navigate = useNavigate();
  const [targetType, setTargetType] = useState<number>(0);
  const [osType, setOsType] = useState<number>(1);
  const [recentVersion, setRecentVersion] = useState<any>();
  const [content, setContent] = useState<string>('');
  const [storeContent, setStoreContent] = useState<string>('');
  const [appUpdateDate, setAppUpdateDate] = useState<any[]>([]);
  const [version, setVersion] = useState<string>('');

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');

  const resetReleaseNote = () => {
    setOsType(1);
    setVersion('');
    setAppUpdateDate([]);
    setContent('');
  };

  const setupReleaseNote = () => {
    setOsType(releaseNote.type);
    setVersion(releaseNote.version);
    setAppUpdateDate([new Date(releaseNote.appUpdateDate * 1000)]);
    setContent(releaseNote.content);
    setStoreContent(
      releaseNote.appStoreContent !== null ? releaseNote.appStoreContent : ''
    );
  };

  const { mutate: postAppUpdateReleaseNoteMutate, isError: isPostMutateError } =
    useMutation(postAppUpdateReleaseNote, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setOpenSnackbar(true);
          setAlertMessage('릴리즈노트를 등록했습니다.');
          onReload();
        } else {
          setAlertMessage(
            `릴리즈노트 등록이 실패했습니다.(${res.resultCodeMsg})`
          );
          setAlertOpen(true);
        }
      },
      onError: error => {
        setOpenSnackbar(true);
        setAlertMessage(
          '릴리즈노트 등록이 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });

  const {
    data,
    isLoading,
    refetch: refetchAppOsVersion,
  } = useQuery(KEY_APP_OS_RECENT_VERSION, () => getAppOsRecentVersion(), {
    onSuccess: res => {
      if (res !== null) {
        setRecentVersion(res.versions);
        setupReleaseNote();
      }
    },
    onError: e => {
      console.log(e);
    },
  });

  const doSave = () => {
    const newData: any = {
      idx: releaseNote === null ? -1 : releaseNote.idx,
      type: osType,
      version: version,
      content: content,
      updateDate: appUpdateDate[0].getTime() / 1000,
      storeContent: storeContent,
    };
    const newReleaseNote: any = {
      releaseNote: newData,
    };
    postAppUpdateReleaseNoteMutate(newReleaseNote);
  };

  useEffect(() => {
    if (open) {
      refetchAppOsVersion();
    } else {
      resetReleaseNote();
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            minHeight: '56vh',
            minWidth: '44vw',
          },
        }}
      >
        <DialogTitle>
          <Box className='flex_between'>
            <Box>
              <Typography className='title'>릴리즈 노트</Typography>
            </Box>
            <IconButton onClick={onclose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider sx={{ m: 0, p: 0 }} />
        <DialogContent>
          <Box>
            <Typography className='title'>최신 업데이트 버전 정보</Typography>
            <Box className='flex_start'>
              <Box>
                <Typography className=''>안드로이드</Typography>
                <TextField
                  sx={{ width: '10rem', mb: 0 }}
                  value={recentVersion ? recentVersion.aosVersion : ''}
                />
              </Box>
              <Box sx={{ ml: '2rem' }}>
                <Typography className=''>iOS</Typography>
                <TextField
                  sx={{ width: '10rem', mb: 0 }}
                  value={recentVersion ? recentVersion.iosVersion : ''}
                />
              </Box>
            </Box>
            <Divider />
          </Box>
          <Box>
            <Box className='flex_start'>
              <Typography className='title'>적용 OS 선택</Typography>
            </Box>
            <Box>
              <RadioGroup
                row
                aria-labelledby='movie-row-radio-buttons-group-label'
                name='row-radio-buttons-group'
                value={osType === 1 ? 'android' : 'ios'}
                onChange={evt => {
                  evt.currentTarget.value === 'android'
                    ? setOsType(1)
                    : setOsType(2);
                }}
              >
                <FormControlLabel
                  value='android'
                  control={<Radio />}
                  label='안드로이드'
                />
                <FormControlLabel value='ios' control={<Radio />} label='iOS' />
              </RadioGroup>
            </Box>
          </Box>
          <Divider />
          <Box>
            <Box className='flex_start'>
              <Typography className='title'>버전 정보</Typography>
            </Box>
            <Box>
              <TextField
                placeholder='버전 정보 입력'
                sx={{ width: '20rem' }}
                value={version}
                onChange={evt => {
                  setVersion(evt.target.value);
                }}
              />
            </Box>
          </Box>
          <Divider />
          <Box>
            <Typography className='title'>앱스토어 업데이트 날짜</Typography>
            <SwDatesPickerWithScroll
              title='업데이트 날짜 선택'
              dates={appUpdateDate}
              setDates={setAppUpdateDate}
              showButton={false}
              allowPastDate={true}
            />
          </Box>
          <Divider />
          <Box>
            <Typography className='title'>수정 내용</Typography>
            <TextField
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: 'auto',
                  padding: '.5rem',
                },
              }}
              fullWidth
              multiline
              rows={6}
              onChange={evt => {
                setContent(evt.target.value.substring(0, 2000));
              }}
              value={content}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <span>{content.length} / 2000</span>
                  </InputAdornment>
                ),
              }}
            />
            <Divider />
          </Box>
          <Box>
            <Typography className='title'>앱스토어에서 표시될 문구</Typography>
            <TextField
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: 'auto',
                  padding: '.5rem',
                },
              }}
              fullWidth
              multiline
              rows={6}
              onChange={evt => {
                setStoreContent(evt.target.value.substring(0, 2000));
              }}
              value={storeContent}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <span>{storeContent ? storeContent.length : 0} / 2000</span>
                  </InputAdornment>
                ),
              }}
            />
            <Divider />
          </Box>
        </DialogContent>
        <Divider sx={{ m: 0, p: 0 }} />
        <DialogActions>
          <Button
            variant='outlined'
            color='info'
            sx={{ fontSize: '.87rem' }}
            onClick={onclose}
          >
            닫기
          </Button>
          <Button
            variant='contained'
            color='info'
            sx={{ fontSize: '.87rem' }}
            onClick={doSave}
          >
            저장
          </Button>
        </DialogActions>
      </Dialog>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={alertMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={alertMessage}
      />
    </>
  );
};
