import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CachedIcon from '@mui/icons-material/Cached';
import { ChangeEvent, useEffect, useState, Dispatch } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { MultiSelect } from 'react-multi-select-component';
import { SwDateRangePicker } from './dateSetting/SwDateRangePicker';
import { useMany } from '../../hooks/api';
import { timestamp2Localestring } from '../../common/helper';

interface Props {
  open: boolean;
  onClose: () => void;
  challengeFilterInfo: any;
  filter: any;
  setFilter: Dispatch<React.SetStateAction<any>>;
}

const columns = ['아이디', '이름'];
const communityFilterItems = [
  '커뮤니티 필터 선택',
  '커뮤니티 필터 있음',
  '커뮤니티 필터 없음',
];

const locationFilterItems = [
  '위치 필터 선택',
  '위치 필터 있음',
  '위치 필터 없음',
];

const regionFilterItems = [
  '지역 필터 선택',
  '지역 필터 있음',
  '지역 필터 없음',
];

export const SwChallengeFilter: React.FC<Props> = ({
  open,
  onClose,
  challengeFilterInfo,
  filter,
  setFilter,
}) => {
  const [accountList, setAccountList] = useState<any>([]);
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [masterType, setMasterType] = useState<any>([]);
  const [rewardUseType, setRewardUseType] = useState<any>([]);
  const [challengeType, setChallengeType] = useState(0);
  const [selectMasterType, setSelectMasterType] = useState([]);
  const [selectCommunityFilter, setSelectCommunityFilter] = useState<number>(0);
  const [selectLocationFilter, setSelectLocationFilter] = useState<number>(0);
  const [selectRegionFilter, setSelectRegionFilter] = useState<number>(0);
  const [selectUseType, setSelectUseType] = useState<number>(0);
  const [selected, setSelected] = useState([]);

  const onApply = (row: any) => {
    const newUseType = rewardUseType
      .filter((item: any, idx: number) => idx === selectUseType)
      .map((item: any) => item.type);
    const newFilter = {
      challenge:
        selectMasterType.length > 0
          ? selectMasterType.map((item: any) => item?.value)
          : [],
      community: selectCommunityFilter,
      location: selectLocationFilter,
      region: selectRegionFilter,
      useType: selectUseType === 0 ? '' : newUseType,
      rangeDate:
        rangeDate[0] !== null && rangeDate[1] !== null
          ? [rangeDate[0].getTime() / 1000, rangeDate[1].getTime() / 1000]
          : [0, 0],
    };
    console.log('filter : ', newFilter);
    setFilter(newFilter);
    onClose();
  };

  const resetFilter = () => {
    setRangeDate([null, null]);
    setSelectMasterType([]);
    setSelectCommunityFilter(0);
    setSelectLocationFilter(0);
    setSelectRegionFilter(0);
    setSelectUseType(0);
  };

  const setupFilter = () => {
    const newUseType = rewardUseType
      .filter((item: any, idx: number) => item.type === filter?.useType)
      .map((item: any) => item.idx);
    const newRangeDate = [];
    newRangeDate[0] = timestamp2Localestring(filter?.rangeDate[0], 1000);
    newRangeDate[1] = timestamp2Localestring(filter?.rangeDate[1], 1000);
    setRangeDate(newRangeDate);
    setSelectMasterType(filter?.challenge);
    setSelectCommunityFilter(filter?.community);
    setSelectLocationFilter(filter?.location);
    setSelectRegionFilter(filter?.region);
    setSelectUseType(newUseType.length > 0 ? newUseType[0] : 0);
  };

  useEffect(() => {
    console.log('SwChallengeFilter initial !!!');
    // resetFilter();
  }, [open]);

  useEffect(() => {
    if (challengeFilterInfo) {
      const newMasterType = challengeFilterInfo.type.map((item: any) => {
        const newItem = {
          value: item.challengeType,
          label: item.challengeTypeDescription,
        };
        return newItem;
      });
      setMasterType(newMasterType);

      const newUseType = challengeFilterInfo.useType.map(
        (item: any, idx: number) => {
          const newItem = {
            idx: idx,
            type: `${item.challengeRewardUsetype}/${item.challengeRewardUsesubtype}`,
            name: item.challengeRewardUsetypeName,
          };
          return newItem;
        }
      );
      setRewardUseType(newUseType);

      if (open) {
        setupFilter();
      }
    }
  }, [challengeFilterInfo]);

  return (
    <Dialog
      open={open}
      fullScreen
      sx={{
        '& .MuiDialog-paper': {
          height: '55vh',
          margin: '10rem',
        },
      }}
    >
      <DialogTitle>
        <Box className='flex_between'>
          <Box>
            <span>검색 필터 선택</span>
            <IconButton
              sx={{ ml: '1rem', fontSize: '0.937rem' }}
              onClick={resetFilter}
            >
              <CachedIcon />
              <span>초기화</span>
            </IconButton>
          </Box>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogContent>
        {/* <Box>
          <Typography className='title'>참여기간</Typography>
          <SwDateRangePicker
            rangeDate={rangeDate}
            setRangeDate={setRangeDate}
          />
        </Box> */}
        <Box>
          <Box sx={{ mb: '1rem' }}>
            <Typography className='title'>챌린지 정보</Typography>
            <MultiSelect
              hasSelectAll={false}
              options={masterType}
              value={selectMasterType}
              onChange={setSelectMasterType}
              labelledBy='챌린지 종류'
            />
          </Box>
          <Box className='flex_between'>
            <TextField
              select
              defaultValue={0}
              value={selectCommunityFilter}
              sx={{ width: '20rem' }}
              onChange={(
                evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => setSelectCommunityFilter(Number(evt.target.value))}
            >
              {communityFilterItems.map((item: any, idx: number) => (
                <MenuItem key={idx} value={idx}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              defaultValue={0}
              value={selectLocationFilter}
              sx={{ width: '20rem' }}
              onChange={(
                evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => setSelectLocationFilter(Number(evt.target.value))}
            >
              {locationFilterItems.map((item: any, idx: number) => (
                <MenuItem key={idx} value={idx}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              defaultValue={0}
              value={selectRegionFilter}
              sx={{ width: '20rem' }}
              onChange={(
                evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => setSelectRegionFilter(Number(evt.target.value))}
            >
              {regionFilterItems.map((item: any, idx: number) => (
                <MenuItem key={idx} value={idx}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Box>
        <Box sx={{ mt: '2rem' }}>
          <Typography className='title'>아이템 정보</Typography>
          <TextField
            select
            defaultValue={0}
            value={selectUseType}
            sx={{ width: '40rem' }}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setSelectUseType(Number(evt.target.value))}
          >
            <MenuItem key={0} value={0}>
              사용방법
            </MenuItem>
            {rewardUseType &&
              rewardUseType.map((item: any, idx: number) => (
                <MenuItem key={idx + 1} value={idx + 1}>
                  {item.name}
                </MenuItem>
              ))}
          </TextField>
        </Box>
      </DialogContent>
      <Divider sx={{ m: 0 }} />
      <DialogActions sx={{ p: '1rem' }}>
        <Button
          variant='contained'
          color='primary'
          sx={{ fontSize: '.87rem' }}
          onClick={onApply}
        >
          적용하기
        </Button>
      </DialogActions>
    </Dialog>
  );
};
