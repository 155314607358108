import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState, Dispatch } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useMany } from '../../hooks/api';
import {
  getDataPortalAccountList,
  getDataPortalAccountDetailList,
} from '../../common/api/ApiDataPortal';
import {
  KEY_DATAPORTAL_ACCOUNT_LIST,
  KEY_DATAPORTAL_ACCOUNT_DETAIL_LIST,
} from '../../common/key';
import { encodeSearchWord } from '../../common/helper';

interface Props {
  title: string;
  searchKey: string;
  searchWord: string;
  isDetail?: boolean;
  filterName?: boolean;
  open: boolean;
  onClose: () => void;
  setTarget: Dispatch<React.SetStateAction<any[]>>;
}

const columns = ['아이디', '이름'];

export const SwSearchDataPortal: React.FC<Props> = ({
  title,
  open,
  onClose,
  searchKey,
  searchWord,
  isDetail = false,
  filterName = false,
  setTarget,
}) => {
  const queryClient = useQueryClient();
  const [accountList, setAccountList] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(0);

  const clickItem = (row: any) => {
    const target =
      isDetail === false ? { portalId: row.portalId, name: row.parent } : row;
    setTarget(target);
    onClose();
  };

  const {
    data,
    isLoading,
    refetch: refetchDataPortal,
  } = useQuery(
    KEY_DATAPORTAL_ACCOUNT_LIST,
    () => {
      console.log('getDataPortalAccountList');
      if (open === true && searchKey !== '' && isDetail === false)
        return getDataPortalAccountList(
          searchKey,
          encodeSearchWord(searchKey, searchWord)
        );
      return null;
    },
    {
      onSuccess: res => {
        if (res != null) {
          setAccountList(res.account);
          if (res.account.length === 1) {
            clickItem(res.account[0]);
          }
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const {
    data: detailData,
    isLoading: isDetailLoading,
    refetch: refetchDetailDataPortal,
  } = useQuery(
    KEY_DATAPORTAL_ACCOUNT_DETAIL_LIST,
    () => {
      console.log('getDataPortalAccountDetailList');
      if (open === true && searchKey !== '' && isDetail === true) {
        const newData = {
          page: page,
          rowsPerPage: rowsPerPage,
          searchKey: searchKey,
          searchWord: encodeSearchWord(searchKey, searchWord),
        };
        return getDataPortalAccountDetailList(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        if (res != null) {
          var newAccount = res.accountDetail;
          if (filterName) {
            newAccount = res.accountDetail.filter(
              (item: any) => item.parent === null || item.parent === ''
            );
          }
          setAccountList(newAccount);
          if (newAccount.length === 1) {
            clickItem(newAccount[0]);
          }
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  useEffect(() => {
    console.log('open:', open, ', searchWord:', searchWord);
    if (open === true) {
      isDetail ? refetchDetailDataPortal() : refetchDataPortal();
    }
    return () => {};
  }, [open]);

  return (
    <Dialog
      open={open}
      fullScreen
      sx={{
        '& .MuiDialog-paper': {
          height: '66vh',
          margin: '10rem',
        },
      }}
    >
      <DialogTitle>
        <Box className='flex_between'>
          <span>
            {searchWord && searchWord.length > 0 && `'${searchWord}'`} {title}{' '}
            검색결과
          </span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogContent>
        <TableContainer>
          <Table stickyHeader>
            <TableHead sx={{ bgcolor: '#F9FAFC' }}>
              <TableRow>
                {columns.map((column, idx) => (
                  <TableCell key={idx.toString()} sx={{ p: '.6rem' }}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {accountList?.map((row: any, idx: number) => (
                <TableRow key={idx.toString()} onClick={() => clickItem(row)}>
                  <TableCell key={row.na} sx={{ p: '.3rem .6rem' }}>
                    {row.portalId}
                  </TableCell>
                  <TableCell sx={{ p: '.3rem .6rem' }}>{row.parent}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <Divider sx={{ m: 0 }} />
      {/* <DialogActions sx={{ p: '1rem' }}>
        <Button
          variant='contained'
          color='secondary'
          sx={{ fontSize: '.87rem' }}
          onClick={onClose}
        >
          닫기
        </Button>
      </DialogActions> */}
    </Dialog>
  );
};
