import { useEffect, useRef, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  styled,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { SwTable2 } from '../../commonComponent/table/SwTable2';
import { SwNaverMap } from '../../commonComponent/map/SwNaverMap';
import { UserChallengeDetailWalkingType } from './UserChallengeDetailWalkingType';
import { UserChallengeDetailWalkingAlongType } from './UserChallengeDetailWalkingAlongType';
import {
  KEY_CHALLENGE_REWARD_LIST_BY_USER_ID,
  KEY_USER_PROGRAM_REPORT,
} from '../../../common/key';
import { getChallengeRewardListByUserId } from '../../../common/api/ApiChallenge';
import { removeNatCodePhoneNumber } from '../../../common/helper';
import { UserProgramDetailReport } from './UserProgramDetailReport';
import { getUserProgramReport } from '../../../common/api/ApiUser';

interface Props {
  open: boolean;
  onClose: () => void;
  userData: any;
  programData: any;
}

export const UserProgramDetail: React.FC<Props> = ({
  open,
  onClose,
  userData,
  programData,
}) => {
  const [type, setType] = useState<any>(1);
  const [content, setContent] = useState<any>([]);
  const [programInfo, setProgramInfo] = useState<any>();
  const [requestData, setRequestData] = useState<boolean>(false);

  const makeData = (res: any) => {
    setProgramInfo({
      userInfo: { ...res.user },
      point: [...res.point],
      reward: [...res.reward],
      draw: [res.draw],
      rewardGivetype: [...res.rewardGivetype],
      rewardUsetype: [...res.rewardUsetype],
    });
  };

  const {
    data,
    isLoading,
    refetch: programReportRefetch,
  } = useQuery(
    KEY_USER_PROGRAM_REPORT,
    () => {
      if (requestData) {
        const newData = {
          programId: programData.programId,
          userId: userData.id,
        };
        return getUserProgramReport(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        if (res !== null && res.report !== undefined && res.report !== null) {
          makeData(res.report);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const title = (newType: number) => {
    switch (newType) {
      case 1:
        return '프로그램 현황';
      default:
        return '미생성 콘텐츠';
    }
  };

  useEffect(() => {
    if (requestData) {
      programReportRefetch();
    }
  }, [requestData]);

  useEffect(() => {
    if (open) {
      setRequestData(true);
    } else {
      setRequestData(false);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      fullScreen
      sx={{
        '& .MuiDialog-paper': {
          height: '88vh',
          margin: type === 1 ? '7%' : '22%',
          minWidth: '345px',
          borderRadius: '12px',
        },
      }}
    >
      <DialogTitle>
        <Box className='flex_between'>
          <span>{title(type)}</span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ p: 0, m: 0 }} />
      <DialogContent>
        <UserProgramDetailReport type={type} programInfo={programInfo} />
      </DialogContent>
    </Dialog>
  );
};
