import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useQuery, useQueryClient } from 'react-query';
import CloseIcon from '@mui/icons-material/Close';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { SelectSearchBox } from '../../commonComponent/SelectSearchBox';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwSwitch } from '../../styles/Styles';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { AppReleaseNote } from './AppReleaseNote';

interface Props {
  osType: number;
  open: boolean;
  onclose: () => void;
}

export const AppReleaseNoteDialog: React.FC<Props> = ({
  osType,
  open,
  onclose,
}) => {
  const navigate = useNavigate();
  const [targetType, setTargetType] = useState<number>(0);

  useEffect(() => {
    open ? setTargetType(osType) : setTargetType(0);
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            minHeight: '56vh',
            minWidth: '85vw',
          },
        }}
      >
        <DialogTitle>
          <Box className='flex_between'>
            <Box>
              <Typography>릴리즈 노트</Typography>
            </Box>
            <IconButton onClick={onclose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider sx={{ m: 0, p: 0 }} />
        <DialogContent>
          <Box>
            <AppReleaseNote osType={targetType} />
          </Box>
        </DialogContent>
        <Divider sx={{ m: 0, p: 0 }} />
        <DialogActions>
          <Button
            variant='outlined'
            color='info'
            sx={{ fontSize: '.87rem' }}
            onClick={onclose}
          >
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
