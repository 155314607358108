import { Dispatch, Fragment, useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { Box, Button, Grid, Paper, styled, Typography } from '@mui/material';
import { SwNaverMap } from '../../commonComponent/map/SwNaverMap';

import {
  timestamp2string,
  getYearList,
  getMonthList,
  commaFormat,
  timestamp2Localestring,
  getCurrentDate,
} from '../../../common/helper';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import { SwUserChangeProgress } from '../../commonComponent/SwUserChangeProgress';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import {
  patchUserChangeComplete,
  patchUserChangeProgress,
} from '../../../common/api/ApiUser';

interface Props {
  type: any;
  userInfo: any;
  rewardInfo: any;
  content: any;
  setContent: Dispatch<React.SetStateAction<any[]>>;
  stepList: any;
}

const TitleTypography = styled(Typography)({
  padding: '.5rem .5rem .5rem .15rem',
  marginBottom: '.33rem',
  fontWeight: 500,
  fontSize: '1rem',
});
const GridRow = styled(Grid)({
  border: '1px solid #E7EDF6',
  backgroundColor: '#F9FAFC',
  borderLeft: 'none',
  padding: '.7em',
});
const GridCell = styled(Grid)({
  border: '1px solid #E7EDF6',
  borderLeft: 'none',
  padding: '.7em',
});

const FIN_OK =
  '<img src="https://walkon-prd.s3.ap-northeast-2.amazonaws.com/v2/image_default_theme/fin_ok.png" alt=22 style="width: 3em">';
const FIN_FAIL =
  '<img src="https://walkon-prd.s3.ap-northeast-2.amazonaws.com/v2/image_default_theme/fin_fail.png" alt=22 style="width: 3em">';

export const UserChallengeDetailWalkingAlongType: React.FC<Props> = ({
  type,
  userInfo,
  rewardInfo,
  content,
  setContent,
  stepList,
}) => {
  const [markers, setMarkers] = useState<any>([]);
  const [postFix, setPostFix] = useState<any>('');
  const [openChangeItem, setOpenChangeItem] = useState<any>(false);
  const [selectedItem, setSelectedItem] = useState<any>();
  const [progress, setProgress] = useState<any>();
  const [filteredSteps, setFilteredSteps] = useState<any>([]);
  const [openChangeResult, setOpenChangeResult] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnakerDialog, setOpenSnakerDialog] = useState<boolean>(false);

  const {
    mutate: userChangeProgressMutate,
    isError: changeProgressMutateError,
  } = useMutation(patchUserChangeProgress, {
    onSuccess: res => {
      console.log('userChangeProgressMutate res: ', res);
      if (res.result === 1) {
        setContent(res.content.content);
        setProgress(res.progress);

        setOpenSnakerDialog(true);
        setDialogMessage('달성률을 수정했습니다.');
      } else {
        setOpenSnakerDialog(true);
        setDialogMessage('달성률을 수정에 실패했습니다');
      }
    },
    onError: error => {
      console.log(error);
      setOpenSnakerDialog(true);
      setDialogMessage(`달성률을 수정하는 동안 오류가 발생했습니다 : ${error}`);
    },
  });

  const {
    mutate: userChangeCompleteMutate,
    isError: changeCompleteMutateError,
  } = useMutation(patchUserChangeComplete, {
    onSuccess: res => {
      console.log('userChangeCompleteMutate res: ', res);
      if (res.result === 1) {
        setContent(res.content.content);
        setOpenSnakerDialog(true);
        setDialogMessage('처리되었습니다.');
        setProgress(res.progress);
      } else {
        setOpenSnakerDialog(true);
        setDialogMessage('처리하는데 실패했습니다');
      }
    },
    onError: error => {
      console.log(error);
      setOpenSnakerDialog(true);
      setDialogMessage(`처리하는동안 오류가 발생했습니다 : ${error}`);
    },
  });

  const onChangeProgress = (date: string, value: number) => {
    const newData = {
      id: userInfo.id,
      day: date,
      progress: value > 100 ? 100 : value,
      challengeId: userInfo.challengeId,
    };
    console.log('onChangeProgress newData :', newData);
    // update content
    // send change progress
    userChangeProgressMutate(newData);
  };

  const onChangeComplete = (item: any, isComplete: boolean) => {
    const newData = {
      id: userInfo.id,
      pointId: item.pointId,
      complete: isComplete ? 1 : 0,
      challengeId: userInfo.challengeId,
    };
    console.log('onChangeComplete newData :', newData);
    // update content
    // send change complete
    userChangeCompleteMutate(newData);
  };

  useEffect(() => {
    console.log('-> rewardInfo:', rewardInfo);
    console.log('-> rewardInfo :', rewardInfo.length);
  }, [rewardInfo]);

  useEffect(() => {
    console.log('-> userInfo:', userInfo);
    setProgress(userInfo.progress);
  }, [userInfo]);

  useEffect(() => {
    console.log('-> content:', content);
    if (
      content[1] !== undefined &&
      content[1].point !== undefined &&
      content[1].point.length > 0
    ) {
      const newData = content[1].point.map((item: any, idx: number) => {
        const newPin = {
          lat: item.latitude,
          lng: item.longitude,
          content: item.isCompletedPoint ? FIN_OK : FIN_FAIL,
        };
        return newPin;
      });
      setPostFix(
        type === 4 || type === 15 || type === 21
          ? '%'
          : type === 7 || type === 14 || type === 20
          ? '개'
          : type === 9 || type === 13 || type === 19
          ? '회'
          : ''
      );
      setMarkers(newData);
    }
    if (
      content[2] !== undefined &&
      content[2].days !== undefined &&
      content[2].days.length > 0
    ) {
      const newFilteredSteps: any[] = content[2].days
        .filter(
          (item: any, idx: number) =>
            getCurrentDate().localeCompare(item.date) !== -1
        )
        .sort((q: any, w: any) => {
          const newA = new Date(q.date);
          const newB = new Date(w.date);
          return newB.getTime() - newA.getTime();
        });
      setFilteredSteps(newFilteredSteps);
    }
  }, [content]);

  return (
    <>
      <Box>
        <TitleTypography>사용자 정보</TitleTypography>
        <Grid container sx={{ borderTop: '1px solid #8899B6' }}>
          <GridRow item xs={3}>
            닉네임
          </GridRow>
          <GridCell item xs={9}>
            {userInfo.name}
          </GridCell>
          <GridRow item xs={3}>
            휴대폰 번호
          </GridRow>
          <GridCell item xs={9}>
            {userInfo.phoneno}
          </GridCell>
          {(type === 19 || type === 20 || type === 21) && (
            <>
              <GridRow item xs={3}>
                청취 콘텐츠 수
              </GridRow>
              <GridCell item xs={9}>
                {userInfo.optionalProgress}
              </GridCell>
            </>
          )}
          <GridRow item xs={3}>
            {type !== 7 && type !== 14 && type !== 18 && type !== 20
              ? '실시간 챌린지 달성률'
              : '달성 스탬프 개수'}
          </GridRow>
          <GridCell item xs={9}>
            {progress}
            {postFix}
          </GridCell>
          <GridRow item xs={3}>
            {type !== 7 && type !== 14 && type !== 18 && type !== 20
              ? '목표 달성률'
              : '총 스탬프 개수'}
          </GridRow>
          <GridCell item xs={9}>
            {type !== 7 &&
            type !== 13 &&
            type !== 14 &&
            type !== 15 &&
            type !== 20
              ? userInfo.goal
              : userInfo.gpsGoal}
            {type !== 9 && type !== 13 && type !== 19 ? postFix : '%'}
          </GridCell>
        </Grid>
      </Box>
      <Box sx={{ m: '1rem 0' }}>
        <Box className='flex_between'>
          <TitleTypography>챌린지 진행 정보</TitleTypography>
          <Box className='flex_end'>
            <img
              src='https://walkon-prd.s3.ap-northeast-2.amazonaws.com/v2/image_default_theme/fin_ok.png'
              alt=''
              style={{ width: '2rem', height: '2rem' }}
            />
            <Typography variant='h5'>달성</Typography>
            <img
              src='https://walkon-prd.s3.ap-northeast-2.amazonaws.com/v2/image_default_theme/fin_fail.png'
              alt=''
              style={{ width: '2rem', height: '2rem' }}
            />
            <Typography variant='h5'>미달성</Typography>
          </Box>
        </Box>
        <Box className='flex_center' style={{ alignItems: 'flex-start' }}>
          {(type === 4 || type === 15 || type === 21) && (
            <>
              <Box sx={{ width: '50%' }}>
                <SwNaverMap markerData={markers} />
              </Box>
              <Box sx={{ width: '50%' }}>
                <Grid
                  container
                  sx={{ borderTop: '1px solid #8899B6', textAlign: 'center' }}
                >
                  <GridRow item xs={6}>
                    순서
                  </GridRow>
                  <GridRow item xs={6}>
                    달성여부
                  </GridRow>
                  {content[1]?.point !== undefined &&
                  content[1]?.point.length > 0 ? (
                    content[1]?.point.map((item: any, idx: number) => (
                      <Fragment key={idx.toString()}>
                        <GridCell item xs={6}>
                          {(idx + 1).toString()}
                        </GridCell>
                        <GridCell className='flex_between' item xs={6}>
                          {item?.isCompletedPoint ? '달성' : '미달성'}
                          {!item?.isCompletedPoint ? (
                            <Button
                              size='small'
                              variant='outlined'
                              color='info'
                              onClick={(evt: any) => {
                                setSelectedItem(item);
                                setOpenChangeResult(true);
                              }}
                            >
                              수정
                            </Button>
                          ) : (
                            <></>
                          )}
                        </GridCell>
                      </Fragment>
                    ))
                  ) : (
                    <Fragment key='0'>
                      <GridCell item xs={12}>
                        콘텐츠가 비어있습니다.
                      </GridCell>
                    </Fragment>
                  )}
                </Grid>
              </Box>
            </>
          )}
          {(type === 7 || type === 14 || type === 18 || type === 20) && (
            <>
              <Box sx={{ width: '50%' }}>
                <SwNaverMap markerData={markers} />
              </Box>
              <Box sx={{ width: '50%' }}>
                <Grid
                  container
                  sx={{ borderTop: '1px solid #8899B6', textAlign: 'center' }}
                >
                  <GridRow item xs={5}>
                    스탬프 이름
                  </GridRow>
                  <GridRow item xs={3}>
                    달성시간
                  </GridRow>
                  <GridRow item xs={4}>
                    달성여부
                  </GridRow>
                  {content[1]?.point !== undefined &&
                  content[1]?.point.length > 0 ? (
                    content[1]?.point.map((item: any, idx: number) => (
                      <Fragment key={idx.toString()}>
                        <GridCell item xs={5}>
                          {item?.required === 1 && (
                            <span
                              style={{
                                color: '#00C36D',
                                backgroundColor: '#11ca711e',
                                padding: '0 0.3rem',
                              }}
                            >
                              필수
                            </span>
                          )}
                          {item?.title}
                        </GridCell>
                        <GridCell item xs={3}>
                          {!item?.isCompletedPoint
                            ? '-'
                            : item?.completeTime !== undefined &&
                              item?.completeTime !== null &&
                              item?.completeTime > 0
                            ? timestamp2Localestring(item?.completeTime, 1)
                            : item?.completedTimestamp !== undefined &&
                              item?.completedTimestamp !== null &&
                              item?.completedTimestamp > 0
                            ? timestamp2Localestring(
                                item?.completedTimestamp,
                                item?.completedTimestamp > 9999999999 ? 1 : 1000
                              )
                            : '-'}
                        </GridCell>
                        <GridCell className='flex_between' item xs={4}>
                          {item?.isCompletedPoint ? '달성' : '미달성'}
                          {!item?.isCompletedPoint ? (
                            <Button
                              size='small'
                              variant='outlined'
                              color='info'
                              onClick={(evt: any) => {
                                setSelectedItem(item);
                                setOpenChangeResult(true);
                              }}
                            >
                              수정
                            </Button>
                          ) : (
                            <></>
                          )}
                        </GridCell>
                      </Fragment>
                    ))
                  ) : (
                    <Fragment key='0'>
                      <GridCell item xs={12}>
                        콘텐츠가 비어있습니다.
                      </GridCell>
                    </Fragment>
                  )}
                </Grid>
              </Box>
            </>
          )}
          {(type === 9 || type === 13 || type === 19) && (
            <>
              <Box sx={{ width: '50%' }}>
                <SwNaverMap markerData={markers} />
              </Box>
              <Box sx={{ width: '50%' }}>
                <Grid
                  container
                  sx={{ borderTop: '1px solid #8899B6', textAlign: 'center' }}
                >
                  <GridRow item xs={4}>
                    달성 여부
                  </GridRow>
                  <GridRow item xs={4}>
                    달성률
                  </GridRow>
                  <GridRow item xs={4}>
                    날짜
                  </GridRow>
                  {content[2]?.days !== undefined &&
                  content[2]?.days.length > 0 ? (
                    content[2]?.days.map((item: any, idx: number) => (
                      <Fragment key={idx.toString()}>
                        <GridCell item xs={4}>
                          {item?.isComplete ? '달성' : '미달성'}
                        </GridCell>
                        <GridCell className='flex_between' item xs={4}>
                          {`${item?.progress}%`}
                          {!item?.isComplete ? (
                            <Button
                              size='small'
                              variant='outlined'
                              color='info'
                              onClick={(evt: any) => {
                                setSelectedItem(item);
                                setOpenChangeItem(true);
                              }}
                            >
                              수정
                            </Button>
                          ) : (
                            <></>
                          )}
                        </GridCell>
                        <GridCell item xs={4}>
                          {item?.date}
                        </GridCell>
                      </Fragment>
                    ))
                  ) : (
                    <Fragment key='0'>
                      <GridCell item xs={12}>
                        콘텐츠가 비어있습니다.
                      </GridCell>
                    </Fragment>
                  )}
                </Grid>
              </Box>
            </>
          )}
        </Box>
      </Box>
      {(type === 13 || type === 14 || type === 15) && (
        <Box sx={{ mt: '2em' }}>
          <TitleTypography>챌린지 진행 정보(걸음수)</TitleTypography>
          <Grid
            container
            sx={{ borderTop: '1px solid #8899B6', textAlign: 'center' }}
          >
            <GridRow item xs={6}>
              날짜
            </GridRow>
            <GridRow item xs={6}>
              걸음 수
            </GridRow>
            {stepList?.map((step: any, idx: number) => (
              <Fragment key={idx.toString()}>
                <GridCell item xs={6}>
                  {step?.day}
                </GridCell>
                <GridCell item xs={6}>
                  {commaFormat(String(step?.step))}
                </GridCell>
              </Fragment>
            ))}
          </Grid>
        </Box>
      )}
      <Box sx={{ mt: '2em' }}>
        <TitleTypography>
          리워드 응모 정보({rewardInfo.draw ? rewardInfo.draw?.length : 0}
          건)
        </TitleTypography>
        {rewardInfo.draw &&
          rewardInfo.draw.map((item: any, idx: number) => (
            <Grid container sx={{ borderTop: '1px solid #8899B6' }}>
              <GridRow item xs={4}>
                쿠폰명
              </GridRow>
              <GridCell item xs={8}>
                {item.rewardTitle}
              </GridCell>
              <GridRow item xs={4}>
                쿠폰 지급 형태
              </GridRow>
              <GridCell item xs={8}>
                {
                  rewardInfo.rewardGivetype[item.rewardGiveType]
                    .challengeRewardGivetypeDescription
                }
              </GridCell>
              <GridRow item xs={4}>
                응모 일시
              </GridRow>
              <GridCell item xs={8}>
                {timestamp2Localestring(item.rewardDrawTime, 1000)}
              </GridCell>
            </Grid>
          ))}
      </Box>
      <Box sx={{ mt: '2em' }}>
        <TitleTypography>
          리워드 발급 정보({rewardInfo.reward ? rewardInfo.reward?.length : 0}
          건)
        </TitleTypography>
        {rewardInfo.reward &&
          rewardInfo.reward.map((item: any, idx: number) => (
            <Grid container sx={{ borderTop: '1px solid #8899B6' }}>
              <GridRow item xs={4}>
                쿠폰명
              </GridRow>
              <GridCell item xs={8}>
                {item.rewardTitle}
              </GridCell>
              <GridRow item xs={4}>
                쿠폰 지급 형태
              </GridRow>
              <GridCell item xs={8}>
                {
                  rewardInfo.rewardGivetype[item.rewardGiveType]
                    .challengeRewardGivetypeDescription
                }
              </GridCell>
              <GridRow item xs={4}>
                응모 일시
              </GridRow>
              <GridCell item xs={8}>
                {item.rewardDrawTime !== null
                  ? timestamp2Localestring(item.rewardDrawTime, 1000)
                  : '-'}
              </GridCell>
              <GridRow item xs={4}>
                발급 일시
              </GridRow>
              <GridCell item xs={8}>
                {item.rewardCreateTime !== null
                  ? timestamp2Localestring(item.rewardCreateTime, 1000)
                  : '-'}
              </GridCell>
              <GridRow item xs={4}>
                사용 일시
              </GridRow>
              <GridCell item xs={8}>
                {item.rewardUsedTime !== null
                  ? timestamp2Localestring(item.rewardUsedTime, 1000)
                  : ''}
              </GridCell>
              <GridRow item xs={4}>
                양식 입력 일시
              </GridRow>
              <GridCell item xs={8}>
                {item.양식입력일시}
              </GridCell>
            </Grid>
          ))}
      </Box>
      <SwConfirmDialog
        contents='수정하시겠습니까?'
        open={openChangeResult}
        onClose={() => setOpenChangeResult(false)}
        onConfirm={() => {
          setOpenChangeResult(false);
          onChangeComplete(selectedItem, true);
        }}
        confirm={1}
      />
      <SwUserChangeProgress
        open={openChangeItem}
        item={selectedItem}
        onClose={() => setOpenChangeItem(false)}
        onChangeItem={onChangeProgress}
      />
      <SwSnackbar
        open={openSnakerDialog}
        onClose={() => setOpenSnakerDialog(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </>
  );
};
