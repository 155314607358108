import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState, Dispatch } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { getProgramTemplateCodeList } from '../../../common/api/ApiProgram';
import { KEY_PROGRAM_TEMPLATE_CODE_LIST } from '../../../common/key';

interface Props {
  open: boolean;
  onClose: () => void;
  linkInfo: any;
  setLinkInfo: Dispatch<React.SetStateAction<any>>;
}

export const SwInputLink: React.FC<Props> = ({
  open,
  onClose,
  linkInfo,
  setLinkInfo,
}) => {
  const [link, setLink] = useState<string>('');
  const [linkType, setLinkType] = useState<string>('url');

  const addLink = () => {
    setLinkInfo((prevState: any) => ({
      ...prevState,
      linkType: linkType,
      link: link,
    }));
    setLink('');
    setLinkType('url');
    onClose();
  };

  return (
    <Dialog
      open={open}
      fullScreen
      sx={{
        '& .MuiDialog-paper': {
          height: '15rem',
          width: '40rem',
        },
      }}
    >
      <DialogTitle>
        <Box className='flex_between'>
          <span>링크 추가하기</span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogContent>
        <TextField
          sx={{ width: '10rem', mr: '1rem' }}
          select
          defaultValue='url'
          value={linkType}
          onChange={evt => setLinkType(evt.target.value)}
        >
          <MenuItem value='url'>URL</MenuItem>
          <MenuItem value='youtube'>유튜브 key</MenuItem>
        </TextField>
        <TextField
          sx={{ width: '25rem' }}
          placeholder={
            linkType === 'url'
              ? '링크(URL)를 입력해 주세요.'
              : '유튜브 링크의 v=XXXXXXXXXX 값을 입력해 주세요.'
          }
          value={link}
          onChange={evt => setLink(evt.target.value)}
        />
      </DialogContent>
      <DialogActions sx={{ p: '1rem' }}>
        <Button
          variant='contained'
          color='info'
          sx={{ fontSize: '.87rem' }}
          onClick={addLink}
        >
          추가
        </Button>
      </DialogActions>
    </Dialog>
  );
};
