import {
  Box,
  Button,
  Collapse,
  IconButton,
  InputAdornment,
  Paper,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useQuery, useQueryClient } from 'react-query';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { SelectSearchBox } from '../../commonComponent/SelectSearchBox';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwSwitch } from '../../styles/Styles';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { AppUpdateGuideForm } from './AppUpdateGuideForm';
import {
  encodeSearchWord,
  timestamp2Localestring,
} from '../../../common/helper';
import { KEY_APP_UPDATE_GUIDE_LIST } from '../../../common/key';
import { getAppUpdateGuideList } from '../../../common/api/ApiAppUpdateManage';

const requestColumnlists = [
  '버전 정보',
  '제목',
  '첫 번째 노출 일시',
  '최대 반복 횟수',
  '대상 정보',
  '대상자 수',
  '작성 일시',
  '상태',
];

interface Props {}

export const AppUpdateGuideInquire: React.FC<Props> = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [requestList, setRequestList] = useState<any>([]);
  const [appUpdateGuideList, setAppUpdateGuideList] = useState<any>([]);
  const [appUpdateGuideInfo, setAppUpdateGuideInfo] = useState<any>();
  const [guideIdx, setGuideIdx] = useState<number>(-1);

  const [checkRequest, setCheckRequest] = useState<boolean>(true);
  const [searchKey, setSearchKey] = useState<string>('appupdateguide');
  const [searchWord, setSearchWord] = useState<string>('');
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [open, setOpen] = useState<boolean>(false);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedRow, setSelectedRow] = useState(-1);

  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<number>(0);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [loading, setLoading] = useState<any>(false);

  const getRecentVersionInfo = (item: any) => {
    const aos = `[안드로이드:${item.aosVersion}]`;
    const ios = `[iOS:${item.iosVersion}]`;
    return item.subType === 0
      ? `${aos},${ios}`
      : item.subType === 1
      ? `${aos}`
      : `${ios}`;
  };

  const getVersionInfo = (item: any) => {
    if (item.type === 1) {
      const aos =
        item.aosMaxVersion !== ''
          ? `[안드로이드:${item.aosMinVersion}~${item.aosMaxVersion}]`
          : `[안드로이드:${item.aosMinVersion}]`;
      const ios =
        item.iosMaxVersion !== ''
          ? `[iOS:${item.iosMinVersion}~${item.iosMaxVersion}]`
          : `[iOS:${item.iosMinVersion}]`;
      return item.subType === 0
        ? `${aos},${ios}`
        : item.subType === 1
        ? `${aos}`
        : `${ios}`;
    }
    return '사용자';
  };

  const {
    data,
    isLoading,
    refetch: appUpdateGuideRefetch,
  } = useQuery(
    KEY_APP_UPDATE_GUIDE_LIST,
    () => {
      const newRequest = {
        startDate: rangeDate[0] !== null ? rangeDate[0].getTime() / 1000 : 0,
        endDate: rangeDate[1] !== null ? rangeDate[1].getTime() / 1000 : 0,
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      if (checkRequest) return getAppUpdateGuideList(newRequest);
      return null;
    },
    {
      onSuccess: res => {
        const newAppUpdateGuide =
          res &&
          res.data &&
          res.data.map((item: any, idx: number) => {
            const newDate = new Date().getTime() / 1000;
            const newRecentVersion = getRecentVersionInfo(item);
            const newVersion = getVersionInfo(item);
            const newCreateDate = timestamp2Localestring(item.createDate, 1000);
            const status =
              item.closed === 1
                ? '종료'
                : item.viewDate < newDate
                ? '진행중'
                : '예약';
            const userCount = JSON.parse(item.usersJson);
            const targetCount =
              item.type === 1
                ? item.aosCount + item.iosCount
                : userCount.length;
            const newViewDate = timestamp2Localestring(item.viewDate, 1000);
            const newData = [
              newRecentVersion,
              item.title,
              newViewDate,
              item.viewRetry,
              newVersion,
              targetCount,
              newCreateDate,
              status,
            ];
            if (guideIdx === item.idx) {
              setSelectedRow(idx);
              setAppUpdateGuideInfo(item);
            }
            return newData;
          });
        setRequestList(newAppUpdateGuide);
        setAppUpdateGuideList(res ? res.data : []);
        setTotalRecords(res.pagination.totalRecordCount);
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const appUpdateGuidePrefetch = (newPage: number) => {
    queryClient.prefetchQuery(KEY_APP_UPDATE_GUIDE_LIST, () => {
      const newData = {
        startDate: rangeDate[0] !== null ? rangeDate[0].getTime() / 1000 : 0,
        endDate: rangeDate[1] !== null ? rangeDate[1].getTime() / 1000 : 0,
        page: newPage,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      return getAppUpdateGuideList(newData);
    });
  };

  const chooseRequest = (i: number) => {
    setSelectedRow(i);
    setGuideIdx(appUpdateGuideList[i].idx);
    setAppUpdateGuideInfo(appUpdateGuideList[i]);
  };

  const searchTarget = () => {
    setPage(1);
    appUpdateGuidePrefetch(1);
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setSelectedRow(-1);
    setPage(newPage);
    appUpdateGuidePrefetch(newPage);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchTarget();
    }
  };

  const onReload = (idx: number) => {
    if (idx !== null && idx !== -1) {
      setGuideIdx(idx);
      appUpdateGuideRefetch();
    }
  };

  useEffect(() => {
    if (
      (rangeDate[0] !== null && rangeDate[1] !== null) ||
      (rangeDate[0] === null && rangeDate[1] === null)
    )
      searchTarget();
  }, [rangeDate]);

  return (
    <>
      <Box className='flex_between' sx={{ m: '1.5rem 0' }}>
        <Box>
          <TextField
            placeholder='안내 팝업 제목, 내용으로 검색해 주세요'
            value={searchWord}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setSearchWord(evt.target.value)}
            onKeyDown={handleKeyDown}
            sx={{ width: '30rem', mr: '1em', mt: '1rem' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton sx={{ pr: 0 }} onClick={searchTarget}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box sx={{ mr: '2rem' }} className='flex_between'>
          <Box className='flex'>
            <Collapse orientation='horizontal' in={openFilter}>
              <SwDateRangePicker
                rangeDate={rangeDate}
                setRangeDate={setRangeDate}
              />
            </Collapse>
            <Button
              color='info'
              variant='outlined'
              sx={{ ml: '1rem' }}
              onClick={() => setOpenFilter(!openFilter)}
            >
              <FilterAltOutlinedIcon />
              필터
              <ArrowBackIosNewOutlinedIcon
                sx={{ fontSize: '1rem', ml: '.5rem' }}
              />
            </Button>
          </Box>
          {/* <Button
            color='info'
            variant='outlined'
            sx={{ ml: '1rem' }}
            onClick={() => onDownloadProgramList()}
          >
            데이터 다운로드
            <FileDownloadRoundedIcon color='success' />
          </Button> */}
        </Box>
      </Box>
      <Paper sx={{ m: '1.5rem 0', p: '1.5rem' }}>
        <Box className='flex_start'>
          <Box>
            <Typography>
              안내 팝업 <span className='bluecontent'>{totalRecords}</span> 개
            </Typography>
          </Box>
        </Box>
        <TableContainer
          sx={{ p: '0rem', mt: '1rem', height: '30vh', overflowY: 'scroll' }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {requestColumnlists.map((column, idx) => (
                  <TableCell key={idx.toString()} sx={{ p: '.4rem .6rem' }}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {requestList?.map((row: any, idx: number) => (
                <TableRow
                  key={idx.toString()}
                  onClick={() => chooseRequest(idx)}
                  className={
                    selectedRow === idx ? 'selected_row' : 'whitecolor'
                  }
                >
                  {row.map((rowItem: any, rowIdx: number) => (
                    <TableCell
                      key={rowIdx.toString()}
                      className={
                        appUpdateGuideList[idx].status === 0
                          ? 'not_important_row'
                          : ''
                      }
                      sx={{ maxWidth: '25rem' }}
                    >
                      {rowIdx !== 1
                        ? row[rowIdx]
                        : appUpdateGuideList[idx].status === 0
                        ? `${row[rowIdx]} (close)`
                        : row[rowIdx]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <SwPagination
          page={page}
          handleChangePage={handleChangePage}
          count={Math.ceil(totalRecords / rowsPerPage)}
        />
      </Paper>
      <AppUpdateGuideForm
        appUpdateGuideInfo={appUpdateGuideInfo}
        onReload={onReload}
      />
    </>
  );
};
