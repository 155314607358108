import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import React, {
  ChangeEvent,
  DragEvent,
  Dispatch,
  MouseEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router';
import moment from 'moment';
import { useSession, xapi } from '../../../hooks/session';
import { debouncehook } from '../../../hooks/debouncehook';
import {
  AddGroupTextField,
  CreatePaper,
  DraggablePaper,
  RowsCell,
  MultilineTextField,
  NoneBorderChip,
  TermPaper,
} from '../../styles/Styles';
import { newUuidId, SwUuidCreate } from '../../commonComponent/SwUuidCreate';
import { SwImageSetting } from '../../commonComponent/SwImageSetting';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { DataportalMultiConnect } from '../../commonComponent/DataportalMultiConnect';
import useLocalStorage from '../../../hooks/storage';
import {
  checkNaN,
  commaFormat,
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import {
  ButtonBox,
  TemplateCodeBox,
  TemplateCodeTitle,
  ContentTitle,
  MarginButton,
  GrayTypography,
} from './ContentManageStyles';
import { IUploadFile } from '../../../models/common';
import { postUploadFile } from '../../../common/api/ApiCommon';
import { registerContentManageContent } from '../../../common/api/ApiContentManage';
import { RESULT_OK } from '../../../common/resultCode';
import { uploadFile } from '../../../common/api/ApiUpload';
import { SwSearchTarget } from '../../commonComponent/SwSearchTarget';
import { CommunityConnect } from '../../commonComponent/CommunityConnect';
import { SwSelectTags } from '../../commonComponent/SwSelectTags';
import {
  authAutoType,
  authAvailableTime,
  authPictureType,
  getAuthAvailableTime,
  getTimeArray,
} from '../../commonComponent/program/SwProgramCommon';

interface Props {
  contentManageInfo: any;
  setContentManageInfo: Dispatch<React.SetStateAction<any>>;
  reloadContentManage: (id: any) => void;
}

export const ContentManageContentLifeStyleForm: React.FC<Props> = ({
  contentManageInfo,
  setContentManageInfo,
  reloadContentManage,
}) => {
  const location = useLocation();
  const { loginVal } = useSession();
  const [categoryId, setCategoryId] = useState<string>('');
  const [parentCategoryId, setParentCategoryId] = useState<string>('');
  const [contentId, setContentId] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [coverImage1, setCoverImage1] = useState<string>('');
  const [coverFile1, setCoverFile1] = useState<any>(null);
  const [coverImage2, setCoverImage2] = useState<string>('');
  const [coverFile2, setCoverFile2] = useState<any>(null);
  const [authTime, setAuthTime] = useState<string>('unlimit');
  const [authDirect1, setAuthDirect1] = useState<string>('00:00');
  const [authDirect2, setAuthDirect2] = useState<string>('00:00');
  const [authType, setAuthType] = useState<string>('auth-picture');
  const [authPicture, setAuthPicture] = useState<string>('required');
  const [authAuto, setAuthAuto] = useState<string>('step');
  const [authAutoValue, setAuthAutoValue] = useState<string>('0');
  const [authCode, setAuthCode] = useState<string>('');
  const [authGuide, setAuthGuide] = useState<string>('');
  const [authSuccessImage1, setAuthSuccessImage1] = useState<string>('');
  const [authSuccessFile1, setAuthSuccessFile1] = useState<any>(null);
  const [authSuccessImage2, setAuthSuccessImage2] = useState<string>('');
  const [authSuccessFile2, setAuthSuccessFile2] = useState<any>(null);
  const [authFailImage1, setAuthFailImage1] = useState<string>('');
  const [authFailFile1, setAuthFailFile1] = useState<any>(null);
  const [authFailImage2, setAuthFailImage2] = useState<string>('');
  const [authFailFile2, setAuthFailFile2] = useState<any>(null);
  const [imageUrlPrefix, setImageUrlPrefix] = useState<any>(
    loginVal.value.user.imageUrlPrefix
  );
  const [status, setStatus] = useState<number>(1);
  const [seq, setSeq] = useState<number>(0);
  const [selectedContentManage, setSelectedContentManage] =
    useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const {
    mutate: registerContentManageContentMutation,
    isError: isMutateError,
  } = useMutation(registerContentManageContent, {
    onSuccess: res => {
      console.log('registerContentManageContent res:', res);
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setAlertMessage('저장되었습니다.');
        reloadContentManage({
          id: categoryId,
          parentCategoryId: parentCategoryId,
        });
      } else {
        setAlertOpen(true);
        setAlertMessage(
          `저장하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setAlertMessage(
        '콘텐츠를 생성하는동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const addContentManageContent = (newUploadUrl: any) => {
    let isValid = 0;

    const newAuthType =
      authType === 'auth-picture'
        ? 1
        : authType === 'optional-auth'
        ? 2
        : authType === 'simple-auth'
        ? 3
        : authType === 'auth-auto'
        ? 4
        : authType === 'code-auth'
        ? 5
        : 1;

    const newAuthTime = authAvailableTime
      .map((option: any, idx: number) => {
        const value =
          option.type === authTime && option.type !== 'direct'
            ? idx + 1
            : option.type === authTime && option.type === 'direct'
            ? 99
            : -1;
        return value;
      })
      .filter(option => option !== -1);
    const newDirect1 =
      authTime !== 'direct'
        ? getAuthAvailableTime(authTime)[0]
        : Number(authDirect1.split(':')[0]);
    const newDirect2 =
      authTime !== 'direct'
        ? getAuthAvailableTime(authTime)[1]
        : Number(authDirect2.split(':')[0]);
    console.log('newAuthTime: ', newAuthTime, ', authTime:', authTime);

    const newAuthAuto = authAutoType
      .map((item: any, idx: number) => (item.type === authAuto ? idx + 1 : -1))
      .filter((item: number) => item > 0);

    let newImage = JSON.stringify([]);
    let newFailImage = JSON.stringify([]);
    let newSuccessImage = JSON.stringify([]);

    const newCoverImage1 =
      coverFile1 !== null
        ? newUploadUrl.uploadUrl1
        : coverImage1.length > 0
        ? coverImage1
        : '';
    const newCoverImage2 =
      coverFile2 !== null
        ? newUploadUrl.uploadUrl2
        : coverImage2.length > 0
        ? coverImage2
        : '';
    const newAuthSuccessImage1 =
      authSuccessFile1 !== null
        ? newUploadUrl.successUploadUrl1
        : authSuccessImage1.length > 0
        ? authSuccessImage1
        : '';
    const newAuthSuccessImage2 =
      authSuccessFile2 !== null
        ? newUploadUrl.successUploadUrl2
        : authSuccessImage2.length > 0
        ? authSuccessImage2
        : '';
    const newAuthFailImage1 =
      authFailFile1 !== null
        ? newUploadUrl.failUploadUrl1
        : authFailImage1.length > 0
        ? authFailImage1
        : '';
    const newAuthFailImage2 =
      authFailFile2 !== null
        ? newUploadUrl.failUploadUrl2
        : authFailImage2.length > 0
        ? authFailImage1
        : '';
    newImage = JSON.stringify([newCoverImage1, newCoverImage2]);
    newSuccessImage = JSON.stringify([
      newAuthSuccessImage1,
      newAuthSuccessImage2,
    ]);
    newFailImage = JSON.stringify([newAuthFailImage1, newAuthFailImage2]);

    if (isValid === 0 && title.length === 0) {
      isValid = 1;
    }

    if (isValid === 0) {
      const newIdx =
        contentManageInfo.idx !== undefined && contentManageInfo.idx !== null
          ? contentManageInfo.idx
          : -1;
      const newQuestionData: any = {
        idx: newIdx,
        type: 'lifestyle',
        contentType: contentManageInfo.type,
        categoryId: categoryId,
        contentId: contentId,
        title: title,
        description: description,
        image: newImage,
        certificateStartTime: newDirect1,
        certificateEndTime: newDirect2,
        certificateTimeType: newAuthTime !== undefined ? newAuthTime[0] : 0,
        certificateType: newAuthType,
        certificateTypeItem: newAuthType === 4 ? newAuthAuto[0] : 0,
        certificateTypeItemValue:
          newAuthType === 4
            ? authAutoValue
            : newAuthType === 5
            ? authCode
            : '0',
        certificateDesc: authGuide,
        certificateSuccessImage: newSuccessImage,
        certificateFailImage: newFailImage,
        status: status,
        seq: seq,
      };
      console.log('registerContentManageQnaQuestion :', newQuestionData);
      registerContentManageContentMutation(newQuestionData);
    } else {
      var message = '';
      switch (isValid) {
        case 1:
          message = '이름을 입력해 주세요';
          break;
        case 7:
          message = '커버 이미지를 등록해 주세요.';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
      setOpenSnackbar(true);
      setDialogMessage(message);
    }
  };

  const checkValid = async () => {
    let skipUpload = false;
    if (
      coverFile1 === null &&
      coverFile2 === null &&
      authSuccessFile1 === null &&
      authSuccessFile2 === null &&
      authFailFile1 === null &&
      authFailFile2 === null
    )
      skipUpload = true;

    if (skipUpload) {
      addContentManageContent(null);
    } else {
      var newUploadUrl1 = '';
      if (coverFile1 !== null) {
        const newUploadFile: any = await uploadFile('program', coverFile1);
        newUploadUrl1 = newUploadFile.data.uploadUrl.file.path;
      }

      var newUploadUrl2 = '';
      if (coverFile2 !== null) {
        const newUploadFile: any = await uploadFile('program', coverFile2);
        newUploadUrl2 = newUploadFile.data.uploadUrl.file.path;
      }

      var newSuccessUploadUrl1 = '';
      if (authSuccessFile1 !== null) {
        const newUploadFile: any = await uploadFile(
          'program',
          authSuccessFile1
        );
        newSuccessUploadUrl1 = newUploadFile.data.uploadUrl.file.path;
      }

      var newSuccessUploadUrl2 = '';
      if (authSuccessFile2 !== null) {
        const newUploadFile: any = await uploadFile(
          'program',
          authSuccessFile2
        );
        newSuccessUploadUrl2 = newUploadFile.data.uploadUrl.file.path;
      }

      var newFailUploadUrl1 = '';
      if (authFailFile1 !== null) {
        const newUploadFile: any = await uploadFile('program', authFailFile1);
        newFailUploadUrl1 = newUploadFile.data.uploadUrl.file.path;
      }

      var newFailUploadUrl2 = '';
      if (authFailFile2 !== null) {
        const newUploadFile: any = await uploadFile('program', authFailFile2);
        newFailUploadUrl2 = newUploadFile.data.uploadUrl.file.path;
      }

      const newUploadUrl = {
        uploadUrl1: newUploadUrl1,
        uploadUrl2: newUploadUrl2,
        successUploadUrl1: newSuccessUploadUrl1,
        successUploadUrl2: newSuccessUploadUrl2,
        failUploadUrl1: newFailUploadUrl1,
        failUploadUrl2: newFailUploadUrl2,
      };

      addContentManageContent(newUploadUrl);
    }
  };

  const handleChangeAuthType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAuthType((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    if (
      contentManageInfo.contentId !== undefined &&
      contentManageInfo.contentId !== null
    ) {
      setTitle(contentManageInfo.title);
      setDescription(contentManageInfo.description);
      setAuthGuide(contentManageInfo.certificateDesc);
      setStatus(contentManageInfo.status === false ? 0 : 1);
      setSeq(contentManageInfo.seq);

      const newAuthType =
        contentManageInfo.certificateType === 1
          ? 'auth-picture'
          : contentManageInfo.certificateType === 2
          ? 'optional-auth'
          : contentManageInfo.certificateType === 3
          ? 'simple-auth'
          : contentManageInfo.certificateType === 4
          ? 'auth-auto'
          : contentManageInfo.certificateType === 5
          ? 'code-auth'
          : 'required-auth';

      setAuthType(newAuthType);
      if (newAuthType === 'auth-auto') {
        const newAuthAuto = authAutoType.filter(
          (item: any, idx: number) =>
            idx + 1 === contentManageInfo.certificateTypeItem
        );
        setAuthAuto(
          newAuthAuto !== undefined && newAuthAuto !== null
            ? newAuthAuto[0].type
            : ''
        );
        setAuthAutoValue(contentManageInfo.certificateTypeItemValue);
      } else if (newAuthType === 'code-auth') {
        setAuthCode(contentManageInfo.certificateTypeItemValue);
      }

      const newAuthTime =
        contentManageInfo.certificateTimeType === 99
          ? 'direct'
          : contentManageInfo.certificateTimeType === 1
          ? 'unlimit'
          : contentManageInfo.certificateTimeType === 2
          ? 'time_06'
          : contentManageInfo.certificateTimeType === 3
          ? 'time_11'
          : contentManageInfo.certificateTimeType === 4
          ? 'time_14'
          : contentManageInfo.certificateTimeType === 5
          ? 'time_18'
          : 'time_00';
      setAuthTime(newAuthTime);
      if (contentManageInfo.certificateTimeType === 99) {
        const newDirect1 = Math.floor(contentManageInfo.certificateStartTime);
        const newDirect2 = Math.floor(contentManageInfo.certificateEndTime);
        setAuthDirect1(`${newDirect1.toString().padStart(2, '0')}:00`);
        setAuthDirect2(`${newDirect2.toString().padStart(2, '0')}:00`);
      }

      const newCoverImage =
        contentManageInfo.image !== undefined &&
        contentManageInfo.image !== null
          ? JSON.parse(contentManageInfo.image)
          : ['', ''];
      const newSuccessImage =
        contentManageInfo.certificateSuccessImage !== undefined &&
        contentManageInfo.certificateSuccessImage !== null
          ? JSON.parse(contentManageInfo.certificateSuccessImage)
          : ['', ''];
      const newFailImage =
        contentManageInfo.certificateFailImage !== undefined &&
        contentManageInfo.certificateFailImage !== null
          ? JSON.parse(contentManageInfo.certificateFailImage)
          : ['', ''];
      setCoverImage1(newCoverImage.length > 0 ? newCoverImage[0] : '');
      setCoverImage2(newCoverImage.length > 1 ? newCoverImage[1] : '');
      setAuthSuccessImage1(
        newSuccessImage.length > 0 ? newSuccessImage[0] : ''
      );
      setAuthSuccessImage2(
        newSuccessImage.length > 1 ? newSuccessImage[1] : ''
      );
      setAuthFailImage1(newFailImage.length > 0 ? newFailImage[0] : '');
      setAuthFailImage2(newFailImage.length > 1 ? newFailImage[1] : '');
    } else {
      setTitle('');
      setDescription('');
      setAuthGuide('');
      setAuthType('auth-picture');
      setAuthAuto('step');
      setAuthAutoValue('0');
      setAuthCode('');
      setCoverImage1('');
      setCoverImage2('');
      setAuthSuccessImage1('');
      setAuthSuccessImage2('');
      setAuthFailImage1('');
      setAuthFailImage2('');
      setCoverFile1(null);
      setCoverFile2(null);
      setAuthSuccessFile1(null);
      setAuthSuccessFile2(null);
      setAuthFailFile1(null);
      setAuthFailFile2(null);
      setStatus(1);
      setSeq(1);
    }
  }, [contentId]);

  useEffect(() => {
    console.log('contentManageInfo -> :', contentManageInfo);
    if (contentManageInfo !== undefined && contentManageInfo !== null) {
      if (contentManageInfo.save) {
        checkValid();
      }
      setContentId(
        contentManageInfo.contentId !== undefined &&
          contentManageInfo.contentId !== null
          ? contentManageInfo.contentId
          : newUuidId()
      );
      setCategoryId(
        contentManageInfo.categoryId !== undefined &&
          contentManageInfo.categoryId !== null
          ? contentManageInfo.categoryId
          : ''
      );
      setParentCategoryId(
        contentManageInfo.parentCategoryId !== undefined &&
          contentManageInfo.parentCategoryId !== null
          ? contentManageInfo.parentCategoryId
          : ''
      );
    }
  }, [contentManageInfo]);

  return (
    <>
      <CreatePaper>
        <Box>
          <Typography className='title'>생활습관 제목</Typography>
          <TextField
            sx={{ width: '48rem' }}
            value={title}
            onChange={(
              evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setTitle(evt.target.value.substring(0, 30))}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <span>{title?.length} / 30</span>
                </InputAdornment>
              ),
            }}
          />
          <Divider />
        </Box>
        <Box>
          <Box className='flex_between'>
            <Typography className='title'>생활습관 설명</Typography>
          </Box>
          <TextField
            placeholder='생활습관 미션에 대해 기본 정보를 입력합니다.'
            value={description}
            fullWidth
            multiline
            rows={5}
            sx={{ '& .MuiOutlinedInput-root': { height: 'auto', mt: '1rem' } }}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setDescription(evt.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <span>{description?.length} / 1000</span>
                </InputAdornment>
              ),
            }}
          />
          <Box className='flex_start'>
            <Box sx={{ mr: '1rem' }}>
              <SwFileSetting
                setImage={setCoverImage1}
                image={
                  coverFile1 !== null
                    ? coverImage1
                    : coverImage1 !== ''
                    ? `${imageUrlPrefix}${coverImage1}`
                    : ''
                }
                setFile={setCoverFile1}
                file={coverFile1}
              />
            </Box>
            <Box>
              <SwFileSetting
                setImage={setCoverImage2}
                image={
                  coverFile2 !== null
                    ? coverImage2
                    : coverImage2 !== ''
                    ? `${imageUrlPrefix}${coverImage2}`
                    : ''
                }
                setFile={setCoverFile2}
                file={coverFile2}
              />
            </Box>
          </Box>
          <Divider />
          <Box>
            <Typography className='title'>인증 가능 시간</Typography>
            <TextField
              sx={{ width: '16rem', mr: '1rem' }}
              select
              defaultValue='unlimit'
              value={authTime}
              onChange={evt => setAuthTime(evt.target.value)}
            >
              {authAvailableTime.map(option => (
                <MenuItem key={`authtime_${option.type}`} value={option.type}>
                  {option.title}
                </MenuItem>
              ))}
            </TextField>
            {authTime === 'direct' && (
              <Box>
                <TextField
                  sx={{ width: '8rem' }}
                  select
                  value={authDirect1}
                  onChange={evt => setAuthDirect1(evt.target.value)}
                >
                  {getTimeArray().map(option => (
                    <MenuItem key={`auth_direct1_${option}`} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
                ~
                <TextField
                  sx={{ width: '8rem' }}
                  select
                  value={authDirect2}
                  onChange={evt => setAuthDirect2(evt.target.value)}
                >
                  {getTimeArray().map(option => (
                    <MenuItem
                      disabled={option <= authDirect1}
                      key={`auth_direct2_${option}`}
                      value={option}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            )}
            <Divider />
          </Box>
          <Box>
            <Typography className='title'>인증수단</Typography>
            <Box className='flex_start'>
              <Box className='flex_start'>
                <Checkbox
                  checked={authType === 'auth-picture'}
                  icon={<RadioButtonUncheckedIcon sx={{ color: '#9EA2A9' }} />}
                  checkedIcon={
                    <RadioButtonCheckedIcon sx={{ color: '#00A2FF' }} />
                  }
                  onChange={e => {
                    if (e.currentTarget.checked) {
                      setAuthType('auth-picture');
                    }
                  }}
                />
                <Typography className='title'>사진 인증 (촬영 필수)</Typography>
              </Box>
              <Box className='flex_start' sx={{ ml: '3rem' }}>
                <Checkbox
                  checked={authType === 'optional-auth'}
                  icon={<RadioButtonUncheckedIcon sx={{ color: '#9EA2A9' }} />}
                  checkedIcon={
                    <RadioButtonCheckedIcon sx={{ color: '#00A2FF' }} />
                  }
                  onChange={e => {
                    if (e.currentTarget.checked) {
                      setAuthType('optional-auth');
                    }
                  }}
                />
                <Typography className='title'>
                  선택 인증 (촬영 선택사항)
                </Typography>
              </Box>
              <Box className='flex_start' sx={{ ml: '3rem' }}>
                <Checkbox
                  checked={authType === 'simple-auth'}
                  icon={<RadioButtonUncheckedIcon sx={{ color: '#9EA2A9' }} />}
                  checkedIcon={
                    <RadioButtonCheckedIcon sx={{ color: '#00A2FF' }} />
                  }
                  onChange={e => {
                    if (e.currentTarget.checked) {
                      setAuthType('simple-auth');
                    }
                  }}
                />
                <Typography className='title'>
                  간편 인증(클릭하여 인증)
                </Typography>
              </Box>
              <Box className='flex_start' sx={{ ml: '3rem' }}>
                <Checkbox
                  checked={authType === 'auth-auto'}
                  icon={<RadioButtonUncheckedIcon sx={{ color: '#9EA2A9' }} />}
                  checkedIcon={
                    <RadioButtonCheckedIcon sx={{ color: '#00A2FF' }} />
                  }
                  onChange={e => {
                    if (e.currentTarget.checked) {
                      setAuthType('auth-auto');
                    }
                  }}
                />
                <Typography className='title'>자동 인증</Typography>
                <TextField
                  sx={{ width: '8rem', ml: '1rem' }}
                  select
                  value={authAuto}
                  onChange={evt => setAuthAuto(evt.target.value)}
                >
                  {authAutoType
                    .filter(option => option.type === 'step')
                    .map(option => (
                      <MenuItem
                        key={`auth_auto_${option.type}`}
                        value={option.type}
                      >
                        {option.title}
                      </MenuItem>
                    ))}
                </TextField>
                {authType === 'auth-auto' && (
                  <TextField
                    value={authAutoValue}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        height: 'auto',
                        ml: '1rem',
                      },
                    }}
                    onChange={(
                      evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                    ) => {
                      setAuthAutoValue(
                        `${checkNaN(evt.target.value, Number(authAutoValue))}`
                      );
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <span>{authAuto === 'step' ? '보' : '분'}</span>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </Box>
              <Box className='flex_start' sx={{ ml: '3rem' }}>
                <Checkbox
                  checked={authType === 'code-auth'}
                  icon={<RadioButtonUncheckedIcon sx={{ color: '#9EA2A9' }} />}
                  checkedIcon={
                    <RadioButtonCheckedIcon sx={{ color: '#00A2FF' }} />
                  }
                  onChange={e => {
                    if (e.currentTarget.checked) {
                      setAuthType('code-auth');
                    }
                  }}
                />
                <Typography className='title'>
                  코드 인증(암호 입력 인증)
                </Typography>
                {authType === 'code-auth' && (
                  <TextField
                    value={authCode}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        height: 'auto',
                        ml: '1rem',
                      },
                    }}
                    onChange={(
                      evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                    ) => {
                      setAuthCode(evt.target.value.substring(0, 8));
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <span>{authCode.length} / 8</span>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </Box>
            </Box>
            <Divider />
          </Box>
          <Box>
            <Typography className='title'>미션 인증 설명</Typography>
            <TextField
              fullWidth
              multiline
              rows={5}
              onChange={evt => setAuthGuide(evt.target.value.substring(0, 500))}
              value={authGuide}
              placeholder='생활습관 미션을 인증할 때 지켜야할 조건을 입력합니다.'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <span>{authGuide?.length} / 500</span>
                  </InputAdornment>
                ),
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: 'auto',
                  padding: '.5rem',
                },
              }}
            />
          </Box>
          {authType !== 'auth-auto' && (
            <>
              <Box className='flex_between'>
                <Stack
                  direction='row'
                  divider={
                    <Divider orientation='vertical' flexItem sx={{ m: 0 }} />
                  }
                  spacing={2}
                >
                  <Box>
                    <Typography className='title'>인증 성공 이미지</Typography>
                    <Box className='flex_between'>
                      <Box sx={{ mr: '1rem' }}>
                        <SwFileSetting
                          setImage={setAuthSuccessImage1}
                          image={
                            authSuccessFile1 !== null
                              ? authSuccessImage1
                              : authSuccessImage1 !== ''
                              ? `${imageUrlPrefix}${authSuccessImage1}`
                              : ''
                          }
                          setFile={setAuthSuccessFile1}
                          file={authSuccessFile1}
                        />
                      </Box>
                      <Box>
                        <SwFileSetting
                          setImage={setAuthSuccessImage2}
                          image={
                            authSuccessFile2 !== null
                              ? authSuccessImage2
                              : authSuccessImage2 !== ''
                              ? `${imageUrlPrefix}${authSuccessImage2}`
                              : ''
                          }
                          setFile={setAuthSuccessFile2}
                          file={authSuccessFile2}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Typography className='title'>인증 실패 이미지</Typography>
                    <Box className='flex_between'>
                      <Box sx={{ mr: '1rem' }}>
                        <SwFileSetting
                          setImage={setAuthFailImage1}
                          image={
                            authFailFile1 !== null
                              ? authFailImage1
                              : authFailImage1 !== ''
                              ? `${imageUrlPrefix}${authFailImage1}`
                              : ''
                          }
                          setFile={setAuthFailFile1}
                          file={authFailFile1}
                        />
                      </Box>
                      <Box>
                        <SwFileSetting
                          setImage={setAuthFailImage2}
                          image={
                            authFailFile2 !== null
                              ? authFailImage2
                              : authFailImage2 !== ''
                              ? `${imageUrlPrefix}${authFailImage2}`
                              : ''
                          }
                          setFile={setAuthFailFile2}
                          file={authFailFile2}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Stack>
              </Box>
              <GrayTypography>*1:1(정사각) 비율 권장</GrayTypography>
              <GrayTypography>
                *용량 10MB 이하의 JPG, PNG만 업로드 가능
              </GrayTypography>
            </>
          )}
        </Box>
        <Divider />
        <Box>
          <Box className='flex_between'>
            <Typography className='title'>순서</Typography>
          </Box>
          <TextField
            value={seq}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setSeq(checkNaN(evt.target.value, seq))}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>상태</Typography>
          <TextField
            sx={{ width: '12rem' }}
            select
            defaultValue={1}
            value={status}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setStatus(Number(evt.target.value))}
          >
            <MenuItem key={0} value={0}>
              Close
            </MenuItem>
            <MenuItem key={1} value={1}>
              Open
            </MenuItem>
          </TextField>
          <Divider />
        </Box>
      </CreatePaper>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={alertMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={alertMessage}
      />
    </>
  );
};
