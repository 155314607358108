import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ChangeEvent, Dispatch, useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useMany } from '../../hooks/api';
import { getTarget } from '../../common/api/ApiCommon';
import {
  KEY_CHALLENGE_COMMUNITY_FILTER_LIST,
  KEY_TARGET_LIST,
} from '../../common/key';
import SwLoading from './spinner/SwLoading';
import { encodeSearchWord } from '../../common/helper';
import { getChallengeCommunityFilterList } from '../../common/api/ApiChallenge';
import { Item } from '../localstore/localstoreManagement/LocalstoreStyles';

interface Props {
  title: string;
  searchKey: string;
  searchWord: string;
  communityFilter?: boolean; // 그룹 없이 커뮤니티로만 검색 여부
  targetData?: any[];
  open: boolean;
  onClose: () => void;
  setTarget: Dispatch<React.SetStateAction<any[]>>;
}

const columns = ['타입', '이름', '아이디'];

const ChosenItemPaper = styled(Paper)({
  margin: '1rem 0 0',
  padding: '1rem',
  widht: '100%',
  height: '10vh',
  overflowY: 'scroll',
});

const SelectChip = styled(Chip)({
  margin: '.5em',
  color: '#00A2FF',
  backgroundColor: 'rgba(0, 162, 255, 0.1)',
  borderColor: 'rgba(0, 162, 255, 0.5)',
  '& .MuiChip-deleteIcon': {
    fontSize: '1rem',
  },
});

export const SwSearchCommunityFilter: React.FC<Props> = ({
  title,
  open,
  onClose,
  searchKey,
  searchWord,
  communityFilter = false,
  targetData,
  setTarget,
}) => {
  const inputRef = useRef<any>();
  const queryClient = useQueryClient();
  const [targetList, setTargetList] = useState<any>([]);
  const [loadReq, setLoadReq] = useState<boolean>(true);
  const [checkedTargets, setCheckedTargets] = useState<any[]>([]);
  const [localSearchWord, setLocalSearchWord] = useState<string>('');
  const [checkedAll, setCheckedAll] = useState<boolean>(false);

  const {
    data,
    isLoading,
    refetch: challengeCommunityFilterRefetch,
  } = useQuery(
    KEY_CHALLENGE_COMMUNITY_FILTER_LIST,
    () => {
      const newData = {
        type: 'community',
        searchKey: searchKey,
        searchWord: localSearchWord,
        communityFilter,
      };
      if (open) return getChallengeCommunityFilterList(newData);
      return null;
    },
    {
      onSuccess: res => {
        if (res !== null) {
          setTargetList(res.filter);
        }
        setLoadReq(false);
      },
      onError: e => {
        setLoadReq(false);
        console.log(e);
      },
    }
  );

  const saveItem = () => {
    const newTarget = targetList.filter((target: any) =>
      checkedTargets.includes(target.targetId)
    );

    setTarget(newTarget);
    onClose();
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedTargets([]);
    if (event.target.checked) {
      setCheckedAll(true);
      targetList.map((row: any, idx: number) => {
        setCheckedTargets(current => [...current, row.targetId]);
      });
    } else {
      setCheckedAll(false);
    }
  };

  const changeHandler = (checked: boolean, item: any) => {
    var newItems: string[] = [];
    if (checked) {
      newItems = [...checkedTargets, item.targetId];
      setCheckedTargets(newItems);
    } else {
      newItems = checkedTargets.filter(target => target !== item.targetId);
      setCheckedTargets(newItems);
    }

    const newTargets: string[] = targetList.map(
      (target: any) => target.targetId
    );
    const difference = newTargets.filter(target => !newItems.includes(target));
    difference.length > 0 ? setCheckedAll(false) : setCheckedAll(true);
  };

  const getLabel = (target: string) => {
    const newTarget = targetList.filter(
      (item: any) => item.targetId === target
    );
    return newTarget.length > 0 ? newTarget[0].communityName : '-';
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      setLocalSearchWord(inputRef.current?.value);
    }
  };

  useEffect(() => {
    if (localSearchWord !== null && localSearchWord.length > 0) {
      inputRef.current.value = localSearchWord;
      setLoadReq(true);
      challengeCommunityFilterRefetch();
    }
  }, [localSearchWord]);

  useEffect(() => {
    if (open) {
      setLocalSearchWord(searchWord);
      // if (targetData !== undefined && targetData.length > 0) {
      //   const newData = targetData.filter(
      //     (item: any) =>
      //       item.id.includes(searchWord) || item.name.includes(searchWord)
      //   );
      //   setTargetList(newData);
      // } else {
      //   setLoadReq(true);
      //   setTargetList([]);
      //   challengeCommunityFilterRefetch();
      // }
    } else {
      setLoadReq(false);
      setLocalSearchWord('');
    }
    setCheckedTargets([]);
    setCheckedAll(false);
    return () => {};
  }, [open]);

  return (
    <Dialog
      open={open}
      fullScreen
      sx={{
        '& .MuiDialog-paper': {
          height: '66vh',
          margin: '30rem',
        },
      }}
    >
      <DialogTitle>
        <Box className='flex_between'>
          <span>
            {searchWord.length > 0 && `'${searchWord}'`} {title} 검색결과
          </span>
          <Box className='flex_start'>
            <Box sx={{ mr: '2rem' }}>
              <TextField
                sx={{ width: '22rem', mb: 0, mr: '.3rem' }}
                placeholder='커뮤니티 아이디로 검색해 주세요.'
                inputRef={inputRef}
                onKeyDown={evt => {
                  handleKeyDown(evt);
                }}
              />
              <Button
                variant='contained'
                onClick={() => {
                  setLocalSearchWord(inputRef.current?.value);
                }}
              >
                조회
              </Button>
            </Box>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogContent>
        <TableContainer>
          <Table stickyHeader>
            <TableHead sx={{ bgcolor: '#F9FAFC' }}>
              <TableRow>
                <TableCell padding='checkbox'>
                  <Checkbox
                    onChange={e => {
                      handleSelectAllClick(e);
                    }}
                    checked={checkedAll}
                  />
                </TableCell>
                {columns.map((column, idx) => (
                  <TableCell key={idx.toString()} sx={{ p: '.6rem' }}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {targetList?.map((row: any, idx: number) => (
                <TableRow key={idx.toString()}>
                  <TableCell padding='checkbox'>
                    <Checkbox
                      id={row.targetId}
                      onChange={e => {
                        changeHandler(e.currentTarget.checked, row);
                      }}
                      checked={checkedTargets.includes(row.targetId)}
                    />
                  </TableCell>
                  <TableCell sx={{ p: '.3rem .6rem' }}>
                    {row.filterType}
                  </TableCell>
                  <TableCell sx={{ p: '.3rem .6rem' }}>
                    {row.communityName}
                  </TableCell>
                  <TableCell sx={{ p: '.3rem .6rem' }}>
                    {row.targetId}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {loadReq === false &&
          (targetList === null || targetList.length === 0) && (
            <Typography sx={{ color: 'red', mt: '2rem' }}>
              데이터가 없습니다.
            </Typography>
          )}
      </DialogContent>
      <Divider sx={{ m: 0 }} />
      <DialogActions sx={{ p: '1rem' }}>
        <ChosenItemPaper sx={{ width: '90%' }}>
          {checkedTargets?.map((target: any, idx: number) => (
            <SelectChip
              label={getLabel(target)}
              variant='outlined'
              deleteIcon={<CloseIcon />}
              key={idx.toString()}
              onDelete={() => {
                const newChosenUser = checkedTargets.filter(
                  (_target: any, i: number) => i !== idx
                );
                setCheckedTargets(newChosenUser);
              }}
            />
          ))}
        </ChosenItemPaper>
        <Button
          variant='contained'
          color='info'
          sx={{ ml: '2rem', width: '10%' }}
          onClick={saveItem}
        >
          선택
        </Button>
      </DialogActions>
      {loadReq && <SwLoading />}
    </Dialog>
  );
};
