import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { xapi } from '../../hooks/session';
import { IStamptour, IStamptourInfo } from '../../models/stamptour';
import { throwError } from '../helper';

/*
 * Stamptour
 */

export const getStamptourList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/stamptour?page=${data.page}&per_page=${data.rowsPerPage}&searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getStamptourDetailInfo = (stamptourId: string) =>
  xapi.get(`/admin/v2/apis/stamptour/${stamptourId}`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const getStamptourRequestList = () =>
  xapi.get(`/admin/v2/apis/stamptour/creation-request`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const getStamptourRequestListByOperator = () =>
  xapi.get(`/admin/v2/apis/stamptour/creation-request-operator`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const getStamptourRequestDetail = (stamptourId: string) =>
  xapi
    .get(`/admin/v2/apis/stamptour/creation-request/${stamptourId}`)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getStamptourRequestDetailByChallengeId = (
  stamptourId: string,
  challengeId: string
) =>
  xapi
    .get(
      `/admin/v2/apis/stamptour/creation-request/${stamptourId}/challenge?challengeId=${challengeId}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const registerStamptour = (data: IStamptour) =>
  xapi.post(`/admin/v2/apis/stamptour`, data);

export const deleteStamptour = (data: IStamptour) =>
  xapi.delete(`/admin/v2/apis/stamptour/${data.stampTourId}`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const changeStampApplyStatus = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/stamptour/${data.stamptourId}/status/${data.status}?message=${data.message}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });
