import { Box, styled, Tab, Tabs } from '@mui/material';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import clsx from 'clsx';
import { useResize } from '../../../hooks/resize';
import { TabBox } from '../../styles/Styles';

interface Props {}

export const AppUpdateGuide: React.FC<Props> = () => {
  const { isMobile } = useResize();
  const navigate = useNavigate();
  const [value, setValue] = useState<number>(1);
  const tabChange = (event: SyntheticEvent, newValue: number) => {
    switch (newValue) {
      case 1:
        setValue(1);
        navigate('regist');
        break;
      case 2:
        setValue(2);
        navigate('inquire');
        break;
      case 3:
        setValue(3);
        navigate('releasenote');
        break;
      default:
        setValue(0);
        navigate('/appupdateguide');
    }
  };
  useEffect(() => {
    const path = window.location.pathname;
    if (path === '/appupdateguide/regist') setValue(1);
    else if (path === '/appupdateguide/inquire') setValue(2);
    else if (path === '/appupdateguide/releasenote') setValue(3);
  }, [window.location.pathname]);
  return (
    <div className={clsx({ root: !isMobile() }, { root_resize: isMobile() })}>
      <Helmet>
        <title>업데이트 안내 관리</title>
      </Helmet>
      <TabBox>
        <Tabs value={value} onChange={tabChange}>
          <Tab label='안내 팝업 생성' value={1} />
          <Tab label='안내 팝업 목록' value={2} />
          <Tab label='앱 릴리즈 노트' value={3} />
        </Tabs>
      </TabBox>
      <Outlet />
    </div>
  );
};
