import {
  Box,
  Button,
  Collapse,
  IconButton,
  InputAdornment,
  Paper,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useQuery, useQueryClient } from 'react-query';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { SelectSearchBox } from '../../commonComponent/SelectSearchBox';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwSwitch } from '../../styles/Styles';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { AppUpdateGuideForm } from './AppUpdateGuideForm';
import {
  encodeSearchWord,
  timestamp2Localestring,
} from '../../../common/helper';
import {
  KEY_APP_UPDATE_GUIDE_LIST,
  KEY_APP_UPDATE_RELEASE_NOTE_LIST,
} from '../../../common/key';
import { getAppUpdateReleaseNoteList } from '../../../common/api/ApiAppUpdateManage';
import { AppReleaseNoteDrawUpDialog } from './AppReleaseNoteDrawUpDialog';

const requestColumnlists = [
  'OS 타입',
  '버전 정보',
  '수정 내용',
  '앱스토어 업데이트 날짜',
  '작성 일시',
];

interface Props {
  osType?: number;
}

export const AppReleaseNote: React.FC<Props> = ({ osType }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [requestList, setRequestList] = useState<any>([]);
  const [appUpdateReleaseNoteList, setAppUpdateReleaseNoteList] = useState<any>(
    []
  );
  const [appUpdateReleaseNote, setAppUpdateReleaseNote] = useState<any>();
  const [guideIdx, setGuideIdx] = useState<number>(-1);

  const [checkRequest, setCheckRequest] = useState<boolean>(true);
  const [searchKey, setSearchKey] = useState<string>('appupdateguide');
  const [searchWord, setSearchWord] = useState<string>('');
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [open, setOpen] = useState<boolean>(false);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRow, setSelectedRow] = useState<number>(-1);
  const [androidSwitch, setAndroidSwitch] = useState<boolean>(true);
  const [iosSwitch, setIosSwitch] = useState<boolean>(true);

  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<number>(0);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [openDrawUpDialog, setOpenDrawUpDialog] = useState<number>(0);
  const [loading, setLoading] = useState<any>(false);

  const getOsType = () =>
    androidSwitch && iosSwitch
      ? 0
      : androidSwitch && !iosSwitch
      ? 1
      : !androidSwitch && iosSwitch
      ? 2
      : 0;

  const {
    data,
    isLoading,
    refetch: appUpdateReleaseNoteRefetch,
  } = useQuery(
    KEY_APP_UPDATE_RELEASE_NOTE_LIST,
    () => {
      const newRequest = {
        type: getOsType(),
        startDate: rangeDate[0] !== null ? rangeDate[0].getTime() / 1000 : 0,
        endDate: rangeDate[1] !== null ? rangeDate[1].getTime() / 1000 : 0,
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      if (osType === 1 || osType === 2 || osType === undefined)
        return getAppUpdateReleaseNoteList(newRequest);
      return null;
    },
    {
      onSuccess: res => {
        const newAppUpdateGuide =
          res &&
          res.data &&
          res.data.map((item: any, idx: number) => {
            const newUpdateDate = timestamp2Localestring(
              item.appUpdateDate,
              1000
            );
            const newCreateDate = timestamp2Localestring(item.createDate, 1000);
            const newData = [
              item.type === 1 ? '안드로이드' : 'iOS',
              item.version,
              item.content,
              newUpdateDate,
              newCreateDate,
            ];
            if (guideIdx === item.idx) {
              setSelectedRow(idx);
              setAppUpdateReleaseNote(item);
            }
            return newData;
          });
        setRequestList(newAppUpdateGuide);
        setAppUpdateReleaseNoteList(res ? res.data : []);
        setTotalRecords(res.pagination.totalRecordCount);
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const appUpdateReleaseNotePrefetch = (newPage: number) => {
    queryClient.prefetchQuery(KEY_APP_UPDATE_RELEASE_NOTE_LIST, () => {
      const newData = {
        type: getOsType(),
        startDate: rangeDate[0] !== null ? rangeDate[0].getTime() / 1000 : 0,
        endDate: rangeDate[1] !== null ? rangeDate[1].getTime() / 1000 : 0,
        page: newPage,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      return getAppUpdateReleaseNoteList(newData);
    });
  };

  const appUpdateReleaseNoteWithOsTypePrefetch = (newOsType: number) => {
    queryClient.prefetchQuery(KEY_APP_UPDATE_RELEASE_NOTE_LIST, () => {
      const newData = {
        type: newOsType,
        startDate: rangeDate[0] !== null ? rangeDate[0].getTime() / 1000 : 0,
        endDate: rangeDate[1] !== null ? rangeDate[1].getTime() / 1000 : 0,
        page: 1,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      return getAppUpdateReleaseNoteList(newData);
    });
  };

  const chooseRequest = (i: number) => {
    if (osType !== undefined) return;
    setSelectedRow(i);
    setGuideIdx(appUpdateReleaseNoteList[i].idx);
    setAppUpdateReleaseNote(appUpdateReleaseNoteList[i]);
  };

  const searchTarget = () => {
    setPage(1);
    appUpdateReleaseNotePrefetch(1);
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setSelectedRow(-1);
    setPage(newPage);
    appUpdateReleaseNotePrefetch(newPage);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchTarget();
    }
  };

  const onReload = () => {
    setOpenDrawUpDialog(0);
    appUpdateReleaseNoteRefetch();
  };

  useEffect(() => {
    searchTarget();
  }, [androidSwitch, iosSwitch]);

  useEffect(() => {
    if (
      (rangeDate[0] !== null && rangeDate[1] !== null) ||
      (rangeDate[0] === null && rangeDate[1] === null)
    )
      searchTarget();
  }, [rangeDate]);

  useEffect(() => {
    if (osType === 1) {
      setRowsPerPage(5);
      setAndroidSwitch(true);
      setIosSwitch(false);
    } else if (osType === 2) {
      setRowsPerPage(5);
      setAndroidSwitch(false);
      setIosSwitch(true);
    }
  }, [osType]);
  return (
    <>
      <Box className='flex_between' sx={{ m: '1.5rem 0' }}>
        <Box>
          <TextField
            placeholder='릴리즈 노트 버전정보, 수정내용으로 검색해 주세요'
            value={searchWord}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setSearchWord(evt.target.value)}
            onKeyDown={handleKeyDown}
            sx={{ width: '30rem', mr: '1em', mt: '1rem' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton sx={{ pr: 0 }} onClick={searchTarget}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box sx={{ mr: '2rem' }} className='flex_between'>
          <Box className='flex'>
            <Collapse orientation='horizontal' in={openFilter}>
              <SwDateRangePicker
                rangeDate={rangeDate}
                setRangeDate={setRangeDate}
              />
            </Collapse>
            <Button
              color='info'
              variant='outlined'
              sx={{ ml: '1rem' }}
              onClick={() => setOpenFilter(!openFilter)}
            >
              <FilterAltOutlinedIcon />
              필터
              <ArrowBackIosNewOutlinedIcon
                sx={{ fontSize: '1rem', ml: '.5rem' }}
              />
            </Button>
          </Box>
          <Box>
            <Button
              variant='outlined'
              onClick={() => {
                selectedRow >= 0 && setOpenDrawUpDialog(2);
              }}
              sx={{ pl: '.5rem', ml: '1rem' }}
            >
              <AddIcon sx={{ fontSize: '1.5rem', mr: '.3rem' }} />
              상세보기
            </Button>
            <Button
              variant='contained'
              onClick={() => setOpenDrawUpDialog(1)}
              sx={{ pl: '.5rem', ml: '1rem' }}
            >
              <AddIcon sx={{ fontSize: '1.5rem', mr: '.3rem' }} />
              작성하기
            </Button>
          </Box>
          {/* <Button
            color='info'
            variant='outlined'
            sx={{ ml: '1rem' }}
            onClick={() => onDownloadProgramList()}
          >
            데이터 다운로드
            <FileDownloadRoundedIcon color='success' />
          </Button> */}
        </Box>
      </Box>
      <Paper sx={{ m: '1.5rem 0', p: '1.5rem' }}>
        <Box className='flex_between'>
          <Box>
            <Typography>
              릴리즈 노트 <span className='bluecontent'>{totalRecords}</span> 개
            </Typography>
          </Box>
          {osType === undefined && (
            <Box className='flex_between'>
              <Typography sx={{ mr: '.4rem' }}>안드로이드</Typography>
              <SwSwitch
                defaultChecked={true}
                value={androidSwitch}
                onChange={() => setAndroidSwitch(!androidSwitch)}
              />
              <Typography sx={{ ml: '1rem', mr: '.4rem' }}>iOS</Typography>
              <SwSwitch
                defaultChecked={true}
                value={iosSwitch}
                onChange={() => setIosSwitch(!iosSwitch)}
              />
            </Box>
          )}
        </Box>
        <TableContainer
          sx={{ p: '0rem', mt: '1rem', height: 'auto', overflowY: 'scroll' }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {requestColumnlists.map((column, idx) => (
                  <TableCell key={idx.toString()} sx={{ p: '.4rem .6rem' }}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {requestList?.map((row: any, idx: number) => (
                <TableRow
                  key={idx.toString()}
                  onClick={() => chooseRequest(idx)}
                  className={
                    selectedRow === idx ? 'selected_row' : 'whitecolor'
                  }
                >
                  {row.map((rowItem: any, rowIdx: number) => (
                    <TableCell
                      key={rowIdx.toString()}
                      className=''
                      sx={{ width: rowIdx !== 2 ? '5rem' : '20rem' }}
                    >
                      {rowIdx !== 2 ? (
                        row[rowIdx]
                      ) : (
                        <TextField
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              height: 'auto',
                              padding: '.5rem',
                            },
                          }}
                          fullWidth
                          multiline
                          rows={3}
                          value={row[rowIdx]}
                        />
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <SwPagination
          page={page}
          handleChangePage={handleChangePage}
          count={Math.ceil(totalRecords / rowsPerPage)}
        />
        <AppReleaseNoteDrawUpDialog
          releaseNote={
            appUpdateReleaseNoteList.length === 0 || openDrawUpDialog === 1
              ? null
              : openDrawUpDialog === 2
              ? appUpdateReleaseNoteList[selectedRow]
              : null
          }
          open={!!openDrawUpDialog}
          onclose={() => setOpenDrawUpDialog(0)}
          onReload={onReload}
        />
      </Paper>
    </>
  );
};
