import { useEffect, useRef, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  styled,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { SwTable2 } from '../../commonComponent/table/SwTable2';
import { SwNaverMap } from '../../commonComponent/map/SwNaverMap';
import { UserChallengeDetailWalkingType } from './UserChallengeDetailWalkingType';
import { UserChallengeDetailWalkingAlongType } from './UserChallengeDetailWalkingAlongType';
import { KEY_CHALLENGE_REWARD_LIST_BY_USER_ID } from '../../../common/key';
import { getChallengeRewardListByUserId } from '../../../common/api/ApiChallenge';
import { removeNatCodePhoneNumber } from '../../../common/helper';

interface Props {
  open: boolean;
  onClose: () => void;
  userData: any;
  challengeData: any;
}

export const UserChallengeDetail: React.FC<Props> = ({
  open,
  onClose,
  userData,
  challengeData,
}) => {
  const [type, setType] = useState<any>(-1);
  const [subType, setSubType] = useState<any>(-1);
  const [userInfo, setUserInfo] = useState<any>({
    name: '',
    phoneno: '',
    progress: '',
    goal: '',
    stepMax: 0,
  });
  const [content, setContent] = useState<any>([]);
  const [stepList, setStepList] = useState<any>([]);
  const [rewardList, setRewardList] = useState<any>([]);
  const [challengeId, setChallengeId] = useState<string>('');
  const [requestData, setRequestData] = useState<boolean>(false);

  const makeData = () => {
    const parsedData = JSON.parse(challengeData.attendContent);
    const parsedStepData =
      challengeData.stepList !== undefined && challengeData.stepList !== null
        ? JSON.parse(challengeData.stepList)
        : [];
    setType(Number(challengeData.challengeType));
    setContent(parsedData.content);
    setStepList(parsedStepData);
    setSubType(challengeData.challengeSubType);

    setUserInfo({
      id: userData.id,
      challengeId: challengeData.challengeId,
      name: challengeData.userName,
      phoneno: removeNatCodePhoneNumber(challengeData.phoneno),
      progress: challengeData.progress,
      optionalProgress: challengeData.optionalProgress,
      goal: challengeData.goal,
      gpsGoal: challengeData.gpsGoal,
      stepMax: parsedData.stepMax,
      stepPerPoint: parsedData.stepPerPoint,
      stepMin: parsedData.stepMin,
      maxPoint: challengeData.maxPoint,
    });
  };

  const {
    data,
    isLoading,
    refetch: challengeRewardRefetch,
  } = useQuery(
    KEY_CHALLENGE_REWARD_LIST_BY_USER_ID,
    () => {
      if (requestData) {
        const newData = {
          challengeId: challengeData.challengeId,
          userId: userData.id,
        };
        return getChallengeRewardListByUserId(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        if (requestData) makeData();
        if (res !== null) {
          setRewardList(res.reward);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const title = (newType: number) => {
    switch (newType) {
      case 1:
        return '일자별 걸음 수 현황';
      case 4:
        return '따라 걷기 현황';
      case 5:
        return '일자별 걸음 수 현황';
      case 6:
        return '일자별 마일리지 적립 현황';
      case 7:
        return '스탬프 투어 현황';
      case 9:
        return '따라 걷기 현황';
      case 11:
        return '우리동네 챌린지 현황';
      case 12:
        return '사진 인증 + 걸음 수 현황';
      case 13:
        return '매일 따라 걷기 + 걸음 수 현황';
      case 14:
        return '스탬프 투어 + 걸음 수 현황';
      case 15:
        return '길 따라 걷기 + 걸음 수 현황';
      case 16:
        return '오디오 듣기 + 걸음 수 현황';
      case 17:
        return '캐릭터 잡기 + 걸음 수 현황';
      case 18:
        return '그룹 대항 챌린지 현황';
      case 19:
        return '오디오 듣기 + 매일 따라 걷기 현황';
      case 20:
        return '오디오 듣기 + 스탬프 투어 현황';
      case 21:
        return '오디오 듣기 + 길 따라 걷기 현황';
      default:
        return '미생성 콘텐츠';
    }
  };

  useEffect(() => {
    if (requestData) {
      challengeRewardRefetch();
    }
  }, [requestData]);

  useEffect(() => {
    if (open) {
      setRequestData(true);
    } else {
      setRequestData(false);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      fullScreen
      sx={{
        '& .MuiDialog-paper': {
          height: '88vh',
          margin: type === 1 ? '7%' : '22%',
          minWidth: '345px',
          borderRadius: '12px',
        },
      }}
    >
      <DialogTitle>
        <Box className='flex_between'>
          <span>{title(type)}</span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ p: 0, m: 0 }} />
      <DialogContent>
        {/* <SwNaverMap /> */}
        {/* <SwTable2 /> */}
        {type === 1 ||
        type === 5 ||
        type === 6 ||
        type === 11 ||
        type === 12 ||
        type === 17 ||
        (type === 18 && subType !== 3) || // except stamp
        type === 22 ? (
          <UserChallengeDetailWalkingType
            type={type}
            subType={subType}
            userInfo={userInfo}
            rewardInfo={rewardList}
            dailySteps={content}
          />
        ) : type === 4 ||
          type === 7 ||
          type === 9 ||
          type === 13 ||
          type === 14 ||
          type === 15 ||
          (type === 18 && subType === 3) || // stamp
          type === 19 ||
          type === 20 ||
          type === 21 ? (
          <UserChallengeDetailWalkingAlongType
            type={type}
            userInfo={userInfo}
            rewardInfo={rewardList}
            content={content}
            setContent={setContent}
            stepList={stepList}
          />
        ) : (
          <UserChallengeDetailWalkingType
            type={type}
            subType={subType}
            userInfo={userInfo}
            rewardInfo={rewardList}
            dailySteps={content}
          />
        )}
      </DialogContent>
      {/* <Divider sx={{ p: 0, m: 0 }} />
      <DialogActions>action</DialogActions> */}
    </Dialog>
  );
};
