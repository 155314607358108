import {
  Box,
  Button,
  Collapse,
  MenuItem,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import React, {
  ChangeEvent,
  Dispatch,
  useCallback,
  useEffect,
  useState,
} from 'react';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { MobileDateRangePicker } from '@mui/x-date-pickers-pro/MobileDateRangePicker';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker';
import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  DateTextField,
  SelectTextField,
  TableTopButton,
} from '../../styles/Styles';
import { SwPagination } from '../tableElement/SwPagination';

interface Props {
  columns: any[];
  rows: any[];
  rowsPerPage: number;
  page: number;
  totalRecords: number;
  onclick?: (i: number) => void;
  handleChangePage: (_event: any, newPage: number) => void;
  showLink?: boolean;
  onClickGoto?: (i: number) => void;
}

const ColumsCell = styled(TableCell)({
  fontSize: '.88rem',
  fontWeight: 400,
  padding: '.6rem',
  whiteSpace: 'nowrap',
});

const RowsCell = styled(TableCell)({
  fontSize: '.88rem',
  fontWeight: 400,
  padding: '.6rem',
  overflowX: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'pre',
  maxWidth: '20rem',
  width: 'fit-contents',
});

const RowsNumberCell = styled(TableCell)({
  fontSize: '.88rem',
  textAlign: 'center',
  fontWeight: 400,
  padding: '.6rem',
  whiteSpace: 'nowrap',
});

export const PartnerTable: React.FC<Props> = ({
  columns,
  rows,
  rowsPerPage,
  page,
  totalRecords,
  onclick,
  handleChangePage,
  showLink = false,
  onClickGoto,
}) => {
  //   const [page, setPage] = useState(1);
  //   const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  //   const handleChangePage = (_event: any, newPage: number) => {
  //     setPage(newPage);
  //   };
  //   const rowsPerPageOptions = [5, 10, 15, 20];
  const [selectedRow, setSelectedRow] = useState(-1);
  const handlerRowClicked = (idx: number) => {
    if (onclick) onclick(idx);
    setSelectedRow(idx);
  };

  useEffect(() => {
    setSelectedRow(-1);
  }, [rows]);

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, idx) => (
                <ColumsCell key={idx.toString()}>{column}</ColumsCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: any, idx: number) => (
              <TableRow
                key={idx.toString()}
                onClick={() => handlerRowClicked(idx)}
                className={selectedRow === idx ? 'selected_row' : 'whitecolor'}
              >
                {showLink && onClickGoto && (
                  <RowsCell
                    key={(columns.length + 1).toString()}
                    onClick={() => onClickGoto(idx)}
                  >
                    <Button variant='outlined' color='info'>
                      바로가기
                    </Button>
                  </RowsCell>
                )}
                {columns
                  .filter(
                    (column, i) =>
                      i < (showLink ? columns.length - 1 : columns.length)
                  )
                  .map((column, i) => (
                    <RowsCell key={i.toString()}>
                      {row[i] !== null && row[i] !== '' ? row[i] : '-'}
                    </RowsCell>
                  ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <SwPagination
        page={page}
        handleChangePage={handleChangePage}
        count={Math.ceil(totalRecords / rowsPerPage)}
      />
    </>
  );
};
