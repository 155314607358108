import {
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import SwLoading from '../../commonComponent/spinner/SwLoading';
import { SelectSearchBox } from '../../commonComponent/SelectSearchBox';
import {
  KEY_COMMUNITY_MEMBER_LIST,
  KEY_USER_LIST,
  KEY_WALKWAY_INFO_BY_COMMUNITY_ID,
} from '../../../common/key';
import { getUserList } from '../../../common/api/ApiUser';
import { RESULT_OK } from '../../../common/resultCode';
import { getWalkwayInfoByCommunityId } from '../../../common/api/ApiWalkway';
import { uploadFile } from '../../../common/api/ApiUpload';
import { useSession } from '../../../hooks/session';
import { downloadS3File } from '../../../common/api/ApiDownload';

import walkwayTop from '../../../images/walkway-top.png';
import walkwayBottom from '../../../images/walkway-bottom.png';
import selectPin from '../../../images/icon-select-pin.png';
import normalPin from '../../../images/icon-normal-pin.png';
import { downloadCommunityRequestCaptureImage } from '../../../common/api/ApiCommunity';
import {
  downloadCommunityYearReport,
  requestCommunityYearReport,
} from '../../../common/api/ApiReport';
import { getReportCurrentYear } from '../../../common/helper';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import { currentYearReportState } from '../../../common/atom';

interface Props {
  communityId: string;
  open: boolean;
  onClose: () => void;
}

const CurrentPointOverlayView = styled(Box)({
  position: 'absolute',
  zIndex: 100,
  padding: '.5rem',
  margin: '1rem',
  height: '8%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '95%',
});

const InfoOverlayView = styled(Box)({
  position: 'absolute',
  zIndex: 100,
  padding: '0rem',
  margin: '0rem',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'end',
  width: '100%',
});

const YearReportDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    minWidth: '44vw',
    minHeight: '60vh',
  },
});

const TagsChip = styled(Chip)({
  margin: '0rem',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  borderColor: 'rgba(206, 204, 204, 0.5)',
  fontSize: '8px',
  '& .MuiChip-label': {
    paddingLeft: '5px',
    paddingRight: '5px',
  },
});

export const CommunityYearReport: React.FC<Props> = ({
  communityId,
  open,
  onClose,
}) => {
  const [currentYearReport, setCurrentYearReport] = useRecoilState(
    currentYearReportState
  );

  const mainMap = useRef<any>();
  const detailMap = useRef<any>();
  const { loginVal } = useSession();
  const [courseInfoList, setCourseInfoList] = useState<any[]>([]);
  const [pointsContent, setPointsContent] = useState<any>([]);
  const [markerContent, setMarkerContent] = useState<any>([]);
  const [isCurrent, setIsCurrent] = useState<boolean>(false);
  const [imageUrlPrefix, setImageUrlPrefix] = useState<string>(
    loginVal.value.user.imageUrlPrefix
  );
  const [imageSrc, setImageSrc] = useState<any>();
  const [mainImagePopuSrc, setMainImagePopuSrc] = useState<any>();
  const [mainImage, setMainImage] = useState<any>('');
  const [detailImage, setDetailImage] = useState<any>('');
  const [dataPortalId, setDataPortalId] = useState<string>('');
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [loading, setLoading] = useState<any>(null);

  const getDownloadImage = async (target: string, imagePath: string) => {
    try {
      const dataUrl = await downloadS3File(imagePath);

      if (dataUrl !== null) {
        if (target === 'mainPopup') setMainImagePopuSrc(dataUrl);
        else if (target === 'mainImage') setImageSrc(dataUrl);
      }
    } catch (error) {
      console.error('Error downloading or displaying image:', error);
    }
  };

  const initMainMap = (walkway: any[]) => {
    if (walkway && walkway.length > 0) {
      const newDistanceList: any = [];

      const newPointsContent = pointsContent;
      const newMarker = markerContent;
      newMarker.map((m: any) => m?.setMap(null));

      walkway.map((course: any, idx: number) => {
        const linePath: any = [];

        const maps = document.getElementById('maps_main');
        newPointsContent[idx] = {
          point: [course.la, course.lo],
        };

        const tempMarker = new naver.maps.Marker({
          position: new naver.maps.LatLng(course.la, course.lo),
          map: mainMap.current,
          icon: {
            // `<img src="/icon/current-location.svg" width="85" height="85" alt="현재 위치"/>`
            // `<div class='walkcourse_marker'>${idx + 1}</div>`
            content:
              idx === 0
                ? `<img src="${selectPin}" width="37" height="37" alt="현재 위치"/>`
                : `<img src="${normalPin}" width="30" height="30" alt="현재 위치"/>`,
            anchor: new naver.maps.Point(18, 38),
          },
          clickable: false,
          draggable: false,
        });
        newMarker[idx] = tempMarker;

        if (maps) {
          const newMap = new naver.maps.Map(maps, {
            center: new naver.maps.LatLng(walkway[0].la, walkway[1].lo),
            zoom: 12,
            draggable: true,
            pinchZoom: false,
            scrollWheel: true,
            keyboardShortcuts: false,
            disableDoubleTapZoom: true,
            disableDoubleClickZoom: true,
            disableTwoFingerTapZoom: true,
            scaleControl: false,
            logoControl: false,
            mapDataControl: false,
            zoomControl: false,
            mapTypeControl: false,
          });

          markerContent?.map((m: any) => m?.setMap(null)); // cleawr preview marker
          newMarker?.map((m: any) => m?.setMap(newMap));
        }
      });

      walkway
        .filter((count: any, idx: number) => idx === 0)
        .map((course: any, idx: number) => {
          const linePath: any = [];
          const maps = document.getElementById('maps_detail');
          const newPoints = JSON.parse(course.route);
          newPoints.map((p: any) =>
            linePath.push(new naver.maps.LatLng(p[0], p[1]))
          );
          const maxx = newPoints.reduce((prev: any, current: any) =>
            prev[0] > current[0] ? prev : current
          )[0];
          const minx = newPoints.reduce((prev: any, current: any) =>
            prev[0] < current[0] ? prev : current
          )[0];
          const maxy = newPoints.reduce((prev: any, current: any) =>
            prev[1] > current[1] ? prev : current
          )[1];
          const miny = newPoints.reduce((prev: any, current: any) =>
            prev[1] < current[1] ? prev : current
          )[1];
          const newBounds = new naver.maps.LatLngBounds(
            new naver.maps.LatLng(minx, miny),
            new naver.maps.LatLng(maxx, maxy)
          );
          if (maps) {
            const newMap = new naver.maps.Map(maps, {
              draggable: false,
              pinchZoom: false,
              scrollWheel: false,
              keyboardShortcuts: false,
              disableDoubleTapZoom: true,
              disableDoubleClickZoom: true,
              disableTwoFingerTapZoom: true,
              scaleControl: false,
              logoControl: false,
              mapDataControl: false,
              zoomControl: false,
              mapTypeControl: false,
            });
            const newPolyline = new naver.maps.Polyline({
              path: linePath,
              map: detailMap.current,
              strokeOpacity: 1,
              strokeWeight: 4,
            });
            newDistanceList.push(newPolyline.getDistance());
            // setCourseDistanceList([...newDistanceList]);
            newMap.fitBounds(newBounds);
            newPolyline.setMap(newMap);
          }
        });
    }
  };

  const {
    data,
    isLoading,
    refetch: walkwayRefetch,
  } = useQuery(
    KEY_WALKWAY_INFO_BY_COMMUNITY_ID,
    () => {
      if (open && communityId !== undefined && communityId !== null)
        return getWalkwayInfoByCommunityId(communityId);
      return null;
    },
    {
      onSuccess: res => {
        if (res.resultCode !== RESULT_OK) {
          setOpenSnackbar(true);
          setDialogMessage('포털 위치 정보 포함 데이터 확인 필요합니다.');
        } else if (res !== null && res.walkway !== undefined) {
          if (res.walkway !== null && res.walkway.walkwayDetail.length > 0) {
            getDownloadImage(
              'mainImage',
              res.walkway.walkwayDetail[0].imagePath
            );
            initMainMap(res.walkway.walkwayDetail);
            setCourseInfoList(res.walkway.walkwayDetail);
            setDataPortalId(res.walkway.dataPortalId);
          } else {
            setCourseInfoList([]);
          }
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const downloadCommunityYearInfoImage = (newData: any) => {
    // downloadCommunityRequestCaptureImage(newData);
    // downloadCommunityYearReport(newData);
    requestCommunityYearReport(newData);
    setLoading(false);
    onClose();
  };

  const captureScreenLocal = (target: string) => {
    const div = document.getElementById(target);
    if (div === null) return;

    domtoimage.toBlob(div).then(blob => {
      saveAs(blob, `${target}.png`);
    });
  };

  const captureScreen = async (target: string) => {
    const div = document.getElementById(target);
    if (div === null) return;

    try {
      domtoimage.toBlob(div).then(async blob => {
        if (blob !== null) {
          const newUploadFile: any = await uploadFile('community', blob);
          if (target === 'maps_main')
            setMainImage(newUploadFile.data.uploadUrl.file.path);
          else if (target === 'maps_detail') {
            setDetailImage(newUploadFile.data.uploadUrl.file.path);
          }
        }
      });
    } catch (error) {
      console.error('Error converting div to image:', error);
    }
  };

  const captureCommunityYearInfoImage = () => {
    setLoading(true);
    captureScreen('maps_main');
    captureScreen('maps_detail');
  };

  function makeKeyword(tags: any) {
    if (tags === null || tags.length === 0) return '';

    const keyword = JSON.parse(tags);
    return (
      <Grid container spacing={0.5} sx={{ width: '20rem' }}>
        {keyword.map((item: any, idx: number) => (
          <Grid key={`keyword_chip_${idx}`} item>
            <TagsChip
              label={`#${item}`}
              variant='outlined'
              sx={{ height: '24px' }}
            />
          </Grid>
        ))}
      </Grid>
    );
  }

  useEffect(() => {
    if (
      mainImage === null ||
      mainImage === '' ||
      detailImage === null ||
      detailImage === ''
    ) {
      return;
    }

    const now = new Date(Date.now());
    const currentYear = isCurrent ? now.getFullYear() : getReportCurrentYear();
    setCurrentYearReport(currentYear);

    const newData: any = {
      year: currentYear,
      portalId: dataPortalId,
      communityId: communityId,
      mainImage: mainImage,
      detailImage: detailImage,
    };
    downloadCommunityYearInfoImage(newData);
  }, [mainImage, detailImage]);

  useEffect(() => {
    if (open === true) {
      setMainImage('');
      setDetailImage('');
      walkwayRefetch();
    }
  }, [open]);

  return (
    <>
      <YearReportDialog open={open} fullWidth>
        <DialogTitle className='flex_between'>
          <Box>연간 보고서</Box>
        </DialogTitle>
        <Divider sx={{ p: 0, m: 0 }} />
        <DialogContent>
          {courseInfoList !== undefined && courseInfoList.length === 0 && (
            <Box>데이터가 없습니다.</Box>
          )}
          <Box className='flex_center'>
            <div
              style={{
                height: '812px',
                width: '375px',
                marginRight: '2rem',
              }}
              ref={mainMap}
              id='maps_main'
            >
              {courseInfoList !== undefined &&
                courseInfoList.length > 0 &&
                imageSrc !== undefined &&
                imageSrc !== null && (
                  <>
                    <CurrentPointOverlayView>
                      <Paper>
                        <IconButton size='small'>
                          <GpsFixedIcon />
                        </IconButton>
                      </Paper>
                    </CurrentPointOverlayView>
                    <InfoOverlayView>
                      <Paper sx={{ width: '100%', height: '35%' }}>
                        <Box className='flex_start'>
                          <img
                            src={walkwayBottom}
                            alt='text'
                            style={{
                              width: '100%',
                              height: '300px',
                            }}
                          />
                          <Box
                            sx={{
                              position: 'absolute',
                              zIndex: 100,
                              top: '0%',
                              left: '0%',
                              padding: '0rem',
                              margin: '0rem',
                              display: 'flex',
                              justifyContent: 'top',
                              alignItems: 'end',
                            }}
                          >
                            <img
                              src={walkwayTop}
                              alt='text'
                              style={{
                                width: '100%',
                                height: '100%',
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              position: 'absolute',
                              zIndex: 100,
                              top: '72%',
                              left: '13%',
                              padding: '0rem',
                              margin: '0rem',
                              display: 'flex',
                              justifyContent: 'top',
                              alignItems: 'end',
                            }}
                          >
                            <img
                              src={imageSrc}
                              alt='text'
                              style={{
                                borderRadius: '50%',
                                width: '75px',
                                height: '75px',
                              }}
                            />
                          </Box>
                          <Typography
                            noWrap
                            sx={{
                              fontWeight: 700,
                              position: 'absolute',
                              zIndex: 100,
                              top: '72%',
                              left: '38%',
                              width: '200px',
                            }}
                          >
                            {courseInfoList[0].title}
                          </Typography>
                          <Typography
                            sx={{
                              position: 'absolute',
                              zIndex: 100,
                              top: '75%',
                              left: '38%',
                              whiteSpace: 'nowrap', // 텍스트를 한 줄로 제한
                              overflow: 'hidden', // 넘친 텍스트를 숨김
                              textOverflow: 'ellipsis', // 숨겨진 텍스트 대신 '...' 표시
                              maxWidth: '200px',
                            }}
                          >
                            {courseInfoList[0].location}
                          </Typography>
                          <Box
                            sx={{
                              position: 'absolute',
                              zIndex: 100,
                              top: '78%',
                              left: '38%',
                            }}
                          >
                            {makeKeyword(courseInfoList[0].tags)}
                          </Box>
                          <Typography
                            sx={{
                              position: 'absolute',
                              zIndex: 100,
                              top: '83%',
                              left: '20%',
                            }}
                          >
                            {`${courseInfoList[0].distance}km`}
                          </Typography>
                          <Typography
                            sx={{
                              position: 'absolute',
                              zIndex: 100,
                              top: '83%',
                              left: '45%',
                            }}
                          >
                            {`${courseInfoList[0].estimatedSteps}걸음`}
                          </Typography>
                          <Typography
                            sx={{
                              position: 'absolute',
                              zIndex: 100,
                              top: '83%',
                              left: '75%',
                            }}
                          >
                            {`${courseInfoList[0].expectedMins}분`}
                          </Typography>
                        </Box>
                      </Paper>
                    </InfoOverlayView>
                  </>
                )}
            </div>
            <div
              style={{
                height: '812px',
                width: '375px',
              }}
              ref={detailMap}
              id='maps_detail'
            >
              {courseInfoList !== undefined &&
                courseInfoList.length > 0 &&
                imageSrc !== undefined &&
                imageSrc !== null && (
                  <>
                    <CurrentPointOverlayView>
                      <Paper>
                        <IconButton size='small'>
                          <GpsFixedIcon />
                        </IconButton>
                      </Paper>
                    </CurrentPointOverlayView>
                    <InfoOverlayView>
                      <Box
                        sx={{
                          position: 'absolute',
                          zIndex: 100,
                          top: '0%',
                          left: '0%',
                          padding: '0rem',
                          margin: '0rem',
                          display: 'flex',
                          justifyContent: 'top',
                          alignItems: 'end',
                        }}
                      >
                        <img
                          src={walkwayTop}
                          alt='text'
                          style={{
                            width: '100%',
                            height: '100%',
                          }}
                        />
                      </Box>
                      <Paper
                        sx={{
                          position: 'absolute',
                          top: '82%',
                          left: '13%',
                          width: '80%',
                          height: '10%',
                        }}
                      >
                        <Box className='flex_start'>
                          <Box
                            sx={{
                              position: 'absolute',
                              zIndex: 100,
                              top: '15%',
                              left: '7%',
                              padding: '0rem',
                              margin: '0rem',
                              display: 'flex',
                              justifyContent: 'top',
                              alignItems: 'end',
                            }}
                          >
                            <img
                              src={imageSrc}
                              alt='text'
                              style={{
                                borderRadius: '50%',
                                width: '60px',
                                height: '60px',
                              }}
                            />
                          </Box>
                          <Typography
                            noWrap
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              fontWeight: 700,
                              position: 'absolute',
                              zIndex: 100,
                              top: '25%',
                              left: '32%',
                              width: '200px',
                            }}
                          >
                            {courseInfoList[0].title}
                          </Typography>
                          <Typography
                            sx={{
                              position: 'absolute',
                              zIndex: 100,
                              top: '50%',
                              left: '32%',
                            }}
                          >
                            {courseInfoList[0].location}
                          </Typography>
                        </Box>
                      </Paper>
                    </InfoOverlayView>
                  </>
                )}
            </div>
          </Box>
          {loading && <SwLoading />}
        </DialogContent>
        <Divider sx={{ p: 0, m: 0 }} />
        <DialogActions>
          <Box className='flex_end'>
            <Button
              sx={{ mr: '2rem' }}
              variant='outlined'
              color='inherit'
              onClick={onClose}
            >
              취소
            </Button>
            <Box>
              <Button
                sx={{ mr: '2rem' }}
                variant='outlined'
                color='info'
                disabled={
                  courseInfoList === null || courseInfoList.length === 0
                }
                onClick={() => {
                  setIsCurrent(false);
                  setOpenConfirm(true);
                }}
              >
                작년도 생성요청
              </Button>
              <Button
                variant='contained'
                color='info'
                disabled={
                  courseInfoList === null || courseInfoList.length === 0
                }
                onClick={() => {
                  setIsCurrent(true);
                  setOpenConfirm(true);
                }}
                sx={{ mr: '2rem' }}
              >
                금년도 생성요청
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </YearReportDialog>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
      <SwConfirmDialog
        contents='연간 보고서를 생성합니다. 멤버수가 작은 경우 30초정도 많은 경우 3~5분정도 소요됩니다. 시간 경과 후 커뮤니티 상세화면에 생긴 다운로드를 이용해 엑셀 파일을 다운로드하시기 바랍니다.  '
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => {
          setOpenConfirm(false);
          captureCommunityYearInfoImage();
        }}
        confirm={1}
      />
    </>
  );
};
