import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Dispatch, useEffect, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useMany } from '../../hooks/api';
import { getProgramTarget, getTarget } from '../../common/api/ApiCommon';
import { KEY_CONTENT_MANAGE_TARGET_LIST } from '../../common/key';
import SwLoading from './spinner/SwLoading';
import { encodeSearchWord } from '../../common/helper';
import { getContentManageTargetList } from '../../common/api/ApiContentManage';

interface Props {
  title: string;
  searchKey: string;
  searchWord: string;
  targetData?: any[];
  open: boolean;
  onClose: () => void;
  setTarget: Dispatch<React.SetStateAction<any[]>>;
}

const columns = ['제목', '아이디'];

export const SwSearchContentManageTarget: React.FC<Props> = ({
  title,
  open,
  onClose,
  searchKey,
  searchWord,
  targetData,
  setTarget,
}) => {
  const queryClient = useQueryClient();
  const [targetList, setTargetList] = useState<any>([]);
  const [loadReq, setLoadReq] = useState<boolean>(true);

  const clickItem = (row: any) => {
    setTarget(row);
    onClose();
  };

  const {
    data,
    isLoading,
    refetch: refetchContentManageTarget,
  } = useQuery(
    KEY_CONTENT_MANAGE_TARGET_LIST,
    () => {
      if (searchKey !== '') {
        const newData: any = {
          searchKey: searchKey,
          searchWord: encodeSearchWord(searchKey, searchWord),
        };
        return getContentManageTargetList(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        if (res != null) {
          setTargetList(res.target);
          if (res.target !== null && res.target.length === 1) {
            clickItem(res.target[0]);
          }
        }
        setLoadReq(false);
      },
      onError: e => {
        setLoadReq(false);
        console.log(e);
      },
    }
  );

  useEffect(() => {
    if (open) {
      if (targetData !== undefined && targetData.length > 0) {
        const newData = targetData.filter(
          (item: any) =>
            item.id.includes(searchWord) || item.name.includes(searchWord)
        );
        setTargetList(newData);
      } else {
        setLoadReq(true);
        setTargetList([]);
        refetchContentManageTarget();
      }
    } else {
      setLoadReq(false);
    }
    return () => {};
  }, [open]);

  return (
    <Dialog
      open={open}
      fullScreen
      sx={{
        '& .MuiDialog-paper': {
          height: '66vh',
          margin: '30rem',
        },
      }}
    >
      <DialogTitle>
        <Box className='flex_between'>
          <span>
            {searchWord.length > 0 && `'${searchWord}'`} {title} 검색결과
          </span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogContent>
        <TableContainer>
          <Table stickyHeader>
            <TableHead sx={{ bgcolor: '#F9FAFC' }}>
              <TableRow>
                {columns.map((column, idx) => (
                  <TableCell key={idx.toString()} sx={{ p: '.6rem' }}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {targetList?.map((row: any, idx: number) => (
                <TableRow
                  key={`program_target_${idx.toString()}`}
                  onClick={() => clickItem(row)}
                >
                  <TableCell key={row.na} sx={{ p: '.3rem .6rem' }}>
                    {row.title}
                  </TableCell>
                  <TableCell sx={{ p: '.3rem .6rem' }}>
                    {row.contentId}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {loadReq === false &&
          (targetList === null || targetList.length === 0) && (
            <Typography sx={{ color: 'red', mt: '2rem' }}>
              데이터가 없습니다.
            </Typography>
          )}
      </DialogContent>
      <Divider sx={{ m: 0 }} />
      {/* <DialogActions sx={{ p: '1rem' }}>
        <Button
          variant='contained'
          color='secondary'
          sx={{ fontSize: '.87rem' }}
          onClick={onClose}
        >
          닫기
        </Button>
      </DialogActions> */}
      {loadReq && <SwLoading />}
    </Dialog>
  );
};
