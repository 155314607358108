import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRange, DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TableTitle } from '../../commonComponent/tableElement/TableTitle';
import { CommunitySettingChange } from './CommunitySettingChange';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SelectTextField, TableTopButton, SwSwitch } from '../../styles/Styles';
import {
  deleteCommunityBanner,
  downloadCommunityWorkStep,
  getCommunityBannerList,
  putCommunityOpenStep,
} from '../../../common/api/ApiCommunity';
import { KEY_COMMUNITY_BANNER_LIST } from '../../../common/key';
import {
  TOOLTIP_COMMUNITY_HIDDEN_STEP,
  TOOLTIP_FONT_SIZE,
  timestamp2Localestring,
} from '../../../common/helper';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import { chosenCommunityState } from '../../../common/atom';
import { ICommunity } from '../../../models/community';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';

interface Props {}

export const CommunitySettingWorkStep: React.FC<Props> = () => {
  const location = useLocation();
  const [chosenCommunity, setChosenCommunity] =
    useRecoilState(chosenCommunityState);
  const [communityData, setCommunityData] = useState<any>(location.state);
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const {
    mutate: changeCommunityOpenStepMutate,
    isError: isChangeISPrivateMutateError,
  } = useMutation(putCommunityOpenStep, {
    onSuccess: () => {},
    onError: error => {},
  });

  const downloadWorkStep = (type: number) => {
    if (rangeDate[0] === null || rangeDate[1] === null) {
      setOpenSnackbar(true);
      setDialogMessage('기간을 지정해주세요');
      return;
    }

    const newData = {
      type: type,
      communityId: communityData.communityId,
      startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime() / 1000,
      endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime() / 1000,
    };
    downloadCommunityWorkStep(newData);
  };

  useEffect(() => {
    if (chosenCommunity) {
      setCommunityData(chosenCommunity);
    }
  }, [chosenCommunity]);

  return (
    <Box sx={{ m: '2rem 0' }}>
      <Typography variant='h4'>츨퇴근 걸음수</Typography>
      <Paper sx={{ m: '2rem 0', p: '2rem 1rem' }}>
        <Box className=''>
          <Typography className='title'>기간 지정</Typography>
          <Box className='flex_start'>
            <SwDateRangePicker
              rangeDate={rangeDate}
              setRangeDate={setRangeDate}
            />
          </Box>
        </Box>
      </Paper>
      <Box className='flex_end'>
        <Button
          variant='contained'
          color='info'
          sx={{ ml: '1rem' }}
          onClick={() => downloadWorkStep(1)}
        >
          출퇴근 걸음수 저장
        </Button>
        <Button
          variant='contained'
          color='info'
          sx={{ ml: '1rem' }}
          onClick={() => downloadWorkStep(2)}
        >
          만보일수 저장
        </Button>
      </Box>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
    </Box>
  );
};
