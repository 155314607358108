import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CloseIcon from '@mui/icons-material/Close';
import koLocale from 'date-fns/locale/ko';
import SearchIcon from '@mui/icons-material/Search';
import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { debouncehook } from '../../../hooks/debouncehook';
import { useMany } from '../../../hooks/api';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwDatesPicker } from '../../commonComponent/dateSetting/SwDatesPicker';
import { SwSearchTarget } from '../../commonComponent/SwSearchTarget';
import { IPush, DefaultPush } from '../../../models/push';
import { RESULT_OK } from '../../../common/resultCode';
import { SwLoadUser } from '../../commonComponent/SwLoadUser';
import {
  commaFormat,
  encodeSearchWord,
  getPushLinkCode,
  timestamp2Localestring,
} from '../../../common/helper';
import { SwPushSelectLocation } from '../../commonComponent/SwPushSelectLocation';
import { SwPushSelectOsVersion } from '../../commonComponent/SwPushSelectOsVersion';
import { getRecentLocaitonList } from '../../../common/api/ApiGeohash';
import {
  KEY_APP_OS_RECENT_VERSION,
  KEY_APP_OS_VERSION_LIST,
  KEY_GEOHASH_RECENT_LOCATION_LIST,
  KEY_REGISTERED_PUSH_LIST,
  KEY_TARGET_LIST,
  KEY_TARGET_PUSH_LIST,
} from '../../../common/key';
import SwLoading from '../../commonComponent/spinner/SwLoading';
import { getTarget } from '../../../common/api/ApiCommon';
import { SwDatesPickerWithScroll } from '../../commonComponent/dateSetting/SwDatesPickerWithScroll';
import { getAppOsRecentVersion } from '../../../common/api/ApiUser';
import { SwOsVersionSetup } from '../../commonComponent/SwOsVersionSetup';
import { AppUpdateGuideForm } from './AppUpdateGuideForm';

interface Props {}

const RootPaper = styled(Paper)({
  // margin: '2.8rem',
  padding: '1rem',
  borderRadius: '12px',
});

const ScrollPaper = styled(Paper)({
  maxHeight: '10rem',
  overflowY: 'scroll',
  padding: '.5rem',
  width: '44rem ',
});

const UserBox = styled(Box)({
  margin: '.4rem .4rem .4rem 0',
  color: '#777777',
  display: 'flex',
  alignItems: 'center',
});

export const AppUpdateGuideRegist: React.FC<Props> = () => {
  const site = 'webAdmin';
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [recentVersion, setRecentVersion] = useState<any>();
  const [targetType, setTargetType] = useState<number>(1);
  const [osType, setOsType] = useState<number>(0);
  const [aosVersion, setAosVersion] = useState<any>();
  const [iosVersion, setIosVersion] = useState<any>();
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [loading, setLoading] = useState<any>(null);

  const {
    data,
    isLoading,
    refetch: refetchAppOsVersion,
  } = useQuery(KEY_APP_OS_RECENT_VERSION, () => getAppOsRecentVersion(), {
    onSuccess: res => {
      setLoading(false);
      if (res !== null) {
        setRecentVersion(res.versions);
      }
    },
    onError: e => {
      setLoading(false);
      console.log(e);
    },
  });

  useEffect(() => {
    refetchAppOsVersion();
  }, []);

  return (
    <>
      <AppUpdateGuideForm appUpdateGuideInfo={null} />
    </>
  );
};
