import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import koLocale from 'date-fns/locale/ko';

const TagsTextField = styled(TextField)({
  '& .MuiInputLabel-root': { top: '-5px' },
  '& .MuiOutlinedInput-root': { pr: '1rem' },
  width: '20rem',
});

const TagsChip = styled(Chip)({
  margin: '.5em',
  backgroundColor: 'rgba(0, 162, 255, 0.1)',
  borderColor: 'rgba(0, 162, 255, 0.5)',
  '& .MuiChip-deleteIcon': {
    fontSize: '1rem',
  },
});

const TagsAddButton = styled(Button)({
  marginTop: '.3rem',
  marginLeft: '.7rem',
  padding: '.1rem 0',
  borderRadius: '20px',
});

interface Props {
  curriculumTypeList: any[];
  curriculumType: number;
  setCurriculumType: Dispatch<React.SetStateAction<number>>;
  open: boolean;
  onClose: () => void;
}

export const SwCurriculumTypeSelector: React.FC<Props> = ({
  curriculumTypeList,
  curriculumType,
  setCurriculumType,
  open,
  onClose,
}) => {
  const [tagName, setTagName] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (open) {
      setCurriculumType(-1);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          height: '30vh',
          width: '40vh',
        },
      }}
    >
      <DialogTitle>
        <Box className='flex_between'>
          <span>커리큘럼 유형 선택</span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ m: 0, p: 0 }} />

      <DialogContent sx={{ p: '1rem 2rem' }}>
        {curriculumTypeList.map((item: any, idx: number) => (
          <List
            key={`curriculum_type_${idx}`}
            sx={{ width: '100%', bgcolor: 'background.paper' }}
          >
            <ListItemButton
              key={`curriculum_type_${idx}`}
              sx={{
                border: '1px solid #D4D9E1',
                borderRadius: '5px',
                marginButtom: '.5rem',
                width: '100%',
              }}
            >
              <ListItemText
                primary={`${item.title}`}
                onClick={() => {
                  setCurriculumType(item.type);
                  onClose();
                }}
              />
            </ListItemButton>
          </List>
        ))}
      </DialogContent>
    </Dialog>
  );
};
