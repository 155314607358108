import {
  Box,
  Button,
  Collapse,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

import SearchIcon from '@mui/icons-material/Search';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { NormalTable } from '../../commonComponent/table/NormalTable';
import { SwGridTable } from '../../commonComponent/table/SwGridTable';
import { SwTable } from '../../commonComponent/table/SwTable';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import SwLoading from '../../commonComponent/spinner/SwLoading';
import {
  getTimelineList,
  cancelTimeline,
} from '../../../common/api/ApiTimeline';
import { KEY_TIMELINE_LIST } from '../../../common/key';
import { ITimeline, ITimelineCancel } from '../../../models/timeline';
import { TimelineUpdate } from './TimelineUpdate';
import {
  encodeSearchWord,
  getHealthItems,
  timestamp2Localestring,
} from '../../../common/helper';

interface Props {}

const columns = [
  '피드 종류',
  '적용 일시',
  '분류',
  '받는 사람',
  '아이디',
  '말머리',
  '내용',
  '이미지',
  '유튜브 key',
  '링크',
  '작성자',
  '내용 수정',
  '피드 삭제',
  'CTR',
];

const targets = [
  { title: '타겟 선택', value: 'empty' },
  { title: '전체', value: 'all' },
  { title: '공식 커뮤니티', value: 'community' },
  { title: '챌린지', value: 'challenge' },
  { title: '리워드', value: 'reward' },
  { title: '개인', value: 'user' },
  { title: '건강정보', value: 'health' },
];

export const AdminTimeline: React.FC<Props> = () => {
  const site = 'webAdmin';
  const targetTypeRef = useRef<any>(null);
  const [targetType, setTargetType] = useState<string>('empty');
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [timelineList, setTimelineList] = useState<any>([]);
  const [targetTimeline, setTargetTimeline] = useState<any>(null);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openUpdate, setOpenUpdate] = useState<boolean>(false);
  const [loading, setLoading] = useState<any>(true);
  const [healthItem, setHealthItem] = useState<string>('1');

  const {
    data,
    isLoading,
    refetch: timelineRefetch,
  } = useQuery(
    KEY_TIMELINE_LIST,
    () => {
      setLoading(true);
      const now = new Date(Date.now());
      const nowStr = `${now.toISOString().split('T')[0]} 23:59:59`;
      const newNow = new Date(nowStr);
      const before30 = new Date(new Date().setDate(newNow.getDate() - 30));
      const newData = {
        site: site,
        targetType: targetType,
        targetId:
          targetTypeRef.current !== null ? targetTypeRef.current.value : '',
        healthItem: Number(healthItem),
        startDate:
          rangeDate[0] === null
            ? Math.round(before30.getTime() / 1000)
            : rangeDate[0].getTime() / 1000,
        endDate:
          rangeDate[1] === null
            ? Math.round(newNow.getTime() / 1000)
            : rangeDate[1].getTime() / 1000,
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      console.log('timeline newdata:', newData);
      if (targetType !== 'empty') {
        return getTimelineList(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        setLoading(false);
        if (res !== null && res.timeline !== null) {
          console.log('timeline res:', res.timeline);
          const newTimeline = res.timeline.map((row: any, idx: number) => {
            const parsedData = JSON.parse(row.data);
            var newData = {};
            if (row.dataType === 7) {
              newData = {
                timestamp: parsedData.timestamp,
                text: parsedData.text,
                imageUrl: parsedData.imageUrl,
                action: parsedData.action,
                extra: parsedData.extra,
                imgSize: parsedData.imgSize,
                ctr: parsedData.ctr ? parsedData.ctr : 'no',
                name: parsedData.name,
              };
            } else {
              newData = {
                timestamp: parsedData.timestamp,
                text: parsedData.text,
                v: parsedData.v,
                thumb: parsedData.thumb,
                action: parsedData.action,
                extra: parsedData.extra,
                ctr: parsedData.ctr ? parsedData.ctr : 'no',
                name: parsedData.name,
              };
            }
            var newTargetType =
              targetType === 'all'
                ? '전체'
                : targetType === 'community'
                ? '공식 커뮤니티'
                : targetType === 'challenge'
                ? '챌린지'
                : targetType === 'reward'
                ? '리워드'
                : targetType === 'user'
                ? '개인'
                : targetType === 'health'
                ? '건강정보'
                : '-';

            var newName =
              targetType === 'all'
                ? '전체'
                : targetType === 'community'
                ? row.name
                : targetType === 'challenge'
                ? row.name
                : targetType === 'reward'
                ? row.name
                : targetType === 'user'
                ? row.name
                : targetType === 'health'
                ? getHealthItems()[row.healthItem - 1].title
                : '-';

            const newRequestDate = timestamp2Localestring(row.timestamp * 1000);

            const temp = {
              timelineId: row.timelineId,
              feedType: row.dataType === 7 ? 'basic' : 'youtube',
              receiver: {
                type: newTargetType,
                id:
                  row.targetId !== null && row.targetId !== ''
                    ? row.targetId
                    : '-',
                name: newName,
                healthItem: row.healthItem,
              },
              data: newData,
              site: site,
              timestamp: row.timestamp,
              requestDate: newRequestDate,
              imageUrlPrefix: row.imageUrlPrefix,
            };
            return temp;
          });
          setTimelineList(newTimeline);
          setTotalRecords(res.pagination.totalRecordCount);
        } else {
          setTimelineList([]);
          setTotalRecords(0);
        }
      },
      onError: e => {
        setLoading(false);
        console.log(e);
      },
    }
  );

  const prefetch = (newPage: number) => {
    queryClient.prefetchQuery(KEY_TIMELINE_LIST, () => {
      const now = new Date(Date.now());
      const nowStr = `${now.toISOString().split('T')[0]} 23:59:59`;
      const newNow = new Date(nowStr);
      const before30 = new Date(new Date().setDate(newNow.getDate() - 30));
      const newData = {
        site: site,
        targetType: targetType,
        targetId:
          targetTypeRef.current !== null ? targetTypeRef.current.value : '',
        healthItem: Number(healthItem),
        startDate:
          rangeDate[0] === null
            ? Math.round(before30.getTime() / 1000)
            : rangeDate[0].getTime() / 1000,
        endDate:
          rangeDate[1] === null
            ? Math.round(newNow.getTime() / 1000)
            : rangeDate[1].getTime() / 1000,
        page: newPage,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      console.log('timeline prefetch !!!!');
      setLoading(true);
      return getTimelineList(newData);
    });
  };

  const { mutate: cancelTimelineMutate, isError: isMutateError } = useMutation(
    cancelTimeline,
    {
      onSuccess: () => {
        setOpenConfirm(false);
        timelineRefetch();
      },
      onError: error => {
        console.log(error);
      },
    }
  );

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
    prefetch(newPage);
  };

  const cancelTimelineFeed = () => {
    const newReceiver = targets.filter(
      r => r.title === targetTimeline.receiver.type
    );

    const newCancel: ITimelineCancel = {
      timelineId: targetTimeline.timelineId,
      type: newReceiver[0].value,
      site: site,
    };
    cancelTimelineMutate(newCancel);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      setPage(1);
      timelineRefetch();
    }
  };

  useEffect(() => {
    setPage(1);
    timelineRefetch();
  }, [healthItem]);

  useEffect(() => {
    setPage(1);
    timelineRefetch();
  }, [targetType]);

  useEffect(() => {
    const now = new Date(Date.now());
    const before30 = new Date(new Date().setDate(now.getDate() - 30));
    console.log('before30', before30.getTime());
    console.log('now', now.getTime());
    setPage(1);
    timelineRefetch();
  }, [rangeDate]);

  return (
    <>
      <Paper sx={{ p: '.5rem 1rem', mt: '1.5rem' }}>
        <Box
          sx={{
            p: '1rem .5rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              p: '1rem .5rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography className='title'>타겟</Typography>
            <TextField
              select
              sx={{ width: '10em', mr: '1rem' }}
              defaultValue='empty'
              value={targetType}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => setTargetType(event.target.value)}
            >
              {targets.map((item: any, idx: number) => (
                <MenuItem value={item.value} key={idx.toString()}>
                  {item.title}
                </MenuItem>
              ))}
            </TextField>
            <div>
              {targetType === 'community' ||
              targetType === 'challenge' ||
              targetType === 'reward' ||
              targetType === 'user' ? (
                <TextField
                  placeholder={`${
                    targetType === 'community'
                      ? '커뮤니티'
                      : targetType === 'challenge'
                      ? '챌린지'
                      : targetType === 'reward'
                      ? '리워드'
                      : '닉네임, 연락처, '
                  } 아이디`}
                  inputRef={targetTypeRef}
                  sx={{ width: '20rem' }}
                  onKeyDown={handleKeyDown}
                />
              ) : targetType === 'health' ? (
                <TextField
                  select
                  defaultValue='1'
                  value={healthItem}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => setHealthItem(event.target.value)}
                  sx={{ width: '12rem', mr: '1rem' }}
                >
                  {getHealthItems().map((q, idx) => (
                    <MenuItem value={q.value} key={idx.toString()}>
                      {q.title}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                <></>
              )}
            </div>
          </Box>
          <Box className='flex'>
            <Collapse orientation='horizontal' in={openFilter}>
              <SwDateRangePicker
                rangeDate={rangeDate}
                setRangeDate={setRangeDate}
              />
            </Collapse>
            <Button
              color='info'
              variant='outlined'
              sx={{ ml: '1rem' }}
              onClick={() => setOpenFilter(!openFilter)}
            >
              <FilterAltOutlinedIcon />
              필터
              <ArrowBackIosNewOutlinedIcon
                sx={{ fontSize: '1rem', ml: '.5rem' }}
              />
            </Button>
          </Box>
        </Box>
        <TableContainer>
          <Table stickyHeader>
            <TableHead sx={{ bgcolor: '#F9FAFC' }}>
              <TableRow>
                {columns.map((column, idx) => (
                  <TableCell key={idx.toString()} sx={{ p: '.6rem' }}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {timelineList.length > 0 ? (
                timelineList?.map((row: any, idx: number) => (
                  <TableRow key={row.timelineId}>
                    <TableCell sx={{ p: '.3rem .6rem' }}>
                      {row.feedType === 'basic' ? '기본' : '유튜브'}
                    </TableCell>
                    <TableCell sx={{ p: '.3rem .6rem' }}>
                      {row.requestDate}
                    </TableCell>
                    <TableCell sx={{ p: '.3rem .6rem' }}>
                      {row.receiver.type}
                    </TableCell>
                    <TableCell sx={{ p: '.3rem .6rem' }}>
                      {row.receiver.name}
                    </TableCell>
                    <TableCell sx={{ p: '.3rem .6rem' }}>
                      {row.receiver.id}
                    </TableCell>
                    <TableCell sx={{ p: '.3rem .6rem' }}>
                      {row.data.timestamp}
                    </TableCell>
                    <TableCell sx={{ p: '.3rem .6rem' }}>
                      {row.data.text}
                    </TableCell>
                    <TableCell sx={{ p: '.3rem .6rem' }}>
                      {row.feedType === 'basic' &&
                      row.data.imageUrl !== undefined &&
                      row.data.imageUrl !== null &&
                      row.data.imageUrl !== '' ? (
                        <img
                          src={`${row.imageUrlPrefix}${row.data.imageUrl}`}
                          alt=''
                          style={{ width: '5vh', height: '5vh' }}
                        />
                      ) : row.feedType === 'youtube' &&
                        row.data.thumb !== undefined &&
                        row.data.thumb !== null &&
                        row.data.thumb !== '' ? (
                        <img
                          src={`${row.imageUrlPrefix}${row.data.thumb}`}
                          alt=''
                          style={{ width: '5vh', height: '5vh' }}
                        />
                      ) : (
                        <>-</>
                      )}
                    </TableCell>
                    <TableCell sx={{ p: '.3rem .6rem' }}>
                      {row.feedType === 'basic' ? '' : row.data.v}
                    </TableCell>
                    <TableCell sx={{ p: '.3rem .6rem' }}>
                      {row.data.action === 3 || row.data.action === 5
                        ? row.data.extra.n
                        : row.data.action === 9
                        ? row.data.extra.url
                        : '-'}
                    </TableCell>
                    <TableCell sx={{ p: '.3rem .6rem' }}>-</TableCell>
                    <TableCell>
                      <Button
                        id={row.timelineId}
                        size='small'
                        variant='outlined'
                        color='info'
                        onClick={(evt: any) => {
                          setTargetTimeline(row);
                          setOpenUpdate(true);
                        }}
                      >
                        내용수정
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        id={row.timelineId}
                        size='small'
                        variant='contained'
                        color='info'
                        onClick={(evt: any) => {
                          setTargetTimeline(row);
                          setOpenConfirm(true);
                        }}
                      >
                        피드 삭제
                      </Button>
                    </TableCell>
                    <TableCell>
                      {row.data.ctr === 'yes' && (
                        <Button
                          id={row.timelineId}
                          size='small'
                          variant='contained'
                          color='info'
                          onClick={(evt: any) => {
                            setTargetTimeline(row);
                            setOpenConfirm(true);
                          }}
                        >
                          상세보기
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={14}>검색결과 없습니다.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <SwPagination
          page={page}
          handleChangePage={handleChangePage}
          count={Math.ceil(totalRecords / rowsPerPage)}
        />
      </Paper>
      <SwConfirmDialog
        contents='타임라인 피드를 취소하겠습니까?'
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => cancelTimelineFeed()}
        confirm={1}
      />
      <TimelineUpdate
        targetTimeline={targetTimeline}
        open={openUpdate}
        onConfirm={() => {
          setOpenUpdate(false);
          prefetch(page);
        }}
        onClose={() => {
          setOpenUpdate(false);
          prefetch(page);
        }}
      />
      {loading && <SwLoading />}
    </>
  );
};
