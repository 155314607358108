import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  styled,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React, { ChangeEvent, Dispatch } from 'react';

interface Props {
  isSearching: () => void;
  searchKey: any;
  searchWord: any;
  searchOption: any[];
  selectLength?: string;
  searchLength?: string;
  placeholderString?: string;
  setSearchKey: Dispatch<React.SetStateAction<string>>;
  setSearchWord: Dispatch<React.SetStateAction<string>>;
}

const Container = styled(Box)({
  borderRadius: '12px',
  border: '1px solid #D4D9E1',
  display: 'flex',
  backgroundColor: '#FFFFFF',
  alignItems: 'center',
});

const NoborderInput = styled(TextField)({
  marginBottom: 0,
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
    },
  },
});

const SearchDivider = styled(Divider)({
  padding: 0,
  margin: '.4rem .4rem .4rem 0',
});

export const SwSelectSearchUser: React.FC<Props> = ({
  searchWord,
  searchKey,
  searchOption,
  isSearching,
  searchLength = '26rem',
  selectLength = '10rem',
  placeholderString = '검색어를 입력해주세요.',
  setSearchKey,
  setSearchWord,
}) => {
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      isSearching();
    }
  };

  return (
    <div className='flex_center'>
      <Container>
        <NoborderInput
          select
          value={searchKey}
          sx={{ width: selectLength }}
          onChange={(
            evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            setSearchKey(evt.target.value);
          }}
        >
          {searchOption.map((option: any) => (
            <MenuItem value={option.value} key={option.value}>
              {option.title}
            </MenuItem>
          ))}
        </NoborderInput>
        <SearchDivider orientation='vertical' flexItem />
        <NoborderInput
          placeholder={placeholderString}
          value={searchWord}
          sx={{ width: searchLength }}
          onKeyDown={handleKeyDown}
          onChange={(
            evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            setSearchWord(evt.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={isSearching} sx={{ pr: 0 }}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Container>
    </div>
  );
};
