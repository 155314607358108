/* eslint-disable global-require */
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  styled,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import AddIcon from '@mui/icons-material/Add';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import React, {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { SwSwitch } from '../../styles/Styles';

const TextBox = styled(Box)({
  width: '6vw',
  padding: '.2rem .4rem',
  maxWidth: '6rem',
  minWidth: '5.9rem',
  marginLeft: '.3rem',
});

const OverlayView = styled(Box)({
  position: 'absolute',
  zIndex: 100,
  padding: '.5rem',
  margin: '1rem',
  height: '8%',
  display: 'flex',
  justifyContent: 'space-between',
  width: '98%',
});

const OverlayViewDivider = styled(Divider)({
  margin: 0,
  padding: 0,
});

const RadiusInput = styled(TextField)({
  maxWidth: '6.5rem',
  backgroundColor: '#FFFFFF',
  '& .MuiOutlinedInput-root': {
    height: '2.4rem',
    borderRadius: '4px',
    paddingRight: 10,
    paddingLeft: 10,
  },
  '& .MuiOutlinedInput-input': {
    padding: 0.1,
  },
});

const SwitchPaper = styled(Paper)({
  // display: 'flex',
  zIndex: 10,
  padding: '.5rem',
  margin: '1rem .5rem',
  minWidth: '6rem',
});

const ContentsPaper = styled(Paper)({
  width: '29%',
  height: '70vh',
  borderRadius: '12px',
  marginLeft: '1%',
});

const ButtonPaper = styled(Paper)({
  borderRadius: '12px',
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  width: '8rem',
  height: '3rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '.1rem',
  cursor: 'grab',
  boxShadow: 'none',
});

interface Props {
  courseData?: any;
  mapInfo?: any;
  setMapInfo?: any;
  elementId?: string;
}

export const SwWalkcourseMap: React.FC<Props> = ({
  courseData = null,
  mapInfo = null,
  setMapInfo = null,
  elementId = 'map',
}) => {
  const map = useRef<any>();
  const maps = document.getElementById(elementId);
  const drawingPointsRef = useRef<any>(null);
  const tempCircleOpacityRef = useRef<any>(0.6);
  const tempPolylineRef = useRef<any>();
  const tempCourseRef = useRef<any>();
  const tempMarkerRef = useRef<any>();
  const centerRef = useRef<any>();
  const radiusRef = useRef<any>();
  const reloadRef = useRef<any>(null);
  const addPointRef = useRef<any>(null);
  const changePointRef = useRef<any>(null);
  const tempPointsRef = useRef<any>([]);
  const stampCircleRef = useRef<any>(150);
  const [showOverlay, setShowOverlay] = useState<boolean>(true);
  const [contentMarkerShow, setContentMarkerShow] = useState<boolean>(false);
  const [selectDrawCircle, setSelectDrawCircle] = useState<boolean>(false);
  const [circle, setCircle] = useState<any>();
  const [pointChange, setPointChange] = useState<boolean>(false);
  const [drawingOpacity, setDrawingOpacity] = useState(false);
  const [mapCenter, setMapCenter] = useState<any>([37.5, 127.012]);
  const [stampCircle, setStampCircle] = useState<number>(150);
  const [courseWeight, setCourseWeight] = useState<number>(10);
  const [courseDistance, setCourseDistance] = useState<number>(0);
  const [currentDistance, setCurrentDistance] = useState<any>([]);
  const [courseTime, setCourseTime] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<any>([]);
  const [markerStart, setMarkerStart] = useState(false);
  const [points, setPoints] = useState<any>([]);
  const [course, setCourse] = useState<any>(null);
  const [marker, setMarker] = useState<any>([]);
  const [polyline, setPolyline] = useState<any>(null);
  const [uploadFile, setUploadFile] = useState<any>('');
  const [tempPoint, setTempPoints] = useState<any>([]);
  const [markerShow, setMarkerShow] = useState<boolean>(true);
  const [isEdit, setIsEdit] = useState<any>(null);
  let ncourse: any;
  let drawingCircle: any;
  let drawingCircleLine: any;
  let length: any;
  let drawingCircleInfo: any;
  let drawingLine: any;
  let tempCircle: any;
  let tempPosition: any;
  let tempPolyline: any;
  let currentMarker: any;
  let currentCircle: any;
  let centerPosition: any;
  let mousePosition: any;
  const pointContent = `<div  class="info" style="padding:5px;margin:10px; background-color:white; border:black solid 1px; white-space: pre;" >지도 위에 원하는\n지점을 일정한 간격으로 클릭하며\n스탬프를 만들어보세요.</div>`;
  const radiusContent = `<div  class="info" style="padding:5px;margin:10px; background-color:white; border:black solid 1px; white-space: pre;" >위치 인식 반경을 그려주세요. :)\n원의 중심점을 클릭해주세요!</div>`;
  const changePointContent = `<div  class="info" style="padding:5px;margin:10px; background-color:white; border:black solid 1px; white-space: pre;" >이동하실 위치를 선택해주세요! :)</div>`;
  const mouseMoveCustomOverlay = new naver.maps.InfoWindow({
    content: pointContent,
    borderWidth: 0,
    disableAnchor: true,
    backgroundColor: 'transparent',
    pixelOffset: new naver.maps.Point(0, -28),
  });

  const setDistance = (distance: number) => {
    const newDistance = Math.round(distance).toString();
    const contentLength = newDistance.length;
    let result: any;
    if (contentLength > 4) {
      const settingKm = Math.floor(distance / 1000);
      const settingMeter = (distance % 1000).toString().split('.')[0];
      result = `${settingKm}.${settingMeter}km`;
    } else if (contentLength === 4) {
      result = `${newDistance[0]},${newDistance.slice(1, 4)}m`;
    } else {
      result = `${newDistance}m`;
    }
    return result;
  };

  const setTime = (time: number) => {
    const newTime = Math.round(time);
    if (time / 60 >= 1) {
      const hour = Math.floor(newTime / 60);
      const minute = newTime % 60;
      return `${hour}시간${minute}분`;
    }
    if (newTime === 0) {
      const seconds = Math.round(time * 60);
      return `${seconds}초`;
    }
    return `${newTime}분`;
  };

  const initMap = () => {
    if (maps) {
      map.current = new naver.maps.Map(elementId, {
        center: new naver.maps.LatLng(mapCenter[0], mapCenter[1]),
        zoom: 15,
      });
      const checkRadius = new naver.maps.Circle({
        map: map.current,
        center: new naver.maps.LatLng(mapCenter[0], mapCenter[1]),
        radius: stampCircle,
        strokeWeight: 1,
        strokeColor: '#00a0e9',
        strokeOpacity: 0,
      });
      naver.maps.Event.addListener(map.current, 'zoom_changed', () => {
        checkRadius.setRadius(stampCircleRef.current);
        const newMaxBounds = checkRadius.getDrawingRect().getMax();
        const newMinBounds = checkRadius.getDrawingRect().getMin();
        const newWeight = newMaxBounds.y - newMinBounds.y;
        setCourseWeight(newWeight);
      });
    }
  };

  // 지도 이벤트 설정

  const drawingMap = () => {};

  const parseGPX = (xml: any) => {
    const parser = new DOMParser();
    const result: any = [];
    const newXML = parser.parseFromString(uploadFile, 'text/xml');
    const toJson = newXML.querySelectorAll('trkpt');
    toJson.forEach((element: any, idx: number) => {
      idx % 2 &&
        idx % 4 === 1 &&
        idx % 8 === 1 &&
        idx % 16 === 1 &&
        // idx % 32 === 1 &&
        //   idx % 64 === 1 &&
        result.push({
          lat: element.attributes[0].value,
          lng: element.attributes[1].value,
        });
    });
    setTempPoints(result);
    setUploadFile('');
    setMapCenter([result[0]?.lat, result[0]?.lng]);
  };

  const getCurrentPosition = () => {
    const handleGeoSucces = (position: any) => {
      setMapCenter([position.coords.latitude, position.coords.longitude]);
    };
    const handleGeoError = () => {
      console.log('access geo location error>>>>>>>>>>>>>');
    };
    return navigator.geolocation.getCurrentPosition(
      handleGeoSucces,
      handleGeoError
    );
  };

  const deletePoint = (
    evt: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    marker?.map((m: any) => m?.setMap(null));
    const newPoints = points.filter(
      (_point: any, i: number) => i !== Number(evt.currentTarget.id)
    );
    setPoints(newPoints);
    const newCourse = course;
    const newPolyline = polyline;
    const newMarker = marker.filter(
      (_m: any, i: number) => i !== Number(evt.currentTarget.id)
    );
    marker[Number(evt.currentTarget.id)]?.setMap(null);
    const newCurrentDistance = currentDistance.filter(
      (_distance: any, i: number) => Number(evt.currentTarget.id) !== i
    );
    const newCurrentTime = currentTime.filter(
      (_time: any, i: number) => Number(evt.currentTarget.id) !== i
    );
    if (newPoints.length > 0) {
      newPolyline.setOptions({
        path: newPoints.map((p: any) => new naver.maps.LatLng(p.lat, p.lng)),
      });
      newCourse.setOptions({
        path: newPoints.map((p: any) => new naver.maps.LatLng(p.lat, p.lng)),
      });
      reloadRef.current = newPoints[newPoints.length - 1];
      setMarker(newMarker);
      setCourse(newCourse);
      setPolyline(newPolyline);
      setCourseDistance(newCourse.getDistance());
      setCourseTime(newCourse.getDistance() / 67);
      setCurrentDistance(newCurrentDistance);
      setCurrentTime(newCurrentTime);
    } else {
      newCourse?.setMap(null);
      newPolyline?.setMap(null);
      setMarkerStart(false);
      setMarker(newMarker);
      setCourse(null);
      setPolyline(null);
      setCourseDistance(0);
      setCourseTime(0);
      setCurrentDistance([]);
      setCurrentTime([]);
      reloadRef.current = 1;
    }
  };

  useMemo(() => {
    if (
      map.current !== null &&
      map.current !== undefined &&
      map.current.updateBy !== undefined
    ) {
      map.current.updateBy(
        new naver.maps.LatLng(mapCenter[0], mapCenter[1]),
        15
      );
    }
  }, [mapCenter]);

  useEffect(() => {
    console.log('maps>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
    initMap();
    drawingMap();
    return () => {
      map.current = null;
      drawingPointsRef.current = null;
      tempCircleOpacityRef.current = 0.6;
      tempPolylineRef.current = null;
      tempCourseRef.current = null;
      tempMarkerRef.current = null;
      centerRef.current = null;
      radiusRef.current = null;
      reloadRef.current = null;
      addPointRef.current = null;
      changePointRef.current = null;
      tempPointsRef.current = [];
      stampCircleRef.current = 150;
    };
  }, [maps]);

  useEffect(() => {
    console.log('drawingOpacity>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
    course?.setOptions({
      strokeOpacity: drawingOpacity ? 0 : 0.3,
    });
    course?.setMap(map.current);
    tempCircleOpacityRef.current = drawingOpacity ? 0 : 0.6;
  }, [drawingOpacity]);

  useEffect(() => {
    if (points.length > 1) {
      console.log('stampCircle>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
      currentCircle = new naver.maps.Circle({
        map: map.current,
        center: new naver.maps.LatLng(
          points[points.length - 1].lat,
          points[points.length - 1].lng
        ),
        radius: stampCircle,
        strokeOpacity: 0,
        fillOpacity: 0,
      });
      const newMaxBounds = currentCircle.getDrawingRect().getMax();
      const newMinBounds = currentCircle.getDrawingRect().getMin();
      const newWeight = newMaxBounds.y - newMinBounds.y;
      setCourseWeight(newWeight);
      course?.setOptions({
        strokeWeight: newWeight,
      });
      polyline?.setOptions({
        strokeWeight: courseWeight / 15,
      });
      course?.setMap(map.current);
      polyline?.setMap(map.current);
    }
  }, [stampCircle]);

  // 워크 코스 포인트 설정
  useEffect(() => {
    if (centerRef.current) {
      console.log('markerStart>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', course);
      const newPoint = { lat: centerRef.current.y, lng: centerRef.current.x };
      const newPoints = [...points, newPoint];
      currentMarker = new naver.maps.Marker({
        position: new naver.maps.LatLng(newPoint?.lat, newPoint?.lng),
        map: map.current,
        icon: {
          content: `<div class='walkcourse_marker'>${newPoints.length}</div>`,
          anchor: new naver.maps.Point(13, 33),
        },
        clickable: false,
        draggable: false,
      });
      // naver.maps.Event.addListener(currentMarker, 'click', (e: any) => {
      //   console.log(e);
      // });
      setMarker([...marker, currentMarker]);
      currentMarker?.setMap(null);
      currentCircle = new naver.maps.Circle({
        map: map.current,
        center: new naver.maps.LatLng(newPoint.lat, newPoint.lng),
        radius: stampCircle,
        strokeWeight: 1,
        strokeColor: '#00a0e9',
        strokeOpacity: 0,
        strokeStyle: 'solid',
        fillColor: '#00a0e9',
        fillOpacity: 0,
      });
      const newMaxBounds = currentCircle.getDrawingRect().getMax();
      const newMinBounds = currentCircle.getDrawingRect().getMin();
      const newWeight = newMaxBounds.y - newMinBounds.y;
      setPoints(newPoints);
      setCourseWeight(newWeight);
      centerRef.current = null;
      if (points.length < 1) {
        ncourse = new naver.maps.Polyline({
          path: newPoints.map(
            (point: any) => new naver.maps.LatLng(point.lat, point.lng)
          ),
          strokeWeight: newWeight,
          strokeColor: '#2681FF6F',
          strokeOpacity: 0.3,
          strokeStyle: 'solid',
          strokeLineCap: 'round',
          strokeLineJoin: 'round',
        });
        tempPolyline = new naver.maps.Polyline({
          path: newPoints.map(
            (point: any) => new naver.maps.LatLng(point.lat, point.lng)
          ),
          strokeWeight: newWeight / 15,
          strokeColor: '#2681FF',
          strokeOpacity: 1,
          strokeStyle: 'solid',
          strokeLineCap: 'round',
          strokeLineJoin: 'round',
        });
        console.log('if>>>>>>>>>>>>>', ncourse?.getDistance());
        setPolyline(tempPolyline);
        setCourse(ncourse);
        setCourseDistance(ncourse.getDistance());
        setCourseTime(ncourse.getDistance() / 67);
        setCurrentDistance([...currentDistance, ncourse.getDistance()]);
        setCurrentTime([...currentTime, ncourse.getDistance() / 67]);
        ncourse.setMap(null);
        tempPolyline.setMap(null);
      } else {
        const prePoint = newPoints.length - 2;
        const newCourse = course;
        const newPolyline = polyline;
        newPolyline.setOptions({
          path: newPoints.map(
            (point: any) => new naver.maps.LatLng(point.lat, point.lng)
          ),
        });
        newCourse.setOptions({
          path: newPoints.map(
            (point: any) => new naver.maps.LatLng(point.lat, point.lng)
          ),
        });
        ncourse = new naver.maps.Polyline({
          map: map.current,
          path: [
            new naver.maps.LatLng(
              newPoints[prePoint]?.lat,
              newPoints[prePoint]?.lng
            ),
            new naver.maps.LatLng(newPoint.lat, newPoint.lng),
          ],
          strokeOpacity: 0,
        });
        console.log('else>>>>>>>>>>>>>', ncourse);
        setCourse(newCourse);
        setPolyline(newPolyline);
        setCourseDistance(newCourse.getDistance());
        setCourseTime(newCourse.getDistance() / 67);
        setCurrentDistance([...currentDistance, ncourse.getDistance()]);
        setCurrentTime([...currentTime, ncourse.getDistance() / 67]);
        ncourse.setMap(null);
      }
    }
  }, [markerStart]);

  useEffect(() => {
    console.log('circle>>>>>>>>>>>>>>>>>>>>>>>>>');
    circle?.setMap(map.current);
  }, [circle]);

  useEffect(() => {
    if (course) {
      console.log('courseWeight>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
      const newCourse = course;
      const newPolyline = polyline;
      newCourse?.setOptions({
        strokeWeight: courseWeight,
      });
      newPolyline?.setOptions({
        strokeWeight: courseWeight / 15,
      });
      setCourse(newCourse);
      setPolyline(newPolyline);
      newCourse?.setMap(map.current);
      newPolyline?.setMap(map.current);
    }
  }, [courseWeight]);

  useEffect(() => {
    console.log('points>>>>>>>>>>>>>>>>>>>>>>>>>');
    marker?.map((m: any, idx: number) =>
      m.setIcon({
        content: `<div class='walkcourse_marker'>${idx + 1}</div>`,
        anchor: new naver.maps.Point(13, 33),
      })
    );
    marker?.map((m: any) => m?.setMap(map.current));
  }, [points]);

  useEffect(() => {
    if (isEdit !== null) {
      console.log('isEdit>>>>>>>>>>>>>>>>>>>>>>>>>null');
      course?.setMap(null);
      polyline?.setMap(null);
      marker?.map((m: any) => m?.setMap(null));
      tempPolylineRef.current = polyline;
      tempCourseRef.current = course;
      tempMarkerRef.current = marker;
      tempMarkerRef.current?.map((m: any) => m?.setMap(map.current));
      const markers = document.querySelectorAll('div.walkcourse_marker');
      markers
        .item(changePointRef.current)
        .classList.add('walkcourse_marker_selected');
      tempCourseRef.current.setMap(map.current);
      tempPolylineRef.current.setMap(map.current);
    } else if (changePointRef.current) {
      console.log('isEdit>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>notnull');
      tempMarkerRef.current?.map((m: any) => m?.setMap(null));
      changePointRef.current = null;
      const newCourse = new naver.maps.Polyline({
        path: tempPointsRef.current?.map(
          (point: any) => new naver.maps.LatLng(point.lat, point.lng)
        ),
        strokeWeight: courseWeight,
        strokeColor: '#2681FF6F',
        strokeOpacity: 0.5,
        strokeStyle: 'solid',
        strokeLineCap: 'round',
        strokeLineJoin: 'round',
      });
      const newPolyline = new naver.maps.Polyline({
        path: tempPointsRef.current?.map(
          (point: any) => new naver.maps.LatLng(point.lat, point.lng)
        ),
        strokeWeight: courseWeight / 15,
        strokeColor: '#2681FF',
        strokeOpacity: 1,
        strokeStyle: 'solid',
        strokeLineCap: 'round',
        strokeLineJoin: 'round',
      });
      const newMarker: any = [];
      tempPointsRef.current?.map((q: any, idx: number) => {
        newMarker.push(
          new naver.maps.Marker({
            position: new naver.maps.LatLng(q?.lat, q?.lng),
            map: map.current,
            icon: {
              content: `<div class='walkcourse_marker'>${idx + 1}</div>`,
              anchor: new naver.maps.Point(13, 33),
            },
            clickable: false,
            draggable: false,
          })
        );
      });
      setPoints(tempPointsRef.current);
      setMarker(newMarker);
      setCourse(newCourse);
      setPolyline(newPolyline);
      reloadRef.current = tempPointsRef.current
        ? tempPointsRef.current[tempPointsRef.current.length - 1]
        : null;
      newCourse?.setMap(map.current);
      newPolyline?.setMap(map.current);
      newMarker?.map((m: any) => m?.setMap(map.current));
    }
  }, [isEdit]);

  useMemo(() => {
    if (tempCourseRef.current && pointChange) {
      console.log('pointchange>>>>>>>>>>>>>>>>>>>>>>');
      const tempTime: any = [];
      const tempDistance: any = [];
      tempPointsRef.current?.map((q: any, idx: number) => {
        tempPolyline = new naver.maps.Polyline({
          path: [
            new naver.maps.LatLng(
              tempPointsRef.current[idx - 1]?.lat,
              tempPointsRef.current[idx - 1]?.lng
            ),
            new naver.maps.LatLng(q.lat, q.lng),
          ],
          map: map.current,
          strokeOpacity: 0,
        });
        tempPolyline?.getDistance() &&
          tempTime.push(tempPolyline.getDistance() / 67);
        tempDistance.push(tempPolyline?.getDistance());
      });
      setCurrentDistance(tempDistance);
      setCurrentTime(tempTime);
      setCourseDistance(tempCourseRef.current.getDistance());
      setCourseTime(tempCourseRef.current.getDistance() / 67);
      console.log(
        'erer>>>>>>>>>>>>>',
        new naver.maps.LatLng(
          tempPointsRef.current[-1]?.lat,
          tempPointsRef.current[-1]?.lng
        )
      );
      tempPolyline?.setMap(null);
      setPointChange(false);
    }
  }, [pointChange]);

  useEffect(() => {
    if (uploadFile) {
      console.log('uploadFile>>>>>>>>>>>>>>>>>>>>>>>>>>>');
      parseGPX(uploadFile);
    }
  }, [uploadFile]);

  useEffect(() => {
    if (tempPoint.length > 0) {
      console.log('tempPoint>>>>>>>>>>>>>>>>>>>>>>');
      course?.setMap(null);
      polyline?.setMap(null);
      marker.map((m: any) => m?.setMap(null));
      const tempstime: any = [];
      const tempsdistance: any = [];
      tempPoint?.map((q: any, idx: number) => {
        tempPolyline = new naver.maps.Polyline({
          path: [
            new naver.maps.LatLng(
              tempPoint[idx - 1]?.lat,
              tempPoint[idx - 1]?.lng
            ),
            new naver.maps.LatLng(q.lat, q.lng),
          ],
          map: map.current,
          strokeOpacity: 0,
        });
        tempPolyline?.getDistance() &&
          tempstime.push(tempPolyline.getDistance() / 67);
        tempsdistance.push(tempPolyline?.getDistance());
      });
      tempPolyline?.setMap(null);
      setCurrentDistance(tempsdistance);
      setCurrentTime(tempstime);
      ncourse = new naver.maps.Polyline({
        path: tempPoint?.map(
          (point: any) => new naver.maps.LatLng(point.lat, point.lng)
        ),
        map: map.current,
        strokeWeight: courseWeight,
        strokeColor: '#2681FF6F',
        strokeOpacity: drawingOpacity ? 0 : 0.3,
        strokeStyle: 'solid',
        strokeLineCap: 'round',
        strokeLineJoin: 'round',
      });
      tempPolyline = new naver.maps.Polyline({
        path: tempPoint.map(
          (point: any) => new naver.maps.LatLng(point.lat, point.lng)
        ),
        map: map.current,
        strokeWeight: courseWeight / 15,
        strokeColor: '#2681FF',
        strokeOpacity: 1,
        strokeStyle: 'solid',
        strokeLineCap: 'round',
        strokeLineJoin: 'round',
      });
      const temps: any = [];
      tempPoint?.map((q: any, idx: number) => {
        temps.push(
          new naver.maps.Marker({
            position: new naver.maps.LatLng(q?.lat, q?.lng),
            map: map.current,
            icon: {
              content: `<div class='walkcourse_marker'>${idx + 1}</div>`,
              anchor: new naver.maps.Point(13, 33),
            },
            clickable: false,
            draggable: false,
          })
        );
        // naver.maps.Event.addListener(temps[idx], 'click', (e: any) => {
        //   console.log(e);
        // });
      });
      setMarker(temps);
      setPolyline(tempPolyline);
      setCourse(ncourse);
      setCourseDistance(ncourse.getDistance());
      setCourseTime(ncourse.getDistance() / 67);
      setPoints(tempPoint);
      setTempPoints([]);
    }
  }, [tempPoint]);

  const test = () => {
    const newDistance = courseDistance / 100;
    let i = 1;
    let sumDistance = 0;
    const reducer = (q: any, val: any, idx: number) => {
      if (idx + 1 < points.length) {
        const newCurrentDistance = currentDistance[idx + 1];
        console.log('newCurrentDistance>>>>>>>>>', newCurrentDistance);
        do {
          const ratio = (newDistance * i - sumDistance) / newCurrentDistance;
          const newPath = {
            lat: val.lat + ratio * (points[idx + 1].lat - val.lat),
            lng: val.lng + ratio * (points[idx + 1].lng - val.lng),
          };
          q.push(newPath);
          i += 1;
        } while (sumDistance + newCurrentDistance > i * newDistance);
        sumDistance += newCurrentDistance;
      }
      return q;
    };
    const newPoints = points.reduce(reducer, []);
    setTempPoints(newPoints);
  };

  useEffect(() => {
    console.log('gps Data>>>>>>>>>>>>>>>>>>>>>>>>>:', courseData);
    setSelectDrawCircle(false);
    if (courseData && courseData.gpsData.length > 0) {
      console.log('gps Data: ', courseData.gpsData);
      const newTempPoints = courseData.gpsData;
      setTempPoints(newTempPoints);
      setMapCenter([newTempPoints[0]?.lat, newTempPoints[0]?.lng]);

      var newRadius = 9999999;
      courseData.gpsData.map((gps: any) => {
        newRadius = gps.radius < newRadius ? gps.radius : newRadius;
      });
      setStampCircle(newRadius);
      stampCircleRef.current = newRadius;
    } else {
      setMapCenter([37.5, 127.012]);
    }
  }, [courseData]);

  return (
    <div style={{ margin: '2rem 0' }}>
      <Box className='flex_center'>
        <div
          id={elementId}
          ref={map}
          style={{
            width: '100%',
            height: '70vh',
            borderRadius: '10px',
            minWidth: '500px',
          }}
          onContextMenu={e => {
            e.preventDefault();
          }}
        >
          <OverlayView>
            {showOverlay && (
              <Paper className='flex_center' sx={{ p: '1rem .5rem' }}>
                <TextBox>
                  <Typography>현재거리</Typography>
                  <Typography>
                    {currentDistance[currentDistance.length - 1]
                      ? setDistance(currentDistance[currentDistance.length - 1])
                      : '0m'}
                  </Typography>
                </TextBox>
                <OverlayViewDivider orientation='vertical' flexItem />
                <TextBox>
                  <Typography>걷기 시간</Typography>
                  <Typography>
                    {currentTime[currentTime.length - 1]
                      ? setTime(currentTime[currentTime.length - 1])
                      : '0분'}
                  </Typography>
                </TextBox>
                <OverlayViewDivider orientation='vertical' flexItem />
                <TextBox>
                  <Typography>총 거리</Typography>
                  <Typography>{setDistance(courseDistance)}</Typography>
                </TextBox>
                <OverlayViewDivider orientation='vertical' flexItem />
                <TextBox>
                  <Typography>총 걷기 시간</Typography>
                  <Typography>{setTime(courseTime)}</Typography>
                </TextBox>
              </Paper>
            )}
            <div className='flex_center'>
              {showOverlay && (
                <>
                  <Box className='ml_1rem'>
                    <RadiusInput
                      type='number'
                      defaultValue={150}
                      onChange={evt => {
                        setStampCircle(Number(evt.target.value));
                        stampCircleRef.current = Number(evt.target.value);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>반경</InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position='end'>m</InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <SwitchPaper
                    className='flex_between'
                    sx={{ alignItems: 'center' }}
                  >
                    <Box className='flex_between'>
                      <Typography sx={{ m: '0 .3rem' }}>반경 표시</Typography>
                      <SwSwitch
                        id='test'
                        defaultChecked
                        value={drawingOpacity}
                        onClick={() => {
                          setDrawingOpacity(!drawingOpacity);
                        }}
                      />
                    </Box>
                    {contentMarkerShow && (
                      <Box className='flex_between'>
                        <Typography sx={{ m: '0 .3rem' }}>마커 표시</Typography>
                        <SwSwitch
                          id='test'
                          defaultChecked
                          value={drawingOpacity}
                          onClick={() => {
                            markerShow
                              ? marker?.map((m: any) => m?.setMap(null))
                              : marker?.map((m: any) => m?.setMap(map.current));
                            setMarkerShow(!markerShow);
                          }}
                        />
                      </Box>
                    )}
                  </SwitchPaper>
                </>
              )}
              {showOverlay && (
                <Paper>
                  <IconButton size='small' onClick={getCurrentPosition}>
                    <GpsFixedIcon />
                  </IconButton>
                </Paper>
              )}
            </div>
          </OverlayView>
        </div>
      </Box>
    </div>
  );
};
