import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ChangeEvent, useEffect, useState, Dispatch } from 'react';

interface Props {
  image: string;
  width?: string;
  height?: string;
  open: boolean;
  onClose: () => void;
}

export const SwImageView: React.FC<Props> = ({
  image,
  width = '100rem',
  height = 'auto',
  open,
  onClose,
}) => {
  useEffect(() => {}, [open]);

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiDialog-container': {
          height: '85%',
        },
      }}
    >
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogContent sx={{ p: '1rem 2rem' }}>
        {image && (
          <Box sx={{ m: '1rem 0' }}>
            <img
              src={image}
              alt={image}
              style={{
                maxWidth: '25vw',
                borderRadius: '14px',
                width: width,
                height: height,
              }}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button color='primary' fullWidth size='small' onClick={onClose}>
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};
