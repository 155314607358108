import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState, Dispatch } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useMany } from '../../hooks/api';
import { getWalkcourseList } from '../../common/api/ApiWalkcourse';
import { KEY_WALKCOURSE_LIST } from '../../common/key';

interface Props {
  title: string;
  searchKey: string;
  searchWord: string;
  open: boolean;
  onClose: () => void;
  setTarget: Dispatch<React.SetStateAction<any[]>>;
}

const columns = ['이름', '아이디'];

export const SwSearchWalkcourse: React.FC<Props> = ({
  title,
  open,
  onClose,
  searchKey,
  searchWord,
  setTarget,
}) => {
  const queryClient = useQueryClient();
  const [targetList, setTargetList] = useState<any>([]);

  const clickItem = (row: any) => {
    const target = [
      {
        id: row.courseId,
        name: row.courseName,
        distance: row.courseDistance,
        time: row.courseTime,
        cal: row.courseCal,
        step: row.courseStep,
      },
    ];
    setTarget(target);
    onClose();
  };

  const {
    data,
    isLoading,
    refetch: walkwayCourseRefetch,
  } = useQuery(
    KEY_WALKCOURSE_LIST,
    () => {
      if (searchKey !== '') {
        const newData = {
          page: 0,
          rowsPerPage: 0,
          searchKey: 'all',
          searchWord: searchWord,
        };
        return getWalkcourseList(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log('SwSearchWalkcourse :', res);
        if (res !== null) {
          setTargetList(res.course);
          if (res.course.length === 1) {
            clickItem(res.course[0]);
          }
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const walkwayCoursePrefetch = (newSearchWord: string) => {
    queryClient.prefetchQuery(KEY_WALKCOURSE_LIST, () => {
      const newData = {
        page: 0,
        rowsPerPage: 0,
        searchKey: 'all',
        searchWord: newSearchWord,
      };
      return getWalkcourseList(newData);
    });
  };

  useEffect(() => {
    if (open) {
      walkwayCourseRefetch();
    }
    return () => {};
  }, [open]);

  return (
    <Dialog
      open={open}
      fullScreen
      sx={{
        '& .MuiDialog-paper': {
          height: '66vh',
          margin: '10rem',
        },
      }}
    >
      <DialogTitle>
        <Box className='flex_between'>
          <span>
            {searchWord.length > 0 && `'${searchWord}'`} {title} 검색결과
          </span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogContent>
        <TableContainer>
          <Table stickyHeader>
            <TableHead sx={{ bgcolor: '#F9FAFC' }}>
              <TableRow>
                {columns.map((column, idx) => (
                  <TableCell key={idx.toString()} sx={{ p: '.6rem' }}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {targetList?.map((row: any, idx: number) => (
                <TableRow key={idx.toString()} onClick={() => clickItem(row)}>
                  <TableCell key={idx} sx={{ p: '.3rem .6rem' }}>
                    {row.courseName}
                  </TableCell>
                  <TableCell sx={{ p: '.3rem .6rem' }}>
                    {row.courseId}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <Divider sx={{ m: 0 }} />
      {/* <DialogActions sx={{ p: '1rem' }}>
        <Button
          variant='contained'
          color='secondary'
          sx={{ fontSize: '.87rem' }}
          onClick={onClose}
        >
          닫기
        </Button>
      </DialogActions> */}
    </Dialog>
  );
};
