import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRange, DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TableTitle } from '../../commonComponent/tableElement/TableTitle';
import { CommunitySettingChange } from './CommunitySettingChange';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SelectTextField, TableTopButton, SwSwitch } from '../../styles/Styles';
import {
  deleteCommunityBanner,
  getCommunityBannerList,
  putCommunityOpenStep,
} from '../../../common/api/ApiCommunity';
import { KEY_COMMUNITY_BANNER_LIST } from '../../../common/key';
import {
  TOOLTIP_COMMUNITY_HIDDEN_STEP,
  TOOLTIP_FONT_SIZE,
  timestamp2Localestring,
} from '../../../common/helper';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import { chosenCommunityState } from '../../../common/atom';
import { ICommunity } from '../../../models/community';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { CommunitySettingWorkStep } from './CommunitySettingWorkStep';

interface Props {}

const columns = ['이미지', '액션', '공개 시작일시', '공개 종료일시'];

export const CommunitySetting: React.FC<Props> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [chosenCommunity, setChosenCommunity] =
    useRecoilState(chosenCommunityState);
  const [communityData, setCommunityData] = useState<any>(location.state);
  const [banners, setBanners] = useState<any>([]);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [openSettingChange, setOpenSettingChange] = useState(false);
  const [bannerId, setBannerId] = useState<number>(0);
  const [title, setTitle] = useState('');
  const [page, setPage] = useState(1);
  const [privateStep, setPrivateStep] = useState<number>(0);
  const [useRank, setUseRank] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [checkedButtons, setCheckedButtons] = useState<string[]>([]);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);

  const {
    data,
    isLoading,
    refetch: communityBannerRefetch,
  } = useQuery(
    KEY_COMMUNITY_BANNER_LIST,
    () => {
      const communityId = communityData && communityData.communityId;
      if (communityId.length > 0) return getCommunityBannerList(communityId);
      return null;
    },
    {
      onSuccess: res => {
        if (res !== null) {
          console.log('banner :', res);
          const banner = res.banner.map((row: any, idx: number) => {
            const parsedData = JSON.parse(row.data);

            const newBanner = {
              bannerId: row.bannerId,
              image: parsedData.imageUrl,
              action: parsedData.extra.url,
              startDate: timestamp2Localestring(row.stime * 1000),
              endDate: timestamp2Localestring(row.etime * 1000),
            };
            return newBanner;
          });
          setBanners(banner);
          console.log('banners:', banner);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { mutate: deleteCommunityBannerMutate, isError: isMutateError } =
    useMutation(deleteCommunityBanner, {
      onSuccess: () => {
        setOpenDialog(true);
        setDialogMessage('배너를 삭제했습니다.');
        communityBannerRefetch();
      },
      onError: error => {
        setOpenDialog(true);
        setDialogMessage(
          '배너 삭제가 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });

  const {
    mutate: changeCommunityOpenStepMutate,
    isError: isChangeISPrivateMutateError,
  } = useMutation(putCommunityOpenStep, {
    onSuccess: () => {
      setOpenDialog(true);
      setDialogMessage(
        useRank === 1
          ? `${
              privateStep === 1
                ? '일괄 걸음수 비공개'
                : privateStep === 2
                ? '일괄 걸음수 공개'
                : '사용자 지정처리'
            }했습니다.`
          : '커뮤니티 걸음 수(랭킹)을 사용안함으로 처리했습니다'
      );
    },
    onError: error => {
      setOpenDialog(true);
      setDialogMessage(
        useRank === 1
          ? `${
              privateStep === 1
                ? '일괄 걸음수 비공개'
                : privateStep === 2
                ? '일괄 걸음수 공개'
                : '사용자 지정처리'
            }가 실패했습니다. 다시 시도해주시기 바랍니다.`
          : '커뮤니티 걸음 수(랭킹)을 사용안함으로 처리중 오류가 발생했습니다.'
      );
    },
  });

  const changeIsPrivate = () => {
    const newData = {
      communityId: communityData.communityId,
      openStep: privateStep,
      useRank: useRank,
    };
    setChosenCommunity((prevState: ICommunity) => ({
      ...prevState,
      useRank: useRank,
      hiddenStep: privateStep,
    }));
    changeCommunityOpenStepMutate(newData);
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
  };
  const [selected, setSelected] = useState<readonly string[]>([]);

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedButtons([]);
    if (event.target.checked) {
      setCheckedAll(true);
      banners?.map((row: any, idx: number) => {
        setCheckedButtons(current => [...current, row.bannerId]);
      });
    } else {
      setCheckedAll(false);
    }
  };

  const changeHandler = (checked: boolean, id: string) => {
    if (checked) {
      setCheckedButtons([...checkedButtons, id]);
    } else {
      setCheckedButtons(checkedButtons.filter(button => button !== id));
    }
  };

  const deleteBanner = () => {
    const newData = {
      communityId: communityData && communityData.communityId,
      bannerIds: checkedButtons,
    };

    deleteCommunityBannerMutate(newData);
  };

  const viewBanner = (id: number) => {
    setBannerId(id);
    setOpenSettingChange(true);
    setTitle('배너 추가하기');
  };

  useEffect(() => {
    if (chosenCommunity) {
      setCommunityData(chosenCommunity);
      setPrivateStep(chosenCommunity.hiddenStep);
      setUseRank(chosenCommunity.useRank);
    }
  }, [chosenCommunity]);

  // useEffect(() => {
  //   if (location.state) {
  //     console.log('--> location.state :', location.state);
  //     setCommunityData(location.state);
  //     setPrivateStep(communityData.hiddenStep);
  //     setUseRank(communityData.useRank);
  //   }
  // }, [location.state]);

  return (
    <Box sx={{ m: '2rem 0' }}>
      <Box className='flex_between' sx={{ mt: '2rem' }}>
        <Typography variant='h4'>배너관리</Typography>
        <Button
          variant='contained'
          sx={{ ml: '2rem' }}
          onClick={() => {
            setBannerId(0);
            setOpenSettingChange(true);
            setTitle('배너 추가하기');
          }}
        >
          + 배너 추가하기
        </Button>
      </Box>
      <Paper sx={{ m: '2rem 0', p: '2rem 1rem' }}>
        <Box className='flex_between' sx={{ pb: '1rem' }}>
          <TableTitle title='배너' count={banners.length} />
          <Box className='flex_center'>
            <Collapse orientation='horizontal' in={openFilter}>
              {/* <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  localeText={{ start: '시작일', end: '종료일' }}
                >
                  <DesktopDateRangePicker
                    value={rangeDate}
                    onChange={newValue => {
                      setRangeDate(newValue);
                    }}
                    renderInput={(startProps, endProps) => (
                      <>
                        <TextField
                          {...startProps}
                          sx={{
                            '& .MuiInputLabel-root': { top: -4 },
                          }}
                        />
                        <Box sx={{ mx: 1 }}> to </Box>
                        <TextField
                          {...endProps}
                          sx={{
                            '& .MuiInputLabel-root': { top: -4 },
                          }}
                        />
                      </>
                    )}
                  />
                </LocalizationProvider> */}
            </Collapse>
            {/* <TableTopButton
              color='info'
              variant='outlined'
              onClick={() => setOpenFilter(!openFilter)}
            >
              <FilterAltOutlinedIcon />
              필터
              <ArrowBackIosNewOutlinedIcon
                sx={{ fontSize: '1rem', ml: '.5rem' }}
              />
            </TableTopButton>
            <SelectTextField
              select
              value={rowsPerPage}
              onChange={evt => {
                setRowsPerPage(Number(evt.target.value));
                setPage(1);
              }}
            >
              {rowsPerPageOptions.map(option => (
                <MenuItem key={option.toString()} value={option}>
                  {option}개씩 보기
                </MenuItem>
              ))}
            </SelectTextField>
            <TableTopButton color='info' variant='outlined'>
              데이터 다운로드
              <FileDownloadRoundedIcon color='success' />
            </TableTopButton> */}
          </Box>
        </Box>
        <Paper>
          <Table size='small'>
            <TableHead sx={{ bgcolor: '#F9FAFC' }}>
              <TableRow>
                <TableCell padding='checkbox'>
                  <Checkbox
                    onChange={e => {
                      handleSelectAllClick(e);
                    }}
                    checked={checkedAll}
                  />
                </TableCell>
                {columns.map((column, idx) => (
                  <TableCell
                    key={idx.toString()}
                    align={idx === 2 || idx === 3 ? 'right' : 'inherit'}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {banners
                ?.slice((page - 1) * rowsPerPage, page * rowsPerPage)
                ?.map((banner: any, idx: number) => (
                  <TableRow key={idx.toString()}>
                    <TableCell padding='checkbox'>
                      <Checkbox
                        id={banner.bannerId.toString()}
                        onChange={e => {
                          changeHandler(
                            e.currentTarget.checked,
                            banner.bannerId
                          );
                        }}
                        checked={checkedButtons.includes(banner.bannerId)}
                      />
                    </TableCell>
                    <TableCell
                      sx={{ width: '20vw' }}
                      onClick={() => viewBanner(banner.bannerId)}
                    >
                      <img
                        src={`${communityData.imageUrlPrefix}${banner.image}`}
                        alt=''
                        style={{ width: '15vw', height: '15vh' }}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        maxWidth: '20vw',
                        maxHeight: '10vh',
                        overflow: 'scroll',
                      }}
                      onClick={() => viewBanner(banner.bannerId)}
                    >
                      {banner.action}
                    </TableCell>
                    <TableCell
                      align='right'
                      onClick={() => viewBanner(banner.bannerId)}
                    >
                      {banner.startDate}
                    </TableCell>
                    <TableCell
                      align='right'
                      onClick={() => viewBanner(banner.bannerId)}
                    >
                      {banner.endDate}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <SwPagination
            page={page}
            handleChangePage={handleChangePage}
            count={banners && Math.ceil(banners.length / rowsPerPage)}
          />
        </Paper>
      </Paper>
      <Box sx={{ textAlign: 'right', mt: '1rem' }}>
        <Button variant='outlined' color='info' onClick={deleteBanner}>
          삭제
        </Button>
        <Button
          variant='contained'
          color='info'
          sx={{ ml: '1rem' }}
          onClick={() => {
            setOpenSettingChange(true);
            setTitle('순서 변경하기');
          }}
        >
          순서 변경하기
        </Button>
      </Box>
      <CommunitySettingChange
        communityId={communityData && communityData.communityId}
        open={openSettingChange}
        title={title}
        onClose={() => setOpenSettingChange(!openSettingChange)}
        onConfirm={() => {
          setOpenSettingChange(!openSettingChange);
          communityBannerRefetch();
        }}
        banners={banners}
        setBanners={setBanners}
        bannerId={bannerId}
      />
      <SwSnackbar
        open={false}
        onClose={() => setOpenDialog(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
      <Box sx={{ mt: '2rem' }}>
        <Typography variant='h4'>일괄 걸음수 비공개</Typography>
        <Paper sx={{ m: '2rem 0', p: '2rem 1rem' }}>
          <Box className=''>
            <Box className='flex_start'>
              <Box sx={{ width: '10rem', mr: '1rem' }}>
                <Typography sx={{ mr: '.4rem' }} className='title'>
                  커뮤니티 랭킹 사용
                </Typography>
              </Box>
              {useRank === 1 && (
                <Box className='flex_start'>
                  <Typography sx={{ mr: '.4rem' }} className='title'>
                    랭킹 걸음 수 비공개
                  </Typography>
                  <Tooltip
                    title={
                      <Typography fontSize={TOOLTIP_FONT_SIZE}>
                        {TOOLTIP_COMMUNITY_HIDDEN_STEP}
                      </Typography>
                    }
                    followCursor
                  >
                    <HelpOutlineIcon sx={{ color: '#999999' }} />
                  </Tooltip>
                </Box>
              )}
            </Box>
            <Box className='flex_start'>
              <TextField
                select
                sx={{ width: '10rem', mr: '1rem' }}
                value={useRank}
                onChange={(
                  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => {
                  setUseRank(Number(event.target.value));
                }}
              >
                <MenuItem key={0} value={0}>
                  사용안함
                </MenuItem>
                <MenuItem key={1} value={1}>
                  사용
                </MenuItem>
              </TextField>
              {useRank === 1 && (
                <RadioGroup
                  row
                  aria-labelledby='movie-row-radio-buttons-group-label'
                  name='row-radio-buttons-group'
                  value={
                    privateStep === 0
                      ? 'user'
                      : privateStep === 1
                      ? 'hidden'
                      : 'open'
                  }
                  onChange={evt => {
                    evt.currentTarget.value === 'user'
                      ? setPrivateStep(0)
                      : evt.currentTarget.value === 'hidden'
                      ? setPrivateStep(1)
                      : setPrivateStep(2);
                  }}
                >
                  <FormControlLabel
                    value='user'
                    control={<Radio />}
                    label='사용자 지정'
                  />
                  <FormControlLabel
                    value='hidden'
                    control={<Radio />}
                    label='비공개'
                  />
                  <FormControlLabel
                    value='open'
                    control={<Radio />}
                    label='강제 공개'
                  />
                </RadioGroup>
              )}
            </Box>
          </Box>
        </Paper>
        <Box className='flex_end'>
          <Button
            variant='contained'
            color='info'
            sx={{ ml: '1rem' }}
            onClick={() => {
              setOpenMore(1);
            }}
          >
            랭킹 정보 저장
          </Button>
        </Box>
      </Box>
      <CommunitySettingWorkStep />
      <SwConfirmDialog
        contents={
          useRank === 1
            ? `모든 커뮤니티 걸음 수(랭킹)를 일괄  ${
                privateStep === 1
                  ? '비공개'
                  : privateStep === 2
                  ? '강제 공개'
                  : '사용자 지정'
              } 처리하시곘습니까?`
            : '커뮤니티 걸음 수(랭킹) 노출을 하지 않겠습니까?'
        }
        open={!!openMore}
        onClose={() => setOpenMore(0)}
        onConfirm={() => {
          changeIsPrivate();
          setOpenMore(0);
        }}
        confirm={1}
      />
    </Box>
  );
};
