import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState, Dispatch } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useMany } from '../../hooks/api';
import {
  getWalkcourseList,
  getWalkcourseRequestDetail,
} from '../../common/api/ApiWalkcourse';
import {
  KEY_FIELD_REQUEST_DETAIL,
  KEY_WALKCOURSE_REQUEST_DETAIL,
} from '../../common/key';
import {
  getStamptourRequestDetail,
  getStamptourRequestDetailByChallengeId,
} from '../../common/api/ApiStamptour';
import {
  DefaultStamptourDetail,
  IStamptourDetail,
} from '../../models/stamptour';
import { SwStamptourMap } from './map/SwStamptourMap';
import { SwConfirmDialog } from './views/SwConfirmDialog';
import { registerChallengeRequiredStamp } from '../../common/api/ApiChallenge';
import { SwSnackbar } from './views/SwSnackbar';
import {
  DefaultWalkcouseDetail,
  IWalkcouseDetail,
} from '../../models/walkcourse';
import { SwWalkcourseMap } from './map/SwWalkcourseMap';
import { SwFieldMap } from './map/SwFieldMap';
import { getFieldRequestDetail } from '../../common/api/ApiField';

interface Props {
  title: string;
  open: boolean;
  onClose: () => void;
  fieldId: string;
  challengeId: string;
}

export const SwFieldView: React.FC<Props> = ({
  title,
  open,
  onClose,
  fieldId,
  challengeId,
}) => {
  const queryClient = useQueryClient();
  const [commandType, setCommandType] = useState(0);
  const [mapReload, setMapReload] = useState(0);
  const [mapInfo, setMapInfo] = useState<any>();
  const [gpsData, setGpsData] = useState<any>([]);
  const [chosenRequest, setChosenRequest] = useState<any>([]);
  const [searchKey, setSearchKey] = useState<string>('name');
  const [searchWord, setSearchWord] = useState<string>('');
  const [stamptourList, setStamptourList] = useState<any>([]);
  const [requestList, setRequestList] = useState<any>([]);
  const [fieldData, setFieldData] = useState<any>();
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [walkcourseDetail, setWalkcourseDetail] = useState<IWalkcouseDetail>(
    DefaultWalkcouseDetail
  );
  const [requiredList, setRequiredList] = useState<string[]>([]);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const { refetch: fieldRequestDetailRefetch } = useQuery(
    KEY_FIELD_REQUEST_DETAIL,
    () => {
      console.log('fieldId:', fieldId);
      if (fieldId.length > 0) return getFieldRequestDetail(fieldId);
      return null;
    },
    {
      onSuccess: res => {
        console.log('detail : ', res);
        if (res && res.creationRequestDetail) {
          // const tmpRoute = res.creationRequestDetail.point.map((point: any) => {
          //   const temp = {
          //     lat: point.pointPtLat,
          //     lng: point.pointPtLng,
          //     radius: point.pointRadius,
          //   };
          //   return temp;
          // });
          const tmpRoute: any = [];
          setGpsData(tmpRoute);

          const newSpot = res.creationRequestDetail.spot.map((spot: any) => {
            const temp = {
              id: spot.spotId,
              name: spot.spotName,
              image: spot.spotImage,
              lat: spot.spotPointLat,
              lng: spot.spotPointLng,
              radius: spot.spotRadius,
              description: spot.description,
              className: spot.className,
              link: spot.link,
              phoneNo: spot.phoneNo,
              address: spot.address,
            };
            return temp;
          });

          const newFieldData = {
            fieldId: fieldId,
            gpsData: tmpRoute,
            spotData: newSpot,
            totalArea: res.creationRequestDetail.field.totalArea,
          };
          setFieldData(newFieldData);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetchDetail = (newFieldId: string) => {
    queryClient.prefetchQuery(KEY_FIELD_REQUEST_DETAIL, () =>
      getFieldRequestDetail(newFieldId)
    );
  };

  useEffect(() => {
    if (open) {
      fieldRequestDetailRefetch();
    } else {
      setOpenConfirm(false);
      setRequiredList([]);
    }
    return () => {};
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      sx={{
        '& .MuiDialog-paper': {
          height: '76vh',
          margin: '30rem',
        },
      }}
    >
      <DialogTitle>
        <Box className='flex_between'>
          <span>{title}</span>
          <IconButton onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogContent>
        <SwFieldMap
          fieldData={fieldData}
          mapInfo={mapInfo}
          setMapInfo={setMapInfo}
        />
      </DialogContent>
      <Divider sx={{ m: 0 }} />
      {/* <DialogActions sx={{ p: '1rem' }}>
        <Button
          variant='contained'
          color='secondary'
          sx={{ fontSize: '.87rem', mr: '1rem' }}
          onClick={() => checkClose()}
        >
          취소
        </Button>
        <Button
          variant='contained'
          color='primary'
          sx={{ fontSize: '.87rem' }}
          onClick={onSave}
        >
          저장
        </Button>
      </DialogActions> */}
    </Dialog>
  );
};
