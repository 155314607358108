import {
  Badge,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { v4 as uuidv4 } from 'uuid';
import React, {
  ChangeEvent,
  Dispatch,
  DragEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useMutation } from 'react-query';
import { DraggablePaper } from '../../styles/Styles';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwImageSetting } from '../../commonComponent/SwImageSetting';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { RESULT_OK } from '../../../common/resultCode';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { useSession, xapi } from '../../../hooks/session';
import { IUploadFile } from '../../../models/common';
import { checkUrl, getAddUrl, timestamp2string } from '../../../common/helper';
import {
  postChallengeBanner,
  putChallengeBanner,
  putChallengeBannerPriority,
} from '../../../common/api/ApiChallenge';
import { SwPushSelectLocation } from '../../commonComponent/SwPushSelectLocation';

interface Props {
  title: string;
  open: boolean;
  banners: any;
  bannerId: number;
  setBanners: Dispatch<any>;
  onClose: () => void;
  onConfirm: () => void;
}

export const ChallengeBannerSetting: React.FC<Props> = ({
  open,
  banners,
  bannerId,
  setBanners,
  onClose,
  onConfirm,
  title,
}) => {
  const { loginVal } = useSession();
  const linkRef = useRef<any>();
  const linkSelectRef = useRef<any>();
  const [tmpBanners, setTmpBanners] = useState<any>([]);
  const [bannerImage, setBannerImage] = useState<string>('');
  const [bannerImageFile, setBannerImageFile] = useState<any>(null);
  const [bannerRangeDate, setBannerRangeDate] = useState<any[]>([null, null]);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [actionType, setActionType] = useState<string>('url');
  const [actionLink, setActionLink] = useState<string>('');
  const [holderMsg, setHolderMsg] = useState<string>('URL입력');
  const [openSnacker, setOpenSnacker] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [uploadUrl, setUploadUrl] = useState<any>();
  const [imageUrlPrefix, setImageUrlPrefix] = useState<any>('');
  const [locationLimit, setLocationLimit] = useState<any[]>([]);
  const groupItems = document.querySelectorAll('.draggable');
  const [listEvent, setListEvent] = useState<any>({
    currentDrag: null,
    over: null,
    clicked: null,
    list: [],
  });

  const { mutate: postChallengeBannerMutate, isError: isMutateBannerError } =
    useMutation(postChallengeBanner, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setOpenSnacker(true);
          setDialogMessage('챌린지 배너를 추가했습니다.');
          onConfirm();
        } else {
          setOpenSnacker(true);
          setDialogMessage(
            `챌린지 배너 추가하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
          );
        }
      },
      onError: error => {
        setOpenSnacker(true);
        setDialogMessage(
          `챌린지 배너 추가가 실패했습니다. 다시 시도해주시기 바랍니다.(${error})`
        );
      },
    });

  const { mutate: putChallengeBannerMutate, isError: isMutateError } =
    useMutation(putChallengeBanner, {
      onSuccess: () => {
        onConfirm();
      },
      onError: error => {
        setOpenSnacker(true);
        setDialogMessage(
          '챌린지 변경이 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });

  const {
    mutate: putChallengeBannerPriorityMutate,
    isError: isMutateOrderError,
  } = useMutation(putChallengeBannerPriority, {
    onSuccess: () => {
      onConfirm();
    },
    onError: error => {
      setOpenSnacker(true);
      setDialogMessage(
        '챌린지 배너 순서 변경이 실패했습니다. 다시 시도해주시기 바랍니다.'
      );
    },
  });

  const changeChallengeBannerOrder = () => {
    const newBanners = tmpBanners.map((item: any, idx: number) => {
      const newBanner = { bannerId: item.bannerId, priority: idx + 1 };
      return newBanner;
    });
    const newData = {
      banners: newBanners,
    };
    putChallengeBannerPriorityMutate(newData);
  };

  const dragStart = (evt: DragEvent<HTMLDivElement>) => {
    setListEvent({
      ...listEvent,
      currentDrag: evt.currentTarget.dataset.position,
      list: tmpBanners,
    });
    evt.currentTarget.style.border = '1px solid #757575';
  };

  const drop = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';

    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = listEvent.currentDrag;
    const filterList = listEvent.list.filter(
      (list: any, idx: number) => idx.toString() !== listEvent.currentDrag
    );
    const newList = [
      ...filterList.slice(0, newOver),
      listEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    setTmpBanners(newList);

    setListEvent({
      currentDrag: null,
      over: null,
      ...listEvent,
    });
  };

  const dragEnd = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    groupItems.forEach(item => item.classList.remove('drag_over'));
  };

  const dragEnter = (evt: DragEvent<HTMLDivElement>) => {
    const newOver = evt.currentTarget.dataset.position;
    setListEvent({ ...listEvent, over: newOver });
  };

  const dragOver = (evt: DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    evt.currentTarget.classList.add('drag_over');
    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = listEvent.currentDrag;
    const filterList = listEvent.list.filter(
      (_list: any, idx: number) => idx.toString() !== newCurrent
    );
    const newList = [
      ...filterList.slice(0, newOver),
      listEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    listEvent.over !== newOver && setTmpBanners(newList);
  };

  const dragLeave = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    groupItems.forEach(item => item.classList.remove('drag_over'));
  };

  const addBanner = (newUploadUrl: string) => {
    const newSTime =
      bannerRangeDate[0] === null
        ? 0
        : new Date(bannerRangeDate[0]).getTime() / 1000;
    const newETime =
      bannerRangeDate[1] === null
        ? 0
        : new Date(bannerRangeDate[1]).getTime() / 1000;
    const linkType = actionType;
    const linkValue =
      linkType === 'url'
        ? checkUrl(getAddUrl(actionLink))
        : `tel:${actionLink}`;
    const newLinkData = {
      action: 9,
      extra: { url: linkValue },
      imageUrl: newUploadUrl,
    };
    const newData = {
      bannerId: bannerId,
      stime: newSTime,
      etime: newETime,
      data: JSON.stringify(newLinkData),
      priority: 1,
      locationLimit: locationLimit.length > 0 ? locationLimit[0].amd : 0,
    };
    console.log('addBanner :', newData);
    if (bannerId === 0) {
      postChallengeBannerMutate(newData);
    } else {
      putChallengeBannerMutate(newData);
    }
  };

  const uploadBannerImage = () => {
    const uploadfile: IUploadFile = {
      type: 'banner',
      file: bannerImageFile,
    };
    var formData = new FormData();
    formData.append('file', bannerImageFile);

    xapi
      .post(`/admin/v2/apis/upload/${uploadfile.type}`, formData)
      .then(res => {
        if (res.status !== 200) throw new Error('http 에러');
        console.log('UploadUrl :', res.data);

        addBanner(res.data.uploadUrl.file.path);

        return res.data;
      });
  };

  const onAction = () => {
    if (title === '순서 변경하기') {
      changeChallengeBannerOrder();
    } else {
      var val = 0;
      if (bannerImageFile === null && bannerImage.length === 0) val = 1;
      if (
        val === 0 &&
        (bannerRangeDate[0] === null || bannerRangeDate[1] === null)
      )
        val = 3;

      if (val === 0) {
        if (bannerImageFile !== null) {
          uploadBannerImage();
        } else {
          addBanner(bannerImage);
        }
      } else {
        const msg =
          val === 1
            ? '이미지를 선택해주세요.'
            : val === 3
            ? '공개 기간을 지정해주세요'
            : '';
        setOpenDialog(true);
        setDialogMessage(msg);
      }
    }
  };

  useEffect(() => {
    if (open) {
      setTmpBanners(banners);
      setBannerImageFile(null);
    }
    setImageUrlPrefix(loginVal.value.user.imageUrlPrefix);
    if (open && bannerId !== 0) {
      const newBanner = banners.filter(
        (banner: any) => banner.bannerId === bannerId
      );
      setBannerImage(newBanner[0].image);
      const newLink = newBanner[0].action.includes('tel:')
        ? newBanner[0].action.split(':')[1]
        : newBanner[0].action;
      setActionLink(newLink);
      newBanner[0].action.includes('tel:')
        ? setActionType('phoneno')
        : setActionType('url');

      const newDate = [newBanner[0].startDate, newBanner[0].endDate];
      setBannerRangeDate(newDate);
      const newLocation = [newBanner[0].locationName];
      setLocationLimit(newLocation);
    } else {
      setBannerImage('');
      setActionLink('');
      setActionType('url');
      setBannerRangeDate([null, null]);
      setLocationLimit([]);
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            minWidth: title === '순서 변경하기' ? '60vw' : '45vw',
            minHeight: title === '순서 변경하기' ? '70vh' : '55vh',
            borderRadius: '12px',
          },
        }}
      >
        <DialogTitle className='flex_between'>
          <Box>{title}</Box>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider sx={{ p: 0, m: 0 }} />
        {title === '순서 변경하기' ? (
          <DialogContent>
            <Typography>마우스 드래그하여 순서를 변경해주세요.</Typography>
            {tmpBanners?.map((banner: any, idx: number) => (
              <Box key={idx.toString()} sx={{ display: 'flex' }}>
                {/* <Checkbox
                  defaultValue='false'
                  onChange={() => {
                    console.log('check>>>>>>>>>>>>>>');
                  }}
                /> */}
                <DraggablePaper
                  className='draggable'
                  id={idx.toString()}
                  variant='outlined'
                  onDragStart={dragStart}
                  onDragEnd={dragEnd}
                  onDragOver={dragOver}
                  onDrop={drop}
                  onDragLeave={dragLeave}
                  onDragEnter={dragEnter}
                  draggable
                  data-position={idx}
                >
                  <Box className='flex_between'>
                    <img
                      src={
                        banner.image.length > 0
                          ? `${imageUrlPrefix}${banner.image}`
                          : ''
                      }
                      alt='s'
                      style={{ width: '10vw', height: '10vh' }}
                    />
                    <Box sx={{ ml: '1rem' }}>
                      <Typography>시작일시 ~ 종료일시</Typography>
                      <Typography>
                        {banner.startDate} ~ {banner.endDate}
                      </Typography>
                    </Box>
                  </Box>
                  <DragHandleIcon />
                </DraggablePaper>
              </Box>
            ))}
          </DialogContent>
        ) : (
          <DialogContent>
            <Box>
              <Typography className='title'>이미지</Typography>
              <Typography
                color='#999999'
                fontSize='.81rem'
                sx={{ m: '-.5rem 0 0 .1rem' }}
              >
                권장 사이즈: 624 * 240
              </Typography>
              <SwFileSetting
                image={
                  bannerImageFile !== null
                    ? bannerImage
                    : bannerImage !== ''
                    ? `${imageUrlPrefix}${bannerImage}`
                    : ''
                }
                setImage={setBannerImage}
                file={bannerImageFile}
                setFile={setBannerImageFile}
                width='22vw'
                height='11vh'
              />
              <Divider />
            </Box>
            <Box>
              <Typography className='title'>링크 이동 (선택)</Typography>
              <Typography
                color='#999999'
                fontSize='.81rem'
                sx={{ m: '-.5rem 0 1rem .1rem' }}
              >
                배너 이미지 클릭 시 이동할 링크를 입력해주세요.
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <TextField
                  select
                  value={actionType}
                  sx={{ width: '10rem' }}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => {
                    setActionType(evt.target.value);
                    setHolderMsg(
                      evt.target.value === 'url' ? 'URL입력' : '전화번호 입력'
                    );
                  }}
                >
                  <MenuItem value='url'>URL</MenuItem>
                  <MenuItem value='phoneno'>전화번호</MenuItem>
                </TextField>
                <TextField
                  value={actionLink}
                  onChange={evt => setActionLink(evt.target.value)}
                  placeholder={holderMsg}
                  sx={{ width: '28rem' }}
                />
              </Box>
              <Divider />
            </Box>
            <Box>
              <Typography className='title'>공개 기간</Typography>
              <SwDateRangePicker
                rangeDate={bannerRangeDate}
                setRangeDate={setBannerRangeDate}
              />
              <Divider />
            </Box>
            <Box>
              <Typography className='title'>공개 범위</Typography>
              <SwPushSelectLocation
                includeAmd={true}
                selectOne={true}
                showButton={false}
                showDepth1={false}
                location={locationLimit}
                setLocation={setLocationLimit}
                mapId='bannerRegion_map'
              />
            </Box>
          </DialogContent>
        )}
        <Divider sx={{ p: 0, mt: '1rem' }} />
        <DialogActions>
          <Box sx={{ textAlign: 'right' }}>
            <Button variant='outlined' color='info' onClick={onClose}>
              취소
            </Button>
            <Button
              variant='contained'
              color='info'
              sx={{ ml: '1rem' }}
              onClick={onAction}
            >
              {title === '순서 변경하기' ? '변경 완료' : '저장'}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <SwSnackbar
        open={openSnacker}
        onClose={() => setOpenSnacker(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </>
  );
};
