import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { useLocation } from 'react-router';
import {
  useEffect,
  useState,
  Dispatch,
  ChangeEvent,
  SyntheticEvent,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import {
  deleteProgramMissionContentFavorites,
  getProgramTemplateCodeList,
  postProgramMissionContentFavorites,
} from '../../../common/api/ApiProgram';
import {
  KEY_CONTENT_MAMANGE_CONTENT_LIST,
  KEY_CONTENT_MAMANGE_MAP,
  KEY_CONTENT_MAMANGE_TEMPLATE_CONTENT_LIST,
  KEY_PROGRAM_TEMPLATE_CODE_LIST,
} from '../../../common/key';
import {
  getContentManageContentList,
  getContentManageMap,
} from '../../../common/api/ApiContentManage';
import { SwTemplateSideTab } from './SwTemplateSideTab';
import { timestamp2Localestring } from '../../../common/helper';
import { useSession } from '../../../hooks/session';
import { MultilineTextField, TabBox } from '../../styles/Styles';
import {
  authAutoType,
  authAvailableTime,
  getParsedImage,
  getTimeArray,
  showImageSlide,
  showKeyword,
} from './SwProgramCommon';
import { SwFileSetting } from '../SwFileSetting';
import { SwSurveyContentForm } from './SwSurveyContentForm';
import { SwQuizContentForm } from './SwQuizContentForm';
import { SwSnackbar } from '../views/SwSnackbar';
import { SwAlert } from '../views/SwAlert';
import { RESULT_OK } from '../../../common/resultCode';
import { SwPagination } from '../tableElement/SwPagination';
import { SwConfirmDialog } from '../views/SwConfirmDialog';

const TagsChip = styled(Chip)({
  margin: '.5em',
  color: '#2581FF',
  backgroundColor: 'rgba(52, 84, 248, 0.08)',
  borderColor: '#2581FF',
  '& .MuiChip-deleteIcon': {
    fontSize: '1rem',
  },
});

interface Props {
  categoryType: number;
  open: boolean;
  onClose: () => void;
  setTarget: Dispatch<React.SetStateAction<any[]>>;
  targetCategory?: string;
}

export const SwSelectTemplate: React.FC<Props> = ({
  categoryType,
  open,
  onClose,
  setTarget,
  targetCategory = 'all',
}) => {
  const location = useLocation();
  const queryClient = useQueryClient();
  const { loginVal } = useSession();
  const [imageUrlPrefix, setImageUrlPrefix] = useState<string>(
    loginVal.value.user.imageUrlPrefix
  );
  const [categoryId, setCategoryId] = useState<string>('');
  const [contentManageList, setContentManageList] = useState<any>([]);
  const [contentManageInfo, setContentManageInfo] = useState<any>({
    save: false,
    depth: 1,
    status: 3,
  });
  const [selected, setSelected] = useState<any>();
  const [selectedContent, setSelectedContent] = useState<any[]>([]);
  const [favorite, setFavorite] = useState<boolean>(false);
  const [likeContent, setLikeContent] = useState<any[]>([]);
  const [contentManageMap, setContentManageMap] = useState<any[]>([]);
  const [menuPath, setMenuPath] = useState<string[]>([]);
  const [tabMenuList, setTabMenuList] = useState<any[]>([]);
  const [editMode, setEditMode] = useState<string>('view');
  const [openNav, setOpenNav] = useState<boolean>(true);
  const [param, setParam] = useState<string>('');
  const [checkRequest, setCheckRequest] = useState<boolean>(true);
  const [searchKey, setSearchKey] = useState<string>('all');
  const [searchWord, setSearchWord] = useState<string>('');
  const [tabValue, setTabValue] = useState(1);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [statusMessage, setStatusMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);

  const showStatusMessage = (type: number, count: number) => {
    setStatusMessage(
      type === 1
        ? `${count}개가 선택되었습니다.`
        : `총 ${count}건의 검색 결과가 있습니다.`
    );
  };

  const {
    data,
    isLoading,
    refetch: programContentManageMapRefetch,
  } = useQuery(
    KEY_CONTENT_MAMANGE_MAP,
    () => {
      const newRequest = {
        type: categoryType,
        sortWay: 1, // asc
      };
      if (open === true) return getContentManageMap(newRequest);
      return null;
    },
    {
      onSuccess: res => {
        if (res !== null && res.map !== undefined) {
          console.log('content ManageMap res:', res);
          setContentManageMap(res.map);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { refetch: contentManageContentRefetch } = useQuery(
    KEY_CONTENT_MAMANGE_TEMPLATE_CONTENT_LIST,
    () => {
      console.log('checkRequest: ', checkRequest);
      const newDate = new Date().getTime() / 1000;
      const status = 3; // all
      const newRequest = {
        type: categoryType,
        categoryId: categoryId,
        status: status,
        startDate: 0,
        endDate: 0,
        favorite: favorite === true ? 1 : 0,
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: searchWord,
        sortWay: 1, // asc
      };
      if (checkRequest && categoryId.length > 0)
        return getContentManageContentList(newRequest);
      return null;
    },
    {
      onSuccess: res => {
        console.log('CMS requestList content :', res);
        if (res !== null) {
          setContentManageList(res ? res.contentManage : []);
          setTotalRecords(res.pagination.totalRecordCount);
          res.contentManage !== null &&
            res.contentManage.map((item: any, idx: number) => {
              if (item.favorite === 1)
                setLikeContent([...likeContent, item.contentId]);
            });
          showStatusMessage(
            2,
            res.contentManage !== null ? res.contentManage.length : 0
          );
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetchContent = (newCategoryId: string, newPage: number) => {
    const newDate = new Date().getTime() / 1000;
    const status = 3;
    const newRequest = {
      type: categoryType,
      categoryId: newCategoryId,
      status: status,
      startDate: 0,
      endDate: 0,
      favorite: favorite === true ? 1 : 0,
      page: newPage,
      rowsPerPage: rowsPerPage,
      searchKey: searchKey,
      searchWord: searchWord,
      sortWay: 1, // asc
    };
    queryClient.prefetchQuery(KEY_CONTENT_MAMANGE_TEMPLATE_CONTENT_LIST, () =>
      getContentManageContentList(newRequest)
    );
  };

  const {
    mutate: postProgramMissionContentFavoritesMutate,
    isError: isMutateError,
  } = useMutation(postProgramMissionContentFavorites, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setDialogMessage('즐겨찾기에 추가되었습니다.');
      } else {
        setOpenSnackbar(true);
        setDialogMessage(
          `즐겨찾기에 등록하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '즐겨찾기에등록이 실패했습니다. 다시 시도해주시기 바랍니다.'
      );
    },
  });

  const {
    mutate: deleteProgramMissionContentFavoritesMutate,
    isError: isDeleteMutateError,
  } = useMutation(deleteProgramMissionContentFavorites, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setDialogMessage('즐겨찾기가 해제되었습니다.');
      } else {
        setOpenSnackbar(true);
        setDialogMessage(
          `즐겨찾기에서 삭제하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage('등록이 실패했습니다. 다시 시도해주시기 바랍니다.');
    },
  });

  const checkLikeContent = (id: string) => likeContent.includes(id);

  const changeLikeContent = (checked: boolean, id: string) => {
    if (checked) {
      setLikeContent([...likeContent, id]);
      const newData: any = {
        contentId: id,
      };
      postProgramMissionContentFavoritesMutate(newData);
    } else {
      setLikeContent(likeContent.filter(button => button !== id));
      const newData: any = {
        contentId: id,
      };
      deleteProgramMissionContentFavoritesMutate(newData);
    }
  };

  const checkSelectedContent = (id: string) => selectedContent.includes(id);

  const changeSelectedContent = (
    checked: boolean,
    id: string,
    saveType: string
  ) => {
    if (saveType === 'single') {
      setSelectedContent([]);
      if (checked) {
        setSelectedContent([id]);
        showStatusMessage(1, 1);
      }
    } else if (saveType === 'multiple') {
      if (checked) {
        const newContent = [...selectedContent, id];
        showStatusMessage(1, newContent.length);
        setSelectedContent(newContent);
      } else {
        const newContent = selectedContent.filter(button => button !== id);
        showStatusMessage(1, newContent.length);
        setSelectedContent(newContent);
      }
    }
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedContent([]);
    if (event.target.checked) {
      setCheckedAll(true);
      contentManageList.map((row: any, idx: number) => {
        setSelectedContent(current => [...current, row.contentId]);
      });
      showStatusMessage(1, contentManageList.length);
    } else {
      setCheckedAll(false);
      showStatusMessage(1, 0);
    }
  };

  const saveTarget = () => {
    const newTarget = contentManageList
      .filter((item: any) => selectedContent.includes(item.contentId))
      .map((item: any) => {
        const newItem = item;
        newItem.menuPath = menuPath;
        return newItem;
      });
    setTarget(newTarget);
  };

  const searchTemplate = (newSearchKey: string, newSearchWord: string) => {
    contentManageContentRefetch();
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchTemplate('all', searchWord);
    }
  };

  const tabChange = (event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue);

    const node = tabMenuList[newValue - 1];
    const newMenuPath = [...menuPath];
    newMenuPath[newMenuPath.length - 1] = node.title;
    setMenuPath(newMenuPath);
    setCategoryId(node.id);
    prefetchContent(node.id, 1);
  };

  const checkTab = (title: string) => {
    const idx = tabMenuList
      .map((item: any, i: number) => (item.title === title ? i : -1))
      .filter((item: any) => item !== -1);
    idx !== null && idx.length > 0 ? setTabValue(idx[0] + 1) : '';
  };

  const doSelected = (node: any) => {
    console.log('selected :', node);
    if (node !== undefined && node !== null && node.nodeType === 'node') {
      setMenuPath(node.menuPath);
      setCategoryId(node.id);

      if (targetCategory !== 'all') {
        if (node.menuPath.length === 3 && targetCategory !== node.title) {
          const a = 1;
        } else {
          prefetchContent(node.id, 1);
        }
      } else {
        prefetchContent(node.id, 1);
      }
      checkTab(node.title);

      node.node.length > 0
        ? setTabMenuList(node.node.nodeList)
        : setTabMenuList([]);
    } else {
      node.nodeType === 'tab'
        ? setTabMenuList(node.node.nodeList)
        : setTabMenuList([]);
      setContentManageList([]);
    }
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
    prefetchContent(categoryId, newPage);
  };

  useEffect(() => {
    contentManageContentRefetch();
  }, [favorite]);

  useEffect(() => {
    if (open === true) {
      const newParam = location.pathname.split('/')[1];
      setCategoryId('');
      setFavorite(false);
      setParam('');
      setSelectedContent([]);
      setLikeContent([]);
      setContentManageList([]);
      programContentManageMapRefetch();
    } else {
      setCategoryId('');
      setFavorite(false);
      setParam('');
      setSelectedContent([]);
      setLikeContent([]);
      setContentManageList([]);
    }
    return () => {};
  }, [open]);

  return (
    <Dialog
      open={open}
      fullScreen
      sx={{
        '& .MuiDialog-paper': {
          height: '66vh',
          margin: '20rem',
        },
      }}
    >
      <DialogTitle>
        <Box className='flex_between'>
          <span>템플릿으로 추가하기</span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogContent>
        <Box
          sx={{
            padding: '1rem 1rem',
            paddingBottom: '1rem',
            backgroundColor: '#F6F7F8',
          }}
        >
          <Box className='flex_start'>
            <Box sx={{ width: '25rem' }}>
              <Typography sx={{ mr: '1rem', fontWeight: 700 }}>
                카테고리 선택
              </Typography>
            </Box>
            <Box className='flex_between' sx={{ ml: '2rem', width: '70%' }}>
              <Box>
                <TextField
                  placeholder='검색어를 입력해주세요.'
                  value={searchWord}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setSearchWord(evt.target.value)}
                  onKeyDown={handleKeyDown}
                  sx={{ width: '30rem', mr: '1em' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          sx={{ pr: 0 }}
                          onClick={() => searchTemplate('all', searchWord)}
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box className='flex_start'>
                <Checkbox
                  icon={<StarOutlineIcon sx={{ color: '#9EA2A9' }} />}
                  checkedIcon={<StarIcon sx={{ color: '#00A2FF' }} />}
                  onChange={e => {
                    e.currentTarget.checked
                      ? setFavorite(true)
                      : setFavorite(false);
                  }}
                />
                <Typography>즐겨찾기 보기</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Stack
          direction={{ xs: 'column', sm: 'column', md: 'row' }}
          divider={
            <Divider
              orientation='vertical'
              flexItem
              sx={{ p: 0, m: '.5rem 2rem 0' }}
            />
          }
        >
          <Box sx={{ height: '40rem' }}>
            <SwTemplateSideTab
              menuMap={contentManageMap}
              openNav={openNav}
              setOpenNav={setOpenNav}
              param={param}
              onSelected={doSelected}
            />
          </Box>
          <Box sx={{ width: '100%' }}>
            {categoryId !== '' && (
              <Box sx={{ m: '0 0' }} className='flex_between'>
                <TabBox className='flex_between'>
                  <Tabs value={tabValue} onChange={tabChange}>
                    {tabMenuList.map((item: any, idx) => (
                      <Tab
                        key={`tab_menu_${idx}`}
                        label={item.title}
                        value={idx + 1}
                      />
                    ))}
                  </Tabs>
                  <span> </span>
                </TabBox>
                {contentManageList !== null &&
                  contentManageList.length > 0 &&
                  (categoryType === 3 || categoryType === 5) && (
                    <Box className='flex_start'>
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlankOutlinedIcon
                            sx={{ color: '#9EA2A9' }}
                          />
                        }
                        checkedIcon={<CheckBoxIcon sx={{ color: '#00A2FF' }} />}
                        checked={checkedAll}
                        onChange={e => {
                          handleSelectAllClick(e);
                        }}
                      />
                      <Typography>전체 선택</Typography>
                    </Box>
                  )}
              </Box>
            )}
            <TableContainer>
              <Table stickyHeader>
                <TableBody>
                  {contentManageList !== null &&
                  contentManageList.length > 0 ? (
                    contentManageList?.map((row: any, idx: number) => (
                      <TableRow key={`template_code_${idx}`}>
                        <TableCell
                          key={`template_cell_${idx}`}
                          sx={{ p: '.3rem .6rem' }}
                        >
                          <Paper sx={{ background: '#FAFBFB' }}>
                            <Box className='flex_between'>
                              <Box>
                                <Typography sx={{ ml: '1rem' }}>
                                  {menuPath.join('>')}
                                </Typography>
                              </Box>
                              <Box>
                                <Checkbox
                                  checked={checkLikeContent(row.contentId)}
                                  icon={
                                    <StarOutlineIcon
                                      sx={{ color: '#9EA2A9' }}
                                    />
                                  }
                                  checkedIcon={
                                    <StarIcon sx={{ color: '#00A2FF' }} />
                                  }
                                  onChange={e => {
                                    changeLikeContent(
                                      e.currentTarget.checked,
                                      row.contentId
                                    );
                                  }}
                                />
                                <Checkbox
                                  checked={checkSelectedContent(row.contentId)}
                                  icon={
                                    <CheckCircleOutlineIcon
                                      sx={{ color: '#9EA2A9' }}
                                    />
                                  }
                                  checkedIcon={
                                    <CheckCircleOutlineIcon
                                      sx={{ color: '#00A2FF' }}
                                    />
                                  }
                                  onChange={e => {
                                    changeSelectedContent(
                                      e.currentTarget.checked,
                                      row.contentId,
                                      categoryType === 1 ||
                                        categoryType === 2 ||
                                        categoryType === 4
                                        ? 'single'
                                        : 'multiple'
                                    );
                                  }}
                                />
                              </Box>
                            </Box>
                            <Divider sx={{ p: 0, m: 0 }} />
                            {categoryType === 1 && (
                              <Box
                                className='flex_between'
                                sx={{ m: '1rem 1rem' }}
                              >
                                <Box>
                                  <img
                                    src={`${imageUrlPrefix}${row.image}`}
                                    alt=''
                                    style={{
                                      maxWidth: '10vw',
                                      borderRadius: '14px',
                                      width: '10rem',
                                      height: 'auto',
                                    }}
                                  />
                                </Box>
                                <Box sx={{ ml: '1rem', width: '100%' }}>
                                  <Typography
                                    sx={{ fontWeight: 700, mb: '.5rem' }}
                                  >
                                    {row.title}
                                  </Typography>
                                  <Typography sx={{ color: '#858C97' }}>
                                    {row.source}
                                  </Typography>
                                  <Divider sx={{ p: 0, m: '.5rem 0' }} />
                                  <MultilineTextField
                                    sx={{
                                      color: '#858C97',
                                      '& fieldset': { border: 'none' },
                                    }}
                                    multiline
                                    fullWidth
                                    rows={3}
                                    value={row.description}
                                  />
                                </Box>
                              </Box>
                            )}
                            {categoryType === 2 && (
                              <Box
                                className='flex_between'
                                sx={{ m: '1rem 1rem' }}
                              >
                                <Box>
                                  {row.image !== null &&
                                    row.image.length > 0 &&
                                    showImageSlide(row.image, imageUrlPrefix)}
                                </Box>
                                <Box sx={{ ml: '1rem', width: '100%' }}>
                                  <Typography
                                    sx={{ fontWeight: 700, mb: '.5rem' }}
                                  >
                                    {row.title}
                                  </Typography>
                                  <Divider sx={{ p: 0, m: '.5rem 0' }} />
                                  <Box>
                                    {row.keyword !== undefined &&
                                      row.keyword.length > 0 &&
                                      showKeyword(row.keyword)}
                                  </Box>
                                </Box>
                              </Box>
                            )}
                            {categoryType === 3 && (
                              <Box
                                className='flex_between'
                                sx={{ m: '1rem 1rem' }}
                              >
                                <SwSurveyContentForm
                                  editMode={editMode}
                                  onChangeEditMode={(value: string) => {
                                    setEditMode(value);
                                  }}
                                  contentManageInfo={row}
                                  setContentManageInfo={setContentManageInfo}
                                  reloadContentManage={id => {}}
                                />
                              </Box>
                            )}
                            {categoryType === 5 && (
                              <Box
                                className='flex_between'
                                sx={{ m: '1rem 1rem' }}
                              >
                                <SwQuizContentForm
                                  editMode={editMode}
                                  onChangeEditMode={(value: string) => {
                                    setEditMode(value);
                                  }}
                                  contentManageInfo={row}
                                  setContentManageInfo={setContentManageInfo}
                                  reloadContentManage={id => {}}
                                />
                              </Box>
                            )}
                            {categoryType === 4 && (
                              <Box sx={{ m: '1rem 1rem' }}>
                                <Box>
                                  <Typography className='title'>
                                    {row.title}
                                  </Typography>
                                  <MultilineTextField
                                    sx={{
                                      color: '#858C97',
                                      '& fieldset': { border: 'none' },
                                    }}
                                    multiline
                                    fullWidth
                                    rows={3}
                                    value={row.description}
                                  />
                                </Box>
                                <Box className='flex_start'>
                                  {getParsedImage(row.image, 0) !== '' && (
                                    <Box sx={{ mr: '1rem' }}>
                                      <img
                                        src={`${imageUrlPrefix}${getParsedImage(
                                          row.image,
                                          0
                                        )}`}
                                        alt=''
                                        style={{
                                          maxWidth: '10vw',
                                          borderRadius: '14px',
                                          width: '10rem',
                                          height: 'auto',
                                        }}
                                      />
                                    </Box>
                                  )}
                                  {getParsedImage(row.image, 1) !== '' && (
                                    <Box sx={{ mr: '1rem' }}>
                                      <img
                                        src={`${imageUrlPrefix}${getParsedImage(
                                          row.image,
                                          1
                                        )}`}
                                        alt=''
                                        style={{
                                          maxWidth: '10vw',
                                          borderRadius: '14px',
                                          width: '10rem',
                                          height: 'auto',
                                        }}
                                      />
                                    </Box>
                                  )}
                                </Box>
                                <Divider />
                                <Box>
                                  <Typography className='title'>
                                    인증 가능 시간
                                  </Typography>
                                  <TextField
                                    sx={{ width: '16rem', mr: '1rem' }}
                                    select
                                    defaultValue='unlimit'
                                    value={
                                      row.certificateTimeType !== 99
                                        ? authAvailableTime[
                                            row.certificateTimeType - 1
                                          ].type
                                        : 'direct'
                                    }
                                  >
                                    {authAvailableTime.map(option => (
                                      <MenuItem
                                        key={`authtime_${option.type}`}
                                        value={option.type}
                                      >
                                        {option.title}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                  {(row.certificateTimeType === 99 ||
                                    (row.certificateTimeType !== 99 &&
                                      authAvailableTime[
                                        row.certificateTimeType - 1
                                      ].type === 'direct')) && (
                                    <Box>
                                      <TextField
                                        sx={{ width: '8rem' }}
                                        value={`${row.certificateStartTime}:00`}
                                      />
                                      ~
                                      <TextField
                                        sx={{ width: '8rem' }}
                                        value={`${row.certificateEndTime}:00`}
                                      />
                                    </Box>
                                  )}
                                  <Divider />
                                </Box>
                                <Box>
                                  <Typography className='title'>
                                    인증방법
                                  </Typography>
                                  <RadioGroup
                                    row
                                    aria-labelledby='auth-row-radio-buttons-group-label'
                                    name='auth-radio-buttons-group'
                                    value={
                                      row.certificateType === 1
                                        ? 'required-auth'
                                        : row.certificateType === 2
                                        ? 'optional-auth'
                                        : row.certificateType === 3
                                        ? 'simple-auth'
                                        : row.certificateType === 4
                                        ? 'auto-auth'
                                        : 'code-auth'
                                    }
                                  >
                                    <FormControlLabel
                                      value='required-auth'
                                      control={<Radio />}
                                      label='사진 인증(촬영 필수)'
                                    />
                                    <FormControlLabel
                                      value='optional-auth'
                                      control={<Radio />}
                                      label='선택 인증(촬영 선택 사항)'
                                    />
                                    <FormControlLabel
                                      value='simple-auth'
                                      control={<Radio />}
                                      label='간편 인증(클릭하여 인증)'
                                    />
                                    <FormControlLabel
                                      value='auto-auth'
                                      control={<Radio />}
                                      label='자동인증'
                                    />
                                    <FormControlLabel
                                      value='code-auth'
                                      control={<Radio />}
                                      label='코드 인증(암호 입력 인증)'
                                    />
                                  </RadioGroup>
                                  {row.certificateType === 4 && (
                                    <Box>
                                      <TextField
                                        sx={{ width: '8rem', ml: '1rem' }}
                                        select
                                        value='step'
                                      >
                                        {authAutoType
                                          .filter(
                                            option => option.type === 'step'
                                          )
                                          .map(option => (
                                            <MenuItem
                                              key={`auth_auto_${option.type}`}
                                              value={option.type}
                                            >
                                              {option.title}
                                            </MenuItem>
                                          ))}
                                      </TextField>
                                      <TextField
                                        value={row.certificateTypeItemValue}
                                        sx={{
                                          '& .MuiOutlinedInput-root': {
                                            height: 'auto',
                                            ml: '1rem',
                                          },
                                        }}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position='end'>
                                              <span>보</span>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </Box>
                                  )}
                                  {row.certificateType === 5 && (
                                    <Box>
                                      <TextField
                                        value={row.certificateTypeItemValue}
                                        sx={{
                                          '& .MuiOutlinedInput-root': {
                                            height: 'auto',
                                            ml: '1rem',
                                          },
                                        }}
                                      />
                                    </Box>
                                  )}
                                  <Divider />
                                </Box>
                                <Box>
                                  <Typography className='title'>
                                    인증 안내(선택)
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    multiline
                                    rows={5}
                                    value={row.certificateDesc}
                                    sx={{
                                      '& .MuiOutlinedInput-root': {
                                        height: 'auto',
                                        padding: '.5rem',
                                      },
                                    }}
                                  />
                                </Box>
                                <Box className='flex_between'>
                                  <Stack
                                    direction='row'
                                    divider={
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        sx={{ m: 0 }}
                                      />
                                    }
                                    spacing={2}
                                  >
                                    <Box>
                                      <Typography className='title'>
                                        인증 성공 이미지
                                      </Typography>
                                      <Box className='flex_between'>
                                        {getParsedImage(
                                          row.certificateSuccessImage,
                                          0
                                        ) !== '' && (
                                          <Box sx={{ mr: '1rem' }}>
                                            <img
                                              src={`${imageUrlPrefix}${getParsedImage(
                                                row.certificateSuccessImage,
                                                0
                                              )}`}
                                              alt=''
                                              style={{
                                                maxWidth: '10vw',
                                                borderRadius: '14px',
                                                width: '10rem',
                                                height: 'auto',
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {getParsedImage(
                                          row.certificateSuccessImage,
                                          1
                                        ) !== '' && (
                                          <Box>
                                            <img
                                              src={`${imageUrlPrefix}${getParsedImage(
                                                row.certificateSuccessImage,
                                                1
                                              )}`}
                                              alt=''
                                              style={{
                                                maxWidth: '10vw',
                                                borderRadius: '14px',
                                                width: '10rem',
                                                height: 'auto',
                                              }}
                                            />
                                          </Box>
                                        )}
                                      </Box>
                                    </Box>
                                    <Box>
                                      <Typography className='title'>
                                        인증 실패 이미지
                                      </Typography>
                                      <Box className='flex_between'>
                                        {getParsedImage(
                                          row.certificateFailImage,
                                          0
                                        ) !== '' && (
                                          <Box sx={{ mr: '1rem' }}>
                                            <img
                                              src={`${imageUrlPrefix}${getParsedImage(
                                                row.certificateFailImage,
                                                0
                                              )}`}
                                              alt=''
                                              style={{
                                                maxWidth: '10vw',
                                                borderRadius: '14px',
                                                width: '10rem',
                                                height: 'auto',
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {getParsedImage(
                                          row.certificateFailImage,
                                          1
                                        ) !== '' && (
                                          <Box>
                                            <img
                                              src={`${imageUrlPrefix}${getParsedImage(
                                                row.certificateFailImage,
                                                1
                                              )}`}
                                              alt=''
                                              style={{
                                                maxWidth: '10vw',
                                                borderRadius: '14px',
                                                width: '10rem',
                                                height: 'auto',
                                              }}
                                            />
                                          </Box>
                                        )}
                                      </Box>
                                    </Box>
                                  </Stack>
                                </Box>
                              </Box>
                            )}
                          </Paper>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <>
                      <TableRow>
                        <TableCell colSpan={1}>
                          등록된 콘텐츠가 없습니다.
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {contentManageList !== undefined &&
              contentManageList.length > 0 &&
              totalRecords && (
                <SwPagination
                  page={page}
                  handleChangePage={handleChangePage}
                  count={
                    contentManageList && Math.ceil(totalRecords / rowsPerPage)
                  }
                />
              )}
          </Box>
        </Stack>
      </DialogContent>
      <Divider sx={{ m: 0 }} />
      <Box className='flex_between' sx={{ m: '1rem 2rem' }}>
        <Box>
          <Typography>{statusMessage}</Typography>
        </Box>
        <Box>
          <Button
            variant='outlined'
            color='info'
            sx={{ fontSize: '.87rem', mr: '1rem' }}
            onClick={onClose}
          >
            취소
          </Button>
          <Button
            disabled={selectedContent === null || selectedContent.length === 0}
            variant='contained'
            color='info'
            sx={{ fontSize: '.87rem' }}
            onClick={() => setOpenConfirm(true)}
          >
            {categoryType === 3 || categoryType === 5
              ? '질문 담기'
              : '선택하기'}
          </Button>
        </Box>
      </Box>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
      <SwConfirmDialog
        contents='질문이 담겼습니다. 질문 작성 화면으로 이동하시겠습니까?'
        open={openConfirm}
        onClose={() => {
          saveTarget();
          setOpenConfirm(false);
        }}
        onConfirm={() => {
          saveTarget();
          onClose();
          setOpenConfirm(false);
        }}
        confirm={1}
      />
    </Dialog>
  );
};
