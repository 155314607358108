import axios from 'axios';
import * as XLSX from 'xlsx';
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRange, DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { CommunityMemberSetting } from './CommunityMemberSetting';
import { TableTitle } from '../../commonComponent/tableElement/TableTitle';
import {
  RowsCell,
  SelectTextField,
  TableTopButton,
  WhiteTextField,
} from '../../styles/Styles';
import { SelectSearchBox } from '../../commonComponent/SelectSearchBox';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import { SwLoadUser } from '../../commonComponent/SwLoadUser';
import SwLoading from '../../commonComponent/spinner/SwLoading';

import {
  deleteCommunityMember,
  downloadCommunityMemberList,
  getCommunityMemberList,
  getCommunityTypeMemberList,
  postCommunityMember,
  postCommunityMemberByPhonenos,
  putCommunityChangeMemberType,
  putCommunityChangeMemberTypeByPhonenos,
} from '../../../common/api/ApiCommunity';
import { xapi } from '../../../hooks/session';
import {
  KEY_COMMUNITY_MEMBER_LIST,
  KEY_COMMUNITY_OPERATOR_LIST,
  KEY_COMMUNITY_TYPE_MEMBER_LIST,
} from '../../../common/key';
import {
  encodeSearchWord,
  timestamp2Localestring,
} from '../../../common/helper';
import { RESULT_OK } from '../../../common/resultCode';

interface Props {}

const MAX_LOAD_PHONE_LENGTH = 500;

const MEMBER_TYPE = '멤버';
const MEMBER_DELETE_TYPE = '멤버탈퇴';
const OPERATOR_TYPE = '운영진';
const columns = [
  '운영진 닉네임',
  '커뮤니티 닉네임 사용 여부',
  '커뮤니티 닉네임',
  '연락처',
  '운영진 아이디',
];
const memberColumns = [
  '닉네임',
  '커뮤니티 닉네임 사용 여부',
  '커뮤니티 닉네임',
  '연락처',
  '그룹',
  '거주 지역',
  '활동 지역',
  '성별',
  '나이',
  '가입 일자',
  '가입 커뮤니티 수',
  '탈퇴일자',
];

const searchOption = [
  {
    title: '닉네임',
    value: 'name',
  },
  {
    title: '연락처',
    value: 'phonenum',
  },
  {
    title: '그룹',
    value: 'groupname',
  },
  {
    title: '거주지역',
    value: 'residence',
  },
  {
    title: '활동지역',
    value: 'activity',
  },
  {
    title: '성별',
    value: 'gender',
  },
  {
    title: '나이',
    value: 'age',
  },
];

export const CommunityMembers: React.FC<Props> = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const [communityData, setCommunityData] = useState<any>(location.state);
  const operatorRef = useRef<any>();
  const selectRef = useRef<any>();
  const searchRef = useRef<any>();
  const joinFormInputRef = useRef<any>();
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [settingOpen, setSettingOpen] = useState(false);
  const [communityMembers, setCommunityMembers] = useState<any>([]);
  const [operators, setOperators] = useState<any>();
  const [operatorCount, setOperatorCount] = useState(0);
  const [memberCount, setMemberCount] = useState(0);
  const [invalidMemberCount, setInvalidMemberCount] = useState(0);
  const [page, setPage] = useState(1);
  const [memberPage, setMemberPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowsPerMemberPage, setRowsPerMemberPage] = useState(10);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [totalRecords, setTotalRecords] = useState(0);
  const [memberTotalRecords, setMemberTotalRecords] = useState(0);
  const [request, setRequest] = useState<boolean>(false);
  const [memberCheckedButtons, setMemberCheckedButtons] = useState<string[]>(
    []
  );
  const [memberCheckedAll, setMemberCheckedAll] = useState<boolean>(false);
  const [loadUserList, setLoadUserList] = useState<any>([]);
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [checkedButtons, setCheckedButtons] = useState<string[]>([]);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [loading, setLoading] = useState<any>(true);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openLoadUser, setOpenLoadUser] = useState<number>(0);

  const {
    data,
    isLoading,
    refetch: communityMemberRefetch,
  } = useQuery(
    KEY_COMMUNITY_OPERATOR_LIST,
    () => {
      const communityId = communityData && communityData.communityId;
      const searchWord = operatorRef.current.value;
      const newData = {
        communityId: communityId,
        searchKey: 'operator',
        searchWord: encodeSearchWord('operator', searchWord),
        type: 1,
        startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime() / 1000,
        endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime() / 1000,
        page: page,
        rowsPerPage: rowsPerPage,
      };
      if (request) return getCommunityTypeMemberList(newData);
      return null;
    },
    {
      onSuccess: res => {
        // setCommunityMembers(res.member);
        if (res != null) {
          setOperators(res.member.filter((member: any) => member.type !== 0));
          setTotalRecords(res.pagination?.totalRecordCount);
          setOperatorCount(res.memberCount);
        }
        setLoading(false);
      },
      onError: e => {
        console.log(e);
        setLoading(false);
      },
    }
  );

  const prefetchMember = (newPage: number) => {
    queryClient.prefetchQuery(KEY_COMMUNITY_OPERATOR_LIST, () => {
      const communityId = communityData && communityData.communityId;
      const searchWord = operatorRef.current.value;
      const newData = {
        communityId: communityId,
        searchKey: 'operator',
        searchWord: encodeSearchWord('operator', searchWord),
        type: 1,
        startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime() / 1000,
        endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime() / 1000,
        page: newPage,
        rowsPerPage: rowsPerPage,
      };
      setLoading(true);
      return getCommunityTypeMemberList(newData);
    });
  };

  const { refetch: communityTypeMemberRefetch } = useQuery(
    KEY_COMMUNITY_TYPE_MEMBER_LIST,
    () => {
      const communityId = communityData && communityData.communityId;
      const searchKey = selectRef.current.value;
      const searchWord = searchRef.current.value;
      const newData = {
        communityId: communityId,
        searchKey: searchKey,
        searchWord:
          searchKey === 'groupname'
            ? searchWord === '기본그룹'
              ? 'no-group'
              : encodeSearchWord(searchKey, searchWord)
            : encodeSearchWord(searchKey, searchWord),
        type: 0,
        startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime() / 1000,
        endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime() / 1000,
        page: memberPage,
        rowsPerPage: rowsPerMemberPage,
      };
      setLoading(true);
      if (request) return getCommunityTypeMemberList(newData);
      return null;
    },
    {
      onSuccess: res => {
        setLoading(false);
        if (res !== null) {
          setCommunityMembers(res.member);
          setMemberTotalRecords(res.pagination?.totalRecordCount);
          setMemberCount(res.memberCount);
          setInvalidMemberCount(res.invalidMemberCount);
        }
      },
      onError: e => {
        setLoading(false);
        console.log(e);
      },
    }
  );

  const prefetchTypeMember = (newPage: number) => {
    queryClient.prefetchQuery(KEY_COMMUNITY_TYPE_MEMBER_LIST, () => {
      const communityId = communityData && communityData.communityId;
      const searchKey = selectRef.current.value;
      const searchWord = searchRef.current.value;
      const newData = {
        communityId: communityId,
        searchKey: searchKey,
        searchWord:
          searchKey === 'groupname'
            ? searchWord === '기본그룹'
              ? 'no-group'
              : encodeSearchWord(searchKey, searchWord)
            : encodeSearchWord(searchKey, searchWord),
        type: 0,
        startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime() / 1000,
        endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime() / 1000,
        page: newPage,
        rowsPerPage: rowsPerPage,
      };
      setLoading(true);
      return getCommunityTypeMemberList(newData);
    });
  };

  const {
    mutate: putCommunityChangeMemberTypeMutate,
    isError: isMutateNameError,
  } = useMutation(putCommunityChangeMemberType, {
    onSuccess: () => {
      setOpenSnackbar(true);
      communityMemberRefetch();
      setOpenMore(0);
    },
    onError: error => {
      setOpenSnackbar(true);
      setSnackbarMessage(
        '커뮤니티 멤버 타입 변경이 실패했습니다. 다시 시도해주시기 바랍니다.'
      );
    },
  });

  const {
    mutate: deleteCommunityMemberMutate,
    isError: isMutateNameDeleteError,
  } = useMutation(deleteCommunityMember, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setSnackbarMessage('커뮤니티 멤버 탈퇴처리했습니다.');
        communityTypeMemberRefetch();
        setMemberCheckedButtons([]);
        setLoading(false);
      } else {
        setOpenSnackbar(true);
        setSnackbarMessage(
          `커뮤니티 멤버 탈퇴하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
        setLoading(false);
      }
    },
    onError: error => {
      setLoading(false);
      setOpenSnackbar(true);
      setSnackbarMessage(
        '커뮤니티 멤버 탈퇴 처리가 실패했습니다. 다시 시도해주시기 바랍니다.'
      );
    },
  });

  const {
    mutate: postCommunityMemberByPhonenosMutate,
    isError: isMutateMemberError,
  } = useMutation(postCommunityMemberByPhonenos, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setSnackbarMessage('커뮤니티 멤버를 추가했습니다.');
        setLoading(false);
        communityTypeMemberRefetch();
      } else {
        setOpenSnackbar(true);
        setSnackbarMessage(
          `커뮤니티 멤버 추가하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
        setLoading(false);
      }
    },
    onError: error => {
      setLoading(false);
      setOpenSnackbar(true);
      setSnackbarMessage(
        `커뮤니티 멤버 추가가 실패했습니다. 다시 시도해주시기 바랍니다.(${error})`
      );
    },
  });

  const {
    mutate: putCommunityTypeMemberByPhonenosMutate,
    isError: isMutateMemberTypeError,
  } = useMutation(putCommunityChangeMemberTypeByPhonenos, {
    onSuccess: () => {
      setLoading(false);
      setOpenSnackbar(true);
      setSnackbarMessage('커뮤니티 멤버 타입을 변경했습니다.');
      setCheckedButtons([]);
      communityMemberRefetch();
    },
    onError: error => {
      setLoading(false);
      setOpenSnackbar(true);
      setSnackbarMessage(
        '커뮤니티 멤버 타입 변경이 실패했습니다. 다시 시도해주시기 바랍니다.'
      );
    },
  });

  const uploadExcelFile = (evt: ChangeEvent<HTMLInputElement>) => {
    const excelFile = evt.target.files && evt.target.files[0];

    var formData = new FormData();
    if (excelFile !== null) formData.append('file', excelFile);

    xapi
      .post(
        `/admin/v2/apis/upload/community/${communityData.communityId}/join-form`,
        formData
      )
      .then(res => {
        joinFormInputRef.current = null;
        if (res.status !== 200) throw new Error('http 에러');
        setOpenSnackbar(true);
        setSnackbarMessage('엑셀 업로드로 커뮤니티 Join Form을 등록했습니다.');
        return res.data;
      });
  };

  const addMember = (userPhonenos: any[]) => {
    const newData = {
      communityId: communityData && communityData.communityId,
      type: 0,
      phonenos: userPhonenos,
    };
    setLoading(true);
    postCommunityMemberByPhonenosMutate(newData);
  };

  const addOperator = (userPhonenos: any[]) => {
    const newData = {
      communityId: communityData && communityData.communityId,
      type: 1,
      phonenos: userPhonenos,
    };
    setLoading(true);
    putCommunityTypeMemberByPhonenosMutate(newData);
  };

  const changeMemberType = (type: number) => {
    const communityId = communityData && communityData.communityId;
    const newUserIds = memberCheckedButtons;
    const newData = {
      communityId: communityId,
      type: type,
      userIds: newUserIds,
    };
    setSnackbarMessage('운영진으로 전환되었습니다.');
    putCommunityChangeMemberTypeMutate(newData);
  };

  const cancelMemberType = () => {
    const communityId = communityData && communityData.communityId;
    const newUserIds = checkedButtons;
    const newData = {
      communityId: communityId,
      type: 0,
      userIds: newUserIds,
    };
    setSnackbarMessage('멤버로 전환되었습니다.');
    putCommunityChangeMemberTypeMutate(newData);
  };

  const deleteMembers = () => {
    const communityId = communityData && communityData.communityId;
    const newUserIds = memberCheckedButtons;
    const newData = {
      communityId: communityId,
      type: 0, // by userId
      userIds: newUserIds,
    };
    if (newUserIds.length === 0) {
      setOpenSnackbar(true);
      setSnackbarMessage('탈퇴 처리할 멤버를 선택해 주세요');
      return;
    }
    setLoading(true);
    deleteCommunityMemberMutate(newData);
  };

  const deleteMemberByPhonenos = (userPhonenos: any[]) => {
    const communityId = communityData && communityData.communityId;
    const newData = {
      communityId: communityId,
      type: 1, // by phonenos
      phonenos: userPhonenos,
    };
    if (userPhonenos.length === 0) {
      setOpenSnackbar(true);
      setSnackbarMessage('탈퇴 처리할 멤버를 선택해 주세요');
      return;
    }
    setLoading(true);
    deleteCommunityMemberMutate(newData);
  };

  const downloadMember = () => {
    const communityId = communityData && communityData.communityId;
    const newData = {
      communityId: communityId,
      searchKey: '',
      searchWord: '',
      type: 0,
      startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime() / 1000,
      endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime() / 1000,
    };
    downloadCommunityMemberList(newData);
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
    prefetchMember(newPage);
  };
  const handleChangeMemberPage = (_event: any, newPage: number) => {
    setMemberPage(newPage);
    prefetchTypeMember(newPage);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedButtons([]);
    if (event.target.checked) {
      setCheckedAll(true);
      operators
        ?.filter((row: any) => row.deleted === 0)
        .map((row: any, idx: number) => {
          setCheckedButtons(current => [...current, row.userId]);
        });
    } else {
      setCheckedAll(false);
    }
    console.log('handleSelectAllClick:', checkedButtons);
  };

  const changeHandler = (checked: boolean, id: string) => {
    if (checked) {
      setCheckedButtons([...checkedButtons, id]);
    } else {
      setCheckedButtons(checkedButtons.filter(button => button !== id));
    }
  };

  const memberHandleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    setMemberCheckedButtons([]);
    if (event.target.checked) {
      setMemberCheckedAll(true);
      communityMembers
        ?.filter((row: any) => row.deleted === 0)
        .map((row: any, idx: number) => {
          setMemberCheckedButtons(current => [...current, row.userId]);
        });
    } else {
      setMemberCheckedAll(false);
    }
    console.log('handleSelectAllClick:', memberCheckedButtons);
  };

  const memberChangeHandler = (checked: boolean, id: string) => {
    if (checked) {
      setMemberCheckedButtons([...memberCheckedButtons, id]);
    } else {
      setMemberCheckedButtons(
        memberCheckedButtons.filter(button => button !== id)
      );
    }
  };

  const onChangeSetting = () => {
    setSettingOpen(false);
    communityMemberRefetch();
    communityTypeMemberRefetch();
  };

  const searchOperator = () => {
    setPage(1);
    communityMemberRefetch();
  };

  const searchMember = () => {
    setMemberPage(1);
    communityTypeMemberRefetch();
  };

  const isMember = (phoneno: string) => {
    if (dialogTitle === MEMBER_TYPE) {
      const newMember = communityMembers.filter(
        (item: any) => item.phoneno === phoneno && item.deleted === 0
      );
      if (newMember.length > 0) return true;
    } else if (dialogTitle === OPERATOR_TYPE) {
      const newMember = operators.filter(
        (item: any) => item.phoneno === phoneno && item.deleted === 0
      );
      if (newMember.length > 0) return true;
    }
    return false;
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchOperator();
    }
  };

  useEffect(() => {
    console.log('loadUserList :', loadUserList);
    if (loadUserList.length > 0)
      dialogTitle === MEMBER_TYPE
        ? addMember(loadUserList)
        : dialogTitle === OPERATOR_TYPE
        ? addOperator(loadUserList)
        : dialogTitle === MEMBER_DELETE_TYPE
        ? deleteMemberByPhonenos(loadUserList)
        : '-';
  }, [loadUserList]);

  useEffect(() => {
    communityMemberRefetch();
  }, [rowsPerPage]);

  useEffect(() => {
    communityTypeMemberRefetch();
  }, [rowsPerMemberPage]);

  useEffect(() => {
    if (rangeDate[0] !== null && rangeDate[1] !== null) {
      communityTypeMemberRefetch();
    }
  }, [rangeDate]);

  useEffect(() => {
    if (request) {
      setRangeDate([null, null]);
      communityMemberRefetch();
      communityTypeMemberRefetch();
    }
  }, [request]);

  useEffect(() => {
    if (location.state) {
      setCommunityData(location.state);
      setRequest(true);
    }
  }, [location.state]);

  return (
    <Box sx={{ m: '2rem 0' }}>
      <Box>
        <Typography variant='h3' sx={{ fontWeight: 500 }}>
          운영진 설정
        </Typography>
        <Box className='flex_between' sx={{ mt: '2rem' }}>
          <WhiteTextField
            placeholder='닉네임, 아이디, 연락처로 검색해 주세요'
            sx={{ width: '20rem' }}
            inputRef={operatorRef}
            onKeyDown={evt => {
              handleKeyDown(evt);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    sx={{ pr: 0 }}
                    onClick={() => {
                      searchOperator();
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div>
            <Button
              variant='outlined'
              color='info'
              onClick={() => {
                setOpenMore(2);
              }}
            >
              권한 해제
            </Button>
            <Button
              variant='contained'
              color='info'
              sx={{ ml: '1rem' }}
              onClick={() => {
                setDialogTitle(OPERATOR_TYPE);
                setSettingOpen(true);
              }}
            >
              운영진 추가
            </Button>
            <Button
              variant='contained'
              color='info'
              sx={{ ml: '1rem' }}
              onClick={() => {
                setDialogTitle(OPERATOR_TYPE);
                setOpenLoadUser(2);
              }}
            >
              복사하여 추가
            </Button>
          </div>
        </Box>
        <Paper sx={{ m: '1rem 0', p: '1rem' }}>
          <Box className='flex_between'>
            <TableTitle title='운영진' count={operators && operatorCount} />
            <SelectTextField
              select
              value={rowsPerPage}
              onChange={evt => {
                setRowsPerPage(Number(evt.target.value));
                setPage(1);
              }}
            >
              {rowsPerPageOptions.map(option => (
                <MenuItem key={option.toString()} value={option}>
                  {option}개씩 보기
                </MenuItem>
              ))}
            </SelectTextField>
          </Box>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell padding='checkbox'>
                  <Checkbox
                    onChange={e => {
                      handleSelectAllClick(e);
                    }}
                    checked={checkedAll}
                  />
                </TableCell>
                {columns.map((column, idx) => (
                  <TableCell key={idx.toString()}>{column}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {operators !== undefined && operators.length > 0 ? (
                operators?.map((operator: any, idx: number) => (
                  <TableRow key={idx.toString()}>
                    <TableCell padding='checkbox'>
                      <Checkbox
                        id={operator.userId}
                        disabled={operator.deleted === 1}
                        onChange={e => {
                          changeHandler(
                            e.currentTarget.checked,
                            operator.userId
                          );
                        }}
                        checked={checkedButtons.includes(operator.userId)}
                      />
                    </TableCell>
                    <TableCell
                      className={
                        operator.deleted === 1 ? clsx('not_important_row') : ''
                      }
                      sx={{ pt: '.5rem', pb: '.5rem' }}
                    >
                      {operator.name}
                    </TableCell>
                    <TableCell
                      className={
                        operator.deleted === 1 ? clsx('not_important_row') : ''
                      }
                    >
                      {operator.nickName === null
                        ? '-'
                        : operator.useNickName === 1
                        ? 'O'
                        : '-'}
                    </TableCell>
                    <TableCell
                      className={
                        operator.deleted === 1 ? clsx('not_important_row') : ''
                      }
                    >
                      {operator.nickName !== null ? operator.nickName : '-'}
                    </TableCell>
                    <TableCell
                      className={
                        operator.deleted === 1 ? clsx('not_important_row') : ''
                      }
                    >
                      {operator.phoneno}
                    </TableCell>
                    <TableCell
                      className={
                        operator.deleted === 1 ? clsx('not_important_row') : ''
                      }
                    >
                      {operator.userId}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={9}>검색결과 없습니다.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {operators !== undefined && operators.length > 0 && totalRecords && (
            <SwPagination
              page={page}
              handleChangePage={handleChangePage}
              count={operators && Math.ceil(totalRecords / rowsPerPage)}
            />
          )}
        </Paper>
      </Box>
      <div style={{ marginTop: '10rem' }}>
        <Typography variant='h3' sx={{ fontWeight: 500 }}>
          멤버 목록
        </Typography>
        <Box className='flex_between' sx={{ mt: '2rem' }}>
          <SelectSearchBox
            searchOption={searchOption}
            searchWord={searchRef}
            optionValue={selectRef}
            defaultOption='name'
            isSearching={() => searchMember()}
          />
          <Box className='flex_center'>
            <div>
              <Button
                variant='outlined'
                color='info'
                onClick={() => setOpenConfirm(true)}
              >
                멤버 탈퇴
              </Button>
              <Button
                variant='outlined'
                color='info'
                sx={{ ml: '1rem' }}
                onClick={() => {
                  setDialogTitle(MEMBER_DELETE_TYPE);
                  setOpenLoadUser(2);
                }}
              >
                복사하여 탈퇴
              </Button>
              <Button
                variant='contained'
                color='info'
                sx={{ ml: '1rem' }}
                onClick={() => {
                  setDialogTitle(MEMBER_TYPE);
                  setSettingOpen(true);
                }}
              >
                멤버 추가
              </Button>
              <Button
                variant='contained'
                color='info'
                sx={{ ml: '1rem' }}
                onClick={() => {
                  changeMemberType(1);
                }}
              >
                운영진 전환
              </Button>
              <Button
                variant='contained'
                color='info'
                sx={{ ml: '1rem' }}
                onClick={() => {
                  setDialogTitle(MEMBER_TYPE);
                  setOpenLoadUser(1);
                }}
              >
                복사하여 추가
              </Button>
              <Button
                variant='contained'
                color='primary'
                sx={{ ml: '1rem' }}
                onClick={() => {
                  const gpxfile = document.getElementById(
                    'community_join_form_excel'
                  );
                  gpxfile?.click();
                }}
              >
                가입정보 엑셀업로드
              </Button>
              <input
                className='nonedisplay'
                id='community_join_form_excel'
                type='file'
                ref={joinFormInputRef}
                onChange={uploadExcelFile}
              />
            </div>
          </Box>
        </Box>
        <Paper sx={{ m: '1rem 0', p: '1rem' }}>
          <Box className='flex_between' sx={{ pb: '1rem' }}>
            <TableTitle
              title='멤버'
              count={communityMembers && memberCount}
              postFix={`(누적 가입자: ${
                memberCount + invalidMemberCount
              }명, 탈퇴 사용자: ${invalidMemberCount}명)`}
            />
            <Box className='flex_center'>
              <Collapse orientation='horizontal' in={openFilter}>
                <SwDateRangePicker
                  rangeDate={rangeDate}
                  setRangeDate={setRangeDate}
                />
              </Collapse>
              <TableTopButton
                color='info'
                variant='outlined'
                onClick={() => setOpenFilter(!openFilter)}
              >
                <FilterAltOutlinedIcon />
                필터
                <ArrowBackIosNewOutlinedIcon
                  sx={{ fontSize: '1rem', ml: '.5rem' }}
                />
              </TableTopButton>
              <SelectTextField
                select
                value={rowsPerMemberPage}
                onChange={evt => {
                  setRowsPerMemberPage(Number(evt.target.value));
                  setMemberPage(1);
                }}
              >
                {rowsPerPageOptions.map(option => (
                  <MenuItem key={option.toString()} value={option}>
                    {option}개씩 보기
                  </MenuItem>
                ))}
              </SelectTextField>
              <TableTopButton
                color='info'
                variant='outlined'
                onClick={downloadMember}
              >
                데이터 다운로드
                <FileDownloadRoundedIcon color='success' />
              </TableTopButton>
            </Box>
          </Box>
          <Table>
            <TableHead sx={{ bgcolor: '#F9FAFC' }}>
              <TableRow>
                <TableCell padding='checkbox'>
                  <Checkbox
                    onChange={e => {
                      memberHandleSelectAllClick(e);
                    }}
                    checked={memberCheckedAll}
                  />
                </TableCell>
                {memberColumns.map((column, idx) => (
                  <TableCell
                    key={idx.toString()}
                    align={idx === 5 || idx === 7 ? 'right' : 'inherit'}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {communityMembers.length > 0 ? (
                communityMembers?.map((member: any, idx: number) => (
                  <TableRow key={idx.toString()}>
                    <TableCell padding='checkbox'>
                      <Checkbox
                        id={member.userId}
                        disabled={member.deleted === 1}
                        onChange={e => {
                          memberChangeHandler(
                            e.currentTarget.checked,
                            member.userId
                          );
                        }}
                        checked={memberCheckedButtons.includes(member.userId)}
                      />
                    </TableCell>
                    <TableCell
                      className={
                        member.deleted === 1 ? clsx('not_important_row') : ''
                      }
                    >
                      {member.name}
                    </TableCell>
                    <TableCell
                      className={
                        member.deleted === 1 ? clsx('not_important_row') : ''
                      }
                    >
                      {member.nickName === null
                        ? '-'
                        : member.useNickName === 1
                        ? 'O'
                        : '-'}
                    </TableCell>
                    <TableCell
                      className={
                        member.deleted === 1 ? clsx('not_important_row') : ''
                      }
                    >
                      {member.nickName !== null ? member.nickName : '-'}
                    </TableCell>
                    <TableCell
                      className={
                        member.deleted === 1 ? clsx('not_important_row') : ''
                      }
                    >
                      {member.phoneno}
                    </TableCell>
                    <TableCell
                      className={
                        member.deleted === 1 ? clsx('not_important_row') : ''
                      }
                    >
                      {member.groupName}
                    </TableCell>
                    <TableCell
                      className={
                        member.deleted === 1 ? clsx('not_important_row') : ''
                      }
                      sx={{
                        color:
                          member.residenceAuthYN === 'Y'
                            ? 'blue'
                            : member.residenceAddress !== null
                            ? 'red'
                            : '',
                      }}
                    >
                      {member.residenceAddress !== null
                        ? member.residenceAddress
                        : '-'}
                    </TableCell>
                    <TableCell
                      className={
                        member.deleted === 1 ? clsx('not_important_row') : ''
                      }
                      sx={{
                        color:
                          member.activityAuthYN === 'Y'
                            ? 'blue'
                            : member.activityAddress !== null
                            ? 'red'
                            : '',
                      }}
                    >
                      {member.activityAddress !== null
                        ? member.activityAddress
                        : '-'}
                    </TableCell>
                    <TableCell
                      className={
                        member.deleted === 1 ? clsx('not_important_row') : ''
                      }
                    >
                      {member.gender}
                    </TableCell>
                    <TableCell
                      align='right'
                      className={
                        member.deleted === 1 ? clsx('not_important_row') : ''
                      }
                    >
                      {member.age}세
                    </TableCell>
                    <TableCell
                      className={
                        member.deleted === 1 ? clsx('not_important_row') : ''
                      }
                    >
                      {timestamp2Localestring(member.joinTime)}
                    </TableCell>
                    <TableCell
                      align='right'
                      className={
                        member.deleted === 1 ? clsx('not_important_row') : ''
                      }
                    >
                      {member.communityCount}개
                    </TableCell>
                    <TableCell
                      align='right'
                      className={
                        member.deleted === 1 ? clsx('not_important_row') : ''
                      }
                    >
                      {member.deleted === 1
                        ? timestamp2Localestring(member.deletedDate, 1000)
                        : '-'}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={9}>검색결과 없습니다.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {communityMembers.length > 0 && memberTotalRecords && (
            <SwPagination
              page={memberPage}
              handleChangePage={handleChangeMemberPage}
              count={
                communityMembers &&
                Math.ceil(memberTotalRecords / rowsPerMemberPage)
              }
            />
          )}
        </Paper>
      </div>
      <CommunityMemberSetting
        communityId={communityData && communityData.communityId}
        open={settingOpen}
        onClose={() => setSettingOpen(!settingOpen)}
        onConfirm={onChangeSetting}
        title={dialogTitle}
        memberType={dialogTitle === MEMBER_TYPE ? 0 : 1}
        isMember={isMember}
      />
      <SwAlert
        title='test'
        contents='contentss'
        confirm={1}
        onClose={() => setAlertOpen(false)}
        onConfirm={() => setAlertOpen(false)}
        open={alertOpen}
      />
      <SwSnackbar
        open={openSnackbar}
        onClose={() => {
          setOpenSnackbar(false);
          setSnackbarMessage('');
        }}
        contents={snackbarMessage}
      />
      <SwConfirmDialog
        contents='선택한 멤버를 탈퇴처리하겠습니까?'
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => {
          deleteMembers();
          setOpenConfirm(false);
        }}
        confirm={1}
      />
      <SwLoadUser
        open={!!openLoadUser}
        onclose={() => {
          setOpenLoadUser(0);
        }}
        setLoadList={setLoadUserList}
        maxSize={MAX_LOAD_PHONE_LENGTH}
      />
      {loading && <SwLoading />}
      {openMore === 2 && (
        <Dialog
          open={!!openMore}
          sx={{
            '& .MuiDialog-container': {
              height: '85%',
            },
          }}
        >
          <Divider sx={{ p: 0, m: 0 }} />
          <DialogContent sx={{ p: '1rem 2rem' }}>
            <Typography sx={{ mt: '0.7rem' }}>
              선택한 운영진의 권한을 해제하시겠습니까?
            </Typography>
          </DialogContent>
          <Divider sx={{ p: 0, m: 0 }} />
          <DialogActions>
            <Button
              color='info'
              fullWidth
              size='small'
              onClick={() => setOpenMore(0)}
            >
              취소
            </Button>
            <Button
              color='primary'
              fullWidth
              size='small'
              onClick={() => cancelMemberType()}
            >
              확인
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};
