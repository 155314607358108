import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ClearIcon from '@mui/icons-material/Clear';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CloseIcon from '@mui/icons-material/Close';
import koLocale from 'date-fns/locale/ko';
import SearchIcon from '@mui/icons-material/Search';
import React, {
  ChangeEvent,
  Dispatch,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { debouncehook } from '../../hooks/debouncehook';
import { useMany } from '../../hooks/api';
import { SwSnackbar } from './views/SwSnackbar';
import { SwAlert } from './views/SwAlert';
import { SwDatesPicker } from './dateSetting/SwDatesPicker';
import { SwSearchTarget } from './SwSearchTarget';
import { IPush, DefaultPush } from '../../models/push';
import { RESULT_OK } from '../../common/resultCode';
import { SwLoadUser } from './SwLoadUser';
import {
  commaFormat,
  encodeSearchWord,
  getPushLinkCode,
  timestamp2Localestring,
} from '../../common/helper';
import { SwPushSelectLocation } from './SwPushSelectLocation';
import { SwPushSelectOsVersion } from './SwPushSelectOsVersion';
import { getRecentLocaitonList } from '../../common/api/ApiGeohash';
import {
  KEY_APP_AOS_VERSION_USER_COUNT,
  KEY_APP_AOS_VERSIONS,
  KEY_APP_IOS_VERSION_USER_COUNT,
  KEY_APP_IOS_VERSIONS,
  KEY_APP_OS_RECENT_VERSION,
  KEY_APP_OS_VERSION_LIST,
  KEY_APP_OS_VERSIONS,
  KEY_GEOHASH_RECENT_LOCATION_LIST,
  KEY_REGISTERED_PUSH_LIST,
  KEY_TARGET_LIST,
  KEY_TARGET_PUSH_LIST,
} from '../../common/key';
import SwLoading from './spinner/SwLoading';
import { getTarget } from '../../common/api/ApiCommon';
import { SwDatesPickerWithScroll } from './dateSetting/SwDatesPickerWithScroll';
import {
  getAppOsRecentVersion,
  getAppOsVersions,
  getAppOsVersionUserCount,
} from '../../common/api/ApiUser';
import { getAppOsVersionList } from '../../common/api/ApiPush';
import { AppReleaseNoteDialog } from '../userManagement/appUpdateGuide/AppReleaseNoteDialog';

interface Props {
  osType: number;
  osVersion: any;
  setOsVersion: Dispatch<any>;
  resetTag: boolean;
}

const RootPaper = styled(Paper)({
  // margin: '2.8rem',
  padding: '1rem',
  borderRadius: '12px',
});

const ScrollPaper = styled(Paper)({
  maxHeight: '10rem',
  overflowY: 'scroll',
  padding: '.5rem',
  width: '44rem ',
});

const UserBox = styled(Box)({
  margin: '.4rem .4rem .4rem 0',
  color: '#777777',
  display: 'flex',
  alignItems: 'center',
});

export const SwOsVersionSetup: React.FC<Props> = ({
  osType,
  osVersion,
  setOsVersion,
  resetTag,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [versionList, setVersionList] = useState<any[]>([]);
  const [sortedVersionList, setSortedVersionList] = useState<any[]>([]);
  const [versionIndex, setVersionIndex] = useState<number>(0);
  const [sortedVersionIndex, setSortedVersionIndex] = useState<number>(0);
  const [userCount, setUserCount] = useState<number>(0);
  const [loading, setLoading] = useState<any>(true);
  const [openReleaseNote, setOpenReleaseNote] = useState<boolean>(false);

  const {
    data,
    isLoading,
    refetch: refetchAppOsVersion,
  } = useQuery(
    osType === 1 ? KEY_APP_AOS_VERSIONS : KEY_APP_IOS_VERSIONS,
    () => {
      if (osType === 1 || osType === 2)
        return getAppOsVersions(osType === 1 ? 'android' : 'ios');
      return null;
    },
    {
      onSuccess: res => {
        setLoading(false);
        if (res !== null) {
          setVersionIndex(0);
          setSortedVersionIndex(0);
          setVersionList(res.versions.versions);
          setSortedVersionList(res.versions.sortedVersions);
        }
      },
      onError: e => {
        setLoading(false);
        console.log(e);
      },
    }
  );

  const { refetch: refetchAppOsVersionUserCount } = useQuery(
    osType === 1
      ? KEY_APP_AOS_VERSION_USER_COUNT
      : KEY_APP_IOS_VERSION_USER_COUNT,
    () => {
      if ((osType === 1 || osType === 2) && sortedVersionIndex > 0) {
        const newData: any = {
          osType: osType === 1 ? 'android' : 'ios',
          minVersion: sortedVersionList[sortedVersionIndex - 1],
          maxVersion:
            versionIndex > 0
              ? versionList[versionIndex - 1]
              : sortedVersionList[sortedVersionIndex - 1],
        };
        return getAppOsVersionUserCount(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        setLoading(false);
        if (res !== null) {
          setUserCount(res.count);
          setOsVersion((prev: any) => ({ ...prev, count: res.count }));
        }
      },
      onError: e => {
        setLoading(false);
        console.log(e);
      },
    }
  );

  useEffect(() => {
    if (sortedVersionIndex !== 0) {
      setOsVersion({
        minVersion: sortedVersionList[sortedVersionIndex - 1],
        maxVersion: '',
        count: userCount,
      });
      refetchAppOsVersionUserCount();
    }
  }, [sortedVersionIndex]);

  useEffect(() => {
    if (versionIndex !== 0 && sortedVersionIndex !== 0) {
      setOsVersion({
        minVersion: sortedVersionList[sortedVersionIndex - 1],
        maxVersion: versionList[versionIndex - 1],
        count: userCount,
      });
      refetchAppOsVersionUserCount();
    } else if (versionIndex === 0 && sortedVersionIndex !== 0) {
      setOsVersion({
        minVersion: sortedVersionList[sortedVersionIndex - 1],
        maxVersion: '',
        count: userCount,
      });
      refetchAppOsVersionUserCount();
    }
  }, [versionIndex]);

  useEffect(() => {
    setVersionIndex(0);
    setSortedVersionIndex(0);
    setSortedVersionList([]);
    setVersionList([]);
    if (osType === 1 || osType === 2) {
      refetchAppOsVersion();
    }
  }, [osType, resetTag]);

  useEffect(() => {
    const isCreate = location.pathname.includes('regist');

    if (!isCreate && osVersion !== undefined && osVersion !== null) {
      const newMinIndex = sortedVersionList
        .map((item: any, idx: number) =>
          item === osVersion.minVersion
            ? { ...item, idx: idx }
            : { ...item, idx: -1 }
        )
        .filter((item: any) => item.idx !== -1);

      const newMaxIndex = versionList
        .map((item: any, idx: number) =>
          item === osVersion.maxVersion
            ? { ...item, idx: idx }
            : { ...item, idx: -1 }
        )
        .filter((item: any) => item.idx !== -1);
      setSortedVersionIndex(
        newMinIndex !== null && newMinIndex.length > 0
          ? newMinIndex[0].idx + 1
          : 0
      );
      setVersionIndex(
        newMaxIndex !== null && newMaxIndex.length > 0
          ? newMaxIndex[0].idx + 1
          : 0
      );
      setUserCount(osVersion.count);
    }
  }, [osVersion]);

  return (
    <>
      <Box>
        <Box>
          <Typography className='title'>{`${
            osType === 1 ? '안드로이드' : 'iOS'
          } 버전 설정`}</Typography>
          <Box display='flex' alignItems='center'>
            <Box sx={{ mr: '2rem' }}>
              <Typography>최소 앱 버전</Typography>
              <TextField
                sx={{ width: '11rem', mb: 0 }}
                select
                defaultValue={0}
                value={sortedVersionIndex}
                onChange={(
                  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => {
                  const newIndx = Number(event.target.value);
                  setSortedVersionIndex(newIndx);
                  setOsVersion(
                    newIndx === 0
                      ? `${osType}>`
                      : `${osType}>${sortedVersionList[newIndx - 1]}`
                  );
                }}
              >
                <MenuItem key={0} value={0}>
                  선택
                </MenuItem>
                {sortedVersionList &&
                  sortedVersionList.map((option: string, idx: number) => (
                    <MenuItem key={idx + 1} value={idx + 1}>
                      {option}
                    </MenuItem>
                  ))}
              </TextField>
            </Box>
            <Box>
              <Typography>최대 앱 버전</Typography>
              <TextField
                sx={{ width: '11rem', mb: 0 }}
                select
                defaultValue={0}
                value={versionIndex}
                onChange={(
                  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => {
                  const newIndx = Number(event.target.value);
                  setVersionIndex(newIndx);
                  setOsVersion(
                    newIndx === 0
                      ? `${osType}>`
                      : `${osType}>${versionList[newIndx - 1]}`
                  );
                }}
              >
                <MenuItem key={0} value={0}>
                  선택
                </MenuItem>
                {versionList &&
                  versionList
                    .filter(
                      (option: string, idx: number) =>
                        idx < versionList.length - sortedVersionIndex
                    )
                    .map((option: string, idx: number) => (
                      <MenuItem key={idx + 1} value={idx + 1}>
                        {option}
                      </MenuItem>
                    ))}
              </TextField>
            </Box>
            <Button
              variant='outlined'
              color='info'
              sx={{ ml: '1rem', alignSelf: 'flex-end' }}
              onClick={() => setOpenReleaseNote(true)}
            >
              릴리즈 노트 보기
            </Button>
          </Box>
          {sortedVersionIndex !== 0 && (
            <Typography className='bluecontent'>{`대상자 수: ${commaFormat(
              String(userCount)
            )}명`}</Typography>
          )}
          <Divider />
        </Box>
      </Box>
      <AppReleaseNoteDialog
        osType={osType}
        open={openReleaseNote}
        onclose={() => setOpenReleaseNote(false)}
      />
    </>
  );
};
