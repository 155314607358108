import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  styled,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import React, {
  ChangeEvent,
  DragEvent,
  Dispatch,
  MouseEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router';
import { useRecoilState, useRecoilValue } from 'recoil';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import moment from 'moment';
import { xapi } from '../../../hooks/session';
import { debouncehook } from '../../../hooks/debouncehook';
import {
  DraggablePaper,
  RowsCell,
  MultilineTextField,
} from '../../styles/Styles';
import { SwUuidCreate } from '../../commonComponent/SwUuidCreate';
import { SwImageSetting } from '../../commonComponent/SwImageSetting';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { DataportalMultiConnect } from '../../commonComponent/DataportalMultiConnect';
import useLocalStorage from '../../../hooks/storage';
import {
  calculateDays,
  checkUrl,
  commaFormat,
  getAddUrl,
  MAX_STEP,
  STEP_STRIDE_CONST,
  STEP_TO_CALORIE_CONST,
  timestamp2Localestring,
  timestamp2string,
  TOOLTIP_CHALLEGE_ALBUM_IMAGE_USE,
  TOOLTIP_CHALLEGE_POST_USE,
  TOOLTIP_CHALLEGE_RANKING_USE,
  TOOLTIP_CHALLEGE_TYPE,
  TOOLTIP_CHALLENGE_CLASS_TYPE,
  TOOLTIP_CHALLENGE_COVER_INFORMATION,
  TOOLTIP_CHALLENGE_INFORMATION,
  TOOLTIP_CHALLENGE_LINK,
  TOOLTIP_CHALLENGE_LOCATION,
  TOOLTIP_CHALLENGE_OPEN,
  TOOLTIP_CHALLENGE_RECOMMEND,
  TOOLTIP_FONT_SIZE,
  trimWhiteSpace,
} from '../../../common/helper';
import {
  ButtonBox,
  ChallengeTypeBox,
  ChallengeTypeTitle,
  ContentTitle,
  CreatePaper,
  MarginButton,
} from './ChallengeStyles';
import {
  DefaultChallengeCreate,
  IChallengeClassType,
  IChallengeCreate,
  IChallengeStatType,
  IChallengeSubType,
  IGroupBattleCommunity,
  IGroupFilter,
  IGuideTool,
} from '../../../models/challenge';
import { IUploadFile } from '../../../models/common';
import { postUploadFile } from '../../../common/api/ApiCommon';
import {
  getChallengeBrandPointAdvertiserList,
  registerChallengeMaster,
} from '../../../common/api/ApiChallenge';
import { RESULT_OK } from '../../../common/resultCode';

import { uploadFile } from '../../../common/api/ApiUpload';
import { SwSearchTarget } from '../../commonComponent/SwSearchTarget';
import {
  KEY_CHALLENGE_BRAND_POINT_ADVERTISER_LIST,
  KEY_WORD_DUMMY_ID,
} from '../../../common/key';
import {
  brandPointAdvertiserState,
  challengeClassTypeState,
  challengeState,
} from '../../../common/atom';
import { SwFindLatLngbyKakaomap } from '../../commonComponent/map/SwFindLatLngbyKakaomap';
import { SwSearchPostCode } from '../../commonComponent/SwSearchPostCode';
import { SwWeekAndTimePicker } from '../../commonComponent/dateSetting/SwWeekAndTimePicker';
import { SwChallengeClassType } from '../../commonComponent/SwChallengeClassType';
import GroupChoice from './GroupChoice';
import { SwStampTourView } from '../../commonComponent/SwStampTourView';
import { SwWalkCourseView } from '../../commonComponent/SwWalkCourseView';
import { SwFieldView } from '../../commonComponent/SwFieldView';

interface Props {
  challengeMasterType: any;
  challengeMasterSubType: IChallengeSubType[];
  challengeMasterStatType: IChallengeStatType[];
  challengeInfo: any;
  setChallengeInfo: Dispatch<React.SetStateAction<any>>;
  reloadChallenge: (id: string) => void;
}

const guidetool: IGuideTool = {
  title: '소제목',
  contents: '안내내용',
  image: '',
  imageFile: null,
  uploadUrl: '',
};

const MAX_GUIDE_PAGE = 6;
const NO_SELECT_ADVERTISER = 99;

export const ChallengeForm: React.FC<Props> = ({
  challengeMasterType,
  challengeMasterSubType,
  challengeMasterStatType,
  challengeInfo,
  setChallengeInfo,
  reloadChallenge,
}) => {
  const classTypeList = useRecoilValue(challengeClassTypeState);
  const [selectedChallengeInfo, setSelectedChallengeInfo] =
    useRecoilState(challengeState);
  const [brandPointAdvertiserList, setBrandPointAdvertiserList] =
    useRecoilState(brandPointAdvertiserState);

  const location = useLocation();
  const usingTicket = useRef<any>();
  const challengeTitleRef = useRef<any>();
  const findWalkcourseRef = useRef<any>();
  const findStampcourseRef = useRef<any>();
  const findFieldRef = useRef<any>();
  const [selectedChallenge, setSelectedChallenge] = useState<boolean>(false);
  const [testType, setTestType] = useState<number>();
  const [challengeHidden, setChallengeHidden] = useState<number>(0);
  const [masterType, setMasterType] = useState<any>([]);
  const [challengeTitle, setChallengeTitle] = useState<string>('');
  const [challengeGoal, setChallengeGoal] = useState<number>(0);
  const [challengeGpsGoal, setChallengeGpsGoal] = useState<number>(0);
  const [challengeStepsMax, setChallengeStepsMax] = useState<number>(0);
  const [challengeStepsMin, setChallengeStepsMin] = useState<number>(0);
  const [maxPoint, setMaxPoint] = useState<number>(0);
  const [challengeStepsPerPoint, setChallengeStepsPerPoint] =
    useState<number>(0);
  const [challengeStatus, setChallengeStatus] = useState<number>(1);
  const [challengeInfoDuration, setChallengeInfoDuration] =
    useState<string>('');
  const [challengeStampTourId, setChallengeStampTourId] = useState<string>('');
  const [challengeWalkCourseId, setChallengeWalkCourseId] =
    useState<string>('');
  const [walkCourseDistance, setWalkCourseDistance] = useState<number>(0);
  const [challengeStampTourName, setChallengeStampTourName] =
    useState<string>('');
  const [challengeWalkCourseName, setChallengeWalkCourseName] =
    useState<string>('');
  const [challengeWalkZoneId, setChallengeWalkZoneId] = useState<string>('');
  const [challengeFieldId, setChallengeFieldId] = useState<string>('');
  const [challengeFieldName, setChallengeFieldName] = useState<string>('');
  const [challengeInfoReward, setChallengeInfoReward] = useState<string>('');
  const [challengeInfoGoal, setChallengeInfoGoal] = useState<string>('');
  const [challengeViewType, setChallengeViewType] = useState<any>('9');
  const [challengeTestStime, setChallengeTestStime] = useState<number>(0);
  const [coverInfo, setCoverInfo] = useState<string>('');
  const [coverReward, setCoverReward] = useState<string>('');
  const [coverAd, setCoverAd] = useState<string>('');
  const [classType, setClassType] = useState<number>(0);
  const [recommendType, setRecommendType] = useState<boolean>(false);
  const [attendLimit, setAttendLimit] = useState<number>(0);
  const [guideTitle, setGuideTitle] = useState<any>('');
  const [guideContent, setGuideContent] = useState<any>('');
  const [guideImage, setGuideImage] = useState<any>('');
  const [guideImageFile, setGuideImageFile] = useState<any>(null);
  const [challengeMainImage, setChallengeMainImage] = useState<string>('');
  const [challengeMainImageFile, setChallengeMainImageFile] =
    useState<any>(null);
  const [bannerImageInChallenge1, setBannerImageInChallenge1] =
    useState<string>('');
  const [bannerImageInChallenge2, setBannerImageInChallenge2] =
    useState<string>('');
  const [bannerImageInChallenge3, setBannerImageInChallenge3] =
    useState<string>('');
  const [bannerFileInChallenge1, setBannerFileInChallenge1] =
    useState<any>(null);
  const [bannerFileInChallenge2, setBannerFileInChallenge2] =
    useState<any>(null);
  const [bannerFileInChallenge3, setBannerFileInChallenge3] =
    useState<any>(null);
  const [challengeBannerLink1, setChallengeBannerLink1] = useState<any>();
  const [challengeBannerLink2, setChallengeBannerLink2] = useState<any>();
  const [challengeBannerLink3, setChallengeBannerLink3] = useState<any>();
  const [uploadUrl, setUploadUrl] = useState<any>();
  const [guidePage, setGuidePage] = useState(1);
  const [guideLists, setGuideLists] = useState<IGuideTool[]>([]);
  const [challengeType, setChallengeType] = useState(0);
  const [challengeSubType, setChallengeSubType] = useState(0); // 그룹대항 챌린지 서브 타입 1-걸음수 2-사진 3-스탬프
  const [statType, setStatType] = useState(1); // 그룹대항 지표 1-평균 2-누적
  const [filters, setFilters] = useState<IGroupFilter[]>([]); // 그룹대항 커뮤니티 필터
  const [groupBattleCommunity, setGroupBattleCommunity] = useState<
    IGroupBattleCommunity[]
  >([]); // 그룹 대항 커뮤니티
  const [resultLatLng, setResultLatLng] = useState<any>('');
  const [challengeAddress, setChallengeAddress] = useState<string>('');
  const [challengeLink, setChallengeLink] = useState<string>('');
  const [targetData, setTargetData] = useState<any>({
    addresss: '',
    sigunguCode: '',
  });
  const [challengeId, setChallengeId] = useState<string>('');
  const [findDataPortal, setFindDataPortal] = useState<string>('');
  const [connectDataPortal, setConnectDataPortal] = useState<any>([]);
  const [dataPortal, setDataPortal] = useState<any>([]);
  const [customPopupText, setCustomPopupText] = useState<any>('');
  const [customPopupUseYn, setCustomPopupUseYn] = useState<any>(0);
  const [challengeApplyStatus, setChallengeApplyStatus] = useState<any>(1);
  const [exposureRangeDate, setExposureRangeDate] = useState<any[]>([
    null,
    null,
  ]);
  const [participationRangeDate, setParticipationRangeDate] = useState<any[]>([
    null,
    null,
  ]);
  const [useChallengeRank, setUseChallengeRank] = useState<number>(0);
  const [isPrivate, setIsPrivate] = useState<number>(0);
  const [useChallengePost, setUseChallengePost] = useState<number>(1);
  const [useStepsMax, setUseStepsMax] = useState<number>(1);
  const [useBrandPoint, setUseBrandPoint] = useState<number>(0);
  const [useGallery, setUseGallery] = useState<number>(0);
  const [isTest, setIsTest] = useState<boolean>(false);
  const [isWithStep, setIsWithStep] = useState<boolean>(false);
  const [brandPointIndex, setBrandPointIndex] =
    useState<number>(NO_SELECT_ADVERTISER);
  const [photoCertificateWeeks, setPhotoCertificateWeeks] = useState<any[]>([]);
  const [photoCertificateTimes, setPhotoCertificateTimes] = useState<any[]>([]);
  const [locationAutoRegisterYn, setLocationAutoRegisterYn] =
    useState<number>(0);
  const [authGuide, setAuthGuide] = useState<string>('');
  const [photoCertDescription, setPhotoCertDescription] = useState<string>();
  const [photoCertSuccessImage, setPhotoCertSuccessImage] =
    useState<string>('');
  const [photoCertSuccessFile, setPhotoCertSuccessFile] = useState<any>(null);
  const [photoCertFailImage, setPhotoCertFailImage] = useState<string>('');
  const [photoCertFailFile, setPhotoCertFailFile] = useState<any>(null);
  const [allowAppReview, setAllowAppReview] = useState<number>(0);
  const [invitationLink, setInvitationLink] = useState<string>('');
  const [isAuto, setIsAuto] = useState<number>(1);
  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [searchResult, setSearchResult] = useState<any[]>([]);
  const [targetTitle, setTargetTitle] = useState<string>('');
  const [durationInfoFalg, setDurationInfoFalg] = useState<boolean>(false);
  const [openClassType, setOpenClassType] = useState<boolean>(false);
  const [openType, setOpenType] = useState<number>(0);
  const [openSearchResult, setOpenSearchResult] = useState<boolean>(false);
  const [openPostCode, setOpenPostCode] = useState<number>(0);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openStampTourView, setOpenStampTourView] = useState<boolean>(false);
  const [openWalkcourseView, setOpenWalkcourseView] = useState<boolean>(false);
  const [openFieldView, setOpenFieldView] = useState<boolean>(false);
  const [imageUrlPrefix, setImageUrlPrefix] = useState<string>('');
  const [audioContent, setAudioContent] = useState<any>();
  const guideItems = document.querySelectorAll('.draggable');
  const [listEvent, setListEvent] = useState<any>({
    currentDrag: null,
    over: null,
    clicked: null,
    list: [],
  });
  var guideIndex = 0;

  const showError = (msg: string) => {
    setOpenSnackbar(true);
    setAlertMessage(msg);
  };

  const {
    mutate: registerChallengeMasterMutation,
    isError: isMasterMutateError,
  } = useMutation(registerChallengeMaster, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        if (selectedChallenge) {
          setOpenSnackbar(true);
          setAlertMessage('챌린지 마스터 정보가 저장되었습니다.');
          reloadChallenge(challengeId);
        } else {
          setAlertOpen(true);
          setAlertMessage('챌린지 생성 탭에서 작성중 내역을 확인해주세요.');
        }
      } else {
        showError(
          `챌린지 마스터 정보를 저장하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      showError(
        `챌린지 마스터 정보를 저장하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.(${error})`
      );
    },
  });

  const ChangePageContents = () => {
    setGuideTitle(
      guideLists[guidePage - 1] ? guideLists[guidePage - 1].title : '소제목'
    );
    setGuideContent(
      guideLists[guidePage - 1]
        ? guideLists[guidePage - 1].contents
        : '안내내용'
    );

    const newImage = guideLists[guidePage - 1]
      ? guideLists[guidePage - 1].image
      : '';
    setGuideImage(newImage);
  };

  const saveFileImage = (evt: ChangeEvent<HTMLInputElement>) => {
    evt.target.files &&
      setChallengeMainImage(URL.createObjectURL(evt.target.files[0]));
  };
  const deleteFileImage = () => {
    URL.revokeObjectURL(challengeMainImage);
    setChallengeMainImage('');
  };

  function addGuideInfo() {
    if (
      guideContent.length > 0 ||
      guideImageFile !== null ||
      guideImage !== ''
    ) {
      const newTitle = guideTitle;
      const newContents = guideContent;
      const newGuideLists = guideLists;
      const newGuide = guideLists[guidePage - 1];
      newGuideLists[guidePage - 1] = {
        ...newGuide,
        title: newTitle,
        contents: newContents,
        image: guideImage,
        imageFile: guideImageFile,
        uploadUrl: '',
      };
      return newGuideLists;
    }
    return [];
  }

  const onChangeChallengeGuideView = (_event: any, newguide: number) => {
    if (
      guideContent.length > 0 ||
      guideImageFile !== null ||
      guideImage !== ''
    ) {
      const newGuideLists = addGuideInfo();
      setGuideLists(newGuideLists);
    }
    setGuidePage(newguide);
    setGuideImage(guideLists[newguide - 1]?.image);
    setGuideImageFile(guideLists[newguide - 1]?.imageFile);
  };

  const addChallengeGuideView = () => {
    if (guideLists.length === MAX_GUIDE_PAGE) {
      setAlertMessage('챌린지 안내 내용은 최대 6까지 추가할 수 있습니다.');
      setAlertOpen(true);
      return;
    }

    if (
      guideContent.length > 0 ||
      guideImageFile !== null ||
      guideImage !== ''
    ) {
      const newGuideLists = addGuideInfo();
      const newGuide = [...newGuideLists, guidetool];
      setGuideLists(newGuide);
      setGuideImage('');
      setGuideImageFile(null);
      setGuidePage(guidePage + 1);
    }
  };

  const updateChallengeGuideView = () => {
    if (
      guideContent.length > 0 ||
      guideImageFile !== null ||
      guideImage !== ''
    ) {
      const newTitle = guideTitle;
      const newContents = guideContent;
      const newGuideLists = [...guideLists];
      const newGuide = newGuideLists[guidePage - 1];
      newGuideLists[guidePage - 1] = {
        ...newGuide,
        title: newTitle,
        contents: newContents,
        image: guideImage,
        imageFile: guideImageFile,
        uploadUrl: '',
      };
      setGuideLists(newGuideLists);
    }
  };

  const removeChallengeGuideView = () => {
    ChangePageContents();
    const newGuide = guideLists.filter((_guide, idx) => idx !== guidePage - 1);
    setGuideLists(newGuide);
    newGuide.length < guidePage && setGuidePage(guidePage - 1);
    // setGuidePage(guidePage);
    if (newGuide.length === 0) {
      setGuideTitle('');
      setGuideContent('');
      setGuidePage(1);
    }
  };

  const addChallengeMaster = (
    newGuideList: any,
    newUploadUrl: string,
    newSuccessUploadUrl: string,
    newFailUploadUrl: string,
    newBannerChallengeList: any
  ) => {
    const newViewStime =
      exposureRangeDate[0] === null
        ? 0
        : new Date(exposureRangeDate[0]).getTime() / 1000;
    const newViewEtime =
      exposureRangeDate[1] === null
        ? 0
        : new Date(exposureRangeDate[1]).getTime() / 1000;
    const newAttendStime =
      participationRangeDate[0] === null
        ? 0
        : new Date(participationRangeDate[0]).getTime() / 1000;
    const newAttendEtime =
      participationRangeDate[1] === null
        ? 0
        : new Date(participationRangeDate[1]).getTime() / 1000;
    const newMainImage =
      challengeMainImageFile !== null
        ? newUploadUrl
        : challengeMainImage.length > 0
        ? challengeMainImage
        : '';
    const newCreator =
      dataPortal.length > 0
        ? dataPortal.map((item: any) => item.portalId)
        : connectDataPortal !== undefined && connectDataPortal.length > 0
        ? connectDataPortal.map((item: any) => item.portalId)
        : ['ADMIN'];
    const newDescription = newGuideList.map((item: any, idx: number) => {
      const newData = {
        idx: `${idx + 1}`,
        t: trimWhiteSpace(item.title),
        c: trimWhiteSpace(item.contents),
        i:
          item.imageFile !== null
            ? item.uploadUrl
            : item.image !== null && item.image !== undefined
            ? item.image
            : '',
      };
      return newData;
    });
    const newChallengeViewType =
      challengeViewType === undefined || challengeViewType === ''
        ? 9
        : challengeViewType;

    const newChallengeApplyStatus =
      challengeInfo !== undefined &&
      challengeInfo !== null &&
      challengeInfo.challengeId === challengeId
        ? challengeApplyStatus
        : 1;

    const newPhotoSuccessImage =
      photoCertSuccessFile !== null
        ? newSuccessUploadUrl
        : photoCertSuccessImage.length > 0
        ? photoCertSuccessImage
        : '';

    const newPhotoFailImage =
      photoCertFailFile !== null
        ? newFailUploadUrl
        : photoCertFailImage.length > 0
        ? photoCertFailImage
        : '';

    if (newMainImage === undefined || newMainImage.length === 0) {
      setAlertMessage('챌린지 메인 이미지를 선택해주세요');
      setAlertOpen(true);
      return;
    }

    const newChallengeWalkCourseId =
      challengeType === 4 ||
      challengeType === 9 ||
      challengeType === 13 ||
      challengeType === 15 ||
      challengeType === 19 ||
      challengeType === 21
        ? challengeWalkCourseId
        : KEY_WORD_DUMMY_ID;

    const newChallengeStampTourId =
      challengeType === 7 ||
      challengeType === 14 ||
      (challengeType === 18 && challengeSubType === 3) ||
      challengeType === 20
        ? challengeStampTourId
        : KEY_WORD_DUMMY_ID;

    const newChallengeWalkZoneId =
      challengeType === 8 ? challengeWalkZoneId : KEY_WORD_DUMMY_ID;

    const newLatLng = resultLatLng.split(' ');
    const newLat = newLatLng[0] ? Number(newLatLng[0]) : 0;
    const newLng = newLatLng[1] ? Number(newLatLng[1]) : 0;

    const newBrandPointId =
      challengeType === 16 && useBrandPoint === 1
        ? brandPointAdvertiserList[brandPointIndex].brandPointId
        : '';

    const newBannerChallenge: any[] = [];
    if (bannerFileInChallenge1 !== null) {
      newBannerChallenge.push({
        image: newBannerChallengeList[0],
        link: checkUrl(challengeBannerLink1),
        seq: 1,
      });
    } else if (bannerImageInChallenge1.length > 0) {
      newBannerChallenge.push({
        image: bannerImageInChallenge1,
        link: checkUrl(challengeBannerLink1),
        seq: 1,
      });
    }

    if (bannerFileInChallenge2 !== null) {
      newBannerChallenge.push({
        image: newBannerChallengeList[1],
        link: checkUrl(challengeBannerLink2),
        seq: 2,
      });
    } else if (bannerImageInChallenge2.length > 0) {
      newBannerChallenge.push({
        image: bannerImageInChallenge2,
        link: checkUrl(challengeBannerLink2),
        seq: 2,
      });
    }

    if (bannerFileInChallenge3 !== null) {
      newBannerChallenge.push({
        image: newBannerChallengeList[2],
        link: checkUrl(challengeBannerLink3),
        seq: 3,
      });
    } else if (bannerImageInChallenge3.length > 0) {
      newBannerChallenge.push({
        image: bannerImageInChallenge3,
        link: checkUrl(challengeBannerLink3),
        seq: 3,
      });
    }

    const newData: IChallengeCreate = {
      ticket: '',
      challengeId: challengeId,
      challengeType: challengeType,
      challengeGoal:
        challengeType === 6
          ? 0
          : challengeType !== 18
          ? challengeGoal
          : challengeType === 18 && challengeSubType === 1
          ? MAX_STEP
          : 0,
      challengeGpsGoal:
        challengeType === 13 ||
        challengeType === 15 ||
        challengeType === 19 ||
        challengeType === 21
          ? challengeGpsGoal
          : 0,
      challengeStepsMax: useStepsMax === 0 ? MAX_STEP : challengeStepsMax,
      challengeStepsPerPoint: challengeStepsPerPoint,
      challengeStepsMin: challengeStepsMin,
      challengeWalkCourseId: newChallengeWalkCourseId,
      challengeWalkZoneId: newChallengeWalkZoneId,
      challengeStampTourId: newChallengeStampTourId,
      challengeTitle: trimWhiteSpace(challengeTitle),
      challengeImgpathMain: newMainImage,
      challengeInfoDuration: challengeInfoDuration,
      challengeInfoReward: challengeInfoReward,
      challengeInfoGoal: challengeInfoGoal,
      challengeTestStime: challengeTestStime,
      challengeViewStime: newViewStime,
      challengeViewEtime: newViewEtime,
      challengeAttendStime: newAttendStime,
      challengeAttendEtime: newAttendEtime,
      challengeDescription: JSON.stringify(newDescription),
      challengeCreator: newCreator[0],
      challengeCreatorList: newCreator,
      challengeStatus: challengeStatus,
      challengeViewType: newChallengeViewType,
      useChallengePost: useChallengePost,
      challengeRewardType: 0,
      challengeApplyStatus: challengeApplyStatus,
      testType: testType === undefined ? 0 : testType,
      customPopupText: customPopupText === null ? '' : customPopupText,
      customPopupDeleted: customPopupUseYn === 0 ? 1 : 0,
      coverInfo: challengeType !== 12 ? coverInfo : '',
      coverReward: coverReward,
      coverAd: coverAd,
      classType: classType,
      recommendType: recommendType ? 1 : 0,
      attendLimit: attendLimit,
      challengeAddress: challengeAddress,
      challengeLocationLat: newLat,
      challengeLocationLng: newLng,
      isWithStep: isWithStep ? 1 : 0,
      isLocationCert: locationAutoRegisterYn,
      photoCertDescription: photoCertDescription,
      photoCertificateWeeks: photoCertificateWeeks,
      photoCertificateTimes: photoCertificateTimes,
      photoCertSuccessImage: newPhotoSuccessImage,
      photoCertFailImage: newPhotoFailImage,
      brandPointId: newBrandPointId,
      hidden: challengeHidden,
      bannerImage:
        newBannerChallenge.length > 0 ? JSON.stringify(newBannerChallenge) : '',
      link: checkUrl(challengeLink),
      challengeFieldId: challengeFieldId,
      maxPoint: maxPoint,
      useRank: useChallengeRank,
      isPrivate: isPrivate,
      useGallery: useGallery,
      allowAppReview: allowAppReview,
      invitationLink: invitationLink,
      // 그룹대항
      challengeSubType: challengeType === 18 ? challengeSubType : undefined,
      statType: challengeType === 18 ? statType : undefined,
      filters: challengeType === 18 ? filters : [],
      geohashs: [],
      region: [],
      location: [],
      authRegions: [],
      authAddress: [],
      ages: [],
      gender: 0,
      isAuto: isAuto,
    };

    registerChallengeMasterMutation(newData);
  };

  const checkValid = () => {
    const newCreator =
      connectDataPortal === undefined
        ? ['ADMIN']
        : dataPortal.length > 0
        ? dataPortal
        : ['ADMIN'];

    const newViewStime =
      exposureRangeDate[0] === null
        ? 0
        : new Date(exposureRangeDate[0]).getTime() / 1000;
    const newViewEtime =
      exposureRangeDate[1] === null
        ? 0
        : new Date(exposureRangeDate[1]).getTime() / 1000;
    const newAttendStime =
      participationRangeDate[0] === null
        ? 0
        : new Date(participationRangeDate[0]).getTime() / 1000;
    const newAttendEtime =
      participationRangeDate[1] === null
        ? 0
        : new Date(participationRangeDate[1]).getTime() / 1000;

    var val = 0;

    if (val === 0 && challengeId.length === 0) val = 1;
    if (
      val === 0 &&
      (challengeType === 1 ||
        challengeType === 4 ||
        challengeType === 5 ||
        challengeType === 8 ||
        challengeType === 9 ||
        challengeType === 13 ||
        challengeType === 15 ||
        challengeType === 22) &&
      challengeGoal === 0
    )
      val = 2;
    if (
      val === 0 &&
      useStepsMax === 1 &&
      (challengeType === 1 ||
        challengeType === 5 ||
        challengeType === 8 ||
        (isWithStep && challengeType === 12) ||
        (isWithStep && challengeType === 13) ||
        (isWithStep && challengeType === 14) ||
        (isWithStep && challengeType === 15)) &&
      challengeStepsMax === 0
    )
      val = 3;
    if (val === 0 && challengeType === 6 && challengeStepsPerPoint === 0)
      val = 4;
    if (val === 0 && challengeType === 6 && challengeStepsMin < 0) val = 5;
    if (val === 0 && challengeType === 6 && challengeStepsMax === 0) val = 6;
    if (
      val === 0 &&
      (challengeType === 4 ||
        challengeType === 9 ||
        challengeType === 13 ||
        challengeType === 15 ||
        challengeType === 19 ||
        challengeType === 21) &&
      challengeWalkCourseId.length === 0
    )
      val = 7;
    if (val === 0 && challengeType === 8 && challengeWalkZoneId.length === 0)
      val = 8;
    if (
      val === 0 &&
      (challengeType === 7 || challengeType === 14 || challengeType === 20) &&
      challengeStampTourId.length === 0
    )
      val = 9;
    if (val === 0 && challengeTitle.length === 0) val = 10;
    // if (val === 0 && newMainImage.length === 0) val = 11;
    if (val === 0 && newCreator?.length === 0) val = 12;
    if (val === 0 && exposureRangeDate[0] === null) val = 13;
    if (val === 0 && participationRangeDate[0] === null) val = 14;
    // if (val === 0 && attendLimit === 0) val = 15;
    if (
      val === 0 &&
      (challengeType === 13 ||
        challengeType === 15 ||
        challengeType === 19 ||
        challengeType === 21) &&
      challengeGpsGoal === 0
    )
      val = 16;
    if (
      val === 0 &&
      challengeType === 16 &&
      useBrandPoint === 1 &&
      brandPointIndex === NO_SELECT_ADVERTISER
    )
      val = 17;
    if (
      val === 0 &&
      !(
        challengeType === 4 ||
        challengeType === 7 ||
        challengeType === 17 ||
        challengeType === 20 ||
        challengeType === 21
      ) &&
      (newViewStime > newAttendStime || newViewEtime < newAttendEtime)
    )
      val = 18;
    if (val === 0 && challengeType === 11 && challengeGoal === 0) val = 19;

    if (challengeType === 18) {
      if (!filters.length) {
        val = 20;
      } else if (challengeSubType === 1 && challengeStepsMax === 0) {
        val = 3;
      } else if (challengeSubType === 3 && challengeStampTourId.length === 0) {
        val = 9;
      }
    }
    if (val !== 0) {
      var message = '';
      switch (val) {
        case 1:
          message = '챌린지 아이디를 입력해주세요.';
          break;
        case 2:
          message = '챌린지 목표 걸음수를 입력해주세요';
          break;
        case 3:
          message = '일일 누적 최대 걸음수를 입력해주세요';
          break;
        case 4:
          message = '마일리지 당 걸음수를 입력해주세요.';
          break;
        case 5:
          message = '일일 누적 최소 걸음 수를 입력해주세요';
          break;
        case 6:
          message = '일일 누적 쵀대 걸음 수를 입력해주세요';
          break;
        case 7:
          message = '길 코스를 선택해주세요';
          break;
        case 8:
          message = '워크 존을 선택해주세요';
          break;
        case 9:
          message = '스탬프 코스를 선택해주세요';
          break;
        case 10:
          message = '챌린지 이름을 입력해주세요';
          break;
        case 11:
          message = '챌린지 메인 이미지를 선택해주세요';
          break;
        case 12:
          message = '데이터 포털 아이디를 선택해주세요';
          break;
        case 13:
          message = '챌린지 노출기간을 지정해주세요';
          break;
        case 14:
          message = '챌린지 참여기간을 지정해주세요';
          break;
        case 15:
          message = '챌린지 참여 인원 제한 인원수를 지정해주세요';
          break;
        case 16:
          message = '목표 퍼센트를 입력해주세요.';
          break;
        case 17:
          message = '브랜드 지급 브랜드를 선택해주세요';
          break;
        case 18:
          message = '노출기간을 확인해주세요';
          break;
        case 19:
          message = '누적 최대 마일리지를 입력해주세요';
          break;
        case 20:
          message = '그룹 대항 그룹을 선택해주세요';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
      setAlertMessage(message);
      setAlertOpen(true);
    }

    return val;
  };

  async function uploadMultipleFiles() {
    var newUploadUrl = '';
    if (challengeMainImageFile !== null) {
      const newUploadFile: any = await uploadFile(
        'challenge',
        challengeMainImageFile
      );
      newUploadUrl = newUploadFile.data.uploadUrl.file.path;
    }
    const newGuideList = await Promise.all(
      guideLists.map(async (item: any, idx: number) => {
        var imageUrl = null;
        var newUploadFile: any = null;
        if (item.imageFile !== null) {
          newUploadFile = await uploadFile('challenge', item.imageFile);
        }

        return {
          title: item.title,
          contents: item.contents,
          image:
            item.image === null || item.image === undefined ? '' : item.image,
          imageFile: item.imageFile,
          uploadUrl:
            item.imageFile !== null
              ? newUploadFile.data.uploadUrl.file.path
              : null,
        };
      })
    );
    var newSuccessUploadUrl = '';
    if (photoCertSuccessFile !== null) {
      const newUploadFile: any = await uploadFile(
        'challenge',
        photoCertSuccessFile
      );
      newSuccessUploadUrl = newUploadFile.data.uploadUrl.file.path;
    }
    var newFailUploadUrl = '';
    if (photoCertFailFile !== null) {
      const newUploadFile: any = await uploadFile(
        'challenge',
        photoCertFailFile
      );
      newFailUploadUrl = newUploadFile.data.uploadUrl.file.path;
    }

    const newChallengeUploadUrl: string[] = [];
    if (bannerFileInChallenge1 !== null) {
      const newUploadFile: any = await uploadFile(
        'challenge',
        bannerFileInChallenge1
      );
      newChallengeUploadUrl.push(newUploadFile.data.uploadUrl.file.path);
    } else {
      newChallengeUploadUrl.push(
        bannerImageInChallenge1.length > 0 ? bannerImageInChallenge1 : ''
      );
    }

    if (bannerFileInChallenge2 !== null) {
      const newUploadFile: any = await uploadFile(
        'challenge',
        bannerFileInChallenge2
      );
      newChallengeUploadUrl.push(newUploadFile.data.uploadUrl.file.path);
    } else {
      newChallengeUploadUrl.push(
        bannerImageInChallenge2.length > 0 ? bannerImageInChallenge2 : ''
      );
    }

    if (bannerFileInChallenge3 !== null) {
      const newUploadFile: any = await uploadFile(
        'challenge',
        bannerFileInChallenge3
      );
      newChallengeUploadUrl.push(newUploadFile.data.uploadUrl.file.path);
    } else {
      newChallengeUploadUrl.push(
        bannerImageInChallenge3.length > 0 ? bannerImageInChallenge3 : ''
      );
    }

    addChallengeMaster(
      newGuideList,
      newUploadUrl,
      newSuccessUploadUrl,
      newFailUploadUrl,
      newChallengeUploadUrl
    );
  }

  const checkChallenge = () => {
    if (checkValid() !== 0) {
      console.log('invalid !!!');
      return;
    }

    // Guide 마지막 페이지 수정사항 저장
    if (
      guideContent.length > 0 ||
      guideImageFile !== null ||
      guideImage !== ''
    ) {
      const newGuideLists = addGuideInfo();
      setGuideLists(newGuideLists);
    }

    uploadMultipleFiles();
  };

  const saveChallenge = () => {
    checkChallenge();
  };

  const dragStart = (evt: DragEvent<HTMLDivElement>) => {
    setListEvent({
      ...listEvent,
      currentDrag: evt.currentTarget.dataset.position,
      list: guideLists,
    });
    evt.currentTarget.style.border = '1px solid #757575';
  };

  const drop = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';

    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = listEvent.currentDrag;
    const filterList = listEvent.list.filter(
      (list: any, idx: number) => idx.toString() !== listEvent.currentDrag
    );
    const newList = [
      ...filterList.slice(0, newOver),
      listEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    setGuideLists(newList);

    setListEvent({
      currentDrag: null,
      over: null,
      ...listEvent,
    });
  };

  const dragEnd = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    guideItems.forEach(item => item.classList.remove('drag_over'));
    // changeCommunityGroupOrder();
  };

  const dragEnter = (evt: DragEvent<HTMLDivElement>) => {
    const newOver = evt.currentTarget.dataset.position;
    setListEvent({ ...listEvent, over: newOver });
  };

  const dragOver = (evt: DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    evt.currentTarget.classList.add('drag_over');
    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = listEvent.currentDrag;
    const filterList = listEvent.list.filter(
      (_list: any, idx: number) => idx.toString() !== newCurrent
    );
    const newList = [
      ...filterList.slice(0, newOver),
      listEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    listEvent.over !== newOver && setGuideLists(newList);
  };

  const dragLeave = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    guideItems.forEach(item => item.classList.remove('drag_over'));
  };

  const selectList = (
    evt: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    const newContain = !evt.currentTarget.classList.contains('click_group');
    guideItems.forEach(item => item.classList.remove('click_group'));
    newContain && evt.currentTarget.classList.add('click_group');
    guideIndex = Number(evt.currentTarget.dataset.position);
    setGuidePage(guideIndex + 1);
    setListEvent({
      ...listEvent,
      clicked: newContain ? guideIndex : null,
    });
    setGuideTitle(guideLists[guideIndex].title);
    setGuideContent(guideLists[guideIndex].contents);
    setGuideImage(guideLists[guideIndex].image);
    setGuideImageFile(guideLists[guideIndex].imageFile);
  };

  const searchWalkcourse = () => {
    setOpenType(0);
    setTargetTitle('길 코스');
    setSearchKey('walkcourse');
    setSearchWord(findWalkcourseRef.current?.value);
    setOpenSearchResult(true);
  };

  const searchStampcourse = () => {
    setOpenType(1);
    setTargetTitle('스탬프 코스');
    setSearchKey('stampcourse');
    setSearchWord(findStampcourseRef.current?.value);
    setOpenSearchResult(true);
  };

  const searchField = () => {
    setOpenType(2);
    setTargetTitle('필드');
    setSearchKey('field');
    setSearchWord(findFieldRef.current?.value);
    setOpenSearchResult(true);
  };

  const handleKeyDown = (e: any, type: string) => {
    if (e.key === 'Enter') {
      type === 'walkcourse'
        ? searchWalkcourse()
        : type === 'stampcourse'
        ? searchStampcourse()
        : type === 'field'
        ? searchField()
        : '';
    }
  };
  /*
   * 1 : 걸음수, 4 : 길따라 걷기, 5 : 기부, 6 : 마일리지, 7: 스탬프, 9 : 매일따라 걷기
   */
  const changeCoverData = (goal: number, info: any = null) => {
    const days = calculateDays(
      participationRangeDate[0],
      participationRangeDate[1]
    );
    if (challengeType === 1 || challengeType === 16 || challengeType === 22) {
      // 걸음수
      // 목표 걸음 수 * 0.03
      // (목표 걸음 수 / 참여 일 수 = n) * 0.03
      const calorie = (goal * STEP_TO_CALORIE_CONST).toFixed(0);
      const avgCalorie = ((goal / days) * STEP_TO_CALORIE_CONST).toFixed(0);
      setCoverInfo(
        `챌린지 달성 시 ${commaFormat(String(calorie))}kcal 소모 가능!`
      );
      setCoverReward(
        `매일 꾸준히 ${commaFormat(String(avgCalorie))}kcal 태워봐요`
      );
    } else if (
      challengeType === 4 ||
      challengeType === 15 ||
      challengeType === 21
    ) {
      // 길따라 걷기
      // [{코스의 총 거리(km) * 달성 기준(%)} / 60cm]의 자연수 미만 버림
      //  [{코스의 총 거리(km) * 달성 기준(%)} / 60cm]의 자연수 미만 버림 값 * 0.03
      const newCourseDistance =
        info !== null ? info.distance : walkCourseDistance;
      const distance = newCourseDistance !== null ? newCourseDistance : 0;
      const stride = Math.floor((distance * goal) / STEP_STRIDE_CONST);
      const calorie = (stride * STEP_TO_CALORIE_CONST).toFixed(0);
      setCoverInfo(
        `약 ${commaFormat(String(stride))}걸음만 따라 걸으면 달성이에요`
      );
      setCoverReward(
        `챌린지 달성 시 ${commaFormat(String(calorie))}kcal 소모 가능!`
      );
    } else if (challengeType === 5) {
      // 기부
      // 공동 목표 걸음 수
      // 데이터 포탈 고객명
      const name = dataPortal.length > 0 ? dataPortal[0].name : '고객';
      setCoverInfo(`${commaFormat(String(goal))}걸음을 함께 모아 기부해요`);
      setCoverReward(`${name}의 기부 활동을 도와주세요!`);
    } else if (challengeType === 6) {
      // 마일리지
      // 하루 제한 걸음 수 존재할 때 : n = 하루 제한 걸음 수 * 0.03, -존재 안할 때 : n = 10000 * 0.03 = 300
      // 리워드 마일리지 값 중 가장 적은 마일리지 값을 걸음 수로 환산한 값
      const calorie =
        goal > 0
          ? (goal * STEP_TO_CALORIE_CONST).toFixed(0)
          : (10000 * STEP_TO_CALORIE_CONST).toFixed(0);
      setCoverInfo(`매일 꾸준히 ${commaFormat(String(calorie))}kcal 태워봐요`);
      setCoverReward(
        coverReward !== null && coverReward.length > 0
          ? coverReward
          : `n걸음으로 리워드 받아가세요!`
      );
    } else if (
      challengeType === 7 ||
      challengeType === 14 ||
      challengeType === 20
    ) {
      // 리워드 달성 기준 스탬프 수중 가장 적은 스탬프 수
      // 스탬프 코스에 있는 총 스탬프 수
      const count = goal !== null ? goal : 0;
      setCoverInfo(`n개 스탬프를 방문하면 달성이에요`);
      setCoverReward(
        `스탬프 ${commaFormat(String(count))}개 획득에 도전해보세요!`
      );
    } else if (
      challengeType === 9 ||
      challengeType === 13 ||
      challengeType === 19
    ) {
      // 매일따라 걷기
      // {코스의 총 거리(km) * 달성 기준(%)} / 60cm]의 자연수 미만 버림 값 * 0.03
      // 리워드 달성 기준 완주 횟수 중 가장 적은 완주 횟수
      const newCourseDistance =
        info !== null ? info.distance : walkCourseDistance;
      const distance = newCourseDistance !== null ? newCourseDistance : 0;
      const stride = Math.floor((distance * goal) / STEP_STRIDE_CONST);
      const calorie = (stride * STEP_TO_CALORIE_CONST).toFixed(0);
      setCoverInfo(`매일 꾸준히 ${commaFormat(String(calorie))}kcal 태워봐요`);
      setCoverReward(
        coverReward !== null && coverReward.length > 0
          ? coverReward
          : `코스 n번 완주하고 리워드 받아가세요!`
      );
    } else if (challengeType === 17) {
      setCoverInfo(`출현 지역에 숨어있는 캐릭터를 잡아보세요`);
      setCoverReward(
        coverReward !== null && coverReward.length > 0
          ? coverReward
          : `n 마일리지로 리워드 받아가세요!`
      );
    } else if (challengeType === 18) {
      // 그룹대항 그룹 수 바뀔 땐 info를 바꾸고 goal을 -1로, 정보가 바뀔 땐 goal 설정
      const newFilterLength = info || info === 0 ? info : undefined;
      if (challengeSubType === 1) {
        if (newFilterLength !== undefined) {
          setCoverInfo(
            `${newFilterLength?.toLocaleString()}개의 그룹과 걸음 수 대결을 펼쳐보세요!`
          );
        }
        if (goal !== -1) {
          const calorie = (goal * STEP_TO_CALORIE_CONST).toFixed(0);
          setCoverReward(
            `매일 꾸준히 ${commaFormat(String(calorie))}kcal 태워봐요`
          );
        }
      }
      if (challengeSubType === 2) {
        if (newFilterLength !== undefined) {
          setCoverInfo(
            `${newFilterLength?.toLocaleString()}개의 그룹과 인증 대결을 펼쳐보세요!`
          );
        }
        setCoverReward('매일 꾸준히 사진 인증 미션에 참여해봐요');
      }
      if (challengeSubType === 3) {
        if (newFilterLength !== undefined) {
          setCoverInfo(
            `${newFilterLength?.toLocaleString()}개의 그룹과 스탬프 대결을 펼쳐보세요!`
          );
        }
        if (goal !== -1) {
          const count = goal !== null ? goal : 0;
          setCoverReward(
            `스탬프 ${commaFormat(String(count))}개 획득에 도전해보세요!`
          );
        }
      }
    }
  };

  const changeCoverAd = (msg: string) => {
    setCoverAd(msg);
  };

  const setupCoverData = (info: any) => {
    setCoverInfo(info.coverInfo ? info.coverInfo : '');
    setCoverReward(info.coverReward ? info.coverReward : '');
    setCoverAd(info.coverAd ? info.coverAd : '');
  };

  const setupBrandPointId = () => {
    if (
      challengeInfo !== undefined &&
      challengeInfo.brandPointId !== undefined &&
      challengeInfo.brandPointId !== null
    ) {
      const newIdx = brandPointAdvertiserList
        .map((item: any, idx: number) =>
          item.brandPointId === challengeInfo.brandPointId ? idx : -1
        )
        .filter(item => item !== -1);

      if (newIdx !== null && newIdx.length > 0) {
        setBrandPointIndex(newIdx[0]);
        setUseBrandPoint(1);
      } else {
        setBrandPointIndex(NO_SELECT_ADVERTISER);
        setUseBrandPoint(0);
      }
    } else {
      setBrandPointIndex(NO_SELECT_ADVERTISER);
      setUseBrandPoint(0);
    }
  };

  useEffect(() => {
    const newSearchResult = [...searchResult];
    if (openType === 0) {
      setChallengeWalkCourseId(newSearchResult[0]?.id);
      setChallengeWalkCourseName(newSearchResult[0]?.name);
      setWalkCourseDistance(newSearchResult[0]?.distance);
      const newData: any = {
        distance: newSearchResult[0]?.distance,
      };
      changeCoverData(challengeGoal, newData);
    } else if (openType === 1) {
      setChallengeStampTourId(newSearchResult[0]?.id);
      setChallengeStampTourName(newSearchResult[0]?.name);
      changeCoverData(newSearchResult[0]?.count);
    } else if (openType === 2) {
      setChallengeFieldId(newSearchResult[0]?.id);
      setChallengeFieldName(newSearchResult[0]?.name);
    }
    return () => {};
  }, [searchResult]);

  useEffect(() => {
    updateChallengeGuideView();
  }, [guideTitle, guideContent]);

  useEffect(() => {
    if (guideLists.length > 0) ChangePageContents();
  }, [guidePage, guideLists]);

  useEffect(() => {
    let newInfoGoal = '';
    if (
      (challengeType === 1 || challengeType === 16 || challengeType === 22) &&
      challengeGoal > 0
    ) {
      // 걸음수
      newInfoGoal =
        challengeStepsMax > 0 && challengeStepsMax !== MAX_STEP
          ? `${commaFormat(
              String(challengeGoal)
            )}걸음 달성시(하루 ${commaFormat(
              String(challengeStepsMax)
            )}걸음 인정)`
          : `${challengeGoal}걸음 달성시`;
    } else if (challengeType === 4 && challengeGoal > 0) {
      // 길따라 걷기
      newInfoGoal = `걷기 코스 구간 ${challengeGoal}% 이상 달성`;
    } else if (challengeType === 21 && challengeGpsGoal > 0) {
      // 길따라 걷기
      newInfoGoal = `걷기 코스 구간 ${challengeGpsGoal}% 이상 달성`;
    } else if (challengeType === 5 && challengeGoal > 0) {
      // 기부
      newInfoGoal =
        challengeStepsMax > 0 && challengeStepsMax !== MAX_STEP
          ? `누적 ${commaFormat(
              String(challengeGoal)
            )}걸음 달성시(1일 ${commaFormat(
              String(challengeStepsMax)
            )}걸음 인정)`
          : `누적 ${commaFormat(String(challengeGoal))}걸음 달성시`;
    } else if (challengeType === 6 || challengeType === 17) {
      // 마일리지
      newInfoGoal = '선물별 교환포인트 달성시 교환가능';
    }

    if (
      challengeInfo !== undefined &&
      challengeInfo !== null &&
      (challengeInfo.challengeInfoGoal === undefined ||
        challengeInfo.challengeInfoGoal === null ||
        challengeInfo.challengeInfoGoal.length === 0)
    ) {
      if (challengeType === 5 && challengeGoal > 0) {
        setChallengeInfoReward('챌린지 종료 후 기부 증서 발급');
      }
      setChallengeInfoGoal(newInfoGoal);
    }
  }, [challengeGoal, challengeStepsMax, challengeStepsPerPoint]);

  useEffect(() => {
    const newDate =
      participationRangeDate[0] !== null && participationRangeDate[1] !== null
        ? `${moment(new Date(participationRangeDate[0])).format(
            'YYYY/MM/DD'
          )} - ${moment(new Date(participationRangeDate[1])).format(
            'YYYY/MM/DD'
          )}`
        : '';
    if (
      durationInfoFalg ||
      challengeInfoDuration === null ||
      challengeInfoDuration.length === 0
    )
      setChallengeInfoDuration(newDate);

    if (
      challengeType === 1 &&
      challengeInfo &&
      (challengeInfo.coverInfo === null || challengeInfo.coverInfo === '')
    ) {
      changeCoverData(challengeGoal);
    }
  }, [participationRangeDate]);

  useEffect(() => {
    if (challengeMasterType) {
      const newMasterType = challengeMasterType.map((item: any) => {
        const newItem = {
          type: item.challengeType,
          name: item.challengeTypeDescription,
        };
        return newItem;
      });
      setMasterType(newMasterType);
      setChallengeType(1);
    }
  }, [challengeMasterType]);

  useEffect(() => {
    if (
      targetData.roadAddress !== undefined &&
      targetData.roadAddress !== null
    ) {
      const newAddress =
        targetData.roadAddress.length > 0
          ? targetData.roadAddress
          : targetData.jibunAddress;
      setChallengeAddress(newAddress);
    }
  }, [targetData]);

  useEffect(() => {
    setupBrandPointId();
  }, [brandPointAdvertiserList]);

  useEffect(() => {
    if (challengeInfo) {
      setDurationInfoFalg(false);
      setBrandPointIndex(NO_SELECT_ADVERTISER);
      setChallengeHidden(challengeInfo.hidden);
      setSelectedChallenge(true);
      setChallengeTitle(challengeInfo.challengeTitle);
      setChallengeId(challengeInfo.challengeId);
      setChallengeType(challengeInfo.challengeType);
      setChallengeSubType(challengeInfo.challengeSubType); // 그룹대항 챌린지 서브타입
      setStatType(challengeInfo.statType); // 그룹대항 챌린지 지표
      // 그룹 대항 필터들
      const newFilters = JSON.parse(challengeInfo.challengeFilters);
      setFilters(
        newFilters?.map((item: any) => ({
          filterType: item.FILTER_TYPE,
          challengeId: item.CHALLENGE_ID,
          targetId: item.TARGET_ID,
          communityName: item.NAME,
        }))
      );
      // 그룹대항 선택된 커뮤니티
      setGroupBattleCommunity(
        newFilters[0] && [
          {
            communityName: newFilters[0]?.COMMUNITY_NAME || '',
            filterType: 'community',
            targetId: newFilters[0]?.COMMUNITY_ID || '',
          },
        ]
      );
      setChallengeGoal(challengeInfo.challengeGoal);
      setChallengeGpsGoal(challengeInfo.challengeGpsGoal);
      setChallengeStepsMax(challengeInfo.challengeStepsMax);
      setUseStepsMax(
        challengeInfo.challengeStepsMax === MAX_STEP
          ? 0
          : challengeInfo.challengeStepsMax > 0
          ? 1
          : 0
      );
      setChallengeStepsMin(challengeInfo.challengeStepsMin);
      setChallengeStepsPerPoint(challengeInfo.challengeStepsPerPoint);
      setChallengeInfoReward(challengeInfo.challengeInfoReward);
      setChallengeInfoGoal(challengeInfo.challengeInfoGoal);
      setChallengeInfoDuration(challengeInfo.challengeInfoDuration);
      setChallengeViewType(challengeInfo.challengeViewType);
      setChallengeStatus(challengeInfo.challengeStatus);
      setMaxPoint(challengeInfo.maxPoint);

      setChallengeWalkCourseId(challengeInfo.challengeWalkCourseId);
      setChallengeStampTourId(challengeInfo.challengeStampTourId);
      setChallengeWalkZoneId(challengeInfo.challengeWalkZoneId);
      setChallengeFieldId(challengeInfo.challengeFieldId);

      setImageUrlPrefix(challengeInfo.imageUrlPrefix);
      setChallengeMainImage(challengeInfo.challengeImgpathMain);
      setChallengeMainImageFile(null);
      setChallengeTestStime(challengeInfo.challengeTestStime);
      const newExposureDate = [
        timestamp2Localestring(challengeInfo.challengeViewStime, 1000),
        timestamp2Localestring(challengeInfo.challengeViewEtime, 1000),
      ];
      const newParticipationDate = [
        timestamp2Localestring(challengeInfo.challengeAttendStime, 1000),
        timestamp2Localestring(challengeInfo.challengeAttendEtime, 1000),
      ];
      setExposureRangeDate(newExposureDate);
      setParticipationRangeDate(newParticipationDate);

      const newCreatorList = JSON.parse(challengeInfo.creatorList);
      const newPortal = newCreatorList.map((item: any) => {
        const newData = {
          portalId: item,
          name: '',
        };
        return newData;
      });
      setConnectDataPortal(newPortal !== null ? newPortal : []);
      // setConnectDataPortal([
      //   {
      //     portalId: challengeInfo.challengeCreator
      //       ? challengeInfo.challengeCreator
      //       : '',
      //     name: '',
      //   },
      // ]);
      setGuideImage('');
      setGuideImageFile(null);
      setGuidePage(1);
      setGuideTitle('');
      setGuideContent('');

      const newDescription = JSON.parse(challengeInfo.challengeDescription);
      const newGuideList =
        newDescription &&
        newDescription.map((item: any) => {
          const newGuide = {
            title: item.t,
            contents: item.c,
            image: item.i,
            imageFile: null,
          };
          return newGuide;
        });
      setGuideLists(newGuideList);

      setUseChallengePost(
        challengeInfo.useChallengePost === null
          ? 1
          : challengeInfo.useChallengePost
      );
      setCustomPopupText(challengeInfo.customPopupText);
      setCustomPopupUseYn(challengeInfo.customPopupDeleted === 0 ? 1 : 0);
      setChallengeApplyStatus(challengeInfo.challengeApplyStatus);

      setupCoverData(challengeInfo);
      setClassType(challengeInfo.classType || 0);
      setRecommendType(challengeInfo.recommendType === 1);
      setAttendLimit(challengeInfo.attendLimit || 0);
      setChallengeAddress(challengeInfo.challengeAddress || '');
      setChallengeLink(challengeInfo.link || '');
      setAllowAppReview(challengeInfo.allowAppReview || 0);
      setInvitationLink(challengeInfo.invitationLink || '');
      setIsAuto(challengeInfo.isAuto);

      if (
        challengeInfo.challengeType === 12 ||
        (challengeInfo.challengeType === 18 &&
          challengeInfo.challengeSubType === 2)
      ) {
        setPhotoCertSuccessFile(null);
        setPhotoCertFailFile(null);

        setIsWithStep(
          challengeInfo.isWithStep !== null ? challengeInfo.isWithStep : false
        );
        setLocationAutoRegisterYn(
          challengeInfo.isLocationCert !== null
            ? challengeInfo.isLocationCert
            : 0
        );
        setPhotoCertSuccessImage(
          challengeInfo.photoCertSuccessImage !== null
            ? challengeInfo.photoCertSuccessImage
            : ''
        );
        setPhotoCertFailImage(
          challengeInfo.photoCertFailImage !== null
            ? challengeInfo.photoCertFailImage
            : ''
        );
        setPhotoCertDescription(challengeInfo.photoCertDescription);

        // photoCertificateTimes
        const parsedTimes: any[] =
          challengeInfo.photoCertificateTimes !== null &&
          challengeInfo.photoCertificateTimes.length > 0
            ? JSON.parse(challengeInfo.photoCertificateTimes)
            : [];

        const newTimes = parsedTimes.map(
          (time: any) => `${time.start}:00~${time.end}:00`
        );
        setPhotoCertificateTimes(newTimes !== null ? newTimes : []);
        // photoCertificateWeeks
        setPhotoCertificateWeeks(
          challengeInfo.photoCertificateWeeks !== null &&
            challengeInfo.photoCertificateWeeks.length > 0
            ? JSON.parse(challengeInfo.photoCertificateWeeks)
            : []
        );
      }

      setUseChallengeRank(
        challengeInfo.useRank === null ? 0 : challengeInfo.useRank
      );
      setIsPrivate(
        challengeInfo.isPrivate === null ? 0 : challengeInfo.isPrivate
      );
      setUseGallery(
        challengeInfo.useGallery === null ? 0 : challengeInfo.useGallery
      );
      setIsTest(challengeInfo.isTest === null ? 0 : challengeInfo.isTest);
      setupBrandPointId();

      const newChallengeBanner =
        challengeInfo.bannerImage !== null &&
        challengeInfo.bannerImage.length > 0
          ? JSON.parse(challengeInfo.bannerImage)
          : [];
      if (newChallengeBanner.length > 0) {
        setBannerImageInChallenge1(newChallengeBanner[0].image || '');
        setChallengeBannerLink1(newChallengeBanner[0].link || '');
      } else {
        setBannerImageInChallenge1('');
        setChallengeBannerLink1('');
      }
      setBannerFileInChallenge1(null);
      if (newChallengeBanner.length > 1) {
        setBannerImageInChallenge2(newChallengeBanner[1].image || '');
        setChallengeBannerLink2(newChallengeBanner[1].link || '');
      } else {
        setBannerImageInChallenge2('');
        setChallengeBannerLink2('');
      }
      setBannerFileInChallenge2(null);
      if (newChallengeBanner.length > 2) {
        setBannerImageInChallenge3(newChallengeBanner[2].image || '');
        setChallengeBannerLink3(newChallengeBanner[2].link || '');
      } else {
        setBannerImageInChallenge3('');
        setChallengeBannerLink3('');
      }
      setBannerFileInChallenge3(null);

      setTimeout(() => {
        setDurationInfoFalg(true);
      }, 1000);
    } else {
      location.pathname.includes('create')
        ? setSelectedChallenge(true)
        : setSelectedChallenge(false);
    }
    return () => {};
  }, [challengeInfo]);

  return (
    <>
      <CreatePaper>
        <Stack
          direction={{ xs: 'column', sm: 'column', md: 'row' }}
          divider={
            <Divider
              orientation='vertical'
              flexItem
              sx={{ p: 0, m: '1.5rem 3rem 0' }}
            />
          }
        >
          <Box>
            <Typography className='title'>사용한 이용권</Typography>
            <TextField
              sx={{ width: '18rem' }}
              disabled
              defaultValue='일반 챌린지 1회 이용권'
              inputRef={usingTicket}
            />
          </Box>
          <Box>
            <Box className='flex_start'>
              <Typography className='title'>챌린지 노출 여부</Typography>
              <Tooltip
                title={
                  <Typography fontSize={TOOLTIP_FONT_SIZE}>
                    {TOOLTIP_CHALLENGE_OPEN}
                  </Typography>
                }
                followCursor
              >
                <HelpOutlineIcon sx={{ color: '#999999' }} />
              </Tooltip>
            </Box>
            <TextField
              select
              sx={{ width: '10rem' }}
              value={challengeHidden}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                setChallengeHidden(Number(event.target.value));
              }}
            >
              <MenuItem key={0} value={0}>
                노출함
              </MenuItem>
              <MenuItem key={1} value={1}>
                노출 안함
              </MenuItem>
            </TextField>
          </Box>
          <SwUuidCreate
            title='챌린지'
            id={challengeId}
            setId={setChallengeId}
          />
          {/* <Box>
            <Typography className='title'>챌린지 참여 인원 제한</Typography>
            <TextField
              sx={{ width: '10rem' }}
              value={attendLimit}
              onChange={(
                evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                if (Number.isInteger(Number(evt.target.value)))
                  setAttendLimit(Number(evt.target.value));
              }}
            />
          </Box> */}
        </Stack>
        <Divider sx={{ mt: '0.5rem' }} />
        <Stack
          direction={{ sm: 'column', md: 'row' }}
          divider={
            <Divider
              orientation='vertical'
              flexItem
              sx={{ p: 0, m: '1.5rem 3rem 0' }}
            />
          }
          sx={{ mb: '1.5rem' }}
        >
          <Box>
            <Box className='flex_start'>
              <Typography className='title'>챌린지 종류</Typography>
              <Tooltip
                title={
                  <Typography fontSize={TOOLTIP_FONT_SIZE}>
                    {TOOLTIP_CHALLEGE_TYPE}
                  </Typography>
                }
                followCursor
              >
                <HelpOutlineIcon sx={{ color: '#999999' }} />
              </Tooltip>
            </Box>
            <TextField
              select
              value={challengeType || ''}
              sx={{ width: '20rem' }}
              onChange={(
                evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => {
                setChallengeType(Number(evt.target.value));
                setChallengeStampTourId('');
                setChallengeStampTourName('');
                setChallengeWalkCourseId('');
                setChallengeWalkCourseName('');
                setChallengeFieldId('');
                setChallengeGoal(0);
                setChallengeSubType(1); // 그룹대항 챌린지 기본은 1-걸음수
                setStatType(1);
                setFilters([]);
                setSelectedChallengeInfo((prevState: any) => ({
                  ...prevState,
                  challengeType: Number(evt.target.value),
                }));
              }}
            >
              {masterType &&
                masterType
                  .filter((item: any) => item.type !== 10)
                  .map((item: any) => (
                    <MenuItem key={item.type} value={item.type}>
                      {item.name}
                    </MenuItem>
                  ))}
            </TextField>
          </Box>
          <Box>
            <Box className='flex_start'>
              <Typography className='title'>챌린지 분류</Typography>
              <Tooltip
                title={
                  <Typography fontSize={TOOLTIP_FONT_SIZE}>
                    {TOOLTIP_CHALLENGE_CLASS_TYPE}
                  </Typography>
                }
                followCursor
              >
                <HelpOutlineIcon sx={{ color: '#999999' }} />
              </Tooltip>
            </Box>
            <Box className='flex_start'>
              <TextField
                sx={{ width: '20rem' }}
                select
                value={classType}
                onChange={(
                  evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  setClassType(Number(evt.target.value));
                }}
              >
                <MenuItem key='class_type_0' value={0}>
                  선택
                </MenuItem>
                {classTypeList &&
                  classTypeList.map((item: IChallengeClassType) => (
                    <MenuItem key={`class_type_${item.type}`} value={item.type}>
                      {item.title}
                    </MenuItem>
                  ))}
              </TextField>
              <Button
                variant='outlined'
                sx={{ ml: '.5rem' }}
                onClick={() => setOpenClassType(true)}
              >
                편집
              </Button>
            </Box>
          </Box>
          <Box>
            <Box className='flex_start'>
              <Typography className='title'>브랜드 지역 챌린지</Typography>
              {/* <Tooltip
                title={
                  <Typography fontSize={TOOLTIP_FONT_SIZE}>
                    {TOOLTIP_CHALLENGE_RECOMMEND}
                  </Typography>
                }
                followCursor
              >
                <HelpOutlineIcon sx={{ color: '#999999' }} />
              </Tooltip> */}
              <Checkbox
                checked={recommendType}
                onChange={() => setRecommendType(!recommendType)}
                sx={{ p: 0, mr: '.5rem' }}
              />
            </Box>
          </Box>
        </Stack>
        {/* // 그룹대항 챌린지 */}
        {challengeType === 18 && (
          <>
            <Stack
              direction={{ sm: 'column', md: 'row' }}
              divider={
                <Divider
                  orientation='vertical'
                  flexItem
                  sx={{ p: 0, m: '1.5rem 3rem 0' }}
                />
              }
              sx={{ mb: '1.5rem' }}
            >
              <Box>
                <Typography className='title'>종류 선택</Typography>
                <TextField
                  select
                  value={challengeSubType || ''}
                  sx={{ width: '20rem' }}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => {
                    setChallengeSubType(Number(evt.target.value));
                    setChallengeStampTourId('');
                    setChallengeStampTourName('');
                    setChallengeGoal(0);
                    setSelectedChallengeInfo((prevState: any) => ({
                      ...prevState,
                      challengeSubType: Number(evt.target.value),
                    }));
                  }}
                >
                  {challengeMasterSubType
                    ?.find(el => el.challengeType === challengeType)
                    ?.subTypeList?.map(item => (
                      <MenuItem
                        key={item.challengeSubType}
                        value={item.challengeSubType}
                      >
                        {item.challengeSubTypeName}
                      </MenuItem>
                    ))}
                </TextField>
              </Box>
              <Box>
                <Typography className='title'>지표 선택</Typography>
                <RadioGroup
                  row
                  name='group_battle_stat_type'
                  value={statType}
                  onChange={evt => {
                    setStatType(Number(evt.currentTarget.value));
                  }}
                >
                  {challengeMasterStatType
                    ?.find(
                      el =>
                        el.challengeType === challengeType &&
                        el.challengeSubType === challengeSubType
                    )
                    ?.statTypeList?.map(type => (
                      <FormControlLabel
                        value={type.challengeStatType}
                        control={<Radio />}
                        label={type.challengeStatTypeName}
                      />
                    ))}
                </RadioGroup>
              </Box>
            </Stack>
          </>
        )}
        {/* <Divider sx={{ mt: '0.5rem' }} /> */}
        {challengeType === 1 ||
        challengeType === 5 ||
        challengeType === 8 ||
        challengeType === 12 ||
        challengeType === 16 ? (
          <ChallengeTypeBox>
            {challengeType === 12 && (
              <>
                {' '}
                <Checkbox
                  checked={isWithStep}
                  onChange={() => setIsWithStep(!isWithStep)}
                  sx={{ p: 0, mr: '.5rem' }}
                />
                <Typography className='title' sx={{ mr: '3rem' }}>
                  걸음 수 목표 달성
                </Typography>
              </>
            )}
            {(challengeType === 1 ||
              challengeType === 5 ||
              challengeType === 8 ||
              (challengeType === 12 && isWithStep) ||
              challengeType === 16) && (
              <>
                <div className='flex_center'>
                  <Typography className='title'>목표 걸음수</Typography>
                  <TextField
                    sx={{ marginRight: '1rem' }}
                    value={challengeGoal}
                    onChange={(
                      evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                    ) => {
                      setChallengeGoal(Number(evt.target.value));
                      changeCoverData(Number(evt.target.value));
                    }}
                  />
                </div>
                <div className='flex_center'>
                  <Typography className='title'>
                    일일 누적 최대 걸음 수
                  </Typography>
                  <TextField
                    select
                    sx={{ width: '10rem', mr: '.5rem' }}
                    value={useStepsMax}
                    onChange={(
                      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                    ) => {
                      setUseStepsMax(Number(event.target.value));
                    }}
                  >
                    <MenuItem key={0} value={0}>
                      제한없음
                    </MenuItem>
                    <MenuItem key={1} value={1}>
                      사용
                    </MenuItem>
                  </TextField>
                  {useStepsMax === 1 && (
                    <TextField
                      sx={{ marginRight: '1rem' }}
                      value={challengeStepsMax}
                      onChange={(
                        evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                      ) => {
                        setChallengeStepsMax(Number(evt.target.value));
                      }}
                    />
                  )}
                </div>
              </>
            )}
          </ChallengeTypeBox>
        ) : challengeType === 6 ||
          challengeType === 11 ||
          challengeType === 17 ? (
          <ChallengeTypeBox>
            <div className='flex_center'>
              <Typography className='title'>마일리지 당 걸음 수</Typography>
              <TextField
                sx={{ marginRight: '1rem' }}
                value={challengeStepsPerPoint}
                onChange={(
                  evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  setChallengeStepsPerPoint(Number(evt.target.value));
                }}
              />
            </div>
            <div className='flex_center'>
              <Typography className='title'>일일 누적 최소 걸음 수</Typography>
              <TextField
                sx={{ marginRight: '1rem' }}
                value={challengeStepsMin}
                onChange={(
                  evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  setChallengeStepsMin(Number(evt.target.value));
                  changeCoverData(Number(evt.target.value));
                }}
              />
            </div>
            <div className='flex_center'>
              <Typography className='title'>일일 누적 최대 걸음 수</Typography>
              <TextField
                select
                sx={{ width: '10rem', mr: '.5rem' }}
                value={useStepsMax}
                onChange={(
                  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => {
                  setUseStepsMax(Number(event.target.value));
                }}
              >
                <MenuItem key={0} value={0}>
                  제한없음
                </MenuItem>
                <MenuItem key={1} value={1}>
                  사용
                </MenuItem>
              </TextField>
              {useStepsMax === 1 && (
                <TextField
                  sx={{ marginRight: '1rem' }}
                  value={challengeStepsMax}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => {
                    setChallengeStepsMax(Number(evt.target.value));
                  }}
                />
              )}
            </div>
            {challengeType === 17 && (
              <>
                <Typography className='title'>
                  일일 캐릭터 최대 누적 마일리지
                </Typography>
                <TextField
                  sx={{ marginRight: '1rem' }}
                  value={maxPoint}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => {
                    setMaxPoint(Number(evt.target.value));
                  }}
                />
              </>
            )}
          </ChallengeTypeBox>
        ) : challengeType === 22 ? (
          <ChallengeTypeBox>
            <div className='flex_center'>
              <Typography className='title'>일일 목표 걸음수</Typography>
              <TextField
                sx={{ marginRight: '1rem' }}
                value={challengeGoal}
                onChange={(
                  evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => setChallengeGoal(Number(evt.target.value))}
              />
            </div>
          </ChallengeTypeBox>
        ) : challengeType !== 4 &&
          challengeType !== 7 &&
          challengeType !== 9 &&
          challengeType !== 12 &&
          challengeType !== 13 &&
          challengeType !== 14 &&
          challengeType !== 15 &&
          challengeType !== 18 &&
          challengeType !== 19 &&
          challengeType !== 20 &&
          challengeType !== 21 &&
          challengeType !== 22 ? (
          <ChallengeTypeBox>
            <div className='flex_center'>
              <Typography className='title'>목표</Typography>
              <TextField
                sx={{ marginRight: '1rem' }}
                value={challengeGoal}
                onChange={(
                  evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => setChallengeGoal(Number(evt.target.value))}
              />
            </div>
          </ChallengeTypeBox>
        ) : (
          <></>
        )}
        {challengeType === 11 && (
          <div className='flex_center'>
            <Typography className='title'>최대 누적 마일리지</Typography>
            <TextField
              sx={{ marginRight: '1rem' }}
              value={challengeGoal}
              onChange={(
                evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => {
                setChallengeGoal(Number(evt.target.value));
                changeCoverData(Number(evt.target.value));
              }}
            />
          </div>
        )}
        {challengeType === 4 ||
        challengeType === 9 ||
        challengeType === 13 ||
        challengeType === 15 ||
        challengeType === 19 ||
        challengeType === 21 ? (
          <ChallengeTypeBox>
            <div className='flex_center'>
              <Typography className='title'>목표 퍼센트</Typography>
              <TextField
                sx={{ marginRight: '1rem' }}
                value={
                  challengeType === 4 || challengeType === 9
                    ? challengeGoal
                    : challengeGpsGoal
                }
                onChange={(
                  evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  challengeType === 4 || challengeType === 9
                    ? setChallengeGoal(Number(evt.target.value))
                    : setChallengeGpsGoal(Number(evt.target.value));
                  changeCoverData(Number(evt.target.value));
                }}
              />
            </div>
            <div className='flex_center'>
              <Typography className='title'>길 코스</Typography>
              <TextField
                placeholder='길 코스 이름, 아이디 검색'
                sx={{ marginRight: '1rem', width: '21rem' }}
                inputRef={findWalkcourseRef}
                onKeyDown={evt => {
                  handleKeyDown(evt, 'walkcourse');
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() => {
                          searchWalkcourse();
                        }}
                        sx={{ pr: 0 }}
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <Typography className='title'>
              {challengeWalkCourseId !== null && challengeWalkCourseId !== ''
                ? `${challengeWalkCourseId}`
                : ''}
            </Typography>
            {challengeWalkCourseId !== null && challengeWalkCourseId !== '' && (
              <Button
                variant='outlined'
                color='primary'
                sx={{ ml: '1rem' }}
                onClick={() => setOpenWalkcourseView(true)}
              >
                코스 확인
              </Button>
            )}
          </ChallengeTypeBox>
        ) : challengeType === 7 ||
          challengeType === 14 ||
          challengeType === 20 ? (
          <ChallengeTypeBox>
            <div className='flex_center'>
              <Typography className='title'>스탬프 코스</Typography>
              <TextField
                placeholder='스탬프 코스 이름, 아이디 검색'
                sx={{ marginRight: '1rem', width: '21rem' }}
                inputRef={findStampcourseRef}
                onKeyDown={evt => {
                  handleKeyDown(evt, 'stampcourse');
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() => {
                          searchStampcourse();
                        }}
                        sx={{ pr: 0 }}
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <Typography className='title'>
              {challengeStampTourId !== null && challengeStampTourId !== ''
                ? `${challengeStampTourId}`
                : ''}
            </Typography>
            {challengeStampTourId !== null && challengeStampTourId !== '' && (
              <Button
                variant='outlined'
                color='primary'
                sx={{ ml: '1rem' }}
                onClick={() => setOpenStampTourView(true)}
              >
                필수 스탬프 지정
              </Button>
            )}
          </ChallengeTypeBox>
        ) : challengeType === 8 ? (
          <ChallengeTypeBox>
            <div className='flex_center'>
              <Typography className='title'>워크존</Typography>
              <TextField
                sx={{ marginRight: '1rem', width: '21rem' }}
                value={challengeWalkZoneId}
                onChange={(
                  evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => setChallengeWalkZoneId(evt.target.value)}
              />
            </div>
          </ChallengeTypeBox>
        ) : challengeType === 12 ? ( // 사진 인증형 챌린지
          <Box>
            <Box>
              <Typography className='title'>사진 인증 가능 시간</Typography>
              <SwWeekAndTimePicker
                times={photoCertificateTimes}
                setTimes={setPhotoCertificateTimes}
                weeks={photoCertificateWeeks}
                setWeeks={setPhotoCertificateWeeks}
              />
            </Box>
            <Box>
              <Typography className='title'>위치값 자동 등록 옵션</Typography>
              <TextField
                select
                sx={{ width: '10rem' }}
                value={
                  locationAutoRegisterYn === undefined
                    ? 0
                    : locationAutoRegisterYn
                }
                onChange={(
                  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => {
                  setLocationAutoRegisterYn(Number(event.target.value));
                }}
              >
                <MenuItem key={0} value={0}>
                  설정안함
                </MenuItem>
                <MenuItem key={1} value={1}>
                  설정
                </MenuItem>
              </TextField>
            </Box>
          </Box>
        ) : (
          <></>
        )}
        {(challengeType === 13 ||
          challengeType === 14 ||
          challengeType === 15) && (
          <ChallengeTypeBox>
            <div className='flex_center'>
              <Typography className='title'>목표 걸음수</Typography>
              <TextField
                sx={{ marginRight: '1rem' }}
                value={challengeGoal}
                onChange={(
                  evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  setChallengeGoal(Number(evt.target.value));
                  changeCoverData(Number(evt.target.value));
                }}
              />
            </div>
            <div className='flex_center'>
              <Typography className='title'>일일 누적 최대 걸음 수</Typography>
              <TextField
                select
                sx={{ width: '10rem', mr: '.5rem' }}
                value={useStepsMax}
                onChange={(
                  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => {
                  setUseStepsMax(Number(event.target.value));
                }}
              >
                <MenuItem key={0} value={0}>
                  제한없음
                </MenuItem>
                <MenuItem key={1} value={1}>
                  사용
                </MenuItem>
              </TextField>
              {useStepsMax === 1 && (
                <TextField
                  sx={{ marginRight: '1rem' }}
                  value={challengeStepsMax}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => {
                    setChallengeStepsMax(Number(evt.target.value));
                  }}
                />
              )}
            </div>
          </ChallengeTypeBox>
        )}
        {challengeType === 16 && (
          <>
            <Typography className='title'>포인트 지급 브랜드</Typography>
            <Box>
              <TextField
                select
                sx={{ width: '10rem', mr: '.5rem' }}
                value={useBrandPoint}
                onChange={(
                  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => {
                  setBrandPointIndex(NO_SELECT_ADVERTISER);
                  setUseBrandPoint(Number(event.target.value));
                }}
              >
                <MenuItem key={0} value={0}>
                  사용안함
                </MenuItem>
                <MenuItem key={1} value={1}>
                  사용
                </MenuItem>
              </TextField>
              {useBrandPoint === 1 && (
                <TextField
                  select
                  sx={{ width: '10rem', mr: '.5rem' }}
                  value={brandPointIndex}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => {
                    setBrandPointIndex(Number(event.target.value));
                  }}
                >
                  <MenuItem key='advertiser_99' value={NO_SELECT_ADVERTISER}>
                    선택
                  </MenuItem>
                  {brandPointAdvertiserList.map((item: any, idx: number) => (
                    <MenuItem key={`advertiser_${idx}`} value={idx}>
                      {item.pointName}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Box>
          </>
        )}

        {challengeType === 17 && (
          <>
            <ChallengeTypeBox sx={{ mt: '1rem' }}>
              <div className='flex_center'>
                <Typography className='title'>필드 입력</Typography>
                <TextField
                  placeholder='필드 이름, 아이디 검색'
                  sx={{ marginRight: '1rem', width: '21rem' }}
                  inputRef={findFieldRef}
                  onKeyDown={evt => {
                    handleKeyDown(evt, 'field');
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={() => {
                            searchField();
                          }}
                          sx={{ pr: 0 }}
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <Typography className='title'>
                {challengeFieldId !== null && challengeFieldId !== ''
                  ? `${challengeFieldId}`
                  : ''}
              </Typography>
              {challengeFieldId !== null && challengeFieldId !== '' && (
                <Button
                  variant='outlined'
                  color='primary'
                  sx={{ ml: '1rem' }}
                  onClick={() => setOpenFieldView(true)}
                >
                  코스 확인
                </Button>
              )}
            </ChallengeTypeBox>
          </>
        )}

        {challengeType === 18 && (
          <>
            <Divider />
            {challengeSubType === 1 && (
              <div className='flex_center'>
                <Typography className='title'>
                  일일 누적 최대 걸음 수
                </Typography>
                <TextField
                  sx={{ marginRight: '1rem' }}
                  value={challengeStepsMax}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => {
                    setChallengeStepsMax(Number(evt.target.value));
                    changeCoverData(Number(evt.target.value));
                  }}
                />
              </div>
            )}
            {challengeSubType === 2 && (
              <>
                <Box>
                  <Box>
                    <Typography className='title'>
                      사진 인증 가능 시간
                    </Typography>
                    <SwWeekAndTimePicker
                      times={photoCertificateTimes}
                      setTimes={setPhotoCertificateTimes}
                      weeks={photoCertificateWeeks}
                      setWeeks={setPhotoCertificateWeeks}
                    />
                  </Box>
                  <Box>
                    <Typography className='title'>
                      위치값 자동 등록 옵션
                    </Typography>
                    <TextField
                      select
                      sx={{ width: '10rem' }}
                      value={
                        locationAutoRegisterYn === undefined
                          ? 0
                          : locationAutoRegisterYn
                      }
                      onChange={(
                        event: ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      ) => {
                        setLocationAutoRegisterYn(Number(event.target.value));
                      }}
                    >
                      <MenuItem key={0} value={0}>
                        설정안함
                      </MenuItem>
                      <MenuItem key={1} value={1}>
                        설정
                      </MenuItem>
                    </TextField>
                  </Box>
                </Box>
              </>
            )}
            {challengeSubType === 3 && (
              <>
                <ChallengeTypeBox>
                  <div className='flex_center'>
                    <Typography className='title'>스탬프 코스</Typography>
                    <TextField
                      placeholder='스탬프 코스 이름, 아이디 검색'
                      sx={{ marginRight: '1rem', width: '21rem' }}
                      inputRef={findStampcourseRef}
                      onKeyDown={evt => {
                        handleKeyDown(evt, 'stampcourse');
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              onClick={() => {
                                searchStampcourse();
                              }}
                              sx={{ pr: 0 }}
                            >
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <Typography className='title'>
                    {challengeStampTourId !== null &&
                    challengeStampTourId !== ''
                      ? `${challengeStampTourId}`
                      : ''}
                  </Typography>
                </ChallengeTypeBox>
              </>
            )}
            <Divider />
            <GroupChoice
              filters={filters}
              setFilters={newFilters => {
                setFilters(newFilters);
                changeCoverData(-1, newFilters.length || 0);
              }}
              groupBattleCommunity={groupBattleCommunity}
              setGroupBattleCommunity={setGroupBattleCommunity}
            />
          </>
        )}
        {(challengeType === 4 ||
          challengeType === 7 ||
          challengeType === 9 ||
          challengeType === 13 ||
          challengeType === 14 ||
          challengeType === 15 ||
          challengeType === 19 ||
          challengeType === 20 ||
          challengeType === 21) && (
          <ChallengeTypeBox sx={{ mt: '1rem' }}>
            <Typography className='title' sx={{ mr: '1rem' }}>
              {challengeType === 7 ||
              challengeType === 14 ||
              challengeType === 20
                ? '스탬프 획득 방식'
                : '코스 인증 방식'}
            </Typography>
            <RadioGroup
              row
              aria-labelledby='is-auto-row-radio-buttons-group-label'
              name='is-auto-row-radio-buttons-group'
              value={isAuto === 0 ? 'bymanual' : 'auto'}
              onChange={evt => {
                evt.currentTarget.value === 'bymanual'
                  ? setIsAuto(0)
                  : setIsAuto(1);
              }}
            >
              <FormControlLabel
                value='bymanual'
                control={<Radio />}
                label='수동'
              />
              <FormControlLabel value='auto' control={<Radio />} label='자동' />
            </RadioGroup>
          </ChallengeTypeBox>
        )}
        <Divider sx={{ mt: 0 }} />
        <Box>
          <Typography className='title'>챌린지 이름</Typography>
          <TextField
            sx={{ width: '48rem' }}
            inputRef={challengeTitleRef}
            value={challengeTitle}
            onChange={(
              evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setChallengeTitle(evt.target.value)}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>챌린지 메인 이미지</Typography>
          <SwFileSetting
            image={
              challengeMainImageFile !== null
                ? challengeMainImage
                : challengeMainImage !== ''
                ? `${imageUrlPrefix}${challengeMainImage}`
                : ''
            }
            setImage={setChallengeMainImage}
            setFile={setChallengeMainImageFile}
            file={challengeMainImageFile}
          />
          <Divider />
        </Box>
        <Box>
          <Box className='flex_start'>
            <Typography className='title'>챌린지 상단 정보</Typography>
            <Tooltip
              title={
                <Typography fontSize={TOOLTIP_FONT_SIZE}>
                  {TOOLTIP_CHALLENGE_INFORMATION}
                </Typography>
              }
              followCursor
            >
              <HelpOutlineIcon sx={{ color: '#999999' }} />
            </Tooltip>
          </Box>
          <TextField
            sx={{ width: '13rem', mr: '1rem' }}
            placeholder='기간 입력'
            value={challengeInfoDuration}
            onChange={(
              evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setChallengeInfoDuration(evt.target.value)}
          />
          <TextField
            sx={{ width: '30rem', mr: '1rem' }}
            placeholder='리워드 입력'
            value={challengeInfoReward}
            onChange={(
              evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setChallengeInfoReward(evt.target.value)}
          />
          <TextField
            sx={{ width: '30rem' }}
            placeholder='목표 입력'
            value={challengeInfoGoal}
            onChange={(
              evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setChallengeInfoGoal(evt.target.value)}
          />
          <Divider />
        </Box>
        <Box>
          <Box className='flex_start'>
            <Typography className='title'>챌린지 커버 문구</Typography>
            <Tooltip
              title={
                <Typography fontSize={TOOLTIP_FONT_SIZE}>
                  {TOOLTIP_CHALLENGE_COVER_INFORMATION}
                </Typography>
              }
              followCursor
            >
              <HelpOutlineIcon sx={{ color: '#999999' }} />
            </Tooltip>
          </Box>
          {challengeType !== 12 && (
            <TextField
              sx={{ width: '25rem', mr: '1rem' }}
              placeholder='매일 꾸준히 nkcal 태워봐요'
              value={coverInfo}
              onChange={(
                evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => setCoverInfo(evt.target.value)}
            />
          )}
          {challengeType !== 16 && (
            <TextField
              sx={{ width: '25rem', mr: '1rem' }}
              placeholder={
                challengeType !== 12
                  ? 'n걸음으로 리워드 받아가세요!'
                  : '인증 n회 달성하고 리워드 받아가세요!'
              }
              value={coverReward}
              onChange={(
                evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => setCoverReward(evt.target.value)}
            />
          )}
          <TextField
            sx={{ width: '40rem' }}
            placeholder='광고 문구(최대 54자 작성)'
            value={coverAd.substring(0, 54)}
            onChange={(
              evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => changeCoverAd(evt.target.value.substring(0, 54))}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <span>{coverAd?.length} / 54</span>
                </InputAdornment>
              ),
            }}
          />
          <Divider />
        </Box>
        <Stack
          direction={{ sm: 'column', md: 'row' }}
          divider={
            <Divider
              orientation='vertical'
              flexItem
              sx={{ p: 0, m: '1.5rem 3rem 0' }}
            />
          }
        >
          <Box>
            <Typography className='title'>챌린지 노출기간</Typography>
            <SwDateRangePicker
              rangeDate={exposureRangeDate}
              setRangeDate={setExposureRangeDate}
              disabled={isTest}
            />
          </Box>
          <Box>
            <Typography className='title'>챌린지 참여기간</Typography>
            <SwDateRangePicker
              rangeDate={participationRangeDate}
              setRangeDate={setParticipationRangeDate}
              disabled={isTest}
            />
          </Box>
        </Stack>
        <Divider />
        <Box>
          <Box className='flex_between' sx={{ width: '20rem' }}>
            <Typography className='title'>광고 배너(챌린지 상세)</Typography>
            <Typography className='title_normal'>
              권장 사이즈 652 * 163
            </Typography>
          </Box>
          <Stack
            direction={{ sm: 'column', md: 'row' }}
            divider={
              <Divider
                orientation='vertical'
                flexItem
                sx={{ p: 0, m: '1.5rem 3rem 0' }}
              />
            }
            sx={{ mb: '1.5rem' }}
          >
            <Box sx={{ width: '25rem' }}>
              <SwFileSetting
                setImage={setBannerImageInChallenge1}
                image={
                  bannerFileInChallenge1 !== null
                    ? bannerImageInChallenge1
                    : bannerImageInChallenge1 !== ''
                    ? `${imageUrlPrefix}${bannerImageInChallenge1}`
                    : ''
                }
                setFile={setBannerFileInChallenge1}
                file={bannerFileInChallenge1}
                width='15rem'
                onDelete={() => setChallengeBannerLink1('')}
              />
            </Box>
            <Box sx={{ width: '25rem' }}>
              <SwFileSetting
                setImage={setBannerImageInChallenge2}
                image={
                  bannerFileInChallenge2 !== null
                    ? bannerImageInChallenge2
                    : bannerImageInChallenge2 !== ''
                    ? `${imageUrlPrefix}${bannerImageInChallenge2}`
                    : ''
                }
                setFile={setBannerFileInChallenge2}
                file={bannerFileInChallenge2}
                width='15rem'
                onDelete={() => setChallengeBannerLink2('')}
              />
            </Box>
            <Box sx={{ width: '25rem' }}>
              <SwFileSetting
                setImage={setBannerImageInChallenge3}
                image={
                  bannerFileInChallenge3 !== null
                    ? bannerImageInChallenge3
                    : bannerImageInChallenge3 !== ''
                    ? `${imageUrlPrefix}${bannerImageInChallenge3}`
                    : ''
                }
                setFile={setBannerFileInChallenge3}
                file={bannerFileInChallenge3}
                width='15rem'
                onDelete={() => setChallengeBannerLink3('')}
              />
            </Box>
          </Stack>
          <Stack
            direction={{ sm: 'column', md: 'row' }}
            divider={
              <Divider
                orientation='vertical'
                flexItem
                sx={{ p: 0, m: '1.5rem 3rem 0' }}
              />
            }
            sx={{ mb: '1.5rem' }}
          >
            <Box>
              <Box>
                <TextField
                  sx={{ width: '25rem' }}
                  placeholder='url를 입력해주세요'
                  value={challengeBannerLink1}
                  onChange={(
                    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => {
                    setChallengeBannerLink1(getAddUrl(evt.target.value));
                  }}
                />
              </Box>
            </Box>
            <Box>
              <Box>
                <TextField
                  sx={{ width: '25rem' }}
                  placeholder='url를 입력해주세요'
                  value={challengeBannerLink2}
                  onChange={(
                    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => {
                    setChallengeBannerLink2(getAddUrl(evt.target.value));
                  }}
                />
              </Box>
            </Box>
            <Box>
              <Box>
                <TextField
                  sx={{ width: '25rem' }}
                  placeholder='url를 입력해주세요'
                  value={challengeBannerLink3}
                  onChange={(
                    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => {
                    setChallengeBannerLink3(getAddUrl(evt.target.value));
                  }}
                />
              </Box>
            </Box>
          </Stack>
        </Box>
        <Divider />
        <Box>
          <Box className='flex_between'>
            <Typography className='title'>챌린지 안내 내용 (선택)</Typography>
            <div>
              <Button onClick={updateChallengeGuideView}>저장</Button>
              <Button onClick={addChallengeGuideView}>추가</Button>
              {guideLists.length > 0 && (
                <Button onClick={removeChallengeGuideView}>삭제</Button>
              )}
            </div>
          </Box>
          <TextField
            sx={{ width: '16rem' }}
            value={guideTitle}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setGuideTitle(evt.target.value)}
          />
          <TextField
            value={guideContent}
            fullWidth
            multiline
            rows={5}
            sx={{ '& .MuiOutlinedInput-root': { height: 'auto', mt: '1rem' } }}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setGuideContent(evt.target.value)}
          />
          <SwFileSetting
            image={
              guideImageFile !== null
                ? guideImage
                : guideImage !== ''
                ? `${imageUrlPrefix}${guideImage}`
                : ''
            }
            setImage={setGuideImage}
            setFile={setGuideImageFile}
            file={guideImageFile}
          />
          {/* <Button
            variant='contained'
            onClick={() => {
              if (
                guideTitleRef.current.value &&
                guideContentsRef.current.value
              ) {
                const newTitle = guideTitleRef.current.value;
                const newContents = guideContentsRef.current.value;
                const newGuideLists = guideLists;
                const newGuideList = guideLists[guidePage - 1];
                newGuideLists[guidePage - 1] = {
                  ...newGuideList,
                  title: newTitle,
                  contents: newContents,
                };
                setGuideLists(newGuideLists);
              }
            }}
          >
            저장
          </Button> */}
          {/* <SwPagination
            page={guidePage}
            handleChangePage={onChangeChallengeGuideView}
            count={guideLists.length}
          /> */}
          <Typography className='title'>챌린지 안내 목록</Typography>
          {guideLists?.map((item: any, idx: number) => (
            <DraggablePaper
              key={idx.toString()}
              className='draggable'
              id={idx.toString()}
              variant='outlined'
              onDragStart={dragStart}
              onDragEnd={dragEnd}
              onDragOver={dragOver}
              onDrop={drop}
              onDragLeave={dragLeave}
              onDragEnter={dragEnter}
              draggable
              data-position={idx}
              onClick={selectList}
              sx={{ width: '50%' }}
            >
              <div>
                <span>{idx}. </span>
                <span>{item.title}</span>
              </div>
              <DragHandleIcon />
            </DraggablePaper>
          ))}
          <Divider />
        </Box>
        {(challengeType === 12 ||
          (challengeType === 18 && challengeSubType === 2)) && (
          <Box>
            <Typography className='title'>챌린지 인증 방법</Typography>
            <Stack
              direction='row'
              divider={
                <Divider orientation='vertical' flexItem sx={{ m: 0 }} />
              }
              spacing={2}
            >
              <Box>
                <Typography>정답 예시</Typography>
                <Box className='flex_between'>
                  <Box sx={{ mr: '1rem' }}>
                    <SwFileSetting
                      setImage={setPhotoCertSuccessImage}
                      image={
                        photoCertSuccessFile !== null
                          ? photoCertSuccessImage
                          : photoCertSuccessImage !== ''
                          ? `${imageUrlPrefix}${photoCertSuccessImage}`
                          : ''
                      }
                      setFile={setPhotoCertSuccessFile}
                      file={photoCertSuccessFile}
                    />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Typography>오답 예시</Typography>
                <Box className='flex_between'>
                  <Box sx={{ mr: '1rem' }}>
                    <SwFileSetting
                      setImage={setPhotoCertFailImage}
                      image={
                        photoCertFailFile !== null
                          ? photoCertFailImage
                          : photoCertFailImage !== ''
                          ? `${imageUrlPrefix}${photoCertFailImage}`
                          : ''
                      }
                      setFile={setPhotoCertFailFile}
                      file={photoCertFailFile}
                    />
                  </Box>
                </Box>
              </Box>
            </Stack>
            <Box>
              <Typography className='title'>인증 방법 내용</Typography>
              <TextField
                fullWidth
                multiline
                rows={5}
                onChange={evt =>
                  setPhotoCertDescription(evt.target.value.substring(0, 500))
                }
                value={photoCertDescription}
                placeholder='내용을 입력해 주세요.'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <span>{photoCertDescription?.length} / 500</span>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    height: 'auto',
                    padding: '.5rem',
                  },
                }}
              />
            </Box>
            <Divider />
          </Box>
        )}
        <Box>
          <Box className='flex_start'>
            <Box className='flex_start'>
              <Typography className='title'>위치 정보(선택)</Typography>
              <Tooltip
                title={
                  <Typography fontSize={TOOLTIP_FONT_SIZE}>
                    {TOOLTIP_CHALLENGE_LOCATION}
                  </Typography>
                }
                followCursor
              >
                <HelpOutlineIcon sx={{ color: '#999999' }} />
              </Tooltip>
            </Box>
            <Button
              sx={{ ml: '1.5rem' }}
              variant='contained'
              color='info'
              onClick={() => {
                setOpenPostCode(1);
              }}
            >
              주소찾기
            </Button>
            <Button
              sx={{ ml: '1.5rem' }}
              variant='contained'
              color='inherit'
              onClick={() => {
                setResultLatLng('');
                setChallengeAddress('');
              }}
            >
              위치 정보 초기화
            </Button>
          </Box>
          <SwFindLatLngbyKakaomap
            elementId='challengePointMap'
            setLatLng={setResultLatLng}
            value={resultLatLng}
            location={challengeAddress}
            setLocation={setChallengeAddress}
          />
          {openPostCode && (
            <SwSearchPostCode
              open={!!openPostCode}
              onClose={() => setOpenPostCode(0)}
              setTarget={setTargetData}
            />
          )}
          <Divider />
        </Box>
        <Box>
          <DataportalMultiConnect
            findDataportal={findDataPortal}
            setFindDataportal={setFindDataPortal}
            connectDataportal={connectDataPortal}
            setConnectDataportal={setConnectDataPortal}
            setTargetInfo={setDataPortal}
            isDetail={true}
          />
          <Divider />
        </Box>
        <Stack
          direction={{ sm: 'column', md: 'row' }}
          divider={
            <Divider
              orientation='vertical'
              flexItem
              sx={{ p: 0, m: '1.5rem 3rem 0' }}
            />
          }
        >
          <Box>
            <Typography className='title'>
              우선 순위(숫자가 작을수록 우선함)
            </Typography>
            <TextField
              sx={{ width: '10rem' }}
              value={challengeViewType}
              onChange={(
                evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                if (Number.isInteger(Number(evt.target.value)))
                  setChallengeViewType(Number(evt.target.value));
              }}
            />
          </Box>
          <Box>
            <Typography className='title'>상태</Typography>
            <TextField
              sx={{ width: '12rem' }}
              select
              value={challengeStatus === undefined ? 1 : challengeStatus}
              onChange={(
                evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => setChallengeStatus(Number(evt.target.value))}
            >
              <MenuItem key={0} value={0}>
                Close
              </MenuItem>
              <MenuItem key={1} value={1}>
                Open
              </MenuItem>
            </TextField>
          </Box>
          <Box>
            <Box className='flex_start'>
              <Typography className='title'>챌린지 게시판 사용</Typography>
              <Tooltip
                title={
                  <Typography fontSize={TOOLTIP_FONT_SIZE}>
                    {TOOLTIP_CHALLEGE_POST_USE}
                  </Typography>
                }
                followCursor
              >
                <HelpOutlineIcon sx={{ color: '#999999' }} />
              </Tooltip>
            </Box>
            <TextField
              select
              sx={{ width: '10rem' }}
              value={useChallengePost === undefined ? 1 : useChallengePost}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                setUseChallengePost(Number(event.target.value));
              }}
            >
              <MenuItem key={0} value={0}>
                사용안함
              </MenuItem>
              <MenuItem key={1} value={1}>
                사용
              </MenuItem>
            </TextField>
          </Box>
          <Box>
            <Box className='flex_start'>
              <Typography className='title'>앨범 사진 사용</Typography>
              <Tooltip
                title={
                  <Typography fontSize={TOOLTIP_FONT_SIZE}>
                    {TOOLTIP_CHALLEGE_ALBUM_IMAGE_USE}
                  </Typography>
                }
                followCursor
              >
                <HelpOutlineIcon sx={{ color: '#999999' }} />
              </Tooltip>
            </Box>
            <TextField
              select
              sx={{ width: '10rem' }}
              value={useGallery === undefined ? 0 : useGallery}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                setUseGallery(Number(event.target.value));
              }}
            >
              <MenuItem key={0} value={0}>
                사용안함
              </MenuItem>
              <MenuItem key={1} value={1}>
                사용
              </MenuItem>
            </TextField>
          </Box>
          <Box className='flex_start'>
            <Box>
              <Box className='flex_start'>
                <Typography className='title'>챌린지 랭킹 사용</Typography>
                <Tooltip
                  title={
                    <Typography fontSize={TOOLTIP_FONT_SIZE}>
                      {TOOLTIP_CHALLEGE_RANKING_USE}
                    </Typography>
                  }
                  followCursor
                >
                  <HelpOutlineIcon sx={{ color: '#999999' }} />
                </Tooltip>
              </Box>
              <TextField
                select
                sx={{ width: '10rem' }}
                value={useChallengeRank === undefined ? 1 : useChallengeRank}
                onChange={(
                  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => {
                  setUseChallengeRank(Number(event.target.value));
                  Number(event.target.value) === 0 ? setIsPrivate(0) : '';
                }}
              >
                <MenuItem key={0} value={0}>
                  사용안함
                </MenuItem>
                <MenuItem key={1} value={1}>
                  사용
                </MenuItem>
              </TextField>
            </Box>
            {useChallengeRank === 1 && (
              <Box sx={{ ml: '1.5rem', mt: '1.5rem' }}>
                <RadioGroup
                  row
                  aria-labelledby='movie-row-radio-buttons-group-label'
                  name='row-radio-buttons-group'
                  value={
                    isPrivate === 0
                      ? 'user'
                      : isPrivate === 1
                      ? 'hidden'
                      : 'open'
                  }
                  onChange={evt => {
                    evt.currentTarget.value === 'user'
                      ? setIsPrivate(0)
                      : evt.currentTarget.value === 'hidden'
                      ? setIsPrivate(1)
                      : setIsPrivate(2);
                    // evt.currentTarget.value === 'user'
                    //   ? setUseChallengeRank(0)
                    //   : setUseChallengeRank(1);
                  }}
                >
                  <FormControlLabel
                    value='user'
                    control={<Radio />}
                    label='사용자 지정'
                  />
                  <FormControlLabel
                    value='hidden'
                    control={<Radio />}
                    label='비공개'
                  />
                  <FormControlLabel
                    value='open'
                    control={<Radio />}
                    label='강제 공개'
                  />
                </RadioGroup>
              </Box>
            )}
          </Box>
        </Stack>
        <Divider />
        <Stack
          direction={{ sm: 'column', md: 'row' }}
          divider={
            <Divider
              orientation='vertical'
              flexItem
              sx={{ p: 0, m: '1.5rem 3rem 0' }}
            />
          }
        >
          <Box>
            <Box>
              <Typography className='title'>
                챌린지 참여 안내사항 팝업
              </Typography>
              <TextField
                select
                sx={{ width: '10rem' }}
                value={customPopupUseYn === undefined ? 0 : customPopupUseYn}
                onChange={(
                  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => {
                  setCustomPopupUseYn(Number(event.target.value));
                }}
              >
                <MenuItem key={0} value={0}>
                  사용안함
                </MenuItem>
                <MenuItem key={1} value={1}>
                  사용
                </MenuItem>
              </TextField>
            </Box>
            {customPopupUseYn === 1 && (
              <Box>
                <MultilineTextField
                  sx={{ width: '40rem' }}
                  inputProps={{ maxLength: 200 }}
                  multiline
                  rows={5}
                  placeholder='챌린지 참여 전 안내할 내용을 작성해주세요. (최대 200자)'
                  onChange={evt => setCustomPopupText(evt.target.value)}
                  value={customPopupText}
                />
              </Box>
            )}
          </Box>
          <Box>
            <Box className='flex_start'>
              <Typography className='title'>챌린지 알아보기(링크)</Typography>
              <Tooltip
                title={
                  <Typography fontSize={TOOLTIP_FONT_SIZE}>
                    {TOOLTIP_CHALLENGE_LINK}
                  </Typography>
                }
                followCursor
              >
                <HelpOutlineIcon sx={{ color: '#999999' }} />
              </Tooltip>
            </Box>
            <TextField
              sx={{ width: '25rem' }}
              value={challengeLink}
              onChange={(
                evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                setChallengeLink(getAddUrl(evt.target.value));
              }}
            />
          </Box>
          <Box>
            <Box className='flex_start'>
              <Typography className='title'>앱 리뷰 요청</Typography>
            </Box>
            <Box>
              <RadioGroup
                row
                aria-labelledby='movie-row-radio-buttons-group-label'
                name='row-radio-buttons-group'
                value={allowAppReview === 0 ? 'notallow' : 'allow'}
                onChange={evt => {
                  evt.currentTarget.value === 'allow'
                    ? setAllowAppReview(1)
                    : setAllowAppReview(0);
                }}
              >
                <FormControlLabel
                  disabled={challengeType === 5}
                  value='allow'
                  control={<Radio />}
                  label='허용'
                />
                <FormControlLabel
                  disabled={challengeType === 5}
                  value='notallow'
                  control={<Radio />}
                  label='허용 안함'
                />
              </RadioGroup>
            </Box>
          </Box>
        </Stack>
        <Divider />
        <Box sx={{ mt: '1.5rem' }}>
          <ButtonBox className='flex_end'>
            <Box>
              <Button variant='contained' onClick={() => saveChallenge()}>
                정보 저장
              </Button>
            </Box>
          </ButtonBox>
        </Box>
      </CreatePaper>
      <SwSearchTarget
        title={targetTitle !== null ? targetTitle : ''}
        searchKey={searchKey}
        searchWord={searchWord}
        open={openSearchResult}
        onClose={() => {
          setOpenSearchResult(false);
        }}
        setTarget={setSearchResult}
      />
      <SwChallengeClassType
        open={openClassType}
        onClose={() => {
          setOpenClassType(false);
          reloadChallenge(challengeId);
        }}
      />
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={alertMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={alertMessage}
      />
      <SwStampTourView
        title='필수 스탬프 지정'
        open={openStampTourView}
        onClose={() => setOpenStampTourView(false)}
        stamptourId={challengeStampTourId}
        challengeId={challengeId}
      />
      <SwWalkCourseView
        title='설정된 코스 정보'
        open={openWalkcourseView}
        onClose={() => setOpenWalkcourseView(false)}
        courseId={challengeWalkCourseId}
        challengeId={challengeId}
      />
      <SwFieldView
        title='설정된 코스 정보'
        open={openFieldView}
        onClose={() => setOpenFieldView(false)}
        fieldId={challengeFieldId}
        challengeId={challengeId}
      />
    </>
  );
};
