import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { xapi } from '../../hooks/session';
import { IAmd } from '../../models/amd';
import { IRegionFilter } from '../../models/regionfilter';
import { throwError } from '../helper';

/*
 * App Update Guide
 */

export const getAppUpdateGuideList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/appupdateguide/info?page=${data.page}&per_page=${data.rowsPerPage}&searchKey=${data.searchKey}&searchWord=${data.searchWord}&startDate=${data.startDate}&endDate=${data.endDate}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postAppUpdateGuideInfo = (data: any) =>
  xapi.post(`/admin/v2/apis/appupdateguide/info`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const updateAppUpdateGuideInfo = (data: any) =>
  xapi.put(`/admin/v2/apis/appupdateguide/info`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

/*
 * App Update Release Note
 */
export const getAppUpdateReleaseNoteList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/appupdateguide/release-note?type=${data.type}&page=${data.page}&per_page=${data.rowsPerPage}&searchKey=${data.searchKey}&searchWord=${data.searchWord}&startDate=${data.startDate}&endDate=${data.endDate}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postAppUpdateReleaseNote = (data: any) =>
  xapi.post(`/admin/v2/apis/appupdateguide/release-note`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });
