import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  styled,
} from '@mui/material';
import React, { useState } from 'react';

interface Props {
  open: boolean;
  onClose?: () => void;
  onConfirm: () => void;
  title?: string;
  contents: any;
  confirm?: number;
  confirmWord?: string;
}
const ConfirmDialog = styled(Dialog)({
  margin: '0 0 10rem 10rem',
  '& .MuiDialog-paper': {
    width: '30vw',
    height: '15vh',
  },
  '& .MuiDialogTitle-root': {
    padding: '.8rem 1rem !important',
    fontSize: '.93rem',
    marginLeft: '.5rem',
  },
  '& .MuiDialogActions-root': {
    padding: '0.33rem !important',
  },
});

export const SwConfirmDialog: React.FC<Props> = ({
  open,
  onClose,
  onConfirm,
  title,
  contents,
  confirm,
  confirmWord = '확인',
}) => {
  const a = 1;
  return (
    <ConfirmDialog open={open}>
      <DialogContent
        sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'pre-wrap' }}
      >
        {contents}
      </DialogContent>
      <Divider sx={{ p: 0, m: 0 }} />
      <DialogActions>
        <Button color='info' fullWidth size='small' onClick={onClose}>
          취소
        </Button>
        <Button color='primary' fullWidth size='small' onClick={onConfirm}>
          {confirmWord}
        </Button>
      </DialogActions>
    </ConfirmDialog>
  );
};
