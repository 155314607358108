import {
  Box,
  Button,
  Collapse,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { SelectTextField, TableButton } from '../../styles/Styles';
import { PartnerTable } from '../../commonComponent/table/PartnerTable';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { getCommunityListByPortal } from '../../../common/api/ApiCommunity';
import { KEY_COMMUNITY_LIST_BY_PORTAL } from '../../../common/key';
import {
  encodeSearchWord,
  MAX_DATE,
  timestamp2string,
} from '../../../common/helper';
import { ICommunityReqeust } from '../../../models/community';
import { downloadCommunityData } from '../../../common/api/ApiContract';

interface Props {
  contractInfo: any;
}

const column = [
  '바로가기',
  '커뮤니티 종류',
  '공식/개인',
  '커뮤니티 이름',
  '커뮤니티 아이디',
  '운영 시작일자',
  '운영 종료일자',
  '커뮤니티 멤버 수',
  '바로가기',
];

export const PartnerCommunityInformation: React.FC<Props> = ({
  contractInfo,
}) => {
  const queryClient = useQueryClient();
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [requestList, setRequestList] = useState<any>([]);
  const [communityList, setCommunityList] = useState<any>([]);
  const [sortValue, setSortValue] = useState(0);
  const [checkRequest, setCheckRequest] = useState<boolean>(true);
  const [portalId, setPortalId] = useState<string>('');
  const [searchKey, setSearchKey] = useState<string>('community');
  const [searchWord, setSearchWord] = useState<string>('');
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [openFilter, setOpenFilter] = useState(false);

  const {
    data,
    isLoading,
    refetch: communityRefetch,
  } = useQuery(
    KEY_COMMUNITY_LIST_BY_PORTAL,
    () => {
      if (checkRequest && portalId.length > 0) {
        const newData: ICommunityReqeust = {
          portalId: portalId,
          page: page,
          rowsPerPage: rowsPerPage,
          searchKey: searchKey,
          searchWord: encodeSearchWord(searchKey, searchWord),
          startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime() / 1000,
          endDate:
            rangeDate[1] === null
              ? new Date(MAX_DATE).getTime() / 1000
              : rangeDate[1].getTime() / 1000,
        };
        return getCommunityListByPortal(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        if (res && res.community) {
          const newCommunity = res.community.map((item: any) => {
            const newData = [
              item.type === 0 ? '공개형' : '승인가입형',
              item.official === 1 ? '공식' : '개인',
              item.name,
              item.communityId,
              item.createTime,
              item.endTime,
              item.memberCount,
            ];
            return newData;
          });
          setRequestList(newCommunity);
          setCommunityList(res.community);
          setTotalRecords(res.pagination.totalRecordCount);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const communityPrefetch = (newPortalId: string, newPage: number) => {
    queryClient.prefetchQuery(KEY_COMMUNITY_LIST_BY_PORTAL, () => {
      const newData: ICommunityReqeust = {
        portalId: newPortalId,
        page: newPage,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
        startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime() / 1000,
        endDate:
          rangeDate[1] === null
            ? new Date(MAX_DATE).getTime() / 1000
            : rangeDate[1].getTime() / 1000,
      };
      return getCommunityListByPortal(newData);
    });
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
    // communityPrefetch(portalId, newPage);
    // communityRefetch();
  };

  const handleClickGoto = (index: number) => {
    const newCommunityId = communityList[index].communityId;
    window.open(
      `${window.location.origin}/communitymanage/list?communityId=${newCommunityId}`,
      '_blank'
    );
  };

  const downloadCommunty = () => {
    const newData = {
      portalId: portalId,
      page: 0,
      rowsPerPage: 0,
      searchKey: searchKey,
      searchWord: encodeSearchWord(searchKey, searchWord),
      startDate: 0,
      endDate: 0,
    };
    downloadCommunityData(newData);
  };

  const searchTarget = () => {
    setPage(1);
    communityPrefetch(portalId, 1);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchTarget();
    }
  };

  useEffect(() => {
    if (
      rangeDate[0] !== null ||
      (rangeDate[0] === null && rangeDate[1] === null)
    ) {
      if (portalId.length > 0) communityRefetch();
    }
  }, [rangeDate]);

  useEffect(() => {
    if (portalId.length > 0) communityRefetch();
  }, [page]);

  useEffect(() => {
    if (portalId.length > 0) communityRefetch();
  }, [portalId]);

  useEffect(() => {
    console.log('PartnerChallengeInformation contractInfo :', contractInfo);
    if (contractInfo) {
      setPage(1);
      setPortalId(contractInfo.id);
      // communityPrefetch(contractInfo.realid, 1);
      // communityRefetch();
    } else {
      setPortalId('');
      setRequestList([]);
    }
  }, [contractInfo]);

  return (
    <>
      <Typography variant='h3' sx={{ fontWeight: 500, p: '2.5rem 0 .5rem' }}>
        커뮤니티 이용 정보
      </Typography>
      <Paper sx={{ p: '1.5rem', m: '1rem 0' }}>
        <Box className='flex_between' sx={{ mb: '1rem' }}>
          <Box>조회 {totalRecords} 건</Box>
          <Box className='flex_center'>
            <TextField
              placeholder='검색어를 입력해주세요.'
              value={searchWord}
              onChange={(
                evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => setSearchWord(evt.target.value)}
              onKeyDown={handleKeyDown}
              sx={{ width: '20rem', mr: '1em', mt: '1rem' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton sx={{ pr: 0 }} onClick={searchTarget}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Collapse orientation='horizontal' in={openFilter}>
              <SwDateRangePicker
                rangeDate={rangeDate}
                setRangeDate={setRangeDate}
              />
            </Collapse>
            <Button
              variant='outlined'
              onClick={() => setOpenFilter(!openFilter)}
              sx={{ mr: '1rem', height: '1.8rem' }}
            >
              <FilterAltOutlinedIcon />
              필터
            </Button>
            <SelectTextField
              select
              value={rowsPerPage}
              onChange={evt => {
                setRowsPerPage(Number(evt.target.value));
                setPage(1);
              }}
            >
              {rowsPerPageOptions.map(option => (
                <MenuItem key={option.toString()} value={option}>
                  {option}개씩 보기
                </MenuItem>
              ))}
            </SelectTextField>
            <TableButton
              color='info'
              variant='outlined'
              onClick={downloadCommunty}
            >
              데이터 다운로드
              <FileDownloadRoundedIcon
                color='success'
                sx={{ fontSize: '1.3rem' }}
              />
            </TableButton>
          </Box>
        </Box>
        <PartnerTable
          columns={column}
          rows={requestList}
          rowsPerPage={rowsPerPage}
          page={page}
          totalRecords={totalRecords}
          handleChangePage={handleChangePage}
          showLink={true}
          onClickGoto={handleClickGoto}
        />
      </Paper>
    </>
  );
};
