import {
  Button,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import React, { MouseEvent, useEffect, useState } from 'react';

interface Props {
  title?: any;
  columns: string[];
  rows: any[];
  isHolding: number;
  getFocusIndex?: () => number;
  onclick?: (i: number) => void;
  onHolding: () => void;
  onWriting: () => void;
}

const CreatePaper = styled(Paper)({
  // margin: '2rem',
  padding: '1rem',
  cursor: 'pointer',
});

export const RequestTableChallenge: React.FC<Props> = ({
  title,
  columns,
  rows,
  isHolding,
  getFocusIndex,
  onclick,
  onHolding,
  onWriting,
}) => {
  const [selectedRow, setSelectedRow] = useState(-1);
  const handlerRowClicked = (idx: number) => {
    if (onclick) onclick(idx);
    setSelectedRow(idx);
  };

  useEffect(() => {
    const newIndex = getFocusIndex && getFocusIndex();
    if (newIndex !== null || newIndex !== undefined) {
      setSelectedRow(newIndex !== undefined ? newIndex : -1);
    }
  }, [rows]);

  return (
    <CreatePaper>
      {/* <Typography component='div' sx={{ ml: '.4rem' }}>
          {title}
        </Typography> */}
      {title} {title && rows && `${rows?.length}개`}
      <TableContainer sx={{ mt: '1rem', maxHeight: '27rem' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column, idx) => (
                <TableCell
                  key={idx.toString()}
                  className={clsx(
                    { request_head: !!title },
                    'request_tablehead'
                  )}
                  sx={{ p: '.4rem .7rem !important' }}
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody sx={{ maxHeight: '10rem', overflowY: 'scroll' }}>
            {rows?.map((row, idx) => (
              <TableRow
                key={idx.toString()}
                className={selectedRow === idx ? 'selected_row' : 'whitecolor'}
                onClick={() => {
                  handlerRowClicked(idx);
                }}
              >
                {row.map((r: any, i: number) => (
                  <TableCell
                    key={i.toString()}
                    className={clsx(
                      // { request_body: !!title },
                      'request_tablecell'
                    )}
                    sx={{ p: '.6rem .7rem' }}
                  >
                    {r}
                  </TableCell>
                ))}
                <TableCell
                  key={rows.length.toString()}
                  className={clsx(
                    // { request_body: !!title },
                    'request_tablecell'
                  )}
                  sx={{ p: '.6rem .7rem' }}
                >
                  <Button
                    variant='contained'
                    color='info'
                    onClick={evt => {
                      evt.stopPropagation();
                      onHolding();
                    }}
                  >
                    {isHolding === 1 ? '보류 해제' : '보류'}
                  </Button>
                </TableCell>
                <TableCell
                  key={rows.length.toString()}
                  className={clsx(
                    // { request_body: !!title },
                    'request_tablecell'
                  )}
                  sx={{ p: '.6rem .7rem' }}
                >
                  <Button
                    variant='contained'
                    color='inherit'
                    onClick={evt => {
                      evt.stopPropagation();
                      onWriting();
                    }}
                  >
                    작성
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CreatePaper>
  );
};
