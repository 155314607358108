import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CloseIcon from '@mui/icons-material/Close';
import koLocale from 'date-fns/locale/ko';
import SearchIcon from '@mui/icons-material/Search';
import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { debouncehook } from '../../../hooks/debouncehook';
import { useMany } from '../../../hooks/api';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwDatesPicker } from '../../commonComponent/dateSetting/SwDatesPicker';
import { SwSearchTarget } from '../../commonComponent/SwSearchTarget';
import { IPush, DefaultPush } from '../../../models/push';
import {
  postPushSingle,
  postPush,
  getRegisteredPushTimeList,
} from '../../../common/api/ApiPush';
import { RESULT_OK } from '../../../common/resultCode';
import { SwLoadUser } from '../../commonComponent/SwLoadUser';
import {
  commaFormat,
  encodeSearchWord,
  getPushLinkCode,
  timestamp2Localestring,
} from '../../../common/helper';
import { SwPushSelectLocation } from '../../commonComponent/SwPushSelectLocation';
import { SwPushSelectOsVersion } from '../../commonComponent/SwPushSelectOsVersion';
import { getRecentLocaitonList } from '../../../common/api/ApiGeohash';
import {
  KEY_GEOHASH_RECENT_LOCATION_LIST,
  KEY_REGISTERED_PUSH_LIST,
  KEY_TARGET_LIST,
  KEY_TARGET_PUSH_LIST,
} from '../../../common/key';
import SwLoading from '../../commonComponent/spinner/SwLoading';
import { getTarget } from '../../../common/api/ApiCommon';
import { SwDatesPickerWithScroll } from '../../commonComponent/dateSetting/SwDatesPickerWithScroll';

interface Props {}

const MAX_LOAD_USER_COUNT = 500;

const DateChip = styled(Chip)({
  margin: '.5em',
  backgroundColor: 'rgba(0, 162, 255, 0.1)',
  borderColor: 'rgba(0, 162, 255, 0.5)',
  '& .MuiChip-deleteIcon': {
    fontSize: '1rem',
  },
});

const ReceiverChip = styled(Chip)({
  border: 'none',
  '& .MuiChip-deleteIcon': {
    fontSize: '.9rem',
    marginTop: '.1rem',
  },
});

const LinkerChip = styled(Chip)({
  border: 'none',
  '& .MuiChip-deleteIcon': {
    fontSize: '.9rem',
    marginTop: '.1rem',
  },
});

const RootPaper = styled(Paper)({
  // margin: '2.8rem',
  padding: '1rem',
  borderRadius: '12px',
});

const ScrollPaper = styled(Paper)({
  maxHeight: '10rem',
  overflowY: 'scroll',
  padding: '.5rem',
  width: '44rem ',
});

const UserBox = styled(Box)({
  margin: '.4rem .4rem .4rem 0',
  color: '#777777',
  display: 'flex',
  alignItems: 'center',
});

const SwReceivers = [
  { title: '전체', value: 'all' },
  { title: '공식 커뮤니티', value: 'community' },
  { title: '그룹', value: 'group' },
  { title: '챌린지', value: 'challenge' },
  { title: '프로그램', value: 'program' },
  { title: '리워드', value: 'reward' },
  { title: '지역(활동/거주지역)', value: 'region' },
  { title: '위치', value: 'location' },
  { title: '앱 업데이트 버전', value: 'osVersion' },
  { title: '단말 OS', value: 'os' },
  { title: '개인', value: 'user' },
];
const push = [
  { title: '워크온 홈', value: 'home' },
  { title: '공식 커뮤니티', value: 'community' },
  { title: '챌린지', value: 'challenge' },
  { title: '프로그램', value: 'program' },
  { title: '질의응답', value: 'questionanswer' },
  // { title: '리워드', value: 'reward' },
  // { title: 'URL', value: 'url' },
];

export const PushRegist: React.FC<Props> = () => {
  const site = 'webAdmin';
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const findReceiverRef = useRef<any>();
  const findGroupRef = useRef<any>();
  const pushLinkRef = useRef<any>();
  const testIdRef = useRef<any>();
  const [inputs, setInputs] = useState([
    {
      id: '제목',
      value: '',
      placeholder: '제목을 입력해주세요.',
      helperText: '',
      getHelperText: (error: boolean) =>
        error ? '50자 이하로 적어주세요' : '',
      isValid: (value: string) => value.length > 50,
    },
    {
      id: '내용',
      value: '',
      placeholder: '알림 내용을 입력해주세요.',
      helperText: '',
      getHelperText: (error: boolean) =>
        error ? '70자 이하로 적어주세요' : '',
      isValid: (value: string) => value.length > 70,
    },
  ]);
  const [receiverClassification, setReceiverClassification] =
    useState<string>('community');
  const [receiverClassificationDetail1, setReceiverClassificationDetail1] =
    useState<string>('all');
  const [receiverClassificationDetail2, setReceiverClassificationDetail2] =
    useState<string>('all');
  const [receiverClassificationDetail3, setReceiverClassificationDetail3] =
    useState<string>('all');
  const [searchResult, setSearchResult] = useState<any[]>([]);
  const [group, setGroup] = useState<any>('id');
  const [groupItems, setGroupItems] = useState<any[]>([]);
  const [findReceiver, setFindReceiver] = useState<string>('');
  const [community, setCommunity] = useState<any>();
  const [receivers, setReceivers] = useState<any[]>([]);
  const [osVersion, setOsVersion] = useState<string>('');
  const [selectedUserList, setSelectedUserList] = useState<any[]>([]);
  const [loadUserList, setLoadUserList] = useState<any>([]);
  const [linkers, setLinkers] = useState<any[]>([]);
  const [openType, setOpenType] = useState<number>(0);
  const [pushLink, setPushLink] = useState<any>('home');
  const [pushDates, setPushDates] = useState<any[]>([]);
  const [sendDates, setSendDates] = useState<any[]>([]);
  const [searchDates, setSearchDates] = useState<any>();
  const [isReservation, setIsReservation] = useState<boolean>(true);
  const [checkPickId, setCheckPickId] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);
  const [openSearchResult, setOpenSearchResult] = useState<boolean>(false);
  const [snackbarContents, setSnackbarContents] = useState<string>('');
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [emdInfo, setEmdInfo] = useState<string>('');
  const [title, setTitle] = useState<string[]>([]);
  const [openLoadUser, setOpenLoadUser] = useState<number>(0);
  const [locationLimit, setLocationLimit] = useState<any[]>([]);
  const [registeredTime, setRegisteredTime] = useState<any>([]);
  const [loading, setLoading] = useState<any>(null);

  const {
    data,
    isLoading,
    refetch: refetchTarget,
  } = useQuery(
    KEY_TARGET_PUSH_LIST,
    () => {
      if (searchKey !== '')
        return getTarget(searchKey, encodeSearchWord(searchKey, searchWord));
      return null;
    },
    {
      onSuccess: res => {
        setLoading(false);
        if (res != null) {
          setEmdInfo(
            `${res.target[0].name} : ${commaFormat(`${res.target[0].count}`)}명`
          );
        } else setEmdInfo('');
      },
      onError: e => {
        setLoading(false);
        console.log(e);
      },
    }
  );

  const prefetchTarget = (newSearchKey: string, newSearchWord: string) => {
    queryClient.prefetchQuery(KEY_TARGET_PUSH_LIST, () =>
      getTarget(newSearchKey, encodeSearchWord(newSearchKey, newSearchWord))
    );
  };

  const { refetch: refetchRegisteredPushTimeList } = useQuery(
    KEY_REGISTERED_PUSH_LIST,
    () => {
      if (searchDates !== null) {
        const newDate =
          searchDates === null ? 0 : new Date(searchDates).getTime() / 1000;
        return getRegisteredPushTimeList(newDate);
      }
      return null;
    },
    {
      onSuccess: res => {
        if (res !== null && res.push !== null) {
          setRegisteredTime(res.push);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const onChangeText = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = event.target.value.replace('"', '`');
    const newInputs = [...inputs];
    const index = inputs.findIndex(input => input.id === event.target.id);
    const newInput = newInputs[index];
    const error = newInput.isValid(newValue);
    newInputs[index] = {
      ...newInput,
      value: newValue,
      helperText: newInput.getHelperText(error),
    };
    setInputs(newInputs);
  };

  const { mutate: pushSingleMutate, isError: isPushSingleMutateError } =
    useMutation(postPushSingle, {
      onSuccess: res => {
        setLoading(false);
        setOpenMore(1);
        if (res.resultCode === RESULT_OK) {
          setSnackbarContents('테스트 푸시가 발송되었습니다.');
        } else {
          setSnackbarContents(
            `테스트 푸시 발송이 실패했습니다.(${res.resultCodeMsg})`
          );
        }
      },
      onError: error => {
        setLoading(false);
        setOpenMore(1);
        setSnackbarContents(
          '테스트 푸시 발송에 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });

  const { mutate: pushMutate, isError: isPushMutateError } = useMutation(
    postPush,
    {
      onSuccess: res => {
        setLoading(false);
        setOpenMore(1);
        if (res.resultCode === RESULT_OK) {
          setSnackbarContents('푸시가 발송되었습니다.');
        } else {
          setSnackbarContents(
            `푸시 발송이 실패했습니다.(${res.resultCodeMsg})`
          );
        }
      },
      onError: error => {
        setLoading(false);
        setOpenMore(1);
        setSnackbarContents(
          '푸시 발송에 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    }
  );

  const sendPush = (type: number) => {
    if (
      (type === 0 && testIdRef.current?.value) ||
      (type === 1 && receivers.length > 0)
    ) {
      const id = type === 0 ? testIdRef.current?.value : receivers[0].id;
      const name = type === 0 ? 'test' : receivers[0].name;
      var targetType = 'user';
      if (
        receiverClassification === 'challenge' ||
        receiverClassification === 'community' ||
        receiverClassification === 'program' ||
        receiverClassification === 'group' ||
        receiverClassification === 'reward' ||
        receiverClassification === 'user' ||
        receiverClassification === 'region' ||
        receiverClassification === 'location' ||
        receiverClassification === 'os' ||
        receiverClassification === 'osVersion'
      ) {
        targetType = receiverClassification;
      }

      /*
       * /home/program?programId=(프로그램 아이디): 프로그램 화면으로 이동
       * /home/program/report?programId=(프로그램 아이디): 프로그램 결과리포트로 이동
       * /home/program/weekly-report?programId=(프로그램 아이디): 프로그램 주차별 리포트로 이동
       * /home/program/certificate마이 페이지 > 프로그램 증서 내역 으로 이동
       * /home/qna?qnaId=(qna 아이디): 퀴즈/질의응답 화면으로 이동
       */
      var linkData = '';
      const linkPostfix = linkers.length > 0 ? linkers[0].id : '';
      if (pushLink === 'home') linkData = 'walkon://home/popup';
      else if (pushLink === 'challenge')
        linkData = `walkon://home/challenge?challenge_id=${linkPostfix}`;
      else if (pushLink === 'community')
        linkData = `walkon://home/my_community/detail?id=${linkPostfix}`;
      else if (pushLink === 'reward')
        linkData = `walkon://home/my_community/detail?id=${linkPostfix}`;
      else if (pushLink === 'questionanswer')
        linkData = `walkon://home/qna?qnaId=${linkPostfix}`;
      else if (pushLink === 'program')
        linkData = `walkon://home/program?programId=${linkPostfix}`;
      else if (pushLink === 'url') linkData = pushLinkRef.current?.value;

      const newDates = pushDates.map(pushDate => pushDate.getTime() / 1000);

      if (linkData === '') {
        setOpenMore(1);
        setSnackbarContents(
          '링크 이동 정보를 확인 후 다시 시도해주시기 바랍니다.'
        );
        return;
      }

      const newPush: IPush = {
        receiver: {
          type: targetType,
          id: id,
          users: selectedUserList.length > 0 ? selectedUserList : [],
          name: name,
        },
        title: inputs[0].value,
        content: inputs[1].value,
        link: linkData,
        linkName: linkers.length > 0 ? linkers[0].name : '',
        linkType: getPushLinkCode(pushLink),
        site: site,
        requestDate: isReservation ? newDates : [],
      };
      setLoading(true);
      if (type === 0) pushSingleMutate(newPush);
      else pushMutate(newPush);
    } else {
      setOpenMore(1);
      setSnackbarContents(
        '누락된 항목이 있습니다. 확인 후 다시 시도해주시기 바랍니다.'
      );
    }
  };

  const confirmSendPush = () => {
    if (receiverClassification === 'all') {
      setAlertOpen(true);
      setAlertMessage('전체 대상 푸시발송은 개발팀에 문의해주세요.');
      return;
    }
    if (isReservation && pushDates.length === 0) {
      setOpenMore(1);
      setSnackbarContents('예약 발송시 적용일시를 지정해야 합니다.');
    } else if (receivers.length > 0) {
      if (
        (receiverClassification === 'region' ||
          receiverClassification === 'location') &&
        receivers[0].id === 'all'
      ) {
        setAlertOpen(true);
        setAlertMessage('전체(전국) 대상 푸시발송은 개발팀에 문의해주세요.');
        return;
      }

      if (receiverClassification === 'osVersion') {
        const newOsVersion = osVersion.split('>');
        if (newOsVersion.length === 2 && newOsVersion[1] === '') {
          setAlertOpen(true);
          setAlertMessage('OS 버전을 선택해주세요');
          return;
        }
      }

      const newSendDates = pushDates.map(pushDate =>
        moment(pushDate).format('YYYY-MM-DD HH:mm:ss')
      );
      setSendDates(newSendDates);

      setOpenMore(2);
      setSnackbarContents('푸시가 발송되었습니다.');
    } else {
      setOpenMore(1);
      setSnackbarContents(
        '누락된 항목이 있습니다. 확인 후 다시 시도해주시기 바랍니다.'
      );
    }
  };

  const deleteUserList = (idx: number) => {
    const newUserList = selectedUserList.filter(
      (item: any, i: number) => i !== idx
    );
    setSelectedUserList(newUserList);
  };

  // const {
  //   data,
  //   isLoading,
  //   refetch: getRecentLocationRefetch,
  // } = useQuery(
  //   KEY_GEOHASH_RECENT_LOCATION_LIST,
  //   () => {
  //     getRecentLocaitonList(1);
  //   },
  //   {
  //     onSuccess: res => {},
  //     onError: e => {},
  //   }
  // );

  // const generateRecentLocation = () => {
  //   getRecentLocationRefetch();
  // };

  const searchTarget = () => {
    const selected = SwReceivers.map(item => {
      const newValue = receiverClassification;
      receiverClassification === 'group' ? 'community' : receiverClassification;
      if (item.value === newValue) return item.title;
      return '';
    }).filter(value => value !== '');
    setTitle(selected);
    setSearchKey(
      receiverClassification === 'group' ? 'community' : receiverClassification
    );
    setSearchWord(
      receiverClassification !== 'user'
        ? findReceiverRef.current?.value
        : encodeSearchWord('phoneNumber', findReceiverRef.current?.value)
    );
    setOpenType(0);
    setOpenSearchResult(true);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchTarget();
    }
  };

  const doChangeDate = (date: Date) => {
    console.log('--> date:', date);
    setSearchDates(date);
  };

  useEffect(() => {
    refetchRegisteredPushTimeList();
  }, [searchDates]);

  useEffect(() => {
    console.log('--> pushDates:', pushDates);
  }, [pushDates]);

  useEffect(() => {
    if (
      receiverClassification === 'os' ||
      receiverClassification === 'osVersion'
    ) {
      const newOsVersion = osVersion.split('>');
      const newReceiver = {
        id: receiverClassification === 'os' ? newOsVersion[0] : osVersion,
        phoneno: '',
        name: receiverClassification === 'os' ? newOsVersion[0] : osVersion,
      };
      setReceivers([newReceiver]);

      setEmdInfo('');
      if (
        (receiverClassification === 'osVersion' && newOsVersion[1] !== '') ||
        receiverClassification === 'os'
      ) {
        setLoading(true);
        prefetchTarget(
          receiverClassification,
          receiverClassification === 'os' ? newOsVersion[0] : osVersion
        );
      }
    }
  }, [osVersion]);

  useEffect(() => {
    console.log('--> locationLimit:', locationLimit);
    if (
      (receiverClassification === 'region' ||
        receiverClassification === 'location') &&
      locationLimit.length > 0
    ) {
      const newReceiver = {
        id: locationLimit.length > 0 ? locationLimit[0].amd : 'all',
        phoneno: '',
        name: locationLimit.length > 0 ? locationLimit[0].location : 'all',
      };
      setReceivers([newReceiver]);
      setSearchKey(receiverClassification);
      setSearchWord(locationLimit ? locationLimit[0].location : '');
      setLoading(true);
      setEmdInfo('');
      prefetchTarget(
        receiverClassification,
        locationLimit ? locationLimit[0].location : ''
      );
    }
  }, [locationLimit]);

  useEffect(() => {
    setTitle(['그룹']);
    const newData = groupItems.filter((item: any) => item.id === group);
    setReceivers(newData);
  }, [group]);

  useEffect(() => {
    console.log('loadUserList :', loadUserList);
    if (receiverClassification === 'user' && loadUserList.length > 0) {
      const newUserList = loadUserList.filter(
        (item: any) => !selectedUserList.includes(item)
      );
      if (newUserList.length !== loadUserList.length) {
        setOpenMore(1);
        setSnackbarContents('중복 사용자 아이디를 제외하고 추가했습니다.');
      }
      setSelectedUserList((prevState: any) => [...prevState, ...newUserList]);
      const newReceiver = {
        id: newUserList[0],
        phoneno: newUserList[0],
        name: '',
      };
      setReceivers([newReceiver]);
    }
  }, [loadUserList]);

  useEffect(() => {
    const newSearchResult = [...searchResult];
    if (receiverClassification === 'group' && searchResult[0].groups !== null) {
      const newGroups =
        searchResult[0].groups !== null
          ? JSON.parse(searchResult[0].groups)
          : [];
      if (newGroups.length > 0) {
        const tmpGroup = newGroups.map((item: any) => {
          const newData = {
            id: item.GROUP_ID,
            name:
              item.GROUP_NAME === 'no-group' ? '기본 그룹' : item.GROUP_NAME,
            count: item.COUNT,
          };
          return newData;
        });
        setGroupItems([...tmpGroup]);
        setCommunity(searchResult[0]);
      }
    }
    if (openType === 0) {
      setReceivers(newSearchResult);
      if (receiverClassification === 'user' && searchResult.length > 0) {
        if (
          (selectedUserList.length > 0 &&
            !selectedUserList.includes(searchResult[0].phoneno)) ||
          selectedUserList.length === 0
        ) {
          setSelectedUserList((prevState: any) => [
            ...prevState,
            searchResult[0].phoneno,
          ]);
        } else {
          setOpenMore(1);
          setSnackbarContents('중복 사용자 아이디입니다.');
        }
      }
    } else if (openType === 1) {
      setLinkers(newSearchResult);
    }
    return () => {};
  }, [searchResult]);

  return (
    <>
      <RootPaper className='root_box'>
        <Box sx={{ p: '1.2rem' }}>
          <Typography className='title'>받는 사람</Typography>
          <div style={{ display: 'flex', marginTop: '.8rem' }}>
            <TextField
              select
              sx={{ width: '12rem', mr: '1rem' }}
              value={receiverClassification}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                setReceiverClassification(event.target.value);
                setGroupItems([]);
                if (receiverClassification !== 'os') setReceivers([]);
                else {
                  const newReceiver = {
                    id: 'android>',
                    phoneno: '',
                    name: 'android>',
                  };
                  setReceivers([newReceiver]);
                }
                setLocationLimit([]);
                setOsVersion('android>');
                setEmdInfo('');
              }}
            >
              {SwReceivers.map((receiver: any, idx: number) => (
                <MenuItem value={receiver.value} key={idx.toString()}>
                  {receiver.title}
                </MenuItem>
              ))}
            </TextField>
            <Box className='flex_start'>
              {(receiverClassification === 'challenge' ||
                receiverClassification === 'community' ||
                receiverClassification === 'program' ||
                receiverClassification === 'group' ||
                receiverClassification === 'reward' ||
                receiverClassification === 'user') && (
                <TextField
                  placeholder={`${
                    receiverClassification === 'challenge'
                      ? '챌린지 이름, 아이디'
                      : receiverClassification === 'community' ||
                        receiverClassification === 'group'
                      ? '커뮤니티 이름, 아이디'
                      : receiverClassification === 'program'
                      ? '프로그램 이름, 아이디'
                      : receiverClassification === 'reward'
                      ? '리워드 이름, 아이디'
                      : receiverClassification === 'user'
                      ? '사용자 핸드폰번호'
                      : ''
                  } 검색`}
                  inputRef={findReceiverRef}
                  sx={{ width: '44rem' }}
                  onKeyDown={evt => {
                    handleKeyDown(evt);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={() => {
                            searchTarget();
                          }}
                          sx={{ pr: 0 }}
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              {receiverClassification === 'user' && (
                <Button
                  color='info'
                  sx={{ ml: '1rem' }}
                  variant='contained'
                  onClick={() => {
                    setOpenLoadUser(1);
                  }}
                >
                  복사하여 업로드
                </Button>
              )}
            </Box>
            {(receiverClassification === 'region' ||
              receiverClassification === 'location') && (
              <Box>
                <SwPushSelectLocation
                  includeAmd={true}
                  selectOne={true}
                  showButton={false}
                  showDepth1={false}
                  location={locationLimit}
                  setLocation={setLocationLimit}
                  mapId='pushRegion_map'
                />
              </Box>
            )}
            {(receiverClassification === 'os' ||
              receiverClassification === 'osVersion') && (
              <Box>
                <SwPushSelectOsVersion
                  osVersion={osVersion}
                  setOsVersion={setOsVersion}
                  showVersion={receiverClassification === 'osVersion'}
                />
              </Box>
            )}
          </div>
          <Box>
            {receiverClassification === 'group' && groupItems.length > 0 && (
              <>
                <TextField
                  select
                  sx={{ width: '12rem', mr: '1rem' }}
                  value={group}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => {
                    setGroup(event.target.value);
                  }}
                >
                  {groupItems.map((item: any, idx: number) => (
                    <MenuItem
                      id={idx.toString()}
                      value={item.id}
                      key={idx.toString()}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  placeholder='그룹 이름, 아이디 검색'
                  inputRef={findGroupRef}
                  sx={{ width: '34rem' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          sx={{ pr: 0 }}
                          onClick={() => {
                            setTitle(['그룹']);
                            setSearchKey(receiverClassification);
                            setSearchWord(findGroupRef.current?.value);
                            setOpenType(0);
                            setOpenSearchResult(true);
                          }}
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            )}
          </Box>
          {receiverClassification !== 'user' &&
            receiverClassification !== 'region' &&
            receiverClassification !== 'location' &&
            receiverClassification !== 'os' &&
            receiverClassification !== 'osVersion' && (
              <Grid container sx={{ mt: '.5rem', width: '200rem ' }}>
                {receivers.length > 0 &&
                  receivers.map((receive: any, idx: number) => (
                    <Grid item xs={5} key={idx.toString()}>
                      <ReceiverChip
                        variant='outlined'
                        label={
                          <Box className='flex_start'>
                            <Box sx={{ mr: '.5rem' }}>{receive.name}</Box>
                            {receiverClassification === 'group' &&
                              title[0] === '그룹' && (
                                <span>
                                  {`(${community?.name})`}&nbsp;&nbsp;
                                </span>
                              )}
                            <Box className='bluecontent' sx={{ mr: '.5rem' }}>
                              {receive.id}
                            </Box>
                            <Box className='bluecontent'>
                              {`(${receive.count} 명)`}
                            </Box>
                          </Box>
                        }
                        onDelete={() => {
                          const newReceiver = receivers.filter(
                            (r: string, i: number) => i !== idx
                          );
                          setReceivers(newReceiver);
                        }}
                      />
                    </Grid>
                  ))}
              </Grid>
            )}
          {receiverClassification === 'user' && (
            <Paper
              sx={{
                backgroundColor: '#FCFCFC',
                p: '2rem 2rem 1rem',
              }}
            >
              <Box sx={{ height: '10rem', overflowY: 'scroll' }}>
                <Typography fontWeight={500}>개인 아이디 목록</Typography>
                {selectedUserList &&
                  selectedUserList.map((item: any, idx: number) => (
                    <UserBox key={idx.toString()}>
                      <Typography>{item}</Typography>
                      <ClearIcon
                        className='clear_icon_button'
                        onClick={() => deleteUserList(idx)}
                      />
                    </UserBox>
                  ))}
              </Box>
              <Box sx={{ textAlign: 'right' }}>
                <Button
                  color='error'
                  onClick={() => {
                    setSelectedUserList([]);
                  }}
                >
                  <DeleteOutlineIcon />
                  전체 삭제
                </Button>
              </Box>
            </Paper>
          )}
          {(receiverClassification === 'region' ||
            receiverClassification === 'location' ||
            receiverClassification === 'os' ||
            receiverClassification === 'osVersion') && (
            <ReceiverChip
              variant='outlined'
              label={<Box className='bluecontent'>{emdInfo}</Box>}
            />
          )}
          <Divider />
          {inputs.map((input, idx) => (
            <div key={idx.toString()}>
              <Typography className='title'>{input.id}</Typography>
              <TextField
                id={input.id}
                placeholder={input.placeholder}
                value={input.value}
                helperText={input.helperText}
                fullWidth
                sx={{ mt: '.8rem' }}
                onChange={onChangeText}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <span>
                        {input.value.length} / {input.id === '제목' ? 50 : 70}
                      </span>
                    </InputAdornment>
                  ),
                }}
              />
              <Divider />
            </div>
          ))}
          <Typography className='title'>링크 이동</Typography>
          <Typography
            sx={{
              fontSize: '.75rem',
              color: '#999999',
              m: '-.8rem 0 1rem .15rem',
            }}
          >
            푸시 알림 클릭 시 이동할 곳을 골라주세요.
          </Typography>
          <div>
            <TextField
              select
              defaultValue='home'
              value={pushLink}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                setPushLink(event.target.value);
                setLinkers([]);
              }}
              sx={{ width: '12rem', mr: '1rem' }}
            >
              {push.map((q, idx) => (
                <MenuItem value={q.value} key={idx.toString()}>
                  {q.title}
                </MenuItem>
              ))}
            </TextField>
            {(pushLink === 'challenge' ||
              pushLink === 'community' ||
              pushLink === 'program' ||
              pushLink === 'questionanswer' ||
              pushLink === 'reward' ||
              pushLink === 'url') && (
              <TextField
                placeholder={`${
                  pushLink === 'challenge'
                    ? '챌린지'
                    : pushLink === 'community'
                    ? '커뮤니티'
                    : pushLink === 'program'
                    ? '프로그램'
                    : pushLink === 'questionanswer'
                    ? '질의응답'
                    : pushLink === 'reward'
                    ? '리워드'
                    : 'url를 입력해주세요.'
                } ${pushLink !== 'url' ? '이름, 아이디 검색' : ''}`}
                sx={{ width: '44rem' }}
                inputRef={pushLinkRef}
                InputProps={{
                  endAdornment: pushLink !== 'url' && (
                    <InputAdornment position='end'>
                      <IconButton
                        sx={{ pr: 0 }}
                        onClick={() => {
                          const selected = push
                            .map(item => {
                              if (item.value === pushLink) return item.title;
                              return '';
                            })
                            .filter(value => value !== '');
                          console.log('title :', selected);
                          setTitle(selected);
                          setSearchKey(pushLink);
                          setSearchWord(pushLinkRef.current?.value);
                          setOpenType(1);
                          setOpenSearchResult(true);
                        }}
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </div>
          <Grid container sx={{ mt: '.5rem', width: '120rem ' }}>
            {linkers.length > 0 &&
              linkers.map((linker: any, idx: number) => (
                <Grid item xs={5} key={idx.toString()}>
                  <LinkerChip
                    variant='outlined'
                    label={
                      <>
                        <span>{linker.name}&nbsp;&nbsp;</span>
                        <span className='bluecontent'>{linker.id}</span>
                      </>
                    }
                    onDelete={() => {
                      const newLinkers = linkers.filter(
                        (r: string, i: number) => i !== idx
                      );
                      setLinkers(newLinkers);
                    }}
                  />
                </Grid>
              ))}
          </Grid>
          <Divider />
          <Box
            className='flex_center'
            sx={{ p: '.5rem', pl: '.15rem', mb: '.33rem' }}
          >
            <Typography sx={{ fontWeight: 500 }}>
              적용 일시 {!!pushDates.length && `(${pushDates.length}개)`}
            </Typography>
            <Checkbox
              checked={!isReservation}
              onChange={() => setIsReservation(!isReservation)}
              sx={{ p: 0, ml: '2rem' }}
            />
            <Typography>즉시 발송</Typography>
          </Box>
          {isReservation && (
            <Stack
              direction={{ xs: 'column', sm: 'column', md: 'row' }}
              divider={
                <Divider
                  orientation='vertical'
                  flexItem
                  sx={{ p: 0, m: '0rem 3rem 0' }}
                />
              }
            >
              <SwDatesPickerWithScroll
                dates={pushDates}
                setDates={setPushDates}
                allowPastDate={false}
                onChangeDate={doChangeDate}
              />
              <Box>
                <Typography sx={{ fontWeight: 700 }}>
                  예약된 푸시 알림
                </Typography>
                {registeredTime.length > 0
                  ? registeredTime.map((item: any) => (
                      <Box className=''>
                        <Typography>
                          {timestamp2Localestring(item, 1000)}
                        </Typography>
                      </Box>
                    ))
                  : '데이터 없음'}
              </Box>
            </Stack>
          )}
          <Divider />
          <Typography className='title'>테스트</Typography>
          <Box sx={{ m: '1rem 0', display: 'flex' }}>
            <TextField
              placeholder='사용자 ID를 입력해 주세요.'
              inputRef={testIdRef}
              sx={{ width: '28rem', mb: 0 }}
            />
            <Button
              sx={{ ml: '.7rem' }}
              variant='outlined'
              onClick={() => {
                sendPush(0);
              }}
            >
              전송
            </Button>
          </Box>
        </Box>
      </RootPaper>
      <Box className='flex_between'>
        <Box className='flex_start'>
          <Button
            sx={{ mr: '1rem', width: '5rem' }}
            variant='contained'
            color='secondary'
            onClick={() => {
              window.location.reload();
            }}
          >
            초기화
          </Button>
          {/* <Button
            sx={{ mr: '1rem', width: '5rem' }}
            variant='contained'
            color='secondary'
            onClick={() => {
              generateRecentLocation();
            }}
          >
            최근 위치 데이터 생성
          </Button> */}
        </Box>
        <Button
          sx={{ width: '9rem' }}
          variant='contained'
          color='info'
          onClick={() => {
            confirmSendPush();
          }}
        >
          발송
        </Button>
      </Box>
      {openMore === 1 && (
        <SwSnackbar
          open={!!openMore}
          onClose={() => {
            setOpenMore(0);
            setSnackbarContents('');
          }}
          contents={snackbarContents}
        />
      )}
      {openMore === 2 && (
        <Dialog
          open={!!openMore}
          sx={{
            '& .MuiDialog-container': {
              height: '85%',
            },
          }}
        >
          <Divider sx={{ p: 0, m: 0 }} />
          <DialogContent sx={{ p: '1rem 2rem' }}>
            <Typography
              sx={{ mb: '0.7rem' }}
            >{`'${receivers[0].name}'에게`}</Typography>
            {sendDates.map((input, idx) => (
              <Box key={idx.toString()}>
                <Typography>{input}</Typography>
              </Box>
            ))}
            <Typography sx={{ mt: '0.7rem' }}>
              푸시 알림을 발송하시겠습니까?
            </Typography>
            {loading && <SwLoading />}
          </DialogContent>
          <Divider sx={{ p: 0, m: 0 }} />
          <DialogActions>
            <Button
              color='info'
              fullWidth
              size='small'
              onClick={() => setOpenMore(0)}
            >
              취소
            </Button>
            <Button
              color='primary'
              fullWidth
              size='small'
              onClick={() => sendPush(1)}
            >
              완료
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {loading && <SwLoading />}
      <SwSearchTarget
        title={title !== null ? title[0] : ''}
        searchKey={searchKey}
        searchWord={searchWord}
        open={openSearchResult}
        onClose={() => {
          setOpenSearchResult(false);
        }}
        targetData={
          receiverClassification === 'group' && title[0] === '그룹'
            ? groupItems
            : []
        }
        setTarget={setSearchResult}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={alertMessage}
      />
      <SwLoadUser
        open={!!openLoadUser}
        onclose={() => {
          setOpenLoadUser(0);
        }}
        setLoadList={setLoadUserList}
        maxSize={MAX_LOAD_USER_COUNT}
      />
    </>
  );
};
