import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CachedIcon from '@mui/icons-material/Cached';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { ChangeEvent, useEffect, useState, Dispatch } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { MultiSelect } from 'react-multi-select-component';
import { timestamp2Localestring } from '../../../common/helper';
import { SwDateRangePicker } from '../dateSetting/SwDateRangePicker';

interface Props {
  openType: string;
  open: boolean;
  onClose: () => void;
  filter: any;
  setFilter: Dispatch<React.SetStateAction<any>>;
}

const columns = ['아이디', '이름'];

const locationFilterItems = [
  '위치 필터 선택',
  '위치 필터 있음',
  '위치 필터 없음',
];

const regionFilterItems = [
  '지역 필터 선택',
  '지역 필터 있음',
  '지역 필터 없음',
];

export const SwQuestionAnswerFilter: React.FC<Props> = ({
  openType,
  open,
  onClose,
  filter,
  setFilter,
}) => {
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [qnaType, setQnaType] = useState<number[]>([0, 0]); // 0: none, 1 : 설문/퀴즈 선택, 2: 설문/퀴즈 선택안함
  const [hasReward, setHasReward] = useState<number[]>([0, 0]); // 0 : none, 1: 있음, 2: 없음
  const [selectDurationType, setSelectDurationType] = useState<string>('all');

  const onApply = (row: any) => {
    let newRangeDate = [0, 0];
    if (selectDurationType === 'week1') {
      const now = new Date();
      const oneWeekAgo = new Date(now);
      oneWeekAgo.setDate(now.getDate() + 7);
      newRangeDate = [
        Math.floor(now.getTime() / 1000),
        Math.floor(oneWeekAgo.getTime() / 1000),
      ];
    } else if (selectDurationType === 'month1') {
      const now = new Date();
      const oneWeekAgo = new Date(now);
      oneWeekAgo.setDate(now.getDate() + 30);
      newRangeDate = [
        Math.floor(now.getTime() / 1000),
        Math.floor(oneWeekAgo.getTime() / 1000),
      ];
    } else {
      newRangeDate =
        rangeDate[0] !== null && rangeDate[1] !== null
          ? [
              Math.floor(rangeDate[0].getTime() / 1000),
              Math.floor(rangeDate[1].getTime() / 1000),
            ]
          : [0, 0];
    }
    var newType: number = 0;
    // eslint-disable-next-line no-bitwise
    if (qnaType[0] === 0 || qnaType[0] === 1) newType |= 0x01;
    // eslint-disable-next-line no-bitwise
    if (qnaType[1] === 0 || qnaType[1] === 1) newType |= 0x02;

    var newHasReward: number = 0;
    // eslint-disable-next-line no-bitwise
    if (hasReward[0] === 0 || hasReward[0] === 1) newHasReward |= 0x01;
    // eslint-disable-next-line no-bitwise
    if (hasReward[1] === 0 || hasReward[1] === 1) newHasReward |= 0x02;

    const newFilter = {
      rangeDate: newRangeDate,
      type: newType,
      hasReward: newHasReward,
    };
    console.log('filter : ', newFilter);
    setFilter(newFilter);
    onClose();
  };

  const resetFilter = () => {
    setRangeDate([null, null]);
    setQnaType([0, 0]);
    setHasReward([0, 0]);
    setSelectDurationType('all');
  };

  const setupFilter = () => {
    const newRangeDate = [];
    newRangeDate[0] = timestamp2Localestring(filter?.rangeDate[0], 1000);
    newRangeDate[1] = timestamp2Localestring(filter?.rangeDate[1], 1000);
    setRangeDate(newRangeDate);
    setHasReward([0, 0]);
    setQnaType([0, 0]);
    setSelectDurationType('all');
  };

  useEffect(() => {
    console.log('SwQuestionAnswerFilter initial !!!');
    //  resetFilter();
  }, [open]);

  return (
    <Dialog
      open={open}
      fullScreen
      sx={{
        '& .MuiDialog-paper': {
          height: '55vh',
          margin: '10rem',
        },
      }}
    >
      <DialogTitle>
        <Box className='flex_between'>
          <Box>
            <span>검색 필터 선택</span>
            <IconButton
              sx={{ ml: '1rem', fontSize: '0.937rem' }}
              onClick={resetFilter}
            >
              <CachedIcon />
              <span>초기화</span>
            </IconButton>
          </Box>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogContent>
        <Box>
          <Typography sx={{ fontWeight: 700 }}>진행 기간</Typography>
          <Box className='flex_start'>
            <Button
              color={selectDurationType === 'all' ? 'primary' : 'info'}
              variant={selectDurationType === 'all' ? 'outlined' : 'contained'}
              sx={{ mr: '.5rem' }}
              onClick={() => {
                setSelectDurationType('all');
              }}
            >
              전체기간
            </Button>
            <Button
              color={selectDurationType === 'week1' ? 'primary' : 'info'}
              variant={
                selectDurationType === 'week1' ? 'outlined' : 'contained'
              }
              sx={{ mr: '.5rem' }}
              onClick={() => {
                setSelectDurationType('week1');
              }}
            >
              일주일
            </Button>
            <Button
              color={selectDurationType === 'month1' ? 'primary' : 'info'}
              variant={
                selectDurationType === 'month1' ? 'outlined' : 'contained'
              }
              sx={{ mr: '.5rem' }}
              onClick={() => {
                setSelectDurationType('month1');
              }}
            >
              1개월
            </Button>
            <Box
              onClick={() => {
                setSelectDurationType('custom');
              }}
            >
              <SwDateRangePicker
                rangeDate={rangeDate}
                setRangeDate={setRangeDate}
              />
            </Box>
          </Box>
        </Box>
        <Box>
          <Box sx={{ m: '2rem 0' }}>
            <Typography sx={{ fontWeight: 700 }}>타입</Typography>
            <Box className='flex_start'>
              <Box className='flex_start'>
                <Checkbox
                  checked={qnaType[0] === 1}
                  onChange={e => {
                    const newQnaType = [...qnaType];
                    newQnaType[0] =
                      newQnaType[0] === 0 || newQnaType[0] === 2 ? 1 : 2;
                    setQnaType(newQnaType);
                  }}
                />
                <Typography className='title'>설문조사</Typography>
              </Box>
              <Box className='flex_start'>
                <Checkbox
                  checked={qnaType[1] === 1}
                  onChange={e => {
                    const newQnaType = [...qnaType];
                    newQnaType[1] =
                      newQnaType[1] === 0 || newQnaType[1] === 2 ? 1 : 2;
                    setQnaType(newQnaType);
                  }}
                />
                <Typography className='title'>퀴즈</Typography>
              </Box>
            </Box>
          </Box>
          {openType === 'list' && (
            <Box sx={{ m: '2rem 0' }}>
              <Typography sx={{ fontWeight: 700 }}>리워드 지급여부</Typography>
              <Box className='flex_start'>
                <Box className='flex_start'>
                  <Checkbox
                    checked={hasReward[0] === 1}
                    onChange={e => {
                      const newHasReward = [...hasReward];
                      newHasReward[0] =
                        newHasReward[0] === 0 || newHasReward[0] === 2 ? 1 : 2;
                      setHasReward(newHasReward);
                    }}
                  />
                  <Typography className='title'>지급</Typography>
                </Box>
                <Box className='flex_start'>
                  <Checkbox
                    checked={hasReward[1] === 1}
                    onChange={e => {
                      const newHasReward = [...hasReward];
                      newHasReward[1] =
                        newHasReward[1] === 0 || newHasReward[1] === 2 ? 1 : 2;
                      setHasReward(newHasReward);
                    }}
                  />
                  <Typography className='title'>미지급</Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
      <Divider sx={{ m: 0 }} />
      <DialogActions sx={{ p: '1rem' }}>
        <Button
          variant='contained'
          color='primary'
          sx={{ fontSize: '.87rem' }}
          onClick={onApply}
        >
          적용하기
        </Button>
      </DialogActions>
    </Dialog>
  );
};
