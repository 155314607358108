import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { Box, Button } from '@mui/material';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { useRecoilState } from 'recoil';
import { useLocation } from 'react-router';
import { AxiosError } from 'axios';
import { UserInfoDetail } from './UserInfoDetail';
import { EmptyPage } from '../../commonComponent/table/EmptyPage';
import { debouncehook } from '../../../hooks/debouncehook';
import { UserInquireTable } from './UserInquireTable';
import { UserEmailAdd } from './UserEmailAdd';
import { IUser } from '../../../models/user';
import { SelectSearchBox } from '../../commonComponent/SelectSearchBox';
import { xapi } from '../../../hooks/session';
import {
  getUserList,
  patchUserEmail,
  downloadUserList,
} from '../../../common/api/ApiUser';
import { KEY_USER_LIST, KEY_USER_EMAIL_CHANGE } from '../../../common/key';
import { RESULT_OK } from '../../../common/resultCode';
import { encodeSearchWord } from '../../../common/helper';
import { selectedUserInfoState } from '../../../common/atom';
import { SwSelectSearchUser } from '../../commonComponent/SwSelectSearchUser';

interface Props {}

const selectVal = [
  {
    title: '아이디',
    value: 'id',
  },
  { title: '닉네임', value: 'name' },
  { title: '연락처', value: 'phoneNumber' },
  { title: '이메일', value: 'email' },
];

const columns = [
  {
    name: '닉네임',
    id: 'name',
  },
  {
    name: '아이디',
    id: 'id',
  },
  {
    name: '연락처',
    id: 'phoneNumber',
  },
  {
    name: '이메일',
    id: 'email',
  },
  {
    name: '성별',
    id: 'gender',
  },
  {
    name: '나이',
    id: 'age',
  },
  {
    name: '주소',
    id: 'address',
  },
  {
    name: '가입 일시',
    id: 'joinDate',
  },
  {
    name: '최근 업데이트',
    id: 'recentUpdate',
  },
  {
    name: '단말 모델정보',
    id: 'deviceModel',
  },
  {
    name: '앱 버전정보',
    id: 'appVersion',
  },
  {
    name: '상세보기',
    id: 'detail',
  },
  {
    name: '사용자 정보',
    id: 'more',
  },
  {
    name: '로그 가져오기',
    id: 'log',
  },
];

export const UserInquire: React.FC<Props> = () => {
  const [selectedUserInfo, setSelectedUserInfo] = useRecoilState(
    selectedUserInfoState
  );
  const location = useLocation();
  const queryClient = useQueryClient();
  const emailRef = useRef<any>();
  const selectRef = useRef<any>('phoneNumber');
  const searchRef = useRef<any>();
  const fileInputRef = useRef<any>();
  const [searchKey, setSearchKey] = useState<string>('phoneNumber');
  const [searchWord, setSearchWord] = useState<string>('');
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [currentRow, setCurrentRow] = useState<string>('');
  const [openEmailAdd, setOpenEmailAdd] = useState<boolean>(false);
  const [userDataforInfoDetail, setUserDataforInfoDetail] = useState<any>([]);
  const [showUserInfoDetail, setShowUserInfoDetail] = useState<boolean>(false);
  const [list, setList] = useState<any>([]);
  const [uOpen, setUOpen] = useState(false);
  const uu = () => setUOpen(!uOpen);

  const {
    data,
    isLoading,
    refetch: refetchUsers,
  } = useQuery(
    KEY_USER_LIST,
    () => {
      if (searchWord !== '') {
        return getUserList(
          page,
          rowsPerPage,
          searchKey,
          encodeSearchWord(searchKey, searchWord)
        );
      }
      return null;
    },
    {
      onSuccess: res => {
        if (res !== null) {
          setList(res.user);
          setTotalRecords(res.pagination.totalRecordCount);
        } else {
          setList([]);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetchUsers = () => {
    queryClient.prefetchQuery(KEY_USER_LIST, () => {
      if (searchWord !== '') {
        return getUserList(
          page,
          rowsPerPage,
          searchKey,
          encodeSearchWord(searchKey, searchWord)
        );
      }
      return null;
    });
  };

  const isSearching = () => {
    if (searchWord !== null && searchWord !== '') {
      setSelectedUserInfo((prevState: any) => ({
        ...prevState,
        type: searchKey,
        value: searchWord,
      }));
    } else {
      setSelectedUserInfo((prevState: any) => ({
        ...prevState,
        type: 'phoneNumber',
        value: '',
      }));

      setSearchKey('');
      setSearchWord('');
    }
    refetchUsers();
  };

  const { mutate: userEmailMutate, isError: isRoleMutateError } = useMutation(
    patchUserEmail,
    {
      onSuccess: res => {
        setOpenEmailAdd(false);
        prefetchUsers();
      },
      onError: error => {
        console.log(error);
      },
    }
  );

  const changeEmail = () => {
    if (emailRef.current.value) {
      const newUser = {
        name: '',
        phoneNumber: '',
        email: emailRef.current.value,
        id: userDataforInfoDetail.id,
      };
      userEmailMutate(newUser);
    }
  };

  const uploadExcelFile = (evt: ChangeEvent<HTMLInputElement>) => {
    const excelFile = evt.target.files && evt.target.files[0];

    var formData = new FormData();
    if (excelFile !== null) formData.append('file', excelFile);

    xapi
      .post(`/admin/v2/apis/upload/user/register-email`, formData)
      .then(res => {
        fileInputRef.current.value = '';
        if (res.status !== 200) throw new Error('http 에러');
        return res.data;
      });
  };

  const onDownloadFile = () => {
    downloadUserList(searchKey, encodeSearchWord(searchKey, searchWord));
  };

  // function exeDeepLink() {
  //   const urlPath = searchRef.current.value;
  //   window.open(urlPath, '_blank');
  // }

  useEffect(() => {
    if (location.state === undefined || location.state === null) {
      setSelectedUserInfo((prevState: any) => ({
        ...prevState,
        type: 'phoneNumber',
        value: '',
      }));

      setSearchKey('phoneNumber');
      setSearchWord('');

      setList([]);
      setTotalRecords(0);
    }
  }, [location]);

  useEffect(() => {
    if (selectedUserInfo && location.state !== null) {
      setSearchKey(selectedUserInfo.type);
      setSearchWord(selectedUserInfo.value);
      setTimeout((_: any) => {
        refetchUsers();
      }, 500);
    }
  }, []);

  return (
    <Box className='root_box'>
      <Box className='flex_between'>
        <SwSelectSearchUser
          searchOption={selectVal}
          searchWord={searchWord}
          isSearching={isSearching}
          searchKey={searchKey}
          setSearchWord={setSearchWord}
          setSearchKey={setSearchKey}
        />
        {/* <Button
          color='info'
          variant='outlined'
          sx={{ mr: '1rem', p: '.2rem 1rem' }}
          onClick={(evt: any) => {
            exeDeepLink();
          }}
        >
          앱 링크 테스트
          <FileDownloadRoundedIcon color='success' />
        </Button> */}
        <Box className='flex_start'>
          <Button
            color='info'
            variant='outlined'
            sx={{ mr: '1rem', p: '.2rem 1rem' }}
            onClick={(evt: any) => {
              onDownloadFile();
            }}
          >
            데이터 다운로드
            <FileDownloadRoundedIcon color='success' />
          </Button>
          {/* <Box sx={{ mr: '2rem' }}>
            <Button
              variant='contained'
              color='info'
              onClick={() => {
                const gpxfile = document.getElementById('user_account_excel');
                gpxfile?.click();
              }}
            >
              엑셀 업로드
            </Button>
            <input
              className='nonedisplay'
              id='user_account_excel'
              type='file'
              ref={fileInputRef}
              onChange={uploadExcelFile}
            />
          </Box> */}
        </Box>
      </Box>
      {/* <Button onClick={uu}>더보기</Button> */}
      {list.length === 0 ? (
        <EmptyPage />
      ) : (
        <UserInquireTable
          columns={columns}
          rows={list}
          totalCount={totalRecords}
          rowsPerPage={rowsPerPage}
          searchData={[searchKey, searchWord]}
          onAddEmail={() => setOpenEmailAdd(true)}
          setUserData={setUserDataforInfoDetail}
          isOpenUserDetail={() => {
            setShowUserInfoDetail(!showUserInfoDetail);
          }}
        />
      )}
      {/* <Button onClick={changeEmail}>email</Button> */}
      <UserInfoDetail
        open={showUserInfoDetail}
        userInfo={userDataforInfoDetail}
        onClose={() => {
          setShowUserInfoDetail(!showUserInfoDetail);
          isSearching();
        }}
      />
      <UserEmailAdd
        email={emailRef}
        open={openEmailAdd}
        onClose={() => {
          setOpenEmailAdd(false);
        }}
        onConfirm={() => {
          changeEmail();
        }}
      />
    </Box>
  );
};
