import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState, Dispatch } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { getProgramTemplateCodeList } from '../../../common/api/ApiProgram';
import {
  KEY_CONTENT_MAMANGE_KEYWORD_LIST,
  KEY_PROGRAM_TEMPLATE_CODE_LIST,
} from '../../../common/key';
import { getContentManageKeywordList } from '../../../common/api/ApiContentManage';

interface Props {
  open: boolean;
  onClose: () => void;
  type: number;
  keywordInfo: any;
  setKeywordInfo: Dispatch<React.SetStateAction<any>>;
}

export const SwSelectKeyword: React.FC<Props> = ({
  open,
  onClose,
  type,
  keywordInfo,
  setKeywordInfo,
}) => {
  const [keywordList, setKeywordList] = useState<any[]>([]);
  const [checkedButtons, setCheckedButtons] = useState<string[]>([]);

  const { refetch: contentManageKeywordRefetch } = useQuery(
    KEY_CONTENT_MAMANGE_KEYWORD_LIST,
    () => {
      const newData: any = {
        type: type,
      };
      if (open) return getContentManageKeywordList(newData);
      return null;
    },
    {
      onSuccess: res => {
        if (res !== null) {
          console.log('CMS keyword :', res);
          setKeywordList(res.keyword);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const addKeyword = () => {
    const newKeywordList: any[] = [];

    keywordList.map((item: any) => {
      item.items.map((keyword: any) => {
        checkedButtons.includes(keyword.title)
          ? newKeywordList.push(keyword)
          : '';
      });
    });

    setKeywordInfo(newKeywordList);
    onClose();
  };

  const changeHandler = (checked: boolean, id: string) => {
    if (checked) {
      setCheckedButtons([...checkedButtons, id]);
    } else {
      setCheckedButtons(checkedButtons.filter(button => button !== id));
    }
  };

  useEffect(() => {
    if (open) {
      if (keywordInfo.length > 0) {
        const newCheckButtons = keywordInfo.map((item: any) => item.title);
        setCheckedButtons(newCheckButtons);
      } else {
        setCheckedButtons([]);
      }
      contentManageKeywordRefetch();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      fullScreen
      sx={{
        '& .MuiDialog-paper': {
          height: '60rem',
          width: '40rem',
        },
      }}
    >
      <DialogTitle>
        <Box className='flex_between'>
          <Box className='flex_start'>
            <Typography variant='h4' sx={{ fontWeight: 500 }}>
              키워드 추가하기
            </Typography>
            <Typography
              variant='h4'
              sx={{ fontWeight: 500, color: '#9EA2A9', ml: '1rem' }}
            >
              {keywordList !== undefined &&
                keywordList.length > 0 &&
                `${keywordList[0].title}`}
            </Typography>
          </Box>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogContent>
        {keywordList.map((item: any, idx: number) => (
          <Box key={`keyword_list_${idx + 1}`}>
            <Typography variant='h3' sx={{ fontWeight: 700, m: '1rem 0rem' }}>
              {item.subTitle}
            </Typography>
            <FormGroup sx={{ flexDirection: 'row' }}>
              {item.items.map((keyword: any, i: number) => (
                <FormControlLabel
                  key={`form_list_${i + 1}`}
                  control={
                    <Checkbox
                      value={idx}
                      sx={{ color: '#808383' }}
                      onChange={e => {
                        changeHandler(e.currentTarget.checked, keyword.title);
                      }}
                      checked={checkedButtons.includes(keyword.title)}
                    />
                  }
                  label={keyword.title}
                />
              ))}
            </FormGroup>
          </Box>
        ))}
      </DialogContent>
      <DialogActions sx={{ p: '1rem' }}>
        <Button
          variant='contained'
          color='info'
          sx={{ fontSize: '.87rem' }}
          onClick={addKeyword}
        >
          추가
        </Button>
      </DialogActions>
    </Dialog>
  );
};
