import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ChangeEvent, useEffect, useState, Dispatch } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useMany } from '../../hooks/api';
import { getDataPortalAccountAmdList } from '../../common/api/ApiDataPortal';
import { KEY_DATAPORTAL_ACCOUNT_AMD_LIST } from '../../common/key';
import { SwConfirmDialog } from './views/SwConfirmDialog';

interface Props {
  open: boolean;
  onClose: () => void;
  onChangePayInfo: (payInfo: any) => void;
}

export const SwUserPayBrandPoint: React.FC<Props> = ({
  open,
  onClose,
  onChangePayInfo,
}) => {
  const [payType, setPayType] = useState<string>('payment');
  const [point, setPoint] = useState<number>(0);
  const [message, setMessage] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);

  const handleChangePayType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPayType((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    setPoint(0);
    setMessage('');
    setPayType('payment');
  }, [open]);

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          height: '35vh',
          width: '40vh',
        },
      }}
    >
      <DialogTitle>
        <Box className='flex_between'>
          <span>포인트 수동 지급/차감</span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogContent sx={{ p: '1rem 2rem' }}>
        <Box>
          <RadioGroup
            row
            aria-labelledby='auth-row-radio-buttons-group-label'
            name='auth-radio-buttons-group'
            value={payType}
            onChange={handleChangePayType}
          >
            <FormControlLabel
              value='payment'
              control={<Radio />}
              label='지급'
            />
            <FormControlLabel
              value='deduction'
              control={<Radio />}
              label='차감'
            />
          </RadioGroup>
        </Box>
        <Typography sx={{ fontWeight: 'bold', mt: '0.7rem' }}>
          포인트 값을 입력해주세요.
        </Typography>
        <TextField
          placeholder='숫자 입력'
          sx={{
            width: '100%',
            mt: '0.71rem',
            mb: 0,
            input: {
              textAlign: 'left',
            },
          }}
          type='number'
          value={point}
          onChange={(
            event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            setPoint(Number(event.target.value));
          }}
        />
        <Typography sx={{ fontWeight: 'bold', mt: '0.7rem' }}>
          내역에 표시될 내용을 입력해주세요
        </Typography>
        <TextField
          placeholder='텍스트 입력(30자)'
          sx={{
            width: '100%',
            mt: '0.71rem',
            mb: 0,
            input: {
              textAlign: 'left',
            },
          }}
          value={message}
          onChange={(
            event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            setMessage(event.target.value);
          }}
        />
      </DialogContent>
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogActions>
        <Box className='flex_end'>
          <Button
            variant='outlined'
            size='small'
            onClick={() => {
              setDialogMessage(
                payType === 'payment'
                  ? `${point}포인트를 지급하시겠습니까?`
                  : `${point}포인트를 차감하시겠습니까?`
              );
              setOpenConfirm(true);
            }}
          >
            완료
          </Button>
        </Box>
      </DialogActions>
      <SwConfirmDialog
        contents={dialogMessage}
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => {
          onChangePayInfo({
            payType:
              payType === 'payment' ? 1 : payType === 'deduction' ? 2 : 0,
            point: point,
            message: message,
          });
          setOpenConfirm(false);
        }}
        confirm={1}
      />
    </Dialog>
  );
};
