import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Paper,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { ChangeEvent, useEffect, useState, Dispatch, useRef } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { v4 as uuidv4 } from 'uuid';
import { useMany } from '../../../hooks/api';
import { getDataPortalAccountAmdList } from '../../../common/api/ApiDataPortal';
import {
  KEY_DATAPORTAL_ACCOUNT_AMD_LIST,
  KEY_PROGRAM_MISSION_LIFESTYLE_DETAIL_INFO,
} from '../../../common/key';
import {
  authAvailableTime,
  countArr,
  getTimeArray,
  getAuthAvailableTime,
  pointPerAchieve,
  authAutoType,
  IMAGE_SIZE_10MB_MESSAGE,
} from './SwProgramCommon';
import { SwFileSetting } from '../SwFileSetting';
import { SwSnackbar } from '../views/SwSnackbar';
import { SwAlert } from '../views/SwAlert';
import { useSession } from '../../../hooks/session';
import { checkNaN, generateUuidId } from '../../../common/helper';
import { IUploadFile } from '../../../models/common';
import { postUploadFile } from '../../../common/api/ApiCommon';
import { RESULT_OK } from '../../../common/resultCode';
import {
  getProgramMissionDetailInfo,
  postProgramMissionLifeStyle,
  putProgramCurriculumMissionDelete,
} from '../../../common/api/ApiProgram';
import { uploadFile } from '../../../common/api/ApiUpload';
import { SwSelectTemplate } from './SwSelectTemplate';
import { MultilineTextField } from '../../styles/Styles';
import { SwConfirmDialog } from '../views/SwConfirmDialog';
import SwLoading from '../spinner/SwLoading';
import { getFileSize } from '../../../common/helperProgram';
import { SwWeekAndTimePicker } from '../dateSetting/SwWeekAndTimePicker';

const GrayTypography = styled(Typography)({
  color: '#858C97',
});

interface Props {
  data: any;
  setData: Dispatch<React.SetStateAction<any>>;
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  disabled?: boolean;
}

export const SwMissionLifeStyle: React.FC<Props> = ({
  data,
  setData,
  open,
  onClose,
  onSave,
  disabled = false,
}) => {
  const { loginVal } = useSession();
  const titleRef = useRef<any>();
  const [title, setTitle] = useState<string>('');
  const [titleHelperText, setTitleHelperText] =
    useState<string>('20자 이내로 작성해주세요!');
  const [point, setPoint] = useState<number>(10);
  const [achieveCount, setAchieveCount] = useState<number>(1);
  const [inputType, setInputType] = useState<string>('direct');
  const [content, setContent] = useState<string>('');
  const [coverImage1, setCoverImage1] = useState<string>('');
  const [coverFile1, setCoverFile1] = useState<any>(null);
  const [coverImage2, setCoverImage2] = useState<string>('');
  const [coverFile2, setCoverFile2] = useState<any>(null);
  const [imageUrlPrefix, setImageUrlPrefix] = useState<any>('');
  const [detailViewType, setDetailViewType] = useState<string>('magazine');
  const [youtubeKey, setYoutubeKey] = useState<string>('');
  const [link, setLink] = useState<string>('');
  const [magazineTypeDepth1, setMagazineTypeDepth1] = useState<number>(10);
  const [magazineTypeDepth2, setMagazineTypeDepth2] = useState<number>(10);
  const [magazineTypeDepth3, setMagazineTypeDepth3] = useState<number>(10);
  const [magazineTypeDepth4, setMagazineTypeDepth4] = useState<number>(10);
  const [authTime, setAuthTime] = useState<string>('direct');
  const [authDirect1, setAuthDirect1] = useState<string>('00:00');
  const [authDirect2, setAuthDirect2] = useState<string>('00:00');
  const [authType, setAuthType] = useState<string>('required-auth');
  const [authAuto, setAuthAuto] = useState<string>('step');
  const [authAutoValue, setAuthAutoValue] = useState<string>('0');
  const [authCode, setAuthCode] = useState<string>('');
  const [authGuide, setAuthGuide] = useState<string>('');
  const [authSuccessImage1, setAuthSuccessImage1] = useState<string>('');
  const [authSuccessFile1, setAuthSuccessFile1] = useState<any>(null);
  const [authSuccessImage2, setAuthSuccessImage2] = useState<string>('');
  const [authSuccessFile2, setAuthSuccessFile2] = useState<any>(null);
  const [authFailImage1, setAuthFailImage1] = useState<string>('');
  const [authFailFile1, setAuthFailFile1] = useState<any>(null);
  const [authFailImage2, setAuthFailImage2] = useState<string>('');
  const [authFailFile2, setAuthFailFile2] = useState<any>(null);
  const [openTemplate, setOpenTemplate] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openChangeInputType, setOpenChangeInputType] = useState<number>(0);
  const [uploadUrl, setUploadUrl] = useState<any>();
  const [missionId, setMissionId] = useState<string>('');
  const [weekId, setWeekId] = useState<string>('');
  const [showDetailView, setShowDetailView] = useState<boolean>(false);
  const [missionDetailInfo, setMissionDetailInfo] = useState<any>(null);
  const [isEdit, setIsEdit] = useState<number>(0);
  const [selectTarget, setSelectTarget] = useState<any>();
  const [loading, setLoading] = useState<any>(false);
  const [hasWeekAndTime, setHasWeekAndTime] = useState<string>('no');
  const [attendWeeks, setAttendWeeks] = useState<any[]>([]);
  const [attendTimes, setAttendTimes] = useState<any[]>([]);

  const reset = () => {
    setTitle('');
    setPoint(10);
    setAchieveCount(1);
    setContent('');
    setAuthGuide('');
    setAuthType('required-auth');
    setCoverImage1('');
    setCoverImage2('');
    setAuthSuccessImage1('');
    setAuthSuccessImage2('');
    setAuthFailImage1('');
    setAuthFailImage2('');
    setCoverFile1(null);
    setCoverFile2(null);
    setAuthSuccessFile1(null);
    setAuthSuccessFile2(null);
    setAuthFailFile1(null);
    setAuthFailFile2(null);
    setInputType('direct');
    setSelectTarget([]);
    setAuthCode('');
    setHasWeekAndTime('no');
    setAttendWeeks([]);
    setAttendTimes([]);
  };

  const resetTemplate = () => {
    setSelectTarget([]);
    setPoint(10);
  };

  const makeFormData = (mission: any, newInputType: number) => {
    newInputType === 1 ? setInputType('direct') : setInputType('template');
    setTitle(mission.title);
    setPoint(newInputType === 1 ? mission.successScore : 10);
    setAchieveCount(newInputType === 1 ? mission.successCount : 1);
    setContent(newInputType === 1 ? mission.content : mission.description);
    setAuthGuide(mission.certificateDesc);

    const newAuthType =
      mission.certificateType === 1
        ? 'required-auth'
        : mission.certificateType === 2
        ? 'optional-auth'
        : mission.certificateType === 3
        ? 'simple-auth'
        : mission.certificateType === 4
        ? 'auth-auto'
        : mission.certificateType === 5
        ? 'code-auth'
        : 'required-auth';
    setAuthType(newAuthType);
    if (newAuthType === 'auth-auto') {
      const newAuthAuto = authAutoType.filter(
        (item: any, idx: number) => idx + 1 === mission.certificateTypeItem
      );
      setAuthAuto(
        newAuthAuto !== undefined && newAuthAuto !== null
          ? newAuthAuto[0].type
          : ''
      );
      setAuthAutoValue(mission.certificateTypeItemValue);
    }
    if (newAuthType === 'code-auth') {
      setAuthCode(mission.certificateTypeItemValue);
    }

    const newAuthTime =
      mission.certificateTimeType === 99
        ? 'direct'
        : mission.certificateTimeType === 1
        ? 'unlimit'
        : mission.certificateTimeType === 2
        ? 'time_06'
        : mission.certificateTimeType === 3
        ? 'time_11'
        : mission.certificateTimeType === 4
        ? 'time_14'
        : mission.certificateTimeType === 5
        ? 'time_18'
        : 'time_00';
    setAuthTime(newAuthTime);
    if (mission.certificateTimeType === 99) {
      const newDirect1 = Math.floor(mission.certificateStartTime);
      const newDirect2 = Math.floor(mission.certificateEndTime);
      setAuthDirect1(`${newDirect1.toString().padStart(2, '0')}:00`);
      setAuthDirect2(`${newDirect2.toString().padStart(2, '0')}:00`);
    }

    const newCoverImage =
      mission.image !== undefined && mission.image !== null
        ? JSON.parse(mission.image)
        : ['', ''];
    const newSuccessImage =
      mission.certificateSuccessImage !== undefined &&
      mission.certificateSuccessImage !== null
        ? JSON.parse(mission.certificateSuccessImage)
        : ['', ''];
    const newFailImage =
      mission.certificateFailImage !== undefined &&
      mission.certificateFailImage !== null
        ? JSON.parse(mission.certificateFailImage)
        : ['', ''];
    setCoverImage1(newCoverImage.length > 0 ? newCoverImage[0] : '');
    setCoverImage2(newCoverImage.length > 1 ? newCoverImage[1] : '');
    setAuthSuccessImage1(newSuccessImage.length > 0 ? newSuccessImage[0] : '');
    setAuthSuccessImage2(newSuccessImage.length > 1 ? newSuccessImage[1] : '');
    setAuthFailImage1(newFailImage.length > 0 ? newFailImage[0] : '');
    setAuthFailImage2(newFailImage.length > 1 ? newFailImage[1] : '');

    const newTimes = [
      mission.attendStartTime
        ? `${mission.attendStartTime
            .toString()
            .padStart(2, '0')}:00~${mission.attendEndTime
            .toString()
            .padStart(2, '0')}:00`
        : '00:00~24:00',
    ];
    const newWeeks: [] =
      mission.attendWeeks && mission.attendWeeks.length > 0
        ? JSON.parse(mission.attendWeeks)
        : [];
    setAttendTimes(newTimes !== null ? newTimes : []);
    setAttendWeeks(newWeeks);
    setHasWeekAndTime(newWeeks.length > 0 ? 'yes' : 'no');
  };

  const {
    data: detailInfo,
    isLoading,
    refetch: programMissionRefetch,
  } = useQuery(
    KEY_PROGRAM_MISSION_LIFESTYLE_DETAIL_INFO,
    () => {
      console.log('missionId: ', missionId);
      if (missionId.length > 0) {
        const newData: any = {
          type: 'lifestyle',
          missionId: missionId,
        };
        return getProgramMissionDetailInfo(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log('mission detailInfo :', res);
        if (res !== null && res.mission !== null) {
          setMissionDetailInfo(res.mission);
          makeFormData(res.mission, 1);
        } else if (missionId.length > 0) {
          setOpenDialog(true);
          setDialogMessage('데이터가 없습니다.');
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const handleChangeDetailView = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDetailViewType((event.target as HTMLInputElement).value);
  };

  const handleChangeAuthType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAuthType((event.target as HTMLInputElement).value);
  };

  // const saveData = () => {
  //   const newData = {
  //     missionId: generateUuidId(),
  //     missionTitle: title,
  //     successScore: point,
  //     successCount: 1,
  //     link: link,
  //     image: '',
  //   };
  //   setData(newData);
  //   onSave();
  // };

  const {
    mutate: deleteProgramCurriculumMissionMutate,
    isError: isMutateDeleteError,
  } = useMutation(putProgramCurriculumMissionDelete, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setDialogMessage('삭제되었습니다.');
        onSave();
      } else {
        setOpenSnackbar(true);
        setDialogMessage(
          `삭제하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage('삭제가 실패했습니다. 다시 시도해주시기 바랍니다.');
    },
  });

  const { mutate: postMissionLifeStyleMutate, isError: isMutateError } =
    useMutation(postProgramMissionLifeStyle, {
      onSuccess: res => {
        setLoading(false);
        if (res.resultCode === RESULT_OK) {
          setOpenDialog(true);
          setDialogMessage('등록되었습니다.');
          onSave();
        } else {
          setOpenDialog(true);
          setDialogMessage(
            `등록하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
          );
        }
      },
      onError: error => {
        setLoading(false);
        setOpenDialog(true);
        setDialogMessage('등록이 실패했습니다. 다시 시도해주시기 바랍니다.');
      },
    });

  const registerCurriculumMission = (newUploadUrl: any) => {
    let isValid = 0;

    if (isValid === 0 && title.length === 0) {
      isValid = 1;
    }

    const newAuthType =
      authType === 'required-auth'
        ? 1
        : authType === 'optional-auth'
        ? 2
        : authType === 'simple-auth'
        ? 3
        : authType === 'auth-auto'
        ? 4
        : authType === 'code-auth'
        ? 5
        : 1;

    // const newAuthTime = authAvailableTime
    //   .map((option: any, idx: number) => {
    //     const value =
    //       option.type === authTime && option.type !== 'direct'
    //         ? idx + 1
    //         : option.type === authTime && option.type === 'direct'
    //         ? 99
    //         : -1;
    //     return value;
    //   })
    //   .filter(option => option !== -1);
    const newAuthTime = [hasWeekAndTime === 'yes' ? 99 : 1];
    const newDirect1 =
      authTime !== 'direct'
        ? getAuthAvailableTime(authTime)[0]
        : Number(authDirect1.split(':')[0]);
    const newDirect2 =
      authTime !== 'direct'
        ? getAuthAvailableTime(authTime)[1]
        : Number(authDirect2.split(':')[0]);
    console.log('newAuthTime: ', newAuthTime, ', authTime:', authTime);

    const newAuthAuto = authAutoType
      .map((item: any, idx: number) => (item.type === authAuto ? idx + 1 : -1))
      .filter((item: number) => item > 0);

    let newImage = JSON.stringify([]);
    let newFailImage = JSON.stringify([]);
    let newSuccessImage = JSON.stringify([]);

    const newCoverImage1 =
      coverFile1 !== null
        ? newUploadUrl.uploadUrl1
        : coverImage1.length > 0
        ? coverImage1
        : '';
    const newCoverImage2 =
      coverFile2 !== null
        ? newUploadUrl.uploadUrl2
        : coverImage2.length > 1
        ? coverImage2
        : '';
    const newAuthSuccessImage1 =
      authSuccessFile1 !== null
        ? newUploadUrl.successUploadUrl1
        : authSuccessImage1.length > 0
        ? authSuccessImage1
        : '';
    const newAuthSuccessImage2 =
      authSuccessFile2 !== null
        ? newUploadUrl.successUploadUrl2
        : authSuccessImage2.length > 1
        ? authSuccessImage2
        : '';
    const newAuthFailImage1 =
      authFailFile1 !== null
        ? newUploadUrl.failUploadUrl1
        : authFailImage1.length > 0
        ? authFailImage1
        : '';
    const newAuthFailImage2 =
      authFailFile2 !== null
        ? newUploadUrl.failUploadUrl2
        : authFailImage2.length > 1
        ? authFailImage1
        : '';
    newImage = JSON.stringify([newCoverImage1, newCoverImage2]);
    newSuccessImage = JSON.stringify([
      newAuthSuccessImage1,
      newAuthSuccessImage2,
    ]);
    newFailImage = JSON.stringify([newAuthFailImage1, newAuthFailImage2]);

    if (isValid === 0) {
      const newMissionData = {
        weekId: weekId,
        missionId: missionId,
        successScore: point,
        successCount: achieveCount,
        title: title,
        content: content,
        image: newImage,
        certificateStartTime: newDirect1,
        certificateEndTime: newDirect2,
        certificateTimeType: newAuthTime !== undefined ? newAuthTime[0] : 0,
        certificateType: newAuthType,
        certificateTypeItem: newAuthType === 4 ? newAuthAuto[0] : 0,
        certificateTypeItemValue:
          newAuthType === 4
            ? authAutoValue
            : newAuthType === 5
            ? authCode
            : '0',
        certificateDesc: authGuide,
        certificateSuccessImage: newSuccessImage,
        certificateFailImage: newFailImage,
        inputType: inputType === 'direct' ? 1 : 2,
        attendWeeks: attendWeeks,
        attendTimes:
          attendTimes !== null && attendTimes.length > 0 ? attendTimes[0] : '',
      };
      postMissionLifeStyleMutate(newMissionData);
    } else {
      var message = '';
      switch (isValid) {
        case 1:
          message = '이름을 입력해 주세요';
          break;
        case 7:
          message = '커버 이미지를 등록해 주세요.';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
      setLoading(false);
      setOpenDialog(true);
      setDialogMessage(message);
    }
  };

  const checkValid = async () => {
    setLoading(true);

    let skipUpload = false;
    if (
      coverFile1 === null &&
      coverFile2 === null &&
      authSuccessFile1 === null &&
      authSuccessFile2 === null &&
      authFailFile1 === null &&
      authFailFile2 === null
    )
      skipUpload = true;

    if (skipUpload) {
      registerCurriculumMission(null);
    } else {
      if (
        (coverFile1 !== null && getFileSize(coverFile1) >= 10) ||
        (coverFile2 !== null && getFileSize(coverFile2) >= 10) ||
        (authSuccessFile1 !== null && getFileSize(authSuccessFile1) >= 10) ||
        (authSuccessFile2 !== null && getFileSize(authSuccessFile2) >= 10) ||
        (authFailFile1 !== null && getFileSize(authFailFile1) >= 10) ||
        (authFailFile2 !== null && getFileSize(authFailFile2) >= 10)
      ) {
        setLoading(false);
        setOpenSnackbar(true);
        setDialogMessage(IMAGE_SIZE_10MB_MESSAGE);
        return;
      }
      var newUploadUrl1 = '';
      if (coverFile1 !== null) {
        const newUploadFile: any = await uploadFile('program', coverFile1);
        newUploadUrl1 = newUploadFile.data.uploadUrl.file.path;
      }

      var newUploadUrl2 = '';
      if (coverFile2 !== null) {
        const newUploadFile: any = await uploadFile('program', coverFile2);
        newUploadUrl2 = newUploadFile.data.uploadUrl.file.path;
      }

      var newSuccessUploadUrl1 = '';
      if (authSuccessFile1 !== null) {
        const newUploadFile: any = await uploadFile(
          'program',
          authSuccessFile1
        );
        newSuccessUploadUrl1 = newUploadFile.data.uploadUrl.file.path;
      }

      var newSuccessUploadUrl2 = '';
      if (authSuccessFile2 !== null) {
        const newUploadFile: any = await uploadFile(
          'program',
          authSuccessFile2
        );
        newSuccessUploadUrl2 = newUploadFile.data.uploadUrl.file.path;
      }

      var newFailUploadUrl1 = '';
      if (authFailFile1 !== null) {
        const newUploadFile: any = await uploadFile('program', authFailFile1);
        newFailUploadUrl1 = newUploadFile.data.uploadUrl.file.path;
      }

      var newFailUploadUrl2 = '';
      if (authFailFile2 !== null) {
        const newUploadFile: any = await uploadFile('program', authFailFile2);
        newFailUploadUrl2 = newUploadFile.data.uploadUrl.file.path;
      }

      // console.log('newUploadUrl1:', newUploadUrl1);
      // console.log('newUploadUrl2:', newUploadUrl2);
      // console.log('newSuccessUploadUrl1:', newSuccessUploadUrl1);
      // console.log('newSuccessUploadUrl2:', newSuccessUploadUrl2);
      // console.log('newFailUploadUrl1:', newFailUploadUrl1);
      // console.log('newFailUploadUrl2:', newFailUploadUrl2);
      const newUploadUrl = {
        uploadUrl1: newUploadUrl1,
        uploadUrl2: newUploadUrl2,
        successUploadUrl1: newSuccessUploadUrl1,
        successUploadUrl2: newSuccessUploadUrl2,
        failUploadUrl1: newFailUploadUrl1,
        failUploadUrl2: newFailUploadUrl2,
      };
      registerCurriculumMission(newUploadUrl);
    }
  };

  const deleteMissionLifeStyle = () => {
    const newMissionData = {
      weekId: weekId,
      missionId: missionId,
      type: 'lifestyle',
    };
    console.log('deleteMissionLifeStyleMutate :', newMissionData);
    deleteProgramCurriculumMissionMutate(newMissionData);
  };

  const getAuthTime = () => {
    let newAuthTime = '';
    if (authTime !== 'direct') {
      const tmpAuthTime = authAvailableTime.filter(
        item => authTime === item.type
      );
      newAuthTime = tmpAuthTime[0].title;
    } else {
      newAuthTime = `${authDirect1}~${authDirect2}`;
    }
    return newAuthTime;
  };

  const handleChange = (newInputType: number) => {
    setInputType(newInputType === 1 ? 'direct' : 'template');
    if (newInputType === 1) {
      reset();
    } else {
      reset();
      resetTemplate();
    }
  };

  const doClose = () => {
    reset();
    onClose();
  };

  useEffect(() => {
    console.log('selectTarget: ', selectTarget);
    if (selectTarget !== undefined && selectTarget.length > 0) {
      makeFormData(selectTarget[0], 2);
    }
  }, [selectTarget]);

  useEffect(() => {
    console.log('missionId:', missionId);
    if (missionId !== undefined && missionId !== null && missionId.length > 0) {
      programMissionRefetch();
      data.isEdit === 2
        ? setIsEdit(2)
        : data.isEdit === 1
        ? setIsEdit(1)
        : setIsEdit(0);
    }
  }, [missionId]);

  useEffect(() => {
    if (open) {
      console.log('lifestyle data:', data);
      setLoading(false);
      setImageUrlPrefix(loginVal.value.user.imageUrlPrefix);
      setMissionId(data.missionId);
      setWeekId(data.weekId);
      if (data.missionId === null || data.missionId.length === 0) {
        reset();
        setIsEdit(0);
      }
    } else {
      reset();
      setMissionId('');
      setWeekId('');
      setIsEdit(0);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      fullScreen
      sx={{
        '& .MuiDialog-paper': {
          height: '75vh',
          margin: '40rem',
        },
      }}
    >
      <DialogTitle>
        <Box className='flex_between'>
          <span>
            {isEdit === 1 ? '생활습관 자세히보기' : '생활습관 추가하기'}
          </span>
          <IconButton onClick={() => doClose()}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ m: 0, p: 0 }} />
      {isEdit === 1 ? (
        <DialogContent sx={{ p: '1rem 2rem' }}>
          <Box>
            <Typography className='title'>달성시 획득 점수</Typography>
            <TextField sx={{ width: '16rem', mr: '1rem' }} value={point} />
            <Divider />
          </Box>
          <Box>
            <Typography className='title'>달성 횟수</Typography>
            <TextField
              sx={{ width: '16rem', mr: '1rem' }}
              value={achieveCount}
            />
            <Divider />
          </Box>
          <Box>
            <Typography sx={{ fontWeight: 700 }}>{title}</Typography>
          </Box>
          <Box>
            <TextField
              fullWidth
              multiline
              rows={5}
              value={content}
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: 'auto',
                  padding: '.5rem',
                },
                '& fieldset': { border: 'none' },
              }}
              InputProps={{
                readOnly: true,
              }}
            />
          </Box>
          <Box className='flex_start'>
            <Box sx={{ mr: '1rem' }}>
              {coverImage1 !== undefined && coverImage1.length > 0 && (
                <img
                  src={`${imageUrlPrefix}${coverImage1}`}
                  alt={`${imageUrlPrefix}${coverImage1}`}
                  style={{
                    maxWidth: '10vw',
                    borderRadius: '14px',
                    width: '10rem',
                    height: 'auto',
                  }}
                />
              )}
            </Box>
            <Box>
              {coverImage2 !== undefined && coverImage2.length > 0 && (
                <img
                  src={`${imageUrlPrefix}${coverImage2}`}
                  alt={`${imageUrlPrefix}${coverImage2}`}
                  style={{
                    maxWidth: '10vw',
                    borderRadius: '14px',
                    width: '10rem',
                    height: 'auto',
                  }}
                />
              )}
            </Box>
          </Box>
          <Divider />
          <Box>
            <Typography className='title'>
              미션에 참여할 수 있는 요일/시간대 제한
            </Typography>
            {hasWeekAndTime !== 'no' ? (
              <SwWeekAndTimePicker
                times={attendTimes}
                setTimes={setAttendTimes}
                weeks={attendWeeks}
                setWeeks={setAttendWeeks}
                showButton={false}
                showSubTitle={true}
                maxLimit={1}
                disabled={true}
              />
            ) : (
              <Typography className=''>설정 안함</Typography>
            )}
            <Divider />
          </Box>
          <Box>
            <Typography className='title'>인증방법</Typography>
            <RadioGroup
              row
              aria-labelledby='auth-row-radio-buttons-group-label'
              name='auth-radio-buttons-group'
              value={authType}
            >
              <FormControlLabel
                value='required-auth'
                control={<Radio />}
                label='사진 인증(촬영 필수)'
              />
              <FormControlLabel
                value='optional-auth'
                control={<Radio />}
                label='선택 인증(촬영 선택 사항)'
              />
              <FormControlLabel
                value='simple-auth'
                control={<Radio />}
                label='간편 인증(클릭하여 인증)'
              />
              <FormControlLabel
                value='auth-auto'
                control={<Radio />}
                label='자동인증'
              />
              <FormControlLabel
                value='code-auth'
                control={<Radio />}
                label='코드 인증(암호 입력 인증)'
              />
            </RadioGroup>
            {authType === 'auth-auto' && (
              <Box>
                <TextField
                  sx={{ width: '8rem', ml: '1rem' }}
                  select
                  value={authAuto}
                >
                  {authAutoType.map(option => (
                    <MenuItem
                      key={`auth_auto_${option.type}`}
                      value={option.type}
                    >
                      {option.title}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  value={authAutoValue}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      height: 'auto',
                      ml: '1rem',
                    },
                  }}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position='end'>
                        <span>{authAuto === 'step' ? '보' : '분'}</span>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            )}
            {authType === 'code-auth' && (
              <Box>
                <Typography className='title'>인증코드</Typography>
                <TextField
                  placeholder='인증 코드를 입력해 주세요.'
                  value={authCode}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      height: 'auto',
                      ml: '1rem',
                    },
                  }}
                />
              </Box>
            )}
            <Divider />
          </Box>
          <Box>
            <Typography className='title'>인증 안내</Typography>
            <TextField
              fullWidth
              multiline
              rows={5}
              value={authGuide}
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: 'auto',
                  padding: '.5rem',
                },
                '& fieldset': { border: 'none' },
              }}
              InputProps={{
                readOnly: true,
              }}
            />
          </Box>
          <Box className='flex_between'>
            <Stack
              direction='row'
              divider={
                <Divider orientation='vertical' flexItem sx={{ m: 0 }} />
              }
              spacing={2}
            >
              <Box>
                <Typography className='title'>인증 성공 이미지</Typography>
                <Box className='flex_between'>
                  <Box sx={{ mr: '1rem' }}>
                    {authSuccessImage1 !== undefined &&
                      authSuccessImage1.length > 0 && (
                        <img
                          src={`${imageUrlPrefix}${authSuccessImage1}`}
                          alt={`${imageUrlPrefix}${authSuccessImage1}`}
                          style={{
                            maxWidth: '10vw',
                            borderRadius: '14px',
                            width: '10rem',
                            height: 'auto',
                          }}
                        />
                      )}
                  </Box>
                  <Box>
                    {authSuccessImage2 !== undefined &&
                      authSuccessImage2.length > 0 && (
                        <img
                          src={`${imageUrlPrefix}${authSuccessImage2}`}
                          alt={`${imageUrlPrefix}${authSuccessImage2}`}
                          style={{
                            maxWidth: '10vw',
                            borderRadius: '14px',
                            width: '10rem',
                            height: 'auto',
                          }}
                        />
                      )}
                  </Box>
                </Box>
              </Box>
              <Box>
                <Typography className='title'>인증 실패 이미지</Typography>
                <Box className='flex_between'>
                  <Box sx={{ mr: '1rem' }}>
                    {authFailImage1 !== undefined && authFailImage1.length > 0 && (
                      <img
                        src={`${imageUrlPrefix}${authFailImage1}`}
                        alt={`${imageUrlPrefix}${authFailImage1}`}
                        style={{
                          maxWidth: '10vw',
                          borderRadius: '14px',
                          width: '10rem',
                          height: 'auto',
                        }}
                      />
                    )}
                  </Box>
                  <Box>
                    {authFailImage2 !== undefined && authFailImage2.length > 0 && (
                      <img
                        src={`${imageUrlPrefix}${authFailImage2}`}
                        alt={`${imageUrlPrefix}${authFailImage2}`}
                        style={{
                          maxWidth: '10vw',
                          borderRadius: '14px',
                          width: '10rem',
                          height: 'auto',
                        }}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            </Stack>
          </Box>
        </DialogContent>
      ) : (
        <DialogContent sx={{ p: '1rem 2rem' }}>
          <Box>
            <Typography className='title'>달성시 획득 점수</Typography>
            <TextField
              sx={{ width: '16rem', mr: '1rem' }}
              select
              defaultValue={10}
              value={point}
              onChange={evt => setPoint(Number(evt.target.value))}
            >
              {pointPerAchieve.map(option => (
                <MenuItem key={`health_${option}`} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <Divider />
          </Box>
          <Box>
            <Typography className='title'>달성 횟수</Typography>
            <TextField
              sx={{ width: '16rem', mr: '1rem' }}
              select
              defaultValue={10}
              value={achieveCount}
              onChange={evt => setAchieveCount(Number(evt.target.value))}
            >
              {countArr.map(option => (
                <MenuItem key={`health_${option}`} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <Divider />
          </Box>
          <Box>
            <RadioGroup
              row
              aria-labelledby='movie-row-radio-buttons-group-label'
              name='row-radio-buttons-group'
              value={inputType}
              onChange={evt => {
                evt.currentTarget.value === 'direct'
                  ? setOpenChangeInputType(1)
                  : setOpenChangeInputType(2);
              }}
            >
              <FormControlLabel
                value='direct'
                control={<Radio />}
                label='직접입력'
              />
              <FormControlLabel
                value='template'
                control={<Radio />}
                label='템플릿'
              />
            </RadioGroup>
            {(inputType === 'direct' || inputType === 'template') && (
              <Paper sx={{ background: '#F7F8F9' }}>
                <Box>
                  <Typography
                    className='title'
                    sx={{ ml: '1rem', mt: '.5rem' }}
                  >
                    생활습관
                  </Typography>
                </Box>
                <Divider sx={{ margin: '1rem 0rem', padding: '0rem' }} />
                <Box sx={{ ml: '2rem', width: '95%' }}>
                  <TextField
                    fullWidth
                    onChange={evt => {
                      setTitle(evt.target.value.substring(0, 30));
                    }}
                    value={title}
                    placeholder='제목을 입력해주세요.'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <span>{title?.length} / 30</span>
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        height: 'auto',
                        padding: '.5rem',
                      },
                    }}
                  />
                  <TextField
                    fullWidth
                    multiline
                    rows={5}
                    onChange={evt =>
                      setContent(evt.target.value.substring(0, 500))
                    }
                    value={content}
                    placeholder='내용을 입력해 주세요.'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <span>{content?.length} / 500</span>
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        height: 'auto',
                        padding: '.5rem',
                      },
                    }}
                  />
                  <Box className='flex_start'>
                    <Box sx={{ mr: '1rem' }}>
                      <SwFileSetting
                        setImage={setCoverImage1}
                        image={
                          coverFile1 !== null
                            ? coverImage1
                            : coverImage1 !== ''
                            ? `${imageUrlPrefix}${coverImage1}`
                            : ''
                        }
                        setFile={setCoverFile1}
                        file={coverFile1}
                      />
                    </Box>
                    <Box>
                      <SwFileSetting
                        setImage={setCoverImage2}
                        image={
                          coverFile2 !== null
                            ? coverImage2
                            : coverImage2 !== ''
                            ? `${imageUrlPrefix}${coverImage2}`
                            : ''
                        }
                        setFile={setCoverFile2}
                        file={coverFile2}
                      />
                    </Box>
                  </Box>
                  <Divider />
                  {showDetailView && (
                    <Box>
                      <RadioGroup
                        row
                        aria-labelledby='detail-row-radio-buttons-group-label'
                        name='detail-radio-buttons-group'
                        value={detailViewType}
                        onChange={handleChangeDetailView}
                      >
                        <FormControlLabel
                          value='magazine'
                          control={<Radio />}
                          label='매거진'
                        />
                        <FormControlLabel
                          value='url'
                          control={<Radio />}
                          label='외부 URL'
                        />
                        <FormControlLabel
                          value='yotube'
                          control={<Radio />}
                          label='유튜브 key'
                        />
                      </RadioGroup>
                      {detailViewType === 'magazine' ? (
                        <>
                          <TextField
                            sx={{ width: '16rem', mr: '1rem' }}
                            select
                            defaultValue={10}
                            value={magazineTypeDepth1}
                            onChange={evt =>
                              setMagazineTypeDepth1(Number(evt.target.value))
                            }
                          >
                            {pointPerAchieve.map(option => (
                              <MenuItem key={`health_${option}`} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </TextField>
                          <TextField
                            sx={{ width: '16rem', mr: '1rem' }}
                            select
                            defaultValue={10}
                            value={magazineTypeDepth2}
                            onChange={evt =>
                              setMagazineTypeDepth2(Number(evt.target.value))
                            }
                          >
                            {pointPerAchieve.map(option => (
                              <MenuItem key={`health_${option}`} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </TextField>
                          <TextField
                            sx={{ width: '16rem', mr: '1rem' }}
                            select
                            defaultValue={10}
                            value={magazineTypeDepth3}
                            onChange={evt =>
                              setMagazineTypeDepth3(Number(evt.target.value))
                            }
                          >
                            {pointPerAchieve.map(option => (
                              <MenuItem key={`health_${option}`} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </TextField>
                          <TextField
                            sx={{ width: '16rem', mr: '1rem' }}
                            select
                            defaultValue={10}
                            value={magazineTypeDepth4}
                            onChange={evt =>
                              setMagazineTypeDepth4(Number(evt.target.value))
                            }
                          >
                            {pointPerAchieve.map(option => (
                              <MenuItem key={`health_${option}`} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </TextField>
                        </>
                      ) : detailViewType === 'url' ? (
                        <TextField
                          fullWidth
                          onChange={evt => setLink(evt.target.value)}
                          value={link}
                          placeholder='URL 입력'
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              height: 'auto',
                              padding: '.5rem',
                            },
                          }}
                        />
                      ) : (
                        <TextField
                          fullWidth
                          onChange={evt => setYoutubeKey(evt.target.value)}
                          value={youtubeKey}
                          placeholder='sFPHNGpQvZ0'
                          helperText='유튜브 링크의 v=XXXXXXXXXX 값을 입력해 주세요.'
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              height: 'auto',
                              padding: '.5rem',
                            },
                          }}
                        />
                      )}
                      <Divider />
                    </Box>
                  )}
                  <Box>
                    <Typography className='title'>
                      미션에 참여할 수 있는 요일/시간대 제한
                    </Typography>
                    <RadioGroup
                      row
                      aria-labelledby='movie-row-radio-buttons-group-label'
                      name='row-radio-buttons-group'
                      value={hasWeekAndTime}
                      onChange={evt => {
                        setHasWeekAndTime(evt.currentTarget.value);
                        setAttendTimes([]);
                        setAttendWeeks([]);
                      }}
                    >
                      <FormControlLabel
                        value='no'
                        control={<Radio />}
                        label='설정 안함'
                      />
                      <FormControlLabel
                        value='yes'
                        control={<Radio />}
                        label='설정'
                      />
                    </RadioGroup>
                    {hasWeekAndTime !== 'no' && (
                      <SwWeekAndTimePicker
                        times={attendTimes}
                        setTimes={setAttendTimes}
                        weeks={attendWeeks}
                        setWeeks={setAttendWeeks}
                        showButton={false}
                        showSubTitle={true}
                        maxLimit={1}
                      />
                    )}
                    <Divider />
                  </Box>
                  <Box>
                    <Typography className='title'>인증방법</Typography>
                    <RadioGroup
                      row
                      aria-labelledby='auth-row-radio-buttons-group-label'
                      name='auth-radio-buttons-group'
                      value={authType}
                      onChange={handleChangeAuthType}
                    >
                      <FormControlLabel
                        value='required-auth'
                        control={<Radio />}
                        label='사진 인증(촬영 필수)'
                      />
                      <FormControlLabel
                        value='optional-auth'
                        control={<Radio />}
                        label='선택 인증(촬영 선택 사항)'
                      />
                      <FormControlLabel
                        value='simple-auth'
                        control={<Radio />}
                        label='간편 인증(클릭하여 인증)'
                      />
                      <FormControlLabel
                        value='auth-auto'
                        control={<Radio />}
                        label='자동인증'
                      />
                      <FormControlLabel
                        value='code-auth'
                        control={<Radio />}
                        label='코드 인증(암호 입력 인증)'
                      />
                    </RadioGroup>
                  </Box>
                  {authType === 'auth-auto' && (
                    <Box className='flex_start'>
                      <Typography className='title'>자동 인증</Typography>
                      <TextField
                        sx={{ width: '8rem', ml: '1rem' }}
                        select
                        value={authAuto}
                        // onChange={evt => setAuthAuto(evt.target.value)}
                      >
                        {authAutoType.map(option => (
                          <MenuItem
                            key={`auth_auto_${option.type}`}
                            value={option.type}
                          >
                            {option.title}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        value={authAutoValue}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            height: 'auto',
                            ml: '1rem',
                          },
                        }}
                        onChange={(
                          evt: ChangeEvent<
                            HTMLTextAreaElement | HTMLInputElement
                          >
                        ) => {
                          setAuthAutoValue(
                            `${checkNaN(
                              evt.target.value,
                              Number(authAutoValue)
                            )}`
                          );
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <span>{authAuto === 'step' ? '보' : '분'}</span>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  )}
                  {authType === 'code-auth' && (
                    <Box>
                      <Typography className='title'>인증코드</Typography>
                      <TextField
                        placeholder='인증 코드를 입력해 주세요.'
                        value={authCode}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            height: 'auto',
                            ml: '1rem',
                          },
                        }}
                        onChange={(
                          evt: ChangeEvent<
                            HTMLTextAreaElement | HTMLInputElement
                          >
                        ) => {
                          setAuthCode(evt.target.value.substring(0, 8));
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <span>{authCode.length} / 8</span>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  )}
                  <Divider />
                  <Box>
                    <Typography className='title'>인증 안내(선택)</Typography>
                    <TextField
                      fullWidth
                      multiline
                      rows={5}
                      onChange={evt =>
                        setAuthGuide(evt.target.value.substring(0, 500))
                      }
                      value={authGuide}
                      placeholder='내용을 입력해 주세요.'
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <span>{authGuide?.length} / 500</span>
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          height: 'auto',
                          padding: '.5rem',
                        },
                      }}
                    />
                  </Box>
                  <Box className='flex_between'>
                    <Stack
                      direction='row'
                      divider={
                        <Divider
                          orientation='vertical'
                          flexItem
                          sx={{ m: 0 }}
                        />
                      }
                      spacing={2}
                    >
                      <Box>
                        <Typography className='title'>
                          인증 성공 이미지
                        </Typography>
                        <Box className='flex_between'>
                          <Box sx={{ mr: '1rem' }}>
                            <SwFileSetting
                              setImage={setAuthSuccessImage1}
                              image={
                                authSuccessFile1 !== null
                                  ? authSuccessImage1
                                  : authSuccessImage1 !== ''
                                  ? `${imageUrlPrefix}${authSuccessImage1}`
                                  : ''
                              }
                              setFile={setAuthSuccessFile1}
                              file={authSuccessFile1}
                            />
                          </Box>
                          <Box>
                            <SwFileSetting
                              setImage={setAuthSuccessImage2}
                              image={
                                authSuccessFile2 !== null
                                  ? authSuccessImage2
                                  : authSuccessImage2 !== ''
                                  ? `${imageUrlPrefix}${authSuccessImage2}`
                                  : ''
                              }
                              setFile={setAuthSuccessFile2}
                              file={authSuccessFile2}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <Typography className='title'>
                          인증 실패 이미지
                        </Typography>
                        <Box className='flex_between'>
                          <Box sx={{ mr: '1rem' }}>
                            <SwFileSetting
                              setImage={setAuthFailImage1}
                              image={
                                authFailFile1 !== null
                                  ? authFailImage1
                                  : authFailImage1 !== ''
                                  ? `${imageUrlPrefix}${authFailImage1}`
                                  : ''
                              }
                              setFile={setAuthFailFile1}
                              file={authFailFile1}
                            />
                          </Box>
                          <Box>
                            <SwFileSetting
                              setImage={setAuthFailImage2}
                              image={
                                authFailFile2 !== null
                                  ? authFailImage2
                                  : authFailImage2 !== ''
                                  ? `${imageUrlPrefix}${authFailImage2}`
                                  : ''
                              }
                              setFile={setAuthFailFile2}
                              file={authFailFile2}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Stack>
                  </Box>
                </Box>
              </Paper>
            )}
            {inputType === 'template' && (
              <Box>
                <Box className='flex_center'>
                  <Button
                    sx={{ mr: '1rem', borderColor: '#00A2FF' }}
                    variant='outlined'
                    color='info'
                    size='small'
                    onClick={() => {
                      setOpenChangeInputType(2);
                    }}
                  >
                    🏷️템플릿에서 선택
                  </Button>
                </Box>
              </Box>
            )}
            <Divider />
          </Box>
        </DialogContent>
      )}
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogActions>
        {isEdit === 1 ? (
          <Box className='flex_end'>
            <Button
              sx={{ mr: '1rem' }}
              variant='outlined'
              color='info'
              size='small'
              onClick={deleteMissionLifeStyle}
            >
              삭제
            </Button>
            <Button
              disabled={disabled}
              variant='contained'
              color='info'
              size='small'
              onClick={() => {
                setIsEdit(2);
              }}
            >
              수정
            </Button>
          </Box>
        ) : isEdit === 2 ? (
          <Box className='flex_end'>
            <Button
              sx={{ mr: '1rem' }}
              variant='outlined'
              color='info'
              size='small'
              onClick={onClose}
            >
              취소
            </Button>
            <Button
              variant='contained'
              color='info'
              size='small'
              onClick={checkValid}
            >
              수정완료
            </Button>
          </Box>
        ) : (
          <Box className='flex_end'>
            <Button
              sx={{ mr: '1rem' }}
              variant='outlined'
              color='info'
              size='small'
              onClick={onClose}
            >
              취소
            </Button>
            <Button
              variant='contained'
              color='info'
              size='small'
              onClick={checkValid}
            >
              작성 완료
            </Button>
          </Box>
        )}
      </DialogActions>
      <SwSelectTemplate
        categoryType={4}
        open={openTemplate}
        onClose={() => {
          setOpenTemplate(false);
        }}
        setTarget={setSelectTarget}
      />
      <SwConfirmDialog
        contents='지금까지 작성한 내용이 모두 사라집니다. 생활습관을 변경하시겠습니까?'
        open={!!openChangeInputType}
        onClose={() => setOpenChangeInputType(0)}
        onConfirm={() => {
          handleChange(openChangeInputType);
          setOpenChangeInputType(0);
          openChangeInputType === 2 && setOpenTemplate(true);
        }}
        confirm={1}
      />
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={false}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
      {loading && <SwLoading />}
    </Dialog>
  );
};
