import clsx from 'clsx';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { Helmet } from 'react-helmet-async';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useResize } from '../../../hooks/resize';
import { TableTitle } from '../../commonComponent/tableElement/TableTitle';
import { SelectTextField } from '../../styles/Styles';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';

import {
  encodeSearchWord,
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { INoticeRequest, INotice } from '../../../models/notice';
import { getNoticeList, deleteNotice } from '../../../common/api/ApiNotice';
import SwLoading from '../../commonComponent/spinner/SwLoading';
import {
  KEY_APP_REVIEW_LIST,
  KEY_APP_REVIEW_TARGET_LIST,
  KEY_NOTICE_LIST,
} from '../../../common/key';
import {
  changeAppReview,
  getAppReviewList,
  getAppReviewTargetList,
  postAppReview,
} from '../../../common/api/ApiAppReview';

interface Props {
  open: boolean;
  onClose: () => void;
}

const columns = ['유형', '고객명', '이름', '종류', '진행기간', '참여 인원수'];

export const AppReviewAdd: React.FC<Props> = ({ open, onClose }) => {
  const queryClient = useQueryClient();
  const { isMobile } = useResize();
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [checkedButtons, setCheckedButtons] = useState<string[]>([]);
  const [openAllowReviewDialog, setOpenAllowReviewDialog] =
    useState<boolean>(false);
  const [appReviewList, setAppReviewList] = useState<any>([]);
  const [selectAllowReview, setSelectAllowReview] = useState<any>();
  const [edit, setEdit] = useState<boolean>(true);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [totalRecords, setTotalRecords] = useState(0);
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [searchKey, setSearchKey] = useState<string>('all');
  const [searchWord, setSearchWord] = useState<string>('');
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);
  const [loading, setLoading] = useState<any>(true);

  const {
    data,
    isLoading,
    refetch: appReviewTargetRefetch,
  } = useQuery(
    KEY_APP_REVIEW_TARGET_LIST,
    () => {
      const newData: any = {
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      if (open) return getAppReviewTargetList(newData);
      return null;
    },
    {
      onSuccess: res => {
        setLoading(false);
        if (res != null) {
          setAppReviewList(res.appReview);
          setTotalRecords(res.pagination.totalRecordCount);
        }
      },
      onError: e => {
        setLoading(false);
        console.log(e);
      },
    }
  );

  const prefetch = (newSearchWord: string, newPage: number) => {
    queryClient.prefetchQuery(KEY_APP_REVIEW_TARGET_LIST, () => {
      const newData = {
        page: newPage,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, newSearchWord),
      };
      return getAppReviewTargetList(newData);
    });
  };

  const { mutate: addAppReivewMutate, isError: isMutateError } = useMutation(
    postAppReview,
    {
      onSuccess: () => {
        setOpenSnackbar(true);
        setDialogMessage('추가되었습니다.');
        setTimeout((_: any) => {
          onClose();
        }, 500);
      },
      onError: error => {
        setOpenSnackbar(true);
        setDialogMessage(
          '추가중 오류가 발생했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    }
  );

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
  };

  const searchAppReviewTarget = (key: string, name: string) => {
    setLoading(true);
    setSearchKey(key);
    setSearchWord(name);
    prefetch(name, page);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchAppReviewTarget('all', searchWord);
    }
  };

  const clickRow = (idx: number) => {
    const selectedTarget = appReviewList[idx];
    const newData: any = {
      allowReview: 1, // allow
      type: selectedTarget.type,
      targetId: selectedTarget.targetId,
    };
    addAppReivewMutate(newData);
  };

  useEffect(() => {
    setLoading(true);
    prefetch(searchWord, page);
  }, [page]);

  useEffect(() => {
    if (open) {
      setOpenSnackbar(false);
      setTotalRecords(0);
      setSearchWord('');
      setAppReviewList([]);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      fullScreen
      sx={{
        '& .MuiDialog-paper': {
          height: '66vh',
          margin: '20rem',
        },
      }}
    >
      <DialogTitle>
        <Box className='flex_between'>
          <span>리뷰 요청 추가하기</span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogContent>
        <Box className='flex_between'>
          <TextField
            placeholder='챌린지 또는 프로그램 이름, 아이디 검색'
            value={searchWord}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setSearchWord(evt.target.value)}
            onKeyDown={handleKeyDown}
            sx={{ width: '30rem', mr: '1em' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    sx={{ pr: 0 }}
                    onClick={() => searchAppReviewTarget('all', searchWord)}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, idx) => (
                <TableCell key={idx.toString()}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {appReviewList?.map((row: any, idx: number) => (
              <TableRow key={idx.toString()} onClick={() => clickRow(idx)}>
                <TableCell>{row.type}</TableCell>
                <TableCell>{row.creator}</TableCell>
                <TableCell>{row.targetName}</TableCell>
                <TableCell>{row.targetType}</TableCell>
                <TableCell>
                  {`${
                    timestamp2Localestring(row.attendStartTime, 1000).split(
                      ' '
                    )[0]
                  }~${
                    timestamp2Localestring(row.attendEndTime, 1000).split(
                      ' '
                    )[0]
                  }`}
                </TableCell>
                <TableCell>{row.attendCount}</TableCell>
              </TableRow>
            ))}
            {(appReviewList === null || appReviewList.length === 0) && (
              <TableRow>
                <TableCell colSpan={8}>
                  검색 결과가 없습니다. 챌린지 또는 프로그램 이름, 아이디를
                  입력해 주세요.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <SwPagination
          page={page}
          handleChangePage={handleChangePage}
          count={appReviewList && Math.ceil(totalRecords / rowsPerPage)}
        />
      </DialogContent>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={false}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
      {loading && <SwLoading />}
    </Dialog>
  );
};
