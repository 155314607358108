import {
  Box,
  Button,
  Divider,
  InputAdornment,
  MenuItem,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import React, { Dispatch, useEffect, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilState } from 'recoil';
import { DataportalConnect } from '../../commonComponent/DataportalConnect';
import {
  convertDistance,
  convertStep,
  convertTime,
  generateUuidId,
} from '../../../common/helper';
import { SwUuidCreate } from '../../commonComponent/SwUuidCreate';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { IField, IFieldDetail } from '../../../models/field';
import {
  getFieldRequestList,
  getFieldRequestDetail,
  registerField,
  deleteField,
} from '../../../common/api/ApiField';
import { RESULT_OK } from '../../../common/resultCode';
import { ChallengeFieldCharacter } from './ChallengeFieldCharacter';
import { selectedFieldIdState } from '../../../common/atom';

const CreatePaper = styled(Paper)({
  margin: '2rem 0',
  padding: '1.5rem',
  borderRadius: '12px',
});

interface Props {
  commandType: number;
  setCommandType: Dispatch<React.SetStateAction<number>>;
  mapInfo: any;
  fieldDetail: IFieldDetail;
  setFieldDetail: Dispatch<React.SetStateAction<IFieldDetail>>;
  onReload: () => void;
}

export const ChallengeFieldForm: React.FC<Props> = ({
  commandType,
  setCommandType,
  mapInfo,
  fieldDetail,
  setFieldDetail,
  onReload,
}) => {
  const [selectedFieldId, setSelectedFieldId] =
    useRecoilState(selectedFieldIdState);
  const [fieldId, setFieldId] = useState<string>('');
  const [findDataportal, setFindDataportal] = useState<string>('');
  const [connectDataportal, setConnectDataportal] = useState<any>(null);
  const [title, setTitle] = useState<string>('');
  const [creator, setCreator] = useState<string>('');
  const [editor, setEditor] = useState<string>('');
  const [hidden, setHidden] = useState<number>(1);
  const [registerStatus, setRegisterStatus] = useState<number>(0);
  const [requestedFrom, setRequestedFrom] = useState<number>(0);
  const [refreshPeriod, setRefreshPeriod] = useState<number>(3600000);
  const [refreshLimit, setRefreshLimit] = useState<number>(15000);
  const [refreshRadius, setRefreshRadius] = useState<number>(10);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const { mutate: registerFieldMutation, isError: isFieldMutateError } =
    useMutation(registerField, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          onReload();
          setOpenSnackbar(true);
          setDialogMessage('필드가 저장되었습니다.');
        } else {
          setOpenSnackbar(true);
          setDialogMessage(
            `필드를 저장하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
          );
        }
      },
      onError: error => {
        setOpenSnackbar(true);
        setDialogMessage(
          `필드를 저장하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.(${error})`
        );
      },
    });

  const { mutate: deleteFieldMutation, isError: isDeleteFieldMutateError } =
    useMutation(deleteField, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          onReload();
          setOpenSnackbar(true);
          setDialogMessage('필드가 삭제되었습니다.');
        } else {
          setOpenSnackbar(true);
          setDialogMessage(
            `필드를 삭제하는 동안 오류가 발생했습니다. (${res.resultCodeMsg})`
          );
        }
      },
      onError: error => {
        setOpenSnackbar(true);
        setDialogMessage(
          '필드를 삭제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
        );
      },
    });

  const deleteFieldInfo = () => {
    const newData: any = {
      fieldId: fieldId,
    };

    deleteFieldMutation(newData);
  };

  const checkValid = () => {
    const newCreator =
      connectDataportal !== undefined &&
      connectDataportal !== null &&
      connectDataportal.portalId !== undefined &&
      connectDataportal.portalId !== null
        ? connectDataportal.portalId
        : creator.length > 0
        ? creator
        : '';

    var val = 0;

    if (val === 0 && fieldId.length === 0) val = 1;
    if (val === 0 && title.length === 0) val = 4;

    if (val !== 0) {
      var message = '';
      switch (val) {
        case 1:
          message = '필드 아이디를 입력해주세요.';
          break;
        case 3:
          message = '데이터 포탈 연결을 해주세요';
          break;
        case 4:
          message = '필드 이름을 입력해주세요';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
      setDialogMessage(message);
      setOpenDialog(true);
    }
    return val;
  };

  const registerFieldInfo = () => {
    console.log('register field:', mapInfo);
    if (checkValid() !== 0) {
      return;
    }

    const newCreator =
      connectDataportal !== undefined &&
      connectDataportal !== null &&
      connectDataportal.portalId !== undefined &&
      connectDataportal.portalId !== null
        ? connectDataportal.portalId
        : '';

    // const newSpotList = mapInfo.pointsContent?.map((item: any, idx: number) => {
    //   const newSpot = {
    //     spotId: generateUuidId(),
    //     spotPointLat: item.lat,
    //     spotPointLng: item.lng,
    //     spotRadius: item.radius !== '' ? item.radius : 150,
    //     spotName: item.name,
    //     spotImage: item.image,
    //     spotSeq: idx,
    //     description: item.description,
    //     className: item.className,
    //     link: item.link,
    //     phoneNo: item.phoneNo,
    //     address: item.address,
    //     spotDeleted: 0,
    //   };
    //   return newSpot;
    // });

    const newData: IField = {
      fieldId: fieldId,
      name: title,
      deleted: 0,
      dataPortalId: newCreator,
      editor: editor,
      spots: [],
      hidden: 0,
      registerStatus: registerStatus,
      requestedFrom: requestedFrom,
      refreshPeriod: refreshPeriod,
      refreshLimit: refreshLimit,
      refreshRadius: refreshRadius,
    };
    console.log('register newData:', newData);
    registerFieldMutation(newData);
  };

  useEffect(() => {
    if (commandType === 1) {
      if (registerStatus === 3) {
        setOpenSnackbar(true);
        setDialogMessage(`수정요청 상태라 저장이 불가합니다.`);
      } else {
        registerFieldInfo();
      }
    } else if (commandType === 2) {
      registerFieldInfo();
    } else if (commandType === 3) {
      deleteFieldInfo();
    }
    setCommandType(0);
  }, [commandType]);

  useEffect(() => {
    setSelectedFieldId(fieldId);
  }, [fieldId]);

  useEffect(() => {
    console.log('FieldForm fieldDetail :', fieldDetail);
    if (fieldDetail && fieldDetail.field.fieldId !== '') {
      setTitle(fieldDetail.field.name || '');
      setFieldId(
        selectedFieldId === '' ? fieldDetail.field.fieldId : selectedFieldId
      );
      setEditor(fieldDetail.field.editor ? fieldDetail.field.editor : '');
      const newCreator = fieldDetail.field.dataPortalId
        ? fieldDetail.field.dataPortalId
        : '';
      setCreator(newCreator);
      setFindDataportal('');
      setConnectDataportal({ portalId: newCreator, name: '' });
      setHidden(fieldDetail.field.hidden);
      setRegisterStatus(fieldDetail.field.registerStatus);
      setRequestedFrom(fieldDetail.field.requestedFrom);

      setRefreshPeriod(fieldDetail.field.refreshPeriod);
      setRefreshLimit(fieldDetail.field.refreshLimit);
      setRefreshRadius(fieldDetail.field.refreshRadius);
    }
  }, [fieldDetail]);

  return (
    <>
      <CreatePaper>
        <Box>
          <Typography className='title'>필드 이름</Typography>
          <TextField
            placeholder='필드 이름을 50자 이내로 작성해 주세요.'
            value={title}
            onChange={evt => setTitle(evt.target.value.substring(0, 50))}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Typography>{title.length} / 50</Typography>
                </InputAdornment>
              ),
            }}
            sx={{ width: '70vw' }}
          />
          <Divider />
        </Box>
        <Box>
          <SwUuidCreate title='필드' id={fieldId} setId={setFieldId} />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>추천 코스</Typography>
          <TextField
            select
            sx={{ width: '10rem' }}
            value={hidden}
            onChange={evt => {
              setHidden(Number(evt.target.value));
            }}
          >
            <MenuItem value={1}>설정 안함 </MenuItem>
            <MenuItem value={0}>설정</MenuItem>
          </TextField>
          <Divider />
        </Box>
        <ChallengeFieldCharacter fieldInfo={fieldDetail} />
        <Divider />
        <DataportalConnect
          findDataportal={findDataportal}
          setFindDataportal={setFindDataportal}
          connectDataportal={connectDataportal}
          setConnectDataportal={setConnectDataportal}
        />
        <Divider />
        <Stack
          direction={{ xs: 'column', sm: 'column', md: 'row' }}
          divider={
            <Divider
              orientation='vertical'
              flexItem
              sx={{ p: 0, m: '1.5rem 3rem 0' }}
            />
          }
        >
          <Box>
            <Typography className='title'>refresh 간격(ms)</Typography>
            <TextField
              value={refreshPeriod}
              onChange={evt => setRefreshPeriod(Number(evt.target.value))}
              sx={{ width: '10vw' }}
            />
          </Box>
          <Box>
            <Typography className='title'>refresh 제한 시간(ms)</Typography>
            <TextField
              value={refreshLimit}
              onChange={evt => setRefreshLimit(Number(evt.target.value))}
              sx={{ width: '10vw' }}
            />
          </Box>
          <Box>
            <Typography className='title'>refresh 제한 반경</Typography>
            <TextField
              value={refreshRadius}
              onChange={evt => setRefreshRadius(Number(evt.target.value))}
              sx={{ width: '10vw' }}
            />
          </Box>
        </Stack>
        <Divider />
        <Box sx={{ mt: '1.5rem' }}>
          <Box className='flex_end' sx={{ paddingBottom: '2rem' }}>
            <Box>
              <Button variant='contained' onClick={() => registerFieldInfo()}>
                정보 저장
              </Button>
            </Box>
          </Box>
        </Box>
      </CreatePaper>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </>
  );
};
