import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import SearchIcon from '@mui/icons-material/Search';
import React, {
  Dispatch,
  ChangeEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import readXlsxFile from 'read-excel-file';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwFindLatLngbyKakaomap } from '../../commonComponent/map/SwFindLatLngbyKakaomap';
import { SwImageSetting } from '../../commonComponent/SwImageSetting';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { SwUuidCreate } from '../../commonComponent/SwUuidCreate';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwSearchChallengeUser } from '../../commonComponent/SwSearchChallengeUser';
import { DrawTable } from '../rewardDrawing/DrawTable';
import { MultilineTextField } from '../../styles/Styles';
import {
  APPLY_FORM_TYPE,
  MobileRegexp,
  removeNatCodePhoneNumber,
  timestamp2string,
} from '../../../common/helper';
import {
  ButtonBox,
  ChallengeTypeBox,
  ChallengeTypeTitle,
  ColorTableCell,
  ContentTitle,
  CreatePaper,
  MarginButton,
} from './ChallengeStyles';
import {
  IGuideTool,
  IChallengeCreate,
  IChallengeReqeust,
  DefaultChallengeCreate,
} from '../../../models/challenge';
import {
  getChallengeRewardList,
  getChallengeRewardUserList,
  drawManualCoupon,
  drawManualTicket,
} from '../../../common/api/ApiChallenge';
import {
  KEY_CHALLENGE_REWARD_COUPON_GOAL_ACHIEVER_LIST,
  KEY_CHALLENGE_REWARD_LIST,
  KEY_CHALLENGE_REWARD_USER_LIST,
  KEY_REWARD_DRAWING_RESULT,
  KEY_USER_LIST_BY_PHONENOS,
} from '../../../common/key';
import {
  RESULT_DUPLICATED_REQUEST,
  RESULT_OK,
} from '../../../common/resultCode';
import {
  getRewardDrawingApplyResultDownload,
  getRewardDrawingResult,
  getRewardDrawingResultDownload,
} from '../../../common/api/ApiReward';
import {
  getGoalAchieverList,
  getRewardDrawUserListByPhonenos,
  getUserListByPhonenos,
} from '../../../common/api/ApiUser';
import { SwLoadUser } from '../../commonComponent/SwLoadUser';
import SwLoading from '../../commonComponent/spinner/SwLoading';
import { SwManageRewardApplyFormValue } from '../../commonComponent/SwManageRewardApplyFormValue';

const columns = [
  '순서',
  '리워드 이름',
  '수량',
  '발급(응모)인원',
  '지급 방법',
  '사용 방법',
  '상태',
];

const drawColumn = [
  '번호',
  '연락처',
  '참여자 닉네임',
  '커뮤니티 닉네임 사용 여부',
  '커뮤니티 닉네임',
];

const guidetool: IGuideTool = {
  title: '소제목',
  contents: '안내내용',
  image: '',
};

const MAX_LOAD_PHONE_LENGTH = 500;

interface Props {
  giveType: number;
  rewardId: string;
  challengeId: string;
  useType?: string;
}

export const RewardDrawUserGivetype0: React.FC<Props> = ({
  giveType,
  rewardId,
  challengeId,
  useType,
}) => {
  const queryClient = useQueryClient();
  const resultInputRef = useRef<any>();
  const putInputRef = useRef<any>();
  const delInputRef = useRef<any>();
  const putUserRegistRef = useRef<any>();
  const delUserRegistRef = useRef<any>();
  const guideTitleRef = useRef<any>();
  const guideContentsRef = useRef<any>();
  const [requestList, setRequestList] = useState<any>([]);
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [resultLatLng, setResultLatLng] = useState<any>('');
  const [challengeType, setChallengeType] = useState<number>(1);
  const [isDuplicate, setIsDuplicate] = useState<string>('');
  const [rewardMaxCount, setRewardMaxCount] = useState<number>();
  const [title, setTitle] = useState<string>('');
  const [guidePage, setGuidePage] = useState(1);
  const [guideLists, setGuideLists] = useState<IGuideTool[]>([guidetool]);
  const [guideImage, setGuideImage] = useState<any>('');
  const [guideImageFile, setGuideImageFile] = useState<any>('');
  const [rewardImage, setRewardImage] = useState<string>('');
  const [rewardImageFile, setRewardImageFile] = useState<string>('');
  const [imageUrlPrefix, setImageUrlPrefix] = useState<string>('');
  const [rewardLocation, setRewardLocation] = useState<string>('');
  const [rewardStatus, setRewardStatus] = useState<number>(1);
  const [rewardSequence, setRewardSequence] = useState<number>(1);
  const [rewardPointPerGet, setRewardPointPerGet] = useState<number>(0);
  const [rewardStampPerGet, setRewardStampPerGet] = useState<number>(0);
  const [winnerUserList, setWinnerUserList] = useState<any>([]);
  const [nonwinnerUserList, setNonwinnerUserList] = useState<any>([]);
  const [rewardUserList, setRewardUserList] = useState<any>([]);
  const [putUserList, setPutUserList] = useState<any>([]);
  const [delUserList, setDelUserList] = useState<any>([]);
  const [targetUserList, setTargetUserList] = useState<any>([]);
  const [filterList, setFilterList] = useState<any>([]);
  const [loadUserList, setLoadUserList] = useState<any>([]);
  const [request, setRequest] = useState<any>();
  const [checkRequest, setCheckRequest] = useState<any>(false);
  const [checkGoalAchiever, setCheckGoalAchiever] = useState<any>(false);
  const [loadType, setLoadType] = useState<string>('');
  const [pointData, setPointData] = useState<any>();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnakerDialog, setOpenSnakerDialog] = useState<boolean>(false);
  const [openLoadUser, setOpenLoadUser] = useState<number>(0);
  const [openPayment, setOpenPayment] = useState<number>(0);
  const [openApplyForm, setOpenApplyForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<any>(false);
  const [disabledClick, setDisabledClick] = useState<boolean>(false);
  let requestType = '';

  const { refetch: rewardDrawingResultRefetch } = useQuery(
    KEY_REWARD_DRAWING_RESULT,
    () => {
      const newSearchWord = resultInputRef.current?.value;
      const tmpSearchWord = Number.isInteger(
        Number(newSearchWord.replaceAll('-', '').trim())
      )
        ? newSearchWord.replaceAll('-', '').trim()
        : newSearchWord;
      const newData: any = {
        type: 'winner',
        rewardId: rewardId,
        searchKey: 'all',
        searchWord: tmpSearchWord,
      };
      if (checkRequest) return getRewardDrawingResult(newData);
      return null;
    },
    {
      onSuccess: res => {
        console.log('rewardDrawingResultRefetch reward user list :', res);
        if (res !== null) {
          const newRewardList = res.rewardResult.map(
            (item: any, idx: number) => {
              const newRewardUser = {
                sequence: idx + 1,
                name: item.name,
                phoneno: item.phoneno,
                useNickName: item.useNickName,
                nickName: item.nickName,
                deleted: item.deleted,
              };
              return newRewardUser;
            }
          );
          setRewardUserList(newRewardList);
        } else setRewardUserList([]);
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const processUserResult = (type: string, results: any[]) => {
    if (type === 'draw') {
      const newPutList = putUserList.map((item: any, idx: number) => {
        const isSearch = results.filter(
          (result: any) => result.userId === item.userId
        );
        const newResult =
          isSearch.length > 0 ? { ...item, status: isSearch[0].status } : item;
        return newResult;
      });
      console.log('newPutList :', newPutList);
      setPutUserList(newPutList);
    } else {
      const newDelList = delUserList.map((item: any, idx: number) => {
        const isSearch = results.filter(
          (result: any) => result.userId === item.userId
        );
        const newResult =
          isSearch.length > 0 ? { ...item, status: isSearch[0].status } : item;
        return newResult;
      });
      console.log('newDelList :', newDelList);
      setDelUserList(newDelList);
    }
  };

  const updateUserList = () => {
    console.log('giveType :', giveType);
    rewardDrawingResultRefetch();
  };

  const { mutate: drawManualCouponMutate, isError: isCouponMutateError } =
    useMutation(drawManualCoupon, {
      onSuccess: res => {
        console.log('drawManualCouponMutate:', res);
        setLoading(false);
        setDisabledClick(false);
        if (res.resultCode === Number(RESULT_OK)) {
          if (res.rewardDraw !== undefined) {
            setOpenSnakerDialog(true);
            setDialogMessage('쿠폰을 지급했습니다.');
            processUserResult('draw', res.rewardDraw);
            updateUserList();
          } else if (res.rewardWithDraw !== undefined) {
            setOpenSnakerDialog(true);
            setDialogMessage('쿠폰을 회수했습니다.');
            processUserResult('widthdraw', res.rewardWithDraw);
            updateUserList();
          }
        } else if (res.resultCode === RESULT_DUPLICATED_REQUEST) {
          setOpenDialog(true);
          setDialogMessage(
            `쿠폰처리 실패 : 중복 요청으로 이전 처리가 끝날때까지 기다린 후 다시 시도해주세요`
          );
        } else {
          setOpenDialog(true);
          setDialogMessage(`쿠폰처리 실패 : ${res.resultCodeMsg}`);
        }
      },
      onError: error => {
        setLoading(false);
        setDisabledClick(false);
        setOpenSnakerDialog(true);
        setDialogMessage(
          '쿠폰지급하기 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
        );
      },
    });

  const rewardUserPrefetch = (newChengeId: string, newRewardId: string) => {
    const newRequest = {
      challengeId: newChengeId,
      rewardId: newRewardId,
    };
    queryClient.prefetchQuery(KEY_CHALLENGE_REWARD_USER_LIST, () =>
      getChallengeRewardUserList(newRequest)
    );
  };

  const rewardUserResultPrefetch = (newRewardId: string) => {
    const newSearchWord = resultInputRef.current?.value;
    const tmpSearchWord = Number.isInteger(
      Number(newSearchWord.replaceAll('-', '').trim())
    )
      ? newSearchWord.replaceAll('-', '').trim()
      : newSearchWord;
    const newData: any = {
      type: 'winner',
      rewardId: newRewardId,
      searchKey: 'all',
      searchWord: tmpSearchWord,
    };
    queryClient.prefetchQuery(KEY_REWARD_DRAWING_RESULT, () =>
      getRewardDrawingResult(newData)
    );
  };

  const showErrorMessage = (errorCode: number) => {
    if (errorCode === 503) {
      setOpenSnakerDialog(true);
      setDialogMessage('쿠폰이 남은수량이 없습니다.');
    } else if (errorCode === 504) {
      setOpenSnakerDialog(true);
      setDialogMessage('쿠폰 발급중 오류가 발생했습니.');
    } else if (errorCode === 505) {
      setOpenSnakerDialog(true);
      setDialogMessage('챌린지 타입(걸음수, 길따라 걷기만 가능) 오류입니다.');
    } else if (errorCode === 506) {
      setOpenSnakerDialog(true);
      setDialogMessage(
        '목표 달성자 인원 수가 남은 쿠폰 수보다 초과하여 지급할 수 없습니다.'
      );
    }
  };

  const { refetch: rewardGoalAchieverRefetch } = useQuery(
    KEY_CHALLENGE_REWARD_COUPON_GOAL_ACHIEVER_LIST,
    () => {
      const newRequest = {
        type: 1, // 쿠폰
        challengeId: challengeId,
        rewardId: rewardId,
      };
      if (checkGoalAchiever) return getGoalAchieverList(newRequest);
      return null;
    },
    {
      onSuccess: res => {
        console.log('rewardDrawingApplyRefetch reward user result list :', res);
        setCheckGoalAchiever(false);
        if (res !== null) {
          const newUserList = res.rewardUser
            .filter(
              (item: any) =>
                putUserList.findIndex((q: any) => q.userId === item.userId) ===
                -1
            )
            .map((item: any) => {
              const newData = {
                name: item.name,
                phoneno: removeNatCodePhoneNumber(item.phoneno),
                userId: item.userId,
                status: '',
                result: item.result,
              };
              return newData;
            });
          if (newUserList !== undefined && newUserList.length > 0) {
            console.log('newUserList :', newUserList);
            if (newUserList[0].result !== 200) {
              showErrorMessage(newUserList[0].result);
            } else {
              setPutUserList([...putUserList, ...newUserList]);
            }
          } else {
            setOpenSnakerDialog(true);
            setDialogMessage('자동 지급 대상자가 없습니다.');
          }
        }
      },
      onError: e => {
        setCheckGoalAchiever(false);
        console.log(e);
      },
    }
  );

  const getGoalAchiever = () => {
    setCheckGoalAchiever(true);
  };

  const { mutate: userListByPhonenosMutate, isError: isUserMutateError } =
    useMutation(getUserListByPhonenos, {
      onSuccess: res => {
        console.log('getUserListByPhonenos:', res);
        if (res !== null && res.user !== undefined) {
          const newUserList = res.user
            .filter((item: any) =>
              requestType === 'putUserRegistType0'
                ? putUserList.findIndex(
                    (q: any) => q.userId === item.userId
                  ) === -1
                : requestType === 'delUserRegistType0'
                ? delUserList.findIndex(
                    (q: any) => q.userId === item.userId
                  ) === -1
                : false
            )
            .map((item: any) => {
              const newData = {
                name: item.name,
                phoneno: removeNatCodePhoneNumber(item.phoneno),
                userId: item.userId,
                status: '',
              };
              return newData;
            });
          requestType === 'putUserRegistType0'
            ? setPutUserList([...putUserList, ...newUserList])
            : requestType === 'delUserRegistType0'
            ? setDelUserList([...delUserList, ...newUserList])
            : [];
        }
      },
      onError: error => {},
    });

  const {
    mutate: rewardDrawUserListByPhonenosMutate,
    isError: isDrawUserMutateError,
  } = useMutation(getRewardDrawUserListByPhonenos, {
    onSuccess: res => {
      console.log('getRewardDrawUserListByPhonenos:', res);
      if (res !== null && res.user !== undefined) {
        const newUserList = res.user
          .filter((item: any) =>
            requestType === 'putUserRegistType0'
              ? putUserList.findIndex((q: any) => q.userId === item.userId) ===
                -1
              : requestType === 'delUserRegistType0'
              ? delUserList.findIndex((q: any) => q.userId === item.userId) ===
                -1
              : false
          )
          .map((item: any) => {
            const newData = {
              name: item.name,
              phoneno: removeNatCodePhoneNumber(item.phoneno),
              userId: item.userId,
              status: '',
            };
            return newData;
          });
        requestType === 'putUserRegistType0'
          ? setPutUserList([...putUserList, ...newUserList])
          : requestType === 'delUserRegistType0'
          ? setDelUserList([...delUserList, ...newUserList])
          : [];
      }
    },
    onError: error => {},
  });

  const getUserList = (data: any) => {
    console.log('getUserList :', data);
    if (data.length === 0) {
      setOpenSnakerDialog(true);
      setDialogMessage('업로드할 데이터가 없습니다. 확인 후 다시 시도해주세요');
      return;
    }
    const newData = {
      challengeId: challengeId,
      rewardId: rewardId,
      phonenos: data,
    };

    giveType === 0
      ? userListByPhonenosMutate(newData)
      : rewardDrawUserListByPhonenosMutate(newData);
  };

  const deleteUser = (index: number, from: string) => {
    if (from === 'put') {
      const newSomething = putUserList.filter(
        (_val: any, i: number) => i !== index
      );
      setPutUserList([...newSomething]);
    } else {
      const newSomething = delUserList.filter(
        (_val: any, i: number) => i !== index
      );
      setDelUserList([...newSomething]);
    }
  };

  const searchUser = (searchType: number) => {
    if (searchType === 0 || searchType === 1) {
      const newSearchWord =
        searchType === 0
          ? putInputRef.current?.value
          : delInputRef.current?.value;
      const tmpSearchWord = Number.isInteger(
        Number(newSearchWord.replaceAll('-', '').trim())
      )
        ? newSearchWord.replaceAll('-', '').trim()
        : newSearchWord;
      const newRequest = {
        searchType: searchType,
        challengeId: challengeId,
        searchKey: 'all',
        searchWord: tmpSearchWord,
        page: 0,
        rowsPerPage: 0,
      };
      setRequest(newRequest);
      setOpen(true);
    } else if (searchType === 10 || searchType === 11) {
      rewardDrawingResultRefetch();
    }
  };

  const putRewardUser = (payInfo: any) => {
    if (rewardId === undefined || rewardId === null || rewardId.length === 0) {
      setOpenSnakerDialog(true);
      setDialogMessage('리워드를 선택해주세요');
      return;
    }
    const newUserIds = putUserList.map((item: any) => item.userId);
    if (
      newUserIds === undefined ||
      newUserIds === null ||
      newUserIds.length === 0
    ) {
      setOpenSnakerDialog(true);
      setDialogMessage('사용자 데이터가 없습니다. 확인 후 다시 시도해주세요');
      return;
    }
    const newRequest = {
      challengeId: challengeId,
      rewardId: rewardId,
      drawType: 'draw',
      point: 0,
      message: '',
      userIds: newUserIds,
    };
    setLoading(true);
    setDisabledClick(true);
    drawManualCouponMutate(newRequest);
  };

  const delRewardUser = (payInfo: any) => {
    if (rewardId === undefined || rewardId === null || rewardId.length === 0) {
      setOpenSnakerDialog(true);
      setDialogMessage('리워드를 선택해주세요');
      return;
    }

    const newUserIds = delUserList.map((item: any) => item.userId);
    if (
      newUserIds === undefined ||
      newUserIds === null ||
      newUserIds.length === 0
    ) {
      setOpenSnakerDialog(true);
      setDialogMessage('사용자 데이터가 없습니다. 확인 후 다시 시도해주세요');
      return;
    }
    const newRequest = {
      challengeId: challengeId,
      rewardId: rewardId,
      drawType: 'withdraw',
      point: 0,
      message: '',
      userIds: newUserIds,
    };
    setLoading(true);
    setDisabledClick(true);
    drawManualCouponMutate(newRequest);
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
  };

  const uploadFile = (v: boolean) => {
    const inputMemberRegist: any = v
      ? document.getElementById('putUserRegistType0')
      : document.getElementById('delUserRegistType0');
    console.log(inputMemberRegist && inputMemberRegist.files);
    if (inputMemberRegist && inputMemberRegist.files.length) {
      readXlsxFile(inputMemberRegist.files[0])
        .then(rows => {
          console.log(rows);
          const list = rows
            .map(row => row.toString())
            .map(r => (r[0] !== '0' ? `0${r}` : r));
          // list.shift(); // 첫번째 항목 삭제

          const reducer = (s: any[], val: string, idx: number) => {
            if (s.findIndex(q => q.value === val) === -1) {
              if (MobileRegexp.test(val)) {
                s.push(val);
              } else {
                // s.push({ value: val, check: 1 });
              }
            } else {
              // v
              //   ? setCheckWinnerDuplicateCount(pre => pre + 1)
              //   : setCheckNonWinnerDuplicateCount(pre => pre + 1);
            }
            return s;
          };
          const sortList = list.reduce(reducer, []);
          if (
            sortList.filter(q => q.check === 1).length > MAX_LOAD_PHONE_LENGTH
          ) {
            setDialogMessage('파일에 너무 많은 오류 행이 있습니다.');
            setOpenSnakerDialog(true);
            // v ? setCheckWinnerTotalCount(0) : setCheckNonWinnerTotalCount(0);
            return;
          }

          const newList = sortList.map((s: any) => {
            const newData = {
              phoneno: s,
            };
            return newData;
          });

          if (v) {
            // setPutUserList([...putUserList, ...newList]);
            requestType = 'putUserRegistType0';
            getUserList(sortList);
            putUserRegistRef.current.value = null;
          } else {
            // setDelUserList([...delUserList, ...newList]);
            requestType = 'delUserRegistType0';
            getUserList(sortList);
            delUserRegistRef.current.value = null;
          }

          // setDialogMessage(`업로드된 번호 개수 : ${sortList.length}`);
          // setOpenSnakerDialog(true);
        })
        .catch(err => {
          console.log('err>>>>>>>>>', err);
        });
    }
  };

  const onDownloadRewardUserList = (type: string) => {
    const newSearchWord = resultInputRef.current?.value;
    const tmpSearchWord = Number.isInteger(
      Number(newSearchWord.replaceAll('-', '').trim())
    )
      ? newSearchWord.replaceAll('-', '').trim()
      : newSearchWord;
    const newData: any = {
      type: 'winner',
      rewardId: rewardId,
      searchKey: 'all',
      searchWord: tmpSearchWord,
    };

    getRewardDrawingApplyResultDownload(newData);
  };

  const handleKeyDown = (e: any, value: number) => {
    if (e.key === 'Enter') {
      searchUser(value);
    }
  };

  const checkUserList = () => {
    if (loadType === 'putUserRegistType0') {
      requestType = 'putUserRegistType0';
    } else if (loadType === 'delUserRegistType0') {
      requestType = 'delUserRegistType0';
    }
  };

  useEffect(() => {
    if (targetUserList.length > 0) {
      console.log('targetUserList :', targetUserList);
      if (request.searchType === 0) {
        const newTargetList = targetUserList.filter((target: any) => {
          const newUser = putUserList.filter(
            (user: any) => target.userId === user.userId
          );
          if (newUser && newUser.length > 0) return false;
          return true;
        });
        const newPutList = [...putUserList, ...newTargetList];
        setPutUserList(newPutList);
      } else {
        const newTargetList = targetUserList.filter((target: any) => {
          const newUser = delUserList.filter(
            (user: any) => target.userId === user.userId
          );
          if (newUser && newUser.length > 0) return false;
          return true;
        });
        const newDelList = [...delUserList, ...newTargetList];
        setDelUserList(newDelList);
      }
    }
  }, [targetUserList]);

  useEffect(() => {
    if (checkGoalAchiever) rewardGoalAchieverRefetch();
  }, [checkGoalAchiever]);

  useEffect(() => {
    if (checkRequest) {
      rewardDrawingResultRefetch();
    }
  }, [checkRequest]);

  useEffect(() => {
    if (loadType === 'putUserRegistType0') {
      requestType = 'putUserRegistType0';
    } else if (loadType === 'delUserRegistType0') {
      requestType = 'delUserRegistType0';
    }
    if (loadUserList.length > 0) getUserList(loadUserList);
  }, [loadUserList]);

  //   useEffect(() => {
  //     getUserList(loadUserList);
  //   }, [requestType]);

  useEffect(() => {
    setRewardUserList([]);
    setPutUserList([]);
    setDelUserList([]);
    if (rewardId !== null && rewardId.length > 0) {
      checkRequest === false
        ? setCheckRequest(true)
        : rewardDrawingResultRefetch();
    }
  }, [rewardId]);

  return (
    <>
      <Box sx={{ p: '1.5rem 0' }}>
        <Paper sx={{ m: '1rem 0', p: '1rem' }}>
          <Box className='flex_between' sx={{ mb: '1.5rem' }}>
            <Typography sx={{ mb: '1.5rem', fontWeight: 'bold' }}>
              쿠폰발급자 목록
              <span style={{ color: '#2581FF' }}>{rewardUserList?.length}</span>
              명
            </Typography>
            <Box className='flex_start'>
              <Box>
                <TextField
                  placeholder='참여자 닉네임, 연락처, 아이디로 검색해 주세요.'
                  inputRef={resultInputRef}
                  onKeyDown={evt => {
                    handleKeyDown(evt, 10);
                  }}
                  sx={{ width: '30rem', mr: '1rem' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          sx={{ pr: 0 }}
                          onClick={() => searchUser(10)}
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Button
                color='info'
                variant='outlined'
                onClick={() => onDownloadRewardUserList('winner')}
              >
                데이터 다운로드
                <FileDownloadRoundedIcon color='success' />
              </Button>
              {useType === APPLY_FORM_TYPE && (
                <Button
                  color='info'
                  sx={{ ml: '1rem' }}
                  variant='contained'
                  onClick={() => {
                    setOpenApplyForm(true);
                  }}
                >
                  입력폼 내용 수정
                </Button>
              )}
            </Box>
          </Box>
          <DrawTable
            columns={drawColumn}
            rows={rewardUserList}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
          />
        </Paper>
        <Paper sx={{ m: '1rem 0', p: '1rem' }}>
          <Box className='flex_start'>
            <Typography sx={{ fontWeight: 'bold', mr: '1rem' }}>
              쿠폰 수동 지급
              <span style={{ color: '#2581FF' }}>{putUserList.length}</span>명
            </Typography>
            <Button
              variant='outlined'
              color='primary'
              onClick={() => {
                getGoalAchiever();
              }}
            >
              목표 달성자 자동 지급
            </Button>
            <Box>
              <Button
                color='info'
                sx={{ ml: '1rem' }}
                variant='contained'
                onClick={() => {
                  const inputMemberRegist =
                    document.getElementById('putUserRegistType0');
                  inputMemberRegist && inputMemberRegist.click();
                }}
              >
                엑셀 업로드(연락처)
              </Button>
              <Button
                color='info'
                sx={{ ml: '1rem' }}
                variant='contained'
                onClick={() => {
                  setLoadType('putUserRegistType0');
                  setOpenLoadUser(1);
                }}
              >
                복사하여 업로드
              </Button>
              <input
                ref={putUserRegistRef}
                type='file'
                id='putUserRegistType0'
                style={{ display: 'none' }}
                onChange={evt => uploadFile(true)}
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', m: '.8rem 0' }}>
            <TextField
              placeholder='참여자 닉네임, 연락처, 아이디로 검색해 주세요.'
              inputRef={putInputRef}
              onKeyDown={evt => {
                handleKeyDown(evt, 0);
              }}
              sx={{ width: '30rem' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton sx={{ pr: 0 }} onClick={() => searchUser(0)}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Paper
            sx={{
              backgroundColor: '#FCFCFC',
              p: '2rem 2rem 1rem',
            }}
          >
            <Box sx={{ height: '10rem', overflowY: 'scroll' }}>
              {putUserList &&
                putUserList.map((item: any, idx: number) => (
                  <ChallengeTypeBox key={idx.toString()}>
                    <Typography>
                      {`${item.name}(${item.phoneno}) : ${item.userId}`}
                    </Typography>
                    <Typography sx={{ m: '0 1rem' }}>
                      {item.status === '성공' ? '발급완료' : item.status}
                    </Typography>
                    <ClearIcon
                      className='clear_icon_button'
                      onClick={() => deleteUser(idx, 'put')}
                    />
                  </ChallengeTypeBox>
                ))}
            </Box>
            <Box sx={{ textAlign: 'right' }} className='flex_between'>
              <Button
                variant='contained'
                disabled={disabledClick}
                onClick={() => {
                  putRewardUser(null);
                }}
              >
                지급하기
              </Button>
              <Button
                color='error'
                onClick={() => {
                  setPutUserList([]);
                  setLoadUserList([]);
                }}
              >
                <DeleteOutlineIcon />
                전체 삭제
              </Button>
            </Box>
          </Paper>
        </Paper>
        <Paper sx={{ m: '1rem 0', p: '1rem' }}>
          <Box className='flex_start'>
            <Typography sx={{ fontWeight: 'bold', mr: '1rem' }}>
              쿠폰 수동 회수
              <span style={{ color: '#2581FF' }}>{delUserList.length}</span>명
            </Typography>
            <Box>
              <Button
                color='info'
                sx={{ ml: '1rem' }}
                variant='contained'
                onClick={() => {
                  const inputMemberRegist =
                    document.getElementById('delUserRegistType0');
                  inputMemberRegist && inputMemberRegist.click();
                }}
              >
                엑셀 업로드(연락처)
              </Button>
              <Button
                color='info'
                sx={{ ml: '1rem' }}
                variant='contained'
                onClick={() => {
                  setLoadType('delUserRegistType0');
                  setOpenLoadUser(2);
                }}
              >
                복사하여 업로드
              </Button>
              <input
                ref={delUserRegistRef}
                type='file'
                id='delUserRegistType0'
                style={{ display: 'none' }}
                onChange={evt => uploadFile(false)}
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', m: '.8rem 0' }}>
            <TextField
              placeholder='참여자 닉네임, 연락처, 아이디로 검색해 주세요.'
              inputRef={delInputRef}
              sx={{ width: '30rem' }}
              onKeyDown={evt => {
                handleKeyDown(evt, 1);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton sx={{ pr: 0 }} onClick={() => searchUser(1)}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Paper
            sx={{
              backgroundColor: '#FCFCFC',
              p: '2rem 2rem 1rem',
            }}
          >
            <Box sx={{ height: '10rem', overflowY: 'scroll' }}>
              {delUserList &&
                delUserList.map((item: any, idx: number) => (
                  <ChallengeTypeBox key={idx.toString()}>
                    <Typography>
                      {`${item.name}(${item.phoneno}) : ${item.userId}`}
                    </Typography>
                    <Typography sx={{ m: '0 1rem' }}>
                      {item.status === '성공' ? '회수완료' : item.status}
                    </Typography>
                    <ClearIcon
                      className='clear_icon_button'
                      onClick={() => deleteUser(idx, 'del')}
                    />
                  </ChallengeTypeBox>
                ))}
            </Box>
            <Box sx={{ textAlign: 'right' }} className='flex_between'>
              <Button
                variant='contained'
                disabled={disabledClick}
                onClick={() => {
                  delRewardUser(null);
                }}
              >
                회수하기
              </Button>
              <Button
                color='error'
                onClick={() => {
                  setDelUserList([]);
                  setLoadUserList([]);
                }}
              >
                <DeleteOutlineIcon />
                전체 삭제
              </Button>
            </Box>
          </Paper>
        </Paper>
      </Box>
      <Box>
        <SwSearchChallengeUser
          title=''
          request={request}
          open={open}
          onClose={() => setOpen(!open)}
          setTarget={setTargetUserList}
        />
      </Box>
      <SwSnackbar
        open={openSnakerDialog}
        onClose={() => setOpenSnakerDialog(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
      <SwLoadUser
        open={!!openLoadUser}
        onclose={() => {
          checkUserList();
          setOpenLoadUser(0);
        }}
        setLoadList={setLoadUserList}
        maxSize={MAX_LOAD_PHONE_LENGTH}
      />
      <SwManageRewardApplyFormValue
        open={openApplyForm}
        onClose={() => setOpenApplyForm(false)}
        rewardId={rewardId}
      />
      {loading && <SwLoading />}
    </>
  );
};
