import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { useLocation } from 'react-router';
import {
  useEffect,
  useState,
  Dispatch,
  ChangeEvent,
  SyntheticEvent,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import {
  deleteProgramMissionContentFavorites,
  getProgramTemplateCodeList,
  postProgramMissionContentFavorites,
} from '../../../common/api/ApiProgram';
import {
  KEY_CONTENT_MAMANGE_CONTENT_LIST,
  KEY_CONTENT_MAMANGE_MAP,
  KEY_PROGRAM_TEMPLATE_CODE_LIST,
} from '../../../common/key';
import {
  getContentManageContentList,
  getContentManageMap,
} from '../../../common/api/ApiContentManage';
import { SwTemplateSideTab } from './SwTemplateSideTab';
import { timestamp2Localestring } from '../../../common/helper';
import { useSession } from '../../../hooks/session';
import { MultilineTextField, TabBox } from '../../styles/Styles';
import {
  authAutoType,
  authAvailableTime,
  getParsedImage,
  getTimeArray,
  showImageSlide,
  showKeyword,
} from './SwProgramCommon';
import { SwFileSetting } from '../SwFileSetting';
import { SwSurveyContentForm } from './SwSurveyContentForm';
import { SwQuizContentForm } from './SwQuizContentForm';
import { SwSnackbar } from '../views/SwSnackbar';
import { SwAlert } from '../views/SwAlert';
import { RESULT_OK } from '../../../common/resultCode';
import { SwPagination } from '../tableElement/SwPagination';
import { SwConfirmDialog } from '../views/SwConfirmDialog';

const TagsChip = styled(Chip)({
  margin: '.5em',
  color: '#2581FF',
  backgroundColor: 'rgba(52, 84, 248, 0.08)',
  borderColor: '#2581FF',
  '& .MuiChip-deleteIcon': {
    fontSize: '1rem',
  },
});

interface Props {
  categoryType: number;
  open: boolean;
  onClose: () => void;
  setTarget: Dispatch<React.SetStateAction<any>>;
  targetCategory?: string;
}

export const SwSelectTemplateCategory: React.FC<Props> = ({
  categoryType,
  open,
  onClose,
  setTarget,
  targetCategory = 'all',
}) => {
  const location = useLocation();
  const queryClient = useQueryClient();
  const { loginVal } = useSession();
  const [imageUrlPrefix, setImageUrlPrefix] = useState<string>(
    loginVal.value.user.imageUrlPrefix
  );
  const [categoryId, setCategoryId] = useState<string>('');
  const [contentManageList, setContentManageList] = useState<any>([]);
  const [contentManageInfo, setContentManageInfo] = useState<any>({
    save: false,
    depth: 1,
    status: 3,
  });
  const [selected, setSelected] = useState<any>();
  const [selectedContent, setSelectedContent] = useState<any[]>([]);
  const [favorite, setFavorite] = useState<boolean>(false);
  const [likeContent, setLikeContent] = useState<any[]>([]);
  const [contentManageMap, setContentManageMap] = useState<any[]>([]);
  const [menuPath, setMenuPath] = useState<string[]>([]);
  const [tabMenuList, setTabMenuList] = useState<any[]>([]);
  const [editMode, setEditMode] = useState<string>('view');
  const [openNav, setOpenNav] = useState<boolean>(true);
  const [param, setParam] = useState<string>('');
  const [checkRequest, setCheckRequest] = useState<boolean>(true);
  const [searchKey, setSearchKey] = useState<string>('all');
  const [searchWord, setSearchWord] = useState<string>('');
  const [tabValue, setTabValue] = useState(1);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [statusMessage, setStatusMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);

  const showStatusMessage = (type: number, count: number) => {
    setStatusMessage(
      type === 1
        ? `${count}개가 선택되었습니다.`
        : `총 ${count}건의 검색 결과가 있습니다.`
    );
  };

  const {
    data,
    isLoading,
    refetch: programContentManageMapRefetch,
  } = useQuery(
    KEY_CONTENT_MAMANGE_MAP,
    () => {
      const newRequest = {
        type: categoryType,
        sortWay: 1, // asc
      };
      if (open === true) return getContentManageMap(newRequest);
      return null;
    },
    {
      onSuccess: res => {
        if (res !== null && res.map !== undefined) {
          console.log('content ManageMap res:', res);
          setContentManageMap(res.map);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const saveTarget = () => {
    setTarget(categoryId);
  };

  const doSelected = (node: any) => {
    console.log('--> selected :', node);
    if (node !== undefined && node !== null && node.nodeType === 'node') {
      if (
        (node.contentCount === 0 && node.nodeList.length === 0) ||
        (node.contentCount > 0 && node.nodeList.length === 0)
      ) {
        setCategoryId(node.id);
      } else {
        setCategoryId('');
      }
    } else if (node !== undefined && node !== null && node.nodeType === 'tab') {
      if (node.node.contentCount === 0 && node.node.nodeList.length === 0) {
        setCategoryId(node.node.id);
      } else {
        setCategoryId('');
      }
    }
  };

  useEffect(() => {
    if (open === true) {
      const newParam = location.pathname.split('/')[1];
      setCategoryId('');
      programContentManageMapRefetch();
    } else {
      setCategoryId('');
    }
    return () => {};
  }, [open]);

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          height: '66vh',
          margin: '40rem',
        },
      }}
    >
      <DialogTitle>
        <Box className='flex_between'>
          <span>카테고리 선택하기</span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogContent>
        <Box sx={{ height: '40rem' }}>
          <SwTemplateSideTab
            menuMap={contentManageMap}
            openNav={openNav}
            setOpenNav={setOpenNav}
            param={param}
            onSelected={doSelected}
          />
        </Box>
      </DialogContent>
      <Divider sx={{ m: 0 }} />
      <Box className='flex_between' sx={{ m: '1rem 2rem' }}>
        <Box>
          <Typography>{statusMessage}</Typography>
        </Box>
        <Box>
          <Button
            variant='outlined'
            color='info'
            sx={{ fontSize: '.87rem', mr: '1rem' }}
            onClick={onClose}
          >
            취소
          </Button>
          <Button
            disabled={categoryId === undefined || categoryId.length === 0}
            variant='contained'
            color='info'
            sx={{ fontSize: '.87rem' }}
            onClick={saveTarget}
          >
            확인
          </Button>
        </Box>
      </Box>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </Dialog>
  );
};
