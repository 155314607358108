import {
  Box,
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { ChangeEvent, useEffect, useRef, useState, Dispatch } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import clsx from 'clsx';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { useResize } from '../../../hooks/resize';
import { RequestPagingTable } from '../../commonComponent/table/SwRequestPagingTable';
import { ConnectPopupDrawup } from './ConnectPopupDrawup';
import { TableTitle } from '../../commonComponent/tableElement/TableTitle';
import { SwRequestTable } from '../../commonComponent/table/SwRequestTable';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import {
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import {
  getDataPortalConnectPopupList,
  deleteDataPortalConnectPopup,
} from '../../../common/api/ApiDataPortal';
import { KEY_DATAPORTAL_CONNECT_POPUP_LIST } from '../../../common/key';
import SwLoading from '../../commonComponent/spinner/SwLoading';

interface Props {}

const columns = ['이미지', '작성자', '공개 시작일시', '공개 종료일시'];

const typeItem = ['이미지', '작성자', '공개 시작일시', '공개 종료일시'];

export const PortalConnectPopup: React.FC<Props> = () => {
  const queryClient = useQueryClient();
  const { isMobile } = useResize();
  const [openDrawup, setOpenDrawup] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [connectPopupList, setConnectPopupList] = useState<any>([]);
  const [selectConnectPopup, setSelectConnectPopup] = useState<any>(null);

  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [checkRequest, setCheckRequest] = useState<boolean>(true);
  const [checkedButtons, setCheckedButtons] = useState<string[]>([]);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);
  const [loading, setLoading] = useState<any>(null);

  const {
    data,
    isLoading,
    refetch: dataportalConnectPopupRefetch,
  } = useQuery(
    KEY_DATAPORTAL_CONNECT_POPUP_LIST,
    () => {
      const newData = {
        page: page,
        rowsPerPage: rowsPerPage,
        operator: 'admin',
      };
      if (checkRequest) {
        setLoading(true);
        return getDataPortalConnectPopupList(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        setLoading(false);
        const newList =
          res &&
          res.notice &&
          res.notice.map((item: any) => {
            const newDate = new Date(item.createDate).toLocaleString();
            const newData = {
              idx: String(item.idx),
              creator: item.creator,
              createDate: timestamp2Localestring(item.createDate, 1),
              startDate: item.startDate,
              endDate: item.endDate,
              conTypes: item.conTypes,
              image: item.image,
              imageUrlPrefix: item.imageUrlPrefix,
              link: item.link,
            };
            return newData;
          });
        setConnectPopupList(newList);
        setTotalRecords(res.pagination.totalRecordCount);
      },
      onError: e => {
        setLoading(false);
        console.log(e);
      },
    }
  );

  const prefetch = (newPage: number) => {
    queryClient.prefetchQuery(KEY_DATAPORTAL_CONNECT_POPUP_LIST, () => {
      const newData = {
        page: newPage,
        rowsPerPage: rowsPerPage,
        operator: 'admin',
      };
      return getDataPortalConnectPopupList(newData);
    });
  };

  const {
    mutate: deleteDataPortalConnectPopupMutation,
    isError: isDeleteMutateError,
  } = useMutation(deleteDataPortalConnectPopup, {
    onSuccess: () => {
      setOpenAlert(false);
      setOpenDialog(true);
      setDialogMessage('안내팝업을 삭제했습니다.');
      dataportalConnectPopupRefetch();
    },
    onError: error => {
      setOpenDialog(true);
      setDialogMessage(
        '안내팝업을 삭제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const deleteConnectPopup = () => {
    const idxList = checkedButtons.map((item: string) => Number(item));
    const newData: any = {
      idxList: idxList,
    };
    deleteDataPortalConnectPopupMutation(newData);
  };

  const clickConnectPopup = (idx: number) => {
    console.log('idx: ', Number(idx), ', guide: ', connectPopupList[idx]);
    setEdit(true);
    setSelectConnectPopup(connectPopupList[idx]);
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
    prefetch(newPage);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedButtons([]);
    if (event.target.checked) {
      setCheckedAll(true);
      connectPopupList?.map((row: any, idx: number) => {
        setCheckedButtons(current => [...current, row.idx]);
      });
    } else {
      setCheckedAll(false);
    }
  };

  const changeHandler = (checked: boolean, id: string) => {
    if (checked) {
      setCheckedButtons([...checkedButtons, id]);
    } else {
      setCheckedButtons(checkedButtons.filter(button => button !== id));
    }
  };

  useEffect(() => {
    if (selectConnectPopup !== null) setOpenDrawup(true);
  }, [selectConnectPopup]);

  return (
    <div className={clsx({ root: !isMobile() }, { root_resize: isMobile() })}>
      <Helmet>
        <title>포털 안내팝업</title>
      </Helmet>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', m: '2rem 0' }}>
        <Button
          variant='contained'
          onClick={() => {
            setEdit(false);
            setOpenDrawup(true);
          }}
        >
          <AddIcon />
          작성하기
        </Button>
      </Box>
      <Paper sx={{ m: '1rem 0', p: '1.5rem' }}>
        <TableTitle title='안내팝업 목록' count={totalRecords} />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding='checkbox'>
                <Checkbox
                  onChange={e => {
                    handleSelectAllClick(e);
                  }}
                  checked={checkedAll}
                />
              </TableCell>
              {columns.map((column, idx) => (
                <TableCell key={idx.toString()}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {connectPopupList?.map((row: any, idx: number) => (
              <TableRow key={idx.toString()}>
                <TableCell padding='checkbox'>
                  <Checkbox
                    id={row.idx}
                    onChange={e => {
                      changeHandler(e.currentTarget.checked, row.idx);
                    }}
                    checked={checkedButtons.includes(row.idx)}
                  />
                </TableCell>
                <TableCell onClick={() => clickConnectPopup(idx)}>
                  {row.image !== undefined && row.imag !== '' ? (
                    <img
                      src={`${row.imageUrlPrefix}${row.image}`}
                      alt=''
                      style={{ width: '5vh', height: '5vh' }}
                    />
                  ) : (
                    <>-</>
                  )}
                </TableCell>
                <TableCell onClick={() => clickConnectPopup(idx)}>
                  {row.creator}
                </TableCell>
                <TableCell onClick={() => clickConnectPopup(idx)}>
                  {timestamp2Localestring(row.startDate, 1)}
                </TableCell>
                <TableCell onClick={() => clickConnectPopup(idx)}>
                  {timestamp2Localestring(row.endDate, 1)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <SwPagination
          page={page}
          handleChangePage={handleChangePage}
          count={connectPopupList && Math.ceil(totalRecords / rowsPerPage)}
        />
      </Paper>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant='outlined'
          color='info'
          onClick={() => setOpenAlert(true)}
        >
          삭제
        </Button>
      </Box>
      <ConnectPopupDrawup
        open={openDrawup}
        onClose={() => {
          setOpenDrawup(false);
          setSelectConnectPopup(null);
        }}
        onConfirm={() => {
          setOpenDrawup(false);
          setSelectConnectPopup(null);
          dataportalConnectPopupRefetch();
        }}
        edit={edit}
        isEdit={() => setEdit(!edit)}
        notice={selectConnectPopup}
      />
      <SwAlert
        contents='선택하신 안내팝업을 삭제하시겠습니까?'
        open={openAlert}
        onClose={() => {
          setOpenAlert(false);
          setSelectConnectPopup(null);
        }}
        onConfirm={() => deleteConnectPopup()}
        confirm={1}
      />
      {loading && <SwLoading />}
    </div>
  );
};
