import { isArrayLiteralExpression } from 'typescript';
import { v4 as uuidv4 } from 'uuid';
import { reactQueryClient } from '..';
import { useSession } from '../hooks/session';

export const devCheck =
  process.env.REACT_APP_SERVER_URL === 'http://52.78.235.179:89' ||
  process.env.REACT_APP_SERVER_URL === 'http://localhost:8091';

export const STEP_TO_CALORIE_CONST = 0.03;
export const STEP_STRIDE_CONST = 60;
export const MAX_STEP = 999999999;
export const MAX_MEMBER = 999999999;
export const MAX_LOAD_USER_COUNT = 500;
export const MAX_DATE = '2037-12-31T23:59:59';
export const INIT_START_DATE = `2024-12-31T15:00:00`;
export const INIT_END_DATE = `2030-12-31T14:59:59`;

export const NORMAL_CHARACTER = 1;
export const FIXED_CHARACTER = 2;

export const APPLY_FORM_TYPE = '4/0';
export const BRAND_POINT_TYPE = '6/0';

export const NO_SELECT_ADVERTISER = 99;

// TOOL TIP LIST
export const TOOLTIP_FONT_SIZE = 14;
export const TOOLTIP_COMMUNITY_MEMBER_LIMIT =
  '현재 인원이 자동으로 제한되지는 않지만, 계약 조건에 따른 인원 수를 이곳에 입력해두고 해당 커뮤니티 인원수가 계약 인원 수보다 크게 많은 경우에는 연락을 시도해야 함';
export const TOOLTIP_COMMUNITY_OPEN =
  '`노출 안함`설정 시, 앞으로 해당 커뮤니티 필터를 걸고 생성하는 모든 챌린지는 자동으로 챌린지 검색 및 테마 분류에 노출하지 않음. 주로 기업 및 프라이빗하게 운영하는 커뮤니티에 설정함. 이때, 해당 기능을 설정하기 이전에 만들어진 챌린지는 따로 미노출 여부를 설정해주어야 함';
export const TOOLTIP_COMMUNITY_POST_USE =
  '커뮤니티 게시판을 가리는 기능, 이미 작성된 게시글이 있는 경우에도 `사용 안함` 선택 후 앱에서 커뮤니티 접속 시 `커뮤니티 게시판을 사용하지 않는다`라는 문구만 나옴';
export const TOOLTIP_COMMUNITY_RECOMMEND =
  '앱 > 커뮤니티 탭 > `더 많은 커뮤니티를 확인해보세요!` 목록에 표시되는 커뮤니티 여부 설정, 아래 `위치 정보`와 연관됨';
export const TOOLTIP_COMMUNITY_LOCATION =
  '추천 커뮤니티 노출 기준에 활용하는 위치 좌표값, 사용자의 위치에 가까운 커뮤니티들을 추천하여 나열함';
export const TOOLTIP_COMMUNITY_JOIN_INFO =
  '커뮤니티 가입 시 가입 신청 질문을 작성하는 기능';
export const TOOLTIP_COMMUNITY_TAG =
  '태그에 단어를 입력하면, 해당 단어 검색 시 커뮤니티가 검색됨. 주로 커뮤니티 이름에 기관명, 지역명이 들어가지 않아 검색이 용이하지 않을 때 사용함';
export const TOOLTIP_COMMUNITY_REGION_AUTH =
  '시/군/구 단위 지역 등록 시, 앱에서 해당 지역을 거주/활동 지역으로 인증한 사용자만 커뮤니티 가입이 가능함';
export const TOOLTIP_COMMUNITY_ADDRESS_AUTH =
  '시/군/구 단위 지역 등록 시, 앱에서 해당 지역을 주민등록상 주소로 인증한 사용자만 커뮤니티 가입이 가능함';
export const TOOLTIP_COMMUNITY_HIDDEN_STEP =
  '커뮤니티 랭킹의 걸음 수를 강제 비공개/강제 공개하는 기능. 설정 시, 사용자의 걸음 수 공개/비공개 여부와 관계없이 강제적으로 적용됨. 기본 설정은 `사용자 지정`임';
export const TOOLTIP_COMMUNITY_TYPE =
  '공개형 : 누구나 바로 가입(보건소, 지역주민 사업), 승인가입형 : 데이터 포탈에서 담당자가 승인해 주어야 가입(기업 및 폐쇄적 운영), 코드입력형 : 커뮤니티 가입 비밀번호를 입력하면 가입(기업)';
export const TOOLTIP_COMMUNITY_LIMIT =
  '공식 커뮤니티 가입 제한(10개) 초과하여 가입할 수 있습니다.';

export const TOOLTIP_CHALLEGE_ALBUM_IMAGE_USE =
  '챌린지 진행 중 해당 값을 변경하지 않습니다.';
export const TOOLTIP_CHALLENGE_OPEN =
  '`노출 안함` 설정 시, 해당 챌린지는 챌린지 검색 및 테마 분류에 노출하지 않음. 해당 챌린지 참여 대상자들에게만 `00님을 위한 챌린지(추천 챌린지)` 영역에 노출함';
export const TOOLTIP_CHALLENGE_CLASS_TYPE =
  '챌린지 분류에 테마명을 입력하면 앱 > 챌린지 메인 해당 테마에 이 챌린지가 분류됨. 해당 챌린지를 특정 분류에서 없애기 위해서는 기본값인 `선택`으로 지정하면, 분류가 해제됨';
export const TOOLTIP_CHALLENGE_RECOMMEND =
  '필터가 아무것도 없는 챌린지(a.k.a브지챌)의 경우 자동으로 모든 사용자의 추천 챌린지 영역에 노출하는데, 이 기능을 사용하는 경우 필터가 걸려있음에도 불구하고 모든 사용자의 추천 챌린지 영역에 노출함';
export const TOOLTIP_CHALLENGE_COVER_INFORMATION =
  '기본적으로 셋팅되어 있는 값을 건드리지 않고 사용합니다만, 세번째 `광고 문구`영역은 담당자가 원하는 문구를 자유롭게 넣어줄 수 있습니다. ';
export const TOOLTIP_CHALLENGE_INFORMATION =
  '기본적으로 셋팅되는 값을 사용하는 것을 추천하지만, 담당자 요청 시 변경 가능합니다.';
export const TOOLTIP_CHALLENGE_LINK =
  '링크(url)연결 시 챌린지 상단 좌측 `알아보기` 버튼 클릭하면 해당 링크로 이동하는 기능';
export const TOOLTIP_CHALLENGE_LOCATION =
  '챌린지 상세 > `안내 내용`쪽에 노출하는 지도(위치 1곳), 챌린지 진행 중, 종료 후에 자유롭게 변경이 가능합니다.';
export const TOOLTIP_CHALLEGE_POST_USE =
  '챌린지 진행 중 사용 여부를 변경할 수 있습니다. (게시판 사용 중 사용 안함 설정 시, 게시글은 남아있지만 글 작성이 더 이상 불가합니다.)';
export const TOOLTIP_CHALLEGE_RANKING_USE =
  '챌린지 진행 중, 종료 후에 자유롭게 변경이 가능합니다.';
export const TOOLTIP_CHALLEGE_REWARD_DUPLICATED =
  ' 마일리지 챌린지에서, 동일한 쿠폰을 여러 번 마일리지를 차감하여 받아갈 수 있는 기능';
export const TOOLTIP_CHALLEGE_REWARD_MAX_COUNT =
  '챌린지 진행 중에 자유롭게 변경이 가능하나, 이미 리워드를 1명이라도 받아간 경우 변경을 자제하는 것이 좋고 개수를 줄이기보단 늘리는 것을 지향합니다.';
export const TOOLTIP_CHALLEGE_REWARD_LOCATION =
  '리워드 상세 > `안내 내용`쪽에 노출하는 지도(위치 1곳)';
export const TOOLTIP_CHALLEGE_POST_ADVERTISER =
  '게시판 광고를 생성하려면, 어드민 > 계약 관리 > 광고주에서 광고주 정보를 등록 후 이곳에서 선택해야 함';

export const TOOLTIP_CHALLEGE_REWARD_RANGE =
  '챌린지 진행 중에 자유롭게 변경이 가능합니다.';
export const TOOLTIP_CHALLEGE_TYPE =
  '챌린지 신청이 들어왔을 때 종류를 변경하시면 그에 맞춰 리워드값을 다시 셋팅해주어야 합니다. 담당자가 종류 변경을 원하시면, 데이터 포탈에서 다시 신청해달라고 말씀드리는 것을 추천합니다. 또한, 챌린지 종류에 맞는 걸음 수 목표, 코스 설정값 등 다양한 셋팅은 챌린지 진행 중에 변경하지 않습니다.';

export const TOOLTIP_PROGRAM_EXPOSURE_RAGE =
  '노출 기간 시작일~진행기간 시작일 사이에는 프로그램 신청을 할 수 있습니다. 프로그램 진행 중에는 노출기간 변경이 불가합니다.';
export const TOOLTIP_PROGRAM_MEMBER_LIMIT =
  '프로그램 작성, 진행 중에는 인원을 늘릴 수만 있습니다.';
export const TOOLTIP_PROGRAM_PARTICIPATION_RAGE =
  '프로그램 진행 중에는 진행 기간 변경이 불가합니다. ';
export const TOOLTIP_PROGRAM_TYPE =
  '신청 들어온 프로그램의 종류를 바꾸는 경우 커리큘럼이 모두 뒤바뀔 수 있어 절대 변경하지 않습니다.';

export const TOOLTIP_PROGRAM_REWARD_GIVE_TYPE =
  '프로그램 진행 중, 종료 후에 원칙적으로 변경하지 않습니다.';
export const TOOLTIP_PROGRAM_REWARD_GOAL_POINT_IN_PROGRAM =
  '프로그램 진행 중에 이수증 발급 목표 변경이 가능하나, 프로그램이 종료되고는 변경할 수 없습니다.';
export const TOOLTIP_PROGRAM_REWARD_GOAL_POINT_IN_REWARD =
  '프로그램 진행 중에 리워드 발급 목표 변경이 가능하나, 리워드를 이미 받아간 사람이 있는 경우 최대한 변경을 하지 않도록 합니다.';
export const TOOLTIP_PROGRAM_REWARD_LOCATION =
  '프로그램 진행 중, 종료 후에 자유롭게 변경이 가능합니다.';
export const TOOLTIP_PROGRAM_REWARD_MAX_COUNT =
  '프로그램 진행 중에 자유롭게 변경이 가능하나, 이미 리워드를 1명이라도 받아간 경우 변경을 자제하는 것이 좋고 개수를 줄이기보단 늘리는 것을 지향합니다.';
export const TOOLTIP_PROGRAM_REWARD_RANGE =
  '프로그램 진행 중에 자유롭게 변경이 가능합니다.';
export const TOOLTIP_PROGRAM_REWARD_USE_TYPE =
  '프로그램 진행 중, 종료 후에 원칙적으로 변경하지 않습니다.';

export const TOOLTIP_QNA_QUESTION_TYPE =
  '질의응답 중 참여자가 있는 경우 질문 수정이 불가합니다.';

export const REWARD_HEALTH_CERT_TITLE = '건강 증서';
export const REWARD_MEMORIAL_CERT_TITLE = '기념 증서';
export const REWARD_HEALTH_CERT_CONTENT =
  '축하드립니다! 나를 바꾸는 건강한 걸음을 모아 챌린지를 달성하셨습니다.';
export const REWARD_MEMORIAL_CERT_CONTENT =
  '축하드립니다! 나를 바꾸는 건강한 걸음을 모아 챌린지를 달성하셨습니다.';

export function getPermission(id: string) {
  return id === 'shjin' || id === 'kmj' ? 1 : 0;
}
export const inforDataTemplate = [
  {
    type: 6,
    title: '이름',
    content: '이름을 입력해주세요',
    hasSubOption: 0,
    subOptions: [],
    parentTitle: '',
    parentOptionIndex: -1,
  },
  {
    type: 3,
    title: '주소',
    content: '주소를 입력해주세요',
    hasSubOption: 0,
    subOptions: [],
    parentTitle: '',
    parentOptionIndex: -1,
  },
  {
    type: 0,
    title: '연락처',
    content: '연락처를 입력해주세요',
    hasSubOption: 0,
    subOptions: [],
    parentTitle: '',
    parentOptionIndex: -1,
  },
];
// invalidateQueries : QueryClient 에서 제공하는 메서드로, 주어지는 키에 매칭되는 쿼리를 stale 한 상태로 만들어서 리패칭 하게 하는 메서드
export function queryInvalidate(key: string | string[]) {
  reactQueryClient.invalidateQueries(key);
}

export function convertDistance(distance: number) {
  const newDistance = Math.round(distance).toString();
  const contentLength = newDistance.length;
  let result: any;
  if (contentLength > 4) {
    const settingKm = Math.floor(distance / 1000);
    const settingMeter = (distance % 1000).toString().split('.')[0];
    result = `${settingKm}.${settingMeter}km`;
  } else if (contentLength === 4) {
    result = `${newDistance[0]},${newDistance.slice(1, 4)}m`;
  } else {
    result = `${newDistance}m`;
  }
  return result;
}

export function convertStep(distance: number) {
  return Math.round((distance * 100) / 60);
}

export function convertTime(time: number) {
  const newTime = Math.round(time);
  if (time / 60 >= 1) {
    const hour = Math.floor(newTime / 60);
    const minute = newTime % 60;
    return `${hour}시간${minute}분`;
  }
  if (newTime === 0) {
    const seconds = Math.round(time * 60);
    return `${seconds}초`;
  }
  return `${newTime}분`;
}

export function dateFormat(currentDate: Date, type: string) {
  const formattedDate = currentDate.toLocaleString(
    'ko-KR',
    type === 'date'
      ? {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }
      : type === 'datepoint'
      ? {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true, // 24시간 형식
        }
      : {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: false, // 24시간 형식
        }
  );

  if (type === 'datepoint') {
    const newDate = `${formattedDate.replace(':', '시 ')}분`;
    return newDate;
  }

  return formattedDate;
}

export function timestamp2string(timestamp: number, degree: number = 1) {
  return new Date(timestamp * degree)
    .toISOString()
    .replaceAll('T', ' ')
    .replaceAll('.000Z', '');
}

export function timestamp2Localestring(timestamp: number, degree: number = 1) {
  var d = new Date(timestamp * degree);
  var dateFormatStr = `${d.getFullYear().toString().padStart(2, '0')}-${(
    d.getMonth() + 1
  )
    .toString()
    .padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')} ${d
    .getHours()
    .toString()
    .padStart(2, '0')}:${d.getMinutes().toString().padStart(2, '0')}:${d
    .getSeconds()
    .toString()
    .padStart(2, '0')}`;

  return dateFormatStr;
}

export function generateUuidId() {
  return uuidv4().replace(/-/gi, '').toUpperCase();
}

export function getRegionCategory() {
  return [
    '경기도',
    '강원특별자치도',
    '경상남도',
    '경상북도',
    '광주광역시',
    '대구광역시',
    '대전광역시',
    '부산광역시',
    '서울특별시',
    '세종특별자치시',
    '울산광역시',
    '인천광역시',
    '전라남도',
    '전북특별자치도',
    '제주특별자치도',
    '충청남도',
    '충청북도',
  ];
}

export function getRegionAmdCode(amdCode: string) {
  const amdCodeList = [
    {
      do: '경기도',
      amdCode: '41',
    },
    { do: '강원특별자치도', amdCode: '51' },
    { do: '경상남도', amdCode: '48' },
    { do: '경상북도', amdCode: '47' },
    { do: '광주광역시', amdCode: '29' },
    { do: '대구광역시', amdCode: '27' },
    { do: '대전광역시', amdCode: '30' },
    { do: '부산광역시', amdCode: '26' },
    { do: '서울특별시', amdCode: '11' },
    { do: '세종특별자치시', amdCode: '36' },
    { do: '울산광역시', amdCode: '31' },
    { do: '인천광역시', amdCode: '28' },
    { do: '전라남도', amdCode: '46' },
    { do: '전북특별자치도', amdCode: '52' },
    { do: '제주특별자치도', amdCode: '50' },
    { do: '충청남도', amdCode: '44' },
    { do: '충청북도', amdCode: '43' },
  ];

  if (amdCode.length !== 2) {
    const found = amdCodeList.filter(
      (amd: any) => amdCode.substring(0, 2) === amd.amdCode
    );
    return found === undefined || found === null ? '' : found[0].do;
  }
  return '';
}

export function splitAddress(address: string) {
  if (address === null || address.length === 0) return '-';
  const newAddress = address.split(' ');

  let simpleAddress = '';
  if (newAddress.length > 2) {
    simpleAddress = newAddress[1].endsWith('시')
      ? `${newAddress[0]} ${newAddress[1]} ${newAddress[2]}`
      : newAddress[1].endsWith('구')
      ? `${newAddress[0]} ${newAddress[1]}`
      : newAddress[1].endsWith('군')
      ? `${newAddress[0]} ${newAddress[1]} ${newAddress[2]}`
      : '-';
  }

  return simpleAddress;
}

export function getPartnerTypeItems() {
  return [
    '보건소',
    '치매센터',
    '정신건강센터',
    '공공기관',
    '주민센터',
    '복지관',
    '학교',
    '교육(지원)청',
    '기업',
    '병원',
    '재단/단체/협회',
    '대행사',
    '교회',
    '기타',
  ];
}

export function getPartnerType(contype: number) {
  var type = '';
  switch (contype) {
    case 1:
      type = '보건소';
      break;
    case 2:
      type = '치매센터';
      break;
    case 3:
      type = '정신건강센터';
      break;
    case 4:
      type = '공공기관';
      break;
    case 5:
      type = '주민센터';
      break;
    case 6:
      type = '복지관';
      break;
    case 7:
      type = '학교';
      break;
    case 8:
      type = '교육(지원)청';
      break;
    case 9:
      type = '기업';
      break;
    case 10:
      type = '병원';
      break;
    case 11:
      type = '재단/단체/협회';
      break;
    case 12:
      type = '대행사';
      break;
    case 13:
      type = '교회';
      break;
    case 99:
      type = '기타';
      break;
    default:
      type = '기타';
  }
  return type;
}

export function getPurposeCodeItems() {
  return [
    '구성원건강관리',
    '지역민건강관리',
    '마케팅',
    '사회공헌',
    '축제/관광/행사',
    '기타',
  ];
}

export function getPurposeCode(purposeCode: number) {
  var type = '';
  switch (purposeCode) {
    case 1:
      type = '구성원건강관리';
      break;
    case 2:
      type = '지역민건강관리';
      break;
    case 3:
      type = '마케팅';
      break;
    case 4:
      type = '사회공헌';
      break;
    case 5:
      type = '축제/관광/행사';
      break;
    default:
      type = '기타';
  }
  return type;
}

export function getChallengeApplyStatus(applyStatus: number) {
  return applyStatus === 1
    ? '작성중'
    : applyStatus === 2
    ? '접수요청'
    : applyStatus === 3
    ? '반려'
    : applyStatus === 4
    ? '접수완료'
    : '미지정';
}

export function getLocalstoreApplyStatus(applyStatus: number) {
  return applyStatus === 1
    ? '작성중'
    : applyStatus === 2
    ? '접수요청'
    : applyStatus === 3
    ? '반려'
    : applyStatus === 4
    ? '접수완료'
    : '미지정';
}

export function getPaymentType() {
  return [
    '카드결제',
    '계좌 이체-월납',
    '계좌 이체-일시납',
    '계좌 이체-분기납',
    '계좌 이체-반기납',
    '계좌 이체-선납',
  ];
}

export function getMessagePaymentType(paymentType: number) {
  return paymentType === 1
    ? '무통장 입금'
    : paymentType === 2
    ? '신용카드'
    : paymentType === 3
    ? '자동환불(전송실패)'
    : paymentType === 4
    ? '문자쿠폰 지급'
    : paymentType === 5
    ? '어드민에서 지급'
    : '-';
}
// 상태 (1:결제 대기, 2:결제 완료, 3:결제 실패)
export function getPaymentStatus(status: number) {
  return status === 1
    ? '결제 대기'
    : status === 2
    ? '결제 완료'
    : status === 3
    ? '결제 실패'
    : '-';
}

export function getMemberLimit() {
  return ['제한 없음', '100명', '300명', '500명', '1000명 이상'];
}

export function getContractStatus() {
  return ['계약 중', '계약 완료', '계약 종료'];
}

export function getHealthItems() {
  return [
    { title: '고혈압', value: '1' },
    { title: '당뇨', value: '2' },
    { title: '체중관리', value: '3' },
    { title: '천식 및 호흡기질환', value: '4' },
    { title: '치매', value: '5' },
    { title: '수면 장애', value: '6' },
    { title: '디스크', value: '7' },
    { title: '관절염', value: '8' },
    { title: '암', value: '9' },
    { title: '만성질환', value: '10' },
    { title: '금연', value: '11' },
    { title: '신체활동', value: '12' },
    { title: '마음건강', value: '13' },
  ];
}

export function getExpirationPeriod() {
  return [
    { title: '1개월', value: '1' },
    { title: '3개월', value: '3' },
    { title: '6개월', value: '6' },
    { title: '9개월', value: '9' },
    { title: '1년', value: '12' },
    { title: '1년6개월', value: '18' },
    { title: '2년', value: '24' },
    { title: '3년', value: '36' },
    { title: '5년', value: '60' },
  ];
}

export function getDayesLater(offset: number) {
  const now = new Date();
  const daysLater = new Date(now);
  return new Date(daysLater.setDate(now.getDate() + offset));
}

export function getCurrentDate() {
  const currentYear = new Date(Date.now()).getFullYear();
  const currentMonth = new Date(Date.now()).getMonth() + 1;
  const currentDay = new Date(Date.now()).getDate();
  return `${currentYear}-${`00${currentMonth}`.slice(
    -2
  )}-${`00${currentDay}`.slice(-2)}`;
}

export function getYearList() {
  var arrYear: string[] = [];
  const endYear = new Date(Date.now()).getFullYear();
  for (var i = 2020; i <= endYear; i += 1) {
    arrYear.push(`${i}년`);
  }

  return arrYear;
}

export function getMonthList() {
  var arrMonth: string[] = [];
  for (var i = 1; i <= 12; i += 1) {
    arrMonth.push(`${i}월`);
  }

  return arrMonth;
}

export const MobileRegexp = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;

export function commaFormat(str: string) {
  return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
}

export function addHyphenToPhoneno(phoneno: string) {
  let newPhoneno = phoneno;

  if (phoneno !== null && phoneno.length >= 9) {
    newPhoneno = phoneno
      .replace(/[^0-9]/g, '')
      .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);

    if (phoneno.indexOf('02') === 0) {
      newPhoneno = phoneno.replace(/^(\d{2})(\d{4})(\d+)/, `$1-$2-$3`);
    }
  }
  return newPhoneno;
}

export function splitPhoneno(phoneno: string) {
  return addHyphenToPhoneno(phoneno).split('-');
}

export function checkImageFileName(str: string) {
  const nonImageExt = [
    'pdf',
    'hwp',
    'hwt',
    'sty',
    'xlsx',
    'xlsm',
    'xlsb',
    'xls',
    'csv',
    'xml',
    'xltx',
    'xltm',
    'xlt',
    'xls',
    'xlam',
    'xps',
    'ods',
    'pptx',
    'pptm',
    'ppt',
    'potx',
    'potm',
    'ppsx',
    'ppsm',
    'pps',
    'ppam',
    'ppa',
    'docx',
    'docm',
    'doc',
    'dotx',
    'dotm',
  ];
  var ext = str.split('.').pop()?.toLocaleLowerCase();
  if (ext && nonImageExt.includes(ext) === false) {
    return true;
  }
  return false;
}

export function validCheck(type: string, str: string) {
  const pattern =
    type === 'email'
      ? /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g
      : type === 'phone'
      ? /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i
      : '';
  const regex = new RegExp(pattern);
  return regex.test(str);
}

export function checkPasswordStrength(password: string) {
  // 정규표현식을 사용하여 각각 대문자, 소문자, 숫자, 특수문자의 포함 여부를 확인
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  // 최소 8자 이상이고, 대문자, 소문자, 숫자, 특수문자가 모두 포함되어 있는 경우 true 반환
  return (
    password.length >= 8 &&
    hasUpperCase &&
    hasLowerCase &&
    hasNumber &&
    hasSpecialChar
  );
}

export function checkJoinCode(joinCode: string) {
  const regex = /^[A-Za-z0-9]+$/;
  if (joinCode === undefined || joinCode === null || joinCode.length === 0)
    return true;
  // 최소 4자 이상, 12자 미만이고, 대문자, 소문자, 숫자 포함되어 있는 경우 true 반환
  return joinCode.length <= 12 && regex.test(joinCode);
}

export function checkNameStrength(name: string) {
  // 최소 2글자 이상 입력, 숫자 및 자음만 입력 불가.
  const hasConsonant = /^[ㄱ-ㅎ]+$/.test(name);
  const hasNumber = /^\d+$/.test(name);
  const hasAlphabet = /^[a-zA-Z]+$/.test(name);
  const hasSpecialChar = /^[!@#$%^&*(),.?":{}|<>]+$/.test(name);
  // 최소 2글자 이하, 숫자 및 자음만으로 구성된 경우 false
  return (
    (name.length < 2 ||
      hasConsonant ||
      hasAlphabet ||
      hasNumber ||
      hasSpecialChar) !== true
  );
}

export function removeNatCodePhoneNumber(str: string) {
  if (str === undefined || str === null) return '';
  return str.replaceAll('+82', '0');
}

export function elapsedTime(
  startTime: number,
  endTime: number,
  degree: number = 1
) {
  const attendStartDate = new Date(startTime * degree);
  const attendEndDate = new Date(endTime * degree);
  const diff = attendEndDate.getTime() - attendStartDate.getTime();
  const elapsedSec = Math.floor((diff / 1000) % 60);
  const elapsedMin = Math.floor((diff / (1000 * 60)) % 60);
  const elapsedHour = Math.floor((diff / (1000 * 60 * 60)) % 24);
  return elapsedHour > 0
    ? `${elapsedHour}시${elapsedMin}분${elapsedSec}초`
    : `${elapsedMin}분${elapsedSec}초`;
}

export function parseGPXContent(xml: any, radius: any, type: number) {
  // 0 : json, 1: array
  const parser = new DOMParser();
  const result: any = [];
  const newXML = parser.parseFromString(xml, 'text/xml');
  const toJson = newXML.querySelectorAll('trkpt');
  console.log('toJson:', toJson);
  toJson.forEach((element: any, idx: number) => {
    // idx % 32 === 1 &&
    //   idx % 64 === 1 &&
    if (idx % 2 && idx % 4 === 1 && idx % 8 === 1 && idx % 16 === 1) {
      if (type === 0) {
        result.push({
          lat: element.attributes[0].value,
          lng: element.attributes[1].value,
          radius: radius,
        });
      } else {
        result.push([
          Number(element.attributes[0].value),
          Number(element.attributes[1].value),
        ]);
      }
    }
  });
  return result;
}

export function getAddUrl(url: string) {
  if (url && !url.startsWith('h')) {
    return `https://${url}`;
  }
  return url;
}

export function getAddUrlWithHttp(url: string) {
  const result = /^(https?):\/\//.test(url);
  if (result) return url;

  const result2 = /^(https?):\//;
  if (result2) return 'http://';

  return `http://${url}`;
}

export function checkUrl(url: string) {
  if (url === null || url === undefined) return '';
  return url === 'http://' ? '' : url;
}

export function encodeSearchWord(searchKey: string, searchWord: string) {
  var newSearchWord = '';

  if (
    searchWord === undefined ||
    searchWord === null ||
    searchWord.length === 0
  )
    return '';

  if (
    searchKey === 'phoneNumber' ||
    searchKey === 'memberPhoneNumber' ||
    searchKey === 'phonenum'
  ) {
    newSearchWord = searchWord.replaceAll('-', '').replaceAll(' ', '').trim();
  } else {
    const tmpSearchWord = Number.isInteger(
      Number(searchWord.replaceAll('-', '').trim())
    )
      ? searchWord.replaceAll('-', '').trim()
      : searchWord.trim();
    newSearchWord = encodeURI(tmpSearchWord);
  }

  // console.log('newSearchWord:', newSearchWord);
  return newSearchWord;
}

export function trimWhiteSpace(inputString: string) {
  var newSearchWord = '';

  if (
    inputString === undefined ||
    inputString === null ||
    inputString.length === 0
  )
    return '';

  newSearchWord = inputString.replaceAll('\t', ' ').trim();

  return newSearchWord;
}

export function phoneNumberHyphen(value: string) {
  if (value === undefined || value === null || value === '') {
    return '--';
  }

  value = value.replace(/[^0-9]/g, '').replaceAll('-', '');

  const result = [];
  var restNumber = '';

  // 지역번호와 나머지 번호로 나누기
  if (value.startsWith('02')) {
    // 서울 02 지역번호
    result.push(value.substring(0, 2));
    restNumber = value.substring(2);
  } else {
    // 나머지 3자리 지역번호
    // 0xx-yyyy-zzzz
    result.push(value.substring(0, 3));
    restNumber = value.substring(3);
  }

  if (restNumber.length === 7) {
    // 7자리만 남았을 때는 xxx-yyyy
    result.push(restNumber.substring(0, 3));
    result.push(restNumber.substring(3));
  } else {
    result.push(restNumber.substring(0, 4));
    result.push(restNumber.substring(4));
  }

  return result.filter((val: any) => val).join('-');
}

export function throwError(status: any) {
  const { logout } = useSession();
  if (status === 401) {
    logout();
  } else {
    throw new Error('http 에러');
  }
}

export function ageFormat(age: any[]) {
  if (age === undefined || age === null) return [];
  const tmpAge = age.sort((a, b) => a - b);
  var newAge: any[] = [];

  if (tmpAge.length === 0) return [];
  // console.log('tmpAge: ', tmpAge);
  newAge.push(tmpAge[0]);
  let pushFlag = 0;
  for (let i = 1; i < tmpAge.length; i += 1) {
    if (Number(tmpAge[i - 1]) + 1 === Number(tmpAge[i])) {
      if (
        pushFlag === 0 &&
        Number(newAge[newAge.length - 1]) + 1 === Number(tmpAge[i])
      ) {
        newAge.push('~');
        newAge.push(tmpAge[i]);
        pushFlag = 1;
      } else {
        newAge[newAge.length - 1] = tmpAge[i];
      }
    } else {
      newAge.push(tmpAge[i]);
      pushFlag = 0;
    }
  }
  console.log('format newAge:', newAge);
  return newAge;
}

export function ageUnFormat(age: any[]) {
  console.log('age: ', age);
  if (age === undefined || age === null) return [];

  var newAge: any[] = [];

  for (let i = 0; i < age.length; i += 1) {
    if (age[i - 1] === '~') {
      console.log(
        'Number(age[i - 2]) + 1:',
        Number(age[i - 2]) + 1,
        ',  Number(age[i]):',
        Number(age[i])
      );
      for (let j = Number(age[i - 2]) + 1; j <= Number(age[i]); j += 1) {
        newAge.push(j);
      }
    } else if (age[i] !== '~') {
      newAge.push(age[i]);
    }
  }
  console.log('unformat newAge:', newAge);
  return newAge;
}

export function splitLocaitonLimit(location: string) {
  const newLocation = location.split(' ');
  const newSi =
    newLocation.length > 1 ? newLocation[1].replace('시', '시 ') : '';

  return newLocation.length > 1 ? `${newLocation[0]} ${newSi}` : location;
}

export function getWeekDates(year: number, weekNumber: number, offset = 0) {
  // offset : 0 : sunday start, 1: monday start
  const firstDayOfYear = new Date(year, 0, 1);
  const daysToFirstDayOfWeek = (weekNumber - 1) * 7;
  const firstDayOfWeek = new Date(year, 0, 1 + daysToFirstDayOfWeek + offset);
  const lastDayOfWeek = new Date(
    year,
    0,
    1 + daysToFirstDayOfWeek + 6 + offset
  );

  return {
    start: firstDayOfWeek,
    end: lastDayOfWeek,
  };
}

export function getCurriculumDration(
  year: number,
  weekNumber: number,
  idx: number
) {
  const duration = getWeekDates(year, weekNumber + idx, 0);

  const newDuration = `${duration.start
    .getFullYear()
    .toString()
    .substring(2, 4)}.${(duration.start.getMonth() + 1)
    .toString()
    .padStart(2, '0')}.${duration.start
    .getDate()
    .toString()
    .padStart(2, '0')}~${duration.end
    .getFullYear()
    .toString()
    .substring(2, 4)}.${(duration.end.getMonth() + 1)
    .toString()
    .padStart(2, '0')}.${duration.end.getDate().toString().padStart(2, '0')}`;
  return newDuration;
}

export function getWeekNumber(date: string) {
  const targetDate = new Date(date);

  const firstDayOfYear = new Date(targetDate.getFullYear(), 0, 1);
  const millisecondsPerWeek = 7 * 24 * 60 * 60 * 1000;
  const diff = Number(targetDate) - Number(firstDayOfYear);
  const weekNumber = Math.ceil((diff + 1) / millisecondsPerWeek);
  return weekNumber;
}

export function getDateRange(startDate: any, endDate: any, range: any) {
  const dateRange = [];
  const currentDate = new Date(startDate);
  const stopDate = new Date(endDate);

  switch (range) {
    case 'day':
      while (currentDate <= stopDate) {
        dateRange.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }
      break;

    case 'week':
      while (currentDate <= stopDate) {
        dateRange.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 7);
      }
      break;

    case 'month':
      while (currentDate <= stopDate) {
        dateRange.push(new Date(currentDate));
        currentDate.setMonth(currentDate.getMonth() + 1);
      }
      break;

    default:
      throw new Error('invalid range.');
  }

  return dateRange;
}

const showRangeSample = () => {
  const startDate = '2023-02-01';
  const endDate = '2023-12-31';

  const dailyRange = getDateRange(startDate, endDate, 'day');
  console.log('일별:', dailyRange);

  const weeklyRange = getDateRange(startDate, endDate, 'week');
  console.log('주별:', weeklyRange);

  const monthlyRange = getDateRange(startDate, endDate, 'month');
  console.log('월별:', monthlyRange);
};

export function checkNaN(str: string, value: number) {
  return Number.isNaN(Number(str)) ? value : Number(str);
}

export function calculateDays(startDate: any, endDate: any) {
  const start = new Date(startDate);
  const end = new Date(endDate);

  // 시작 날짜와 끝 날짜 사이의 날짜 수 계산
  const differenceInTime = end.getTime() - start.getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);

  return Math.round(differenceInDays);
}

export function getReportCurrentYear() {
  const now = new Date(Date.now());
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth() + 1;
  return currentYear - 1;
}

export function convertIphoneModel(model: string) {
  // 참조 : https://gist.github.com/adamawolf/3048717
  let newModel = '-';
  if (model.includes('iPhone1,1')) newModel = 'iPhone';
  else if (model.includes('iPhone1,2')) newModel = 'iPhone 3G';
  else if (model.includes('iPhone2,1')) newModel = 'iPhone 3GS';
  else if (model.includes('iPhone3,1')) newModel = 'iPhone 4';
  else if (model.includes('iPhone3,2')) newModel = 'iPhone 4 GSM Rev A';
  else if (model.includes('iPhone3,3')) newModel = 'iPhone 4 CDMA';
  else if (model.includes('iPhone4,1')) newModel = 'iPhone 4S';
  else if (model.includes('iPhone5,1')) newModel = 'iPhone 5 (GSM)';
  else if (model.includes('iPhone5,2')) newModel = 'iPhone 5 (GSM+CDMA)';
  else if (model.includes('iPhone5,3')) newModel = 'iPhone 5C (GSM)';
  else if (model.includes('iPhone5,4')) newModel = 'iPhone 5C (Global)';
  else if (model.includes('iPhone6,1')) newModel = 'iPhone 5S (GSM)';
  else if (model.includes('iPhone6,2')) newModel = 'iPhone 5S (Global)';
  else if (model.includes('iPhone7,1')) newModel = 'iPhone 6 Plus';
  else if (model.includes('iPhone7,2')) newModel = 'iPhone 6';
  else if (model.includes('iPhone8,1')) newModel = 'iPhone 6s';
  else if (model.includes('iPhone8,2')) newModel = 'iPhone 6s Plus';
  else if (model.includes('iPhone8,4')) newModel = 'iPhone SE (GSM)';
  else if (model.includes('iPhone9,1')) newModel = 'iPhone 7';
  else if (model.includes('iPhone9,2')) newModel = 'iPhone 7 Plus';
  else if (model.includes('iPhone9,3')) newModel = 'iPhone 7';
  else if (model.includes('iPhone9,4')) newModel = 'iPhone 7 Plus';
  else if (model.includes('iPhone10,1')) newModel = 'iPhone 8';
  else if (model.includes('iPhone10,2')) newModel = 'iPhone 8 Plus';
  else if (model.includes('iPhone10,3')) newModel = 'iPhone X Global';
  else if (model.includes('iPhone10,4')) newModel = 'iPhone 8';
  else if (model.includes('iPhone10,5')) newModel = 'iPhone 8 Plus';
  else if (model.includes('iPhone10,6')) newModel = 'iPhone X GSM';
  else if (model.includes('iPhone11,2')) newModel = 'iPhone XS';
  else if (model.includes('iPhone11,4')) newModel = 'iPhone XS Max';
  else if (model.includes('iPhone11,6')) newModel = 'iPhone XS Max Global';
  else if (model.includes('iPhone11,8')) newModel = 'iPhone XR';
  else if (model.includes('iPhone12,1')) newModel = 'iPhone 11';
  else if (model.includes('iPhone12,3')) newModel = 'iPhone 11 Pro';
  else if (model.includes('iPhone12,5')) newModel = 'iPhone 11 Pro Max';
  else if (model.includes('iPhone12,8')) newModel = 'iPhone SE 2nd Gen';
  else if (model.includes('iPhone13,1')) newModel = 'iPhone 12 Mini';
  else if (model.includes('iPhone13,2')) newModel = 'iPhone 12';
  else if (model.includes('iPhone13,3')) newModel = 'iPhone 12 Pro';
  else if (model.includes('iPhone13,4')) newModel = 'iPhone 12 Pro Max';
  else if (model.includes('iPhone14,2')) newModel = 'iPhone 13 Pro';
  else if (model.includes('iPhone14,3')) newModel = 'iPhone 13 Pro Max';
  else if (model.includes('iPhone14,4')) newModel = 'iPhone 13 Mini';
  else if (model.includes('iPhone14,5')) newModel = 'iPhone 13';
  else if (model.includes('iPhone14,6')) newModel = 'iPhone SE 3rd Gen';
  else if (model.includes('iPhone14,7')) newModel = 'iPhone 14';
  else if (model.includes('iPhone14,8')) newModel = 'iPhone 14 Plus';
  else if (model.includes('iPhone15,2')) newModel = 'iPhone 14 Pro';
  else if (model.includes('iPhone15,3')) newModel = 'iPhone 14 Pro Max';
  else if (model.includes('iPhone15,4')) newModel = 'iPhone 15';
  else if (model.includes('iPhone15,5')) newModel = 'iPhone 15 Plus';
  else if (model.includes('iPhone16,1')) newModel = 'iPhone 15 Pro';
  else if (model.includes('iPhone16,2')) newModel = 'iPhone 15 Pro Max';

  return newModel;
}

function toRadians(degrees: number) {
  return degrees * (Math.PI / 180);
}

function toDegrees(radians: number) {
  return radians * (180 / Math.PI);
}

export function addDistanceToCoordinates(
  lat: number,
  lon: number,
  distanceInKm: number
) {
  const R = 6371; // 지구의 반지름 (단위: km)

  // 각도를 라디안으로 변환
  const latRad = toRadians(lat);
  const lonRad = toRadians(lon);

  // 새로운 위도 계산
  const newLatRad = latRad + distanceInKm / R;

  // 새로운 경도 계산
  const newLonRad = lonRad + distanceInKm / R / Math.cos(latRad);

  // 라디안을 각도로 변환
  const newLat = toDegrees(newLatRad);
  const newLon = toDegrees(newLonRad);

  return { lat: newLat, lng: newLon };
}

export function getGeometryObjectCenterLocation(coordinates: any[]) {
  if (coordinates.length === 0) {
    return null; // 좌표 배열이 비어있으면 중심 좌표를 계산할 수 없음
  }

  // 각 좌표의 위도와 경도의 합 구하기
  const sumLat = coordinates.reduce((acc, coord) => acc + coord.lat, 0);
  const sumLng = coordinates.reduce((acc, coord) => acc + coord.lng, 0);

  // 평균 위도와 경도 계산
  const avgLat = sumLat / coordinates.length;
  const avgLng = sumLng / coordinates.length;

  return { lat: avgLat, lng: avgLng };
}

export function squareMetersToSquareKilometers(squareMeters: number) {
  return Number((squareMeters / 1000000).toFixed(1)); // 1 square kilometer = 1,000,000 square meters
}

export function isPointInPolygon(point: any, polygon: any[]) {
  let isInside = false;

  let j = polygon.length - 1;
  for (let i = 0; i < polygon.length; i += 1) {
    const xi = polygon[i].lng;
    const yi = polygon[i].lat;
    const xj = polygon[j].lng;
    const yj = polygon[j].lat;
    const intersect =
      yi > point.lat !== yj > point.lat &&
      point.lng < ((xj - xi) * (point.lat - yi)) / (yj - yi) + xi;

    if (intersect) {
      isInside = !isInside;
    }
    j = i;
  }

  return isInside;
}

export function percentageCalculator(numerator: number, denominator: number) {
  const percentage = denominator !== 0 ? (numerator / denominator) * 100 : 0;
  return percentage.toFixed(1);
}

export function getDeletedReason(reason: number) {
  return reason === 1
    ? '어드민에서 탈퇴'
    : reason === 2
    ? '민감정보 동의 철회'
    : reason === 3
    ? '성별:나이 변경'
    : reason === 4
    ? '커뮤니티 탈퇴'
    : reason === 5
    ? '워크온 탈퇴'
    : reason === 6
    ? '중복유저 탈퇴'
    : reason === 7
    ? '관리자에 의해 탈퇴'
    : reason === 8
    ? '휴면계정'
    : reason === 9
    ? '어드민에서 커뮤니티 탈퇴'
    : reason === 10
    ? '어드민에서 챌린지 탈퇴'
    : reason === 11
    ? '어드민에서 프로그램 탈퇴'
    : reason === 12
    ? '관리자에 의해 챌린지 탈퇴'
    : reason === 13
    ? '관리자에 의해 프로그램 탈퇴'
    : reason === 14
    ? '커뮤니티 그룹 변경'
    : '-';
}

export function getAttendType(type: number) {
  return type === 0
    ? ''
    : type === 1
    ? '(강제참여)'
    : type === 2
    ? '(API참여)'
    : '';
}

export function getPushLinkCode(pushLink: string) {
  return pushLink === 'home'
    ? 1
    : pushLink === 'community'
    ? 2
    : pushLink === 'challenge'
    ? 3
    : pushLink === 'program'
    ? 4
    : pushLink === 'questionanswer'
    ? 5
    : pushLink === 'reward'
    ? 6
    : pushLink === 'url'
    ? 7
    : 99;
}

export function getPushLinkByCode(linkTye: number) {
  return linkTye === 1
    ? 'home'
    : linkTye === 2
    ? 'community'
    : linkTye === 3
    ? 'challenge'
    : linkTye === 4
    ? 'program'
    : linkTye === 5
    ? 'questionanswer'
    : linkTye === 6
    ? 'reward'
    : linkTye === 7
    ? 'url'
    : 'etc';
}
