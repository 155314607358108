import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ClearIcon from '@mui/icons-material/Clear';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CloseIcon from '@mui/icons-material/Close';
import koLocale from 'date-fns/locale/ko';
import SearchIcon from '@mui/icons-material/Search';
import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { debouncehook } from '../../../hooks/debouncehook';
import { useMany } from '../../../hooks/api';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwDatesPicker } from '../../commonComponent/dateSetting/SwDatesPicker';
import { SwSearchTarget } from '../../commonComponent/SwSearchTarget';
import { IPush, DefaultPush } from '../../../models/push';
import { RESULT_OK } from '../../../common/resultCode';
import { SwLoadUser } from '../../commonComponent/SwLoadUser';
import {
  commaFormat,
  encodeSearchWord,
  getDayesLater,
  getPushLinkCode,
  MAX_LOAD_USER_COUNT,
  timestamp2Localestring,
} from '../../../common/helper';
import { SwPushSelectLocation } from '../../commonComponent/SwPushSelectLocation';
import { SwPushSelectOsVersion } from '../../commonComponent/SwPushSelectOsVersion';
import { getRecentLocaitonList } from '../../../common/api/ApiGeohash';
import {
  KEY_APP_OS_RECENT_VERSION,
  KEY_APP_OS_VERSION_LIST,
  KEY_GEOHASH_RECENT_LOCATION_LIST,
  KEY_REGISTERED_PUSH_LIST,
  KEY_TARGET_LIST,
  KEY_TARGET_PUSH_LIST,
} from '../../../common/key';
import SwLoading from '../../commonComponent/spinner/SwLoading';
import { getTarget } from '../../../common/api/ApiCommon';
import { SwDatesPickerWithScroll } from '../../commonComponent/dateSetting/SwDatesPickerWithScroll';
import { getAppOsRecentVersion } from '../../../common/api/ApiUser';
import { SwOsVersionSetup } from '../../commonComponent/SwOsVersionSetup';
import {
  postAppUpdateGuideInfo,
  updateAppUpdateGuideInfo,
} from '../../../common/api/ApiAppUpdateManage';
import { SwLoadUserIdByPhoneno } from '../../commonComponent/SwLoadUserIdByPhoneno';

interface Props {
  appUpdateGuideInfo: any;
  onReload?: (idx: number) => void;
}

const RootPaper = styled(Paper)({
  // margin: '2.8rem',
  padding: '1rem',
  borderRadius: '12px',
});

const ScrollPaper = styled(Paper)({
  maxHeight: '10rem',
  overflowY: 'scroll',
  padding: '.5rem',
  width: '44rem ',
});

const UserBox = styled(Box)({
  margin: '.4rem .4rem .4rem 0',
  color: '#777777',
  display: 'flex',
  alignItems: 'center',
});

const DEFAULT_TITLE = `새로운 업데이트 소식이 있어요!`;
const DEFAULT_CONTENT_TEXT = `예시) 이번 업데이트를 통해 
  •  새로운 기능 추가 : 새로운 챌린지 타입 추가
  •  앱의 성능 개선
  •  버그 수정 
  지금 업데이트하고, 더 나은 경험을 누려보세요!`;

export const AppUpdateGuideForm: React.FC<Props> = ({
  appUpdateGuideInfo,
  onReload,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const findReceiverRef = useRef<any>();
  const [recentVersion, setRecentVersion] = useState<any>();
  const [targetType, setTargetType] = useState<number>(1);
  const [osType, setOsType] = useState<number>(0);
  const [aosVersion, setAosVersion] = useState<any>();
  const [iosVersion, setIosVersion] = useState<any>();
  const [title, setTitle] = useState<string>(DEFAULT_TITLE);
  const [content, setContent] = useState<string>(DEFAULT_CONTENT_TEXT);
  const [viewDate, setViewDate] = useState<any[]>([]);
  const [viewDuration, setViewDuration] = useState<number>(7);
  const [viewRetry, setViewRetry] = useState<number>(3);
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [loadUserList, setLoadUserList] = useState<any>([]);
  const [searchResult, setSearchResult] = useState<any[]>([]);
  const [selectedUserList, setSelectedUserList] = useState<any[]>([]);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [searchKey, setSearchKey] = useState<string>('user');
  const [searchWord, setSearchWord] = useState<string>('');
  const [openLoadUser, setOpenLoadUser] = useState<number>(0);
  const [openSearchResult, setOpenSearchResult] = useState<boolean>(false);
  const [loading, setLoading] = useState<any>(null);

  const setupAppUpdateGuideInfo = (newAppUpdateGuideInfo: any) => {
    const newAosVersion: any = {
      minVersion: newAppUpdateGuideInfo.aosMinVersion,
      maxVersion: newAppUpdateGuideInfo.aosMaxVersion,
      count: newAppUpdateGuideInfo.aosCount,
    };
    setAosVersion(newAosVersion);
    const newIosVersion: any = {
      minVersion: newAppUpdateGuideInfo.iosMinVersion,
      maxVersion: newAppUpdateGuideInfo.iosMaxVersion,
      count: newAppUpdateGuideInfo.iosCount,
    };
    setIosVersion(newIosVersion);
    setTitle(newAppUpdateGuideInfo.title);
    setContent(newAppUpdateGuideInfo.content);
    setViewRetry(newAppUpdateGuideInfo.viewRetry);
    setViewDuration(newAppUpdateGuideInfo.viewDuration);
    setViewDate([new Date(newAppUpdateGuideInfo.viewDate * 1000)]);
    setTargetType(newAppUpdateGuideInfo.type);
    setOsType(newAppUpdateGuideInfo.subType);
    if (newAppUpdateGuideInfo.type === 2) {
      const parsedData = JSON.parse(newAppUpdateGuideInfo.usersJson);
      setSelectedUserList(parsedData);
    } else {
      setSelectedUserList([]);
    }
  };

  const {
    data,
    isLoading,
    refetch: refetchAppOsVersion,
  } = useQuery(KEY_APP_OS_RECENT_VERSION, () => getAppOsRecentVersion(), {
    onSuccess: res => {
      setLoading(false);
      if (res !== null) {
        setRecentVersion(res.versions);
      }
    },
    onError: e => {
      setLoading(false);
      console.log(e);
    },
  });

  const { mutate: postAppUpdateInfoMutate, isError: isPostMutateError } =
    useMutation(postAppUpdateGuideInfo, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setOpenSnackbar(true);
          setAlertMessage('안내팝업을 등록했습니다.');
          onReload && onReload(appUpdateGuideInfo.idx);
        } else {
          setAlertMessage(
            `안내팝업 등록이 실패했습니다.(${res.resultCodeMsg})`
          );
          setAlertOpen(true);
        }
      },
      onError: error => {
        setOpenSnackbar(true);
        setAlertMessage(
          '안내팝업 등록이 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });

  const { mutate: updateAppUpdateInfoMutate, isError: isUpdateMutateError } =
    useMutation(updateAppUpdateGuideInfo, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setOpenSnackbar(true);
          setAlertMessage('안내팝업을 등록했습니다.');
          onReload && onReload(appUpdateGuideInfo.idx);
        } else {
          setAlertMessage(
            `안내팝업 등록이 실패했습니다.(${res.resultCodeMsg})`
          );
          setAlertOpen(true);
        }
      },
      onError: error => {
        setOpenSnackbar(true);
        setAlertMessage(
          '안내팝업 등록이 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });

  const doSave = () => {
    const newUsers = selectedUserList.map((item: any, idx: number) => item.id);
    const newAosData: any = {
      recentVersion: recentVersion.aosVersion,
      minVersion: aosVersion.minVersion,
      maxVersion: aosVersion.maxVersion,
      count: aosVersion.count,
    };
    const newIosData: any = {
      recentVersion: recentVersion.iosVersion,
      minVersion: iosVersion.minVersion,
      maxVersion: iosVersion.maxVersion,
      count: iosVersion.count,
    };
    const newData: any = {
      idx: appUpdateGuideInfo !== null ? appUpdateGuideInfo.idx : -1,
      type: targetType,
      subType: osType,
      aosData: newAosData,
      iosData: newIosData,
      viewDate: viewDate[0].getTime() / 1000,
      viewDuration: viewDuration,
      viewRetry: viewRetry,
      title: title,
      content: content,
      users: newUsers !== null && newUsers.length > 0 ? newUsers : [],
    };
    const newAppUpdateInfo: any = {
      appUpdateInfo: newData,
    };
    isCreate
      ? postAppUpdateInfoMutate(newAppUpdateInfo)
      : updateAppUpdateInfoMutate(newAppUpdateInfo);
  };

  const checkValid = () => {
    var isValid = 0;
    if (isValid === 0 && targetType === 2 && selectedUserList.length === 0) {
      isValid = 1;
    }
    if (
      isValid === 0 &&
      targetType === 1 &&
      osType === 0 &&
      (aosVersion.minVersion === '' || iosVersion.minVersion === '')
    ) {
      isValid = 2;
    }

    if (
      isValid === 0 &&
      targetType === 1 &&
      osType === 1 &&
      aosVersion.minVersion === ''
    ) {
      isValid = 2;
    }

    if (
      isValid === 0 &&
      targetType === 1 &&
      osType === 2 &&
      iosVersion.minVersion === ''
    ) {
      isValid = 2;
    }

    if (title.length === 0) {
      isValid = 3;
    }

    if (content.length === 0) {
      isValid = 4;
    }

    if (
      !isCreate &&
      (appUpdateGuideInfo === null || appUpdateGuideInfo.idx <= 0)
    ) {
      isValid = 5;
    }

    if (isValid === 0) {
      doSave();
    } else {
      var message = '';
      switch (isValid) {
        case 1:
          message = '사용자를 추가해주세요';
          break;
        case 2:
          message = '적용 OS의 버전을 선택해주세요';
          break;
        case 3:
          message = '제목을 입력해주세요';
          break;
        case 4:
          message = '내용을 입력해주세요';
          break;
        case 5:
          message = '수정할 안내 팝업을 선택해주세요';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
      setOpenSnackbar(true);
      setAlertMessage(message);
    }
  };

  const deleteUserList = (idx: number) => {
    const newUserList = selectedUserList.filter(
      (item: any, i: number) => i !== idx
    );
    setSelectedUserList(newUserList);
  };

  const searchTarget = () => {
    setSearchWord(
      encodeSearchWord('phoneNumber', findReceiverRef.current?.value)
    );
    setOpenSearchResult(true);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchTarget();
    }
  };

  const resetData = () => {
    setOsType(0);
    setAosVersion([]);
    setIosVersion([]);
    setSelectedUserList([]);
  };

  useEffect(() => {
    if (loadUserList.length > 0) {
      const newUserList = loadUserList
        .filter((item: any) => {
          const newData = selectedUserList.filter(
            (i: any, idx: number) => i.id === item.id
          );
          if (newData !== null && newData.length > 0) return false;
          return true;
        })
        .map((item: any, idx: number) => {
          const newData: any = { id: item.id, name: item.name };
          return newData;
        });
      if (
        newUserList.length > 0 &&
        newUserList.length !== loadUserList.length
      ) {
        setOpenSnackbar(true);
        setAlertMessage('중복 사용자 아이디를 제외하고 추가했습니다.');
      }
      setSelectedUserList((prevState: any) => [...prevState, ...newUserList]);
    }
  }, [loadUserList]);

  useEffect(() => {
    if (searchResult.length > 0) {
      const newData = selectedUserList.filter(
        (item: any, idx: number) => item.id === searchResult[0].id
      );
      if (
        (selectedUserList.length > 0 && newData.length === 0) ||
        selectedUserList.length === 0
      ) {
        setSelectedUserList((prevState: any) => [
          ...prevState,
          { name: searchResult[0].name, id: searchResult[0].id },
        ]);
      } else {
        setOpenSnackbar(true);
        setAlertMessage('중복 사용자 아이디입니다.');
      }
    }
  }, [searchResult]);

  useEffect(() => {
    const newIsCreate = location.pathname.includes('regist');
    setIsCreate(newIsCreate);

    if (
      !newIsCreate &&
      appUpdateGuideInfo !== undefined &&
      appUpdateGuideInfo !== null
    ) {
      setupAppUpdateGuideInfo(appUpdateGuideInfo);
    } else {
      resetData();
      setViewDate([getDayesLater(3)]);
    }
  }, [appUpdateGuideInfo]);

  return (
    <>
      <RootPaper className='root_box'>
        <Box sx={{ p: '1.2rem' }}>
          <Box>
            <Typography className='title'>최신 업데이트 버전 정보</Typography>
            <Box className='flex_start'>
              <Box>
                <Typography className=''>안드로이드</Typography>
                <TextField
                  sx={{ width: '10rem', mb: 0 }}
                  value={recentVersion ? recentVersion.aosVersion : ''}
                />
              </Box>
              <Box sx={{ ml: '2rem' }}>
                <Typography className=''>iOS</Typography>
                <TextField
                  sx={{ width: '10rem', mb: 0 }}
                  value={recentVersion ? recentVersion.iosVersion : ''}
                />
              </Box>
            </Box>
            <Divider />
          </Box>
          <Box>
            <Box className='flex_start'>
              <Typography className='title'>적용 대상 설정 방식</Typography>
            </Box>
            <Box>
              <RadioGroup
                row
                aria-labelledby='movie-row-radio-buttons-group-label'
                name='row-radio-buttons-group'
                value={targetType === 1 ? 'os' : 'user'}
                onChange={evt => {
                  resetData();
                  evt.currentTarget.value === 'os'
                    ? setTargetType(1)
                    : setTargetType(2);
                }}
              >
                <FormControlLabel
                  value='os'
                  control={<Radio />}
                  label='OS 버전'
                />
                <FormControlLabel
                  value='user'
                  control={<Radio />}
                  label='사용자'
                />
              </RadioGroup>
            </Box>
            <Divider />
          </Box>
          {targetType === 1 && (
            <>
              <Box>
                <Box className=''>
                  <Typography className='title'>적용 OS 선택</Typography>
                </Box>
                <TextField
                  select
                  sx={{ width: '10rem' }}
                  value={osType}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => {
                    setOsType(Number(event.target.value));
                  }}
                >
                  {['모든 OS', '안드로이드', 'iOS'].map(
                    (item: any, idx: number) => (
                      <MenuItem
                        id={idx.toString()}
                        value={idx}
                        key={`os_tpe_${idx}`}
                      >
                        {item}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </Box>
              <Divider />
              <Box>
                {(osType === 0 || osType === 1) && (
                  <SwOsVersionSetup
                    osType={1}
                    osVersion={aosVersion}
                    setOsVersion={setAosVersion}
                    resetTag={osType === 0}
                  />
                )}
              </Box>
              <Box>
                {(osType === 0 || osType === 2) && (
                  <SwOsVersionSetup
                    osType={2}
                    osVersion={iosVersion}
                    setOsVersion={setIosVersion}
                    resetTag={osType === 0}
                  />
                )}
              </Box>
            </>
          )}
          {targetType === 2 && (
            <>
              <Typography className='title'>사용자 추가</Typography>
              <TextField
                placeholder='사용자 핸드폰번호 검색'
                inputRef={findReceiverRef}
                sx={{ width: '44rem' }}
                onKeyDown={evt => {
                  handleKeyDown(evt);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() => {
                          searchTarget();
                        }}
                        sx={{ pr: 0 }}
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                color='info'
                sx={{ ml: '1rem' }}
                variant='contained'
                onClick={() => {
                  setOpenLoadUser(1);
                }}
              >
                복사하여 업로드
              </Button>
              <Paper
                sx={{
                  backgroundColor: '#FCFCFC',
                  p: '2rem 2rem 1rem',
                }}
              >
                <Box sx={{ height: '10rem', overflowY: 'scroll' }}>
                  <Typography fontWeight={500}>개인 아이디 목록</Typography>
                  {selectedUserList &&
                    selectedUserList.map((item: any, idx: number) => (
                      <UserBox key={idx.toString()}>
                        <Typography>{`${item.name}: ${item.id}`}</Typography>
                        <ClearIcon
                          className='clear_icon_button'
                          onClick={() => deleteUserList(idx)}
                        />
                      </UserBox>
                    ))}
                </Box>
                <Box sx={{ textAlign: 'right' }}>
                  <Button
                    color='error'
                    onClick={() => {
                      setSelectedUserList([]);
                    }}
                  >
                    <DeleteOutlineIcon />
                    전체 삭제
                  </Button>
                </Box>
              </Paper>
              {selectedUserList.length > 0 && (
                <Typography className='bluecontent'>{`대상자 수: ${commaFormat(
                  String(selectedUserList.length)
                )}명`}</Typography>
              )}
              <Divider />
            </>
          )}

          <Box>
            <Typography className='title'>제목</Typography>
            <TextField
              fullWidth
              onChange={evt => {
                setTitle(evt.target.value.substring(0, 30));
              }}
              value={title}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <span>{title.length} / 30</span>
                  </InputAdornment>
                ),
              }}
            />
            <Divider />
          </Box>
          <Box>
            <Typography className='title'>내용</Typography>
            <TextField
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: 'auto',
                  padding: '.5rem',
                },
              }}
              fullWidth
              multiline
              rows={6}
              onChange={evt => {
                setContent(evt.target.value.substring(0, 200));
              }}
              value={content}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <span>{content.length} / 200</span>
                  </InputAdornment>
                ),
              }}
            />
            <Divider />
          </Box>
          <Box>
            <Typography className='title'>첫 번째 팝업 노출 시기</Typography>
            <Typography className='' sx={{ mb: '.5rem' }}>
              업데이트가 출시되고 2~3일 후를 권장합니다.
            </Typography>
            <SwDatesPickerWithScroll
              title='참여날짜 선택'
              dates={viewDate}
              setDates={setViewDate}
              showButton={false}
              allowPastDate={true}
            />
            <Divider />
          </Box>
          <Box>
            <Typography className='title'>노출 주기 설정</Typography>
            <Typography className='' sx={{ mb: '.5rem' }}>
              첫 번째 팝업 노출 후, 사용자가 업데이트를 완료하지 않으면
              반복적으로 노출되는 주기를 설정해 주세요.
            </Typography>
            <Box className='flex_start'>
              <Box sx={{ mr: '1rem' }}>
                <Typography className='title'>재노출 일수</Typography>
                <TextField
                  select
                  sx={{ width: '10rem' }}
                  value={viewDuration}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => {
                    setViewDuration(Number(event.target.value));
                  }}
                >
                  {[1, 7, 14].map((item: any, idx: number) => (
                    <MenuItem
                      id={idx.toString()}
                      value={item}
                      key={`duration_${idx}`}
                    >
                      {`${item}일`}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box>
                <Typography className='title'>최대 반복 횟수</Typography>
                <TextField
                  select
                  sx={{ width: '10rem' }}
                  value={viewRetry}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => {
                    setViewRetry(Number(event.target.value));
                  }}
                >
                  {[1, 2, 3, 4, 5].map((item: any, idx: number) => (
                    <MenuItem
                      id={idx.toString()}
                      value={item}
                      key={`retry_${idx}`}
                    >
                      {`${item}회`}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Box>
            <Divider />
          </Box>
        </Box>
      </RootPaper>
      <Box className='flex_end'>
        {!isCreate && (
          <Button
            sx={{ width: '4rem', mr: '1rem' }}
            variant='outlined'
            color='info'
            onClick={() => {}}
          >
            종료
          </Button>
        )}
        <Button
          sx={{ width: '4rem' }}
          variant='contained'
          color='info'
          onClick={checkValid}
        >
          저장
        </Button>
      </Box>
      {loading && <SwLoading />}
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={alertMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={alertMessage}
      />
      <SwSearchTarget
        title='사용자'
        searchKey={searchKey}
        searchWord={searchWord}
        open={openSearchResult}
        onClose={() => {
          setOpenSearchResult(false);
        }}
        targetData={[]}
        setTarget={setSearchResult}
      />
      <SwLoadUserIdByPhoneno
        open={!!openLoadUser}
        onclose={() => {
          setOpenLoadUser(0);
        }}
        setLoadList={setLoadUserList}
        maxSize={MAX_LOAD_USER_COUNT}
      />
    </>
  );
};
