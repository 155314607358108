import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState, Dispatch } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useMany } from '../../hooks/api';
import { getWalkcourseList } from '../../common/api/ApiWalkcourse';
import { KEY_WALKCOURSE_LIST } from '../../common/key';
import { WalkwayLocalSimple } from './WalkwayLocalSimple';

interface Props {
  title?: string;
  open: boolean;
  onClose: () => void;
  setTarget: Dispatch<React.SetStateAction<any[]>>;
}

export const SwSearchWalkwayLocal: React.FC<Props> = ({
  title,
  open,
  onClose,
  setTarget,
}) => {
  const [walkwayCommand, setWalkwayCommand] = useState<any>();

  useEffect(() => {
    setTarget(walkwayCommand);
  }, [walkwayCommand]);

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiDialog-container': {
          height: '85%',
        },
      }}
    >
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogContent sx={{ p: '1rem 2rem' }}>
        <Typography sx={{ mt: '0.7rem' }}>워크웨이 지역 선택</Typography>
        <WalkwayLocalSimple
          setWalkwayCommand={setWalkwayCommand}
          isSearch={true}
        />
      </DialogContent>
      <DialogActions>
        <Button color='info' fullWidth size='small' onClick={onClose}>
          취소
        </Button>
        <Button color='primary' fullWidth size='small' onClick={onClose}>
          확인
        </Button>
      </DialogActions>
    </Dialog>
  );
};
