import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import React, {
  ChangeEvent,
  useEffect,
  useRef,
  useState,
  SetStateAction,
  Dispatch,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { MultilineTextField } from '../styles/Styles';
import { SwDatesPicker } from './dateSetting/SwDatesPicker';
import { SwSnackbar } from './views/SwSnackbar';
import { SwAlert } from './views/SwAlert';
import { RESULT_OK } from '../../common/resultCode';
import {
  addChallengeAttendUser,
  changeChallengeAttendUser,
} from '../../common/api/ApiChallenge';
import { validCheck } from '../../common/helper';
import { KEY_WORD_PHONE } from '../../common/key';
import { SwDatesPickerWithScroll } from './dateSetting/SwDatesPickerWithScroll';

interface Props {
  challengeId: string;
  attendType: number;
  open: boolean;
  onclose: () => void;
}

export const SwLoadAttendUser: React.FC<Props> = ({
  challengeId,
  attendType,
  open,
  onclose,
}) => {
  const [openSearchResult, setOpenSearchResult] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [searchResult, setSearchResult] = useState<any>();
  const [title, setTitle] = useState<string>('');
  const [content, setContent] = useState<any>('');
  const [phonenoContent, setPhonenoContent] = useState<any>('');
  const [attendDates, setAttendDates] = useState<any[]>([]);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const showError = (msg: string) => {
    setOpenSnackbar(true);
    setAlertMessage(msg);
  };

  const { mutate: addChallengeAttendUserMutation, isError: addMutateError } =
    useMutation(addChallengeAttendUser, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setOpenSnackbar(true);
          setAlertMessage('요청한 사용자를 챌린지 사용자에 추가했습니다.');
        } else {
          showError(
            `챌린지 참여자를 추가하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
          );
        }
        onclose();
      },
      onError: error => {
        showError(
          `챌린지 참여자를 추가하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.(${error})`
        );
      },
    });

  const {
    mutate: changeChallengeAttendUserMutation,
    isError: changeMutateError,
  } = useMutation(changeChallengeAttendUser, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setAlertMessage('요청한 사용자를 챌린지 사용자를 보정했습니다.');
      } else {
        showError(
          `챌린지 참여자를 보정하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
      onclose();
    },
    onError: error => {
      showError(
        `챌린지 참여자를 보정하는동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.(${error})`
      );
    },
  });

  const checkContent = () => {
    const newContent = content.split('\n');
    const validContent = newContent.filter((row: any) => row.length === 32);
    const invalidContent = newContent.filter(
      (row: any) => row !== '' && row.length !== 32
    );

    const newTargetDate =
      attendDates[0] === undefined || attendDates[0] === null
        ? 0
        : new Date(attendDates[0]).getTime() / 1000;

    if (newTargetDate === 0) {
      showError(`참여날짜를 선택 후 다시 시도해 주세요.`);
      return;
    }

    if (
      challengeId === undefined ||
      challengeId === null ||
      challengeId === ''
    ) {
      showError(`챌린지를 선택한 후 다시 시도해 주세요.`);
      return;
    }

    if (validContent.length === 0) {
      showError(`사용자 아이디를 추가후 다시 시도해 주세요.`);
      return;
    }

    const newAttendUserData = {
      challengeId: challengeId,
      userIds: validContent,
      targetDate: newTargetDate,
    };
    attendType === 1
      ? addChallengeAttendUserMutation(newAttendUserData)
      : changeChallengeAttendUserMutation(newAttendUserData);
  };

  const checkPhonenoContent = () => {
    const newContent = phonenoContent.split('\n');
    const validContent = newContent.filter((row: any) =>
      validCheck(KEY_WORD_PHONE, row)
    );
    const invalidContent = newContent.filter(
      (row: any) => row !== '' && !validCheck(KEY_WORD_PHONE, row)
    );

    const newTargetDate =
      attendDates[0] === undefined || attendDates[0] === null
        ? 0
        : new Date(attendDates[0]).getTime() / 1000;

    if (newTargetDate === 0) {
      showError(`참여날짜를 선택 후 다시 시도해 주세요.`);
      return;
    }

    if (
      challengeId === undefined ||
      challengeId === null ||
      challengeId === ''
    ) {
      showError(`챌린지를 선택한 후 다시 시도해 주세요.`);
      return;
    }

    if (validContent.length === 0) {
      showError(`유저 폰번호를 추가후 다시 시도해 주세요.`);
      return;
    }

    const newAttendUserData = {
      challengeId: challengeId,
      phonenos: validContent,
      targetDate: newTargetDate,
    };
    attendType === 1
      ? addChallengeAttendUserMutation(newAttendUserData)
      : changeChallengeAttendUserMutation(newAttendUserData);
  };

  const checkValidation = () => {
    if (content !== '' && phonenoContent === '') {
      checkContent();
    } else if (content === '' && phonenoContent !== '') {
      checkPhonenoContent();
    } else {
      showError(`유저 아이디나 유저 폰번호를 확인 후 다시 시도해 주세요.`);
    }
  };

  useEffect(() => {
    if (open) {
      setContent('');
      setPhonenoContent('');
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            minHeight: '56vh',
            minWidth: '44vw',
          },
        }}
      >
        <DialogTitle>
          <Box className='flex_between'>
            <Box>
              <Typography>참여자 추가</Typography>
            </Box>
            <IconButton onClick={onclose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider sx={{ m: 0, p: 0 }} />
        <DialogContent>
          <Box>
            <Box>
              <Typography sx={{ m: '1rem 0 ' }}>참여 날짜</Typography>
              <SwDatesPickerWithScroll
                title='참여날짜 선택'
                dates={attendDates}
                setDates={setAttendDates}
                showButton={false}
                allowPastDate={true}
              />
            </Box>
            <Box className='flex_between'>
              <Box>
                <Typography sx={{ m: '1rem 0 ' }}>
                  유저 아이디(최대 500명)
                </Typography>

                <MultilineTextField
                  placeholder='예시
                115D7D63F5294CACAF59C3ECCA86207C
                09C6F313F5C94286A4DA859CB14E584'
                  multiline
                  rows={20}
                  value={content}
                  onChange={evt => setContent(evt.target.value)}
                  sx={{
                    height: '100%',
                    width: '20vw',
                  }}
                />
              </Box>
              <Box>
                <Typography sx={{ m: '1rem 0 ' }}>
                  유저 폰번호(최대 500명)
                </Typography>

                <MultilineTextField
                  placeholder='예시
                01012345678
                01045679876'
                  multiline
                  rows={20}
                  value={phonenoContent}
                  onChange={evt => setPhonenoContent(evt.target.value)}
                  sx={{
                    height: '100%',
                    width: '20vw',
                  }}
                />
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <Divider sx={{ m: 0, p: 0 }} />
        <DialogActions>
          <Button
            variant='outlined'
            color='info'
            sx={{ fontSize: '.87rem' }}
            onClick={() => checkValidation()}
          >
            완료
          </Button>
        </DialogActions>
      </Dialog>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={alertMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={alertMessage}
      />
    </>
  );
};
