/* eslint-disable global-require */
import {
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  styled,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import CloseIcon from '@mui/icons-material/Close';
import React, {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useResize } from '../../../hooks/resize';
import { SwSnackbar } from '../views/SwSnackbar';

const OverlayView = styled(Box)({
  position: 'absolute',
  zIndex: 100,
  padding: '.5rem',
  margin: '1rem',
  height: '8%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '98%',
});

const ContentsPaper = styled(Paper)({
  width: '29%',
  minWidth: '350px',
  height: '70vh',
  borderRadius: '12px',
  marginLeft: '1%',
});

const ButtonPaper = styled(Paper)({
  borderRadius: '12px',
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  width: '8rem',
  height: '3rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '.1rem',
  cursor: 'grab',
  boxShadow: 'none',
});

interface Props {
  stamptourData?: any;
  mapInfo?: any;
  setMapInfo?: any;
  elementId?: string;
}

const STAMP_RADIUS = 5000;

export const SwStamptourMap: React.FC<Props> = ({
  stamptourData = null,
  mapInfo = null,
  setMapInfo = null,
  elementId = 'stampmap',
}) => {
  const { isMobile } = useResize();
  const map = useRef<any>();
  const maps = document.getElementById(elementId);
  const drawingPointsRef = useRef<any>(null);
  const tempCircleInfoRef = useRef<any>();
  const tempCourseRef = useRef<any>();
  const radiusRef = useRef<any>();
  const reloadRef = useRef<any>(null);
  const tempPointsRef = useRef<any>([]);
  const stampCircleRef = useRef<any>(150);
  const testRef = useRef<any>();
  const [stampInfoIndex, setStampInfoIndex] = useState<number>(0);
  const [stampInfo, setStampInfo] = useState<any>();
  const [openCreateStamp, setOpenCreateStamp] = useState<boolean>(false);
  const [selectDrawCircle, setSelectDrawCircle] = useState<boolean>(false);
  const [circle, setCircle] = useState<any>();
  const [pointChange, setPointChange] = useState<boolean>(false);
  const [circleStart, setCircleStart] = useState(false);
  const [mapCenter, setMapCenter] = useState<any>([37.5, 127.012]);
  const [stampCircle, setStampCircle] = useState<number>(150);
  const [markerStart, setMarkerStart] = useState(false);
  const [marker, setMarker] = useState<any>([]);
  const [stamps, setStamps] = useState<any>([]);
  const [stampsCircle, setStampsCircle] = useState<any>([]);
  const [tempPoints, setTempPoints] = useState<any>([]);
  const [circleData, setCircleData] = useState<any>();
  const [checkedButtons, setCheckedButtons] = useState<string[]>([]);
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  let drawingStart = false;
  let circleDrawStart = false;
  let drawingCircle: any;
  let drawingCircleLine: any;
  let length: any;
  let drawingCircleInfo: any;
  let drawingLine: any;
  let tempCircle: any;
  let tempPosition: any;
  let tempPolyline: any;
  let centerPosition: any;
  let mousePosition: any;
  const pointContent = `<div  class="info" style="padding:5px;margin:10px; background-color:white; border:black solid 1px; white-space: pre;" >지도 위에 원하는\n지점을 일정한 간격으로 클릭하며\n스탬프를 만들어보세요.</div>`;
  const radiusContent = `<div  class="info" style="padding:5px;margin:10px; background-color:white; border:black solid 1px; white-space: pre;" >위치 인식 반경을 그려주세요. :)\n원의 중심점을 클릭해주세요!</div>`;
  const mouseMoveCustomOverlay = new naver.maps.InfoWindow({
    content: pointContent,
    borderWidth: 0,
    disableAnchor: true,
    backgroundColor: 'transparent',
    pixelOffset: new naver.maps.Point(0, -28),
  });

  const setDistance = (distance: number) => {
    const newDistance = Math.round(distance).toString();
    const contentLength = newDistance.length;
    let result: any;
    if (contentLength > 4) {
      const settingKm = Math.floor(distance / 1000);
      const settingMeter = (distance % 1000).toString().split('.')[0];
      result = `${settingKm}.${settingMeter}km`;
    } else if (contentLength === 4) {
      result = `${newDistance[0]},${newDistance.slice(1, 4)}m`;
    } else {
      result = `${newDistance}m`;
    }
    return result;
  };

  const initMap = () => {
    if (maps) {
      map.current = new naver.maps.Map(elementId, {
        center: new naver.maps.LatLng(mapCenter[0], mapCenter[1]),
        zoom: 15,
      });
    }
  };

  // 지도 이벤트 설정
  const clickEvent = (evt: any) => {
    if (!drawingPointsRef.current) {
      if (!drawingStart) {
        centerPosition = evt.coord;
        mouseMoveCustomOverlay.close();
        drawingStart = true;
        setMarkerStart(true);
        drawingLine = new naver.maps.Polyline({
          strokeWeight: 3,
          strokeColor: '#6C65FF',
          path: [centerPosition],
          map: map.current,
        });
        tempCircle = new naver.maps.Circle({
          map: map.current,
          center: mousePosition,
          radius: stampCircleRef.current,
          fillColor: '#6C65FF',
          fillOpacity: 0.1,
          strokeColor: '#6C65FF',
          strokeOpacity: 0.7,
        });
        drawingLine?.setMap(map.current);
        tempCircle?.setMap(map.current);
      } else {
        length = 0;
        drawingLine?.setMap(null);
        tempCircle?.setMap(null);
      }
    } else {
      mouseMoveCustomOverlay.close();
      length = 0;
      if (!circleDrawStart) {
        circleDrawStart = true;
        setCircleStart(true);
        circle?.setMap(null);
        centerPosition = evt.coord;
        drawingCircleLine = new naver.maps.Polyline({
          strokeWeight: 2,
          strokeOpacity: 0.6,
          strokeColor: '#00a0e9',
          path: [centerPosition],
          map: map.current,
        });
        drawingCircle = new naver.maps.Circle({
          map: map.current,
          center: centerPosition,
          radius: length,
          fillColor: '#FF8A65',
          fillOpacity: 0.3,
          strokeColor: '#FF8A65',
          strokeOpacity: 0.3,
        });
        drawingCircleLine?.setMap(map.current);
        drawingCircle?.setMap(map.current);
      }
    }
  };
  const moveEvent = (evt: any) => {
    mousePosition = evt.coord;
    tempCircle?.setMap(null);
    if (!drawingPointsRef.current) {
      if (drawingStart) {
        drawingLine.setPath([centerPosition, mousePosition]);
        tempCircle.setRadius(drawingLine?.getDistance());
        length = setDistance(drawingLine?.getDistance());
        drawingLine?.setMap(map.current);
        tempCircle?.setMap(map.current);
        const stampContent = `<div  class="drawing_stampinfo">마우스 우클릭으로 마침\n<div style="color:#A4ADFC;">반경 ${length}</div></div>`;
        mouseMoveCustomOverlay.setOptions({
          content: stampContent,
          pixelOffset: new naver.maps.Point(-35, -50),
        });
        mouseMoveCustomOverlay.setPosition(mousePosition);
        mouseMoveCustomOverlay.setMap(map.current);
        if (reloadRef.current !== 1) {
          const linePath = [centerPosition, mousePosition];
          drawingLine?.setPath(linePath);
          drawingLine?.setMap(map.current);
        }
      } else {
        mouseMoveCustomOverlay.setContent(pointContent);
        mouseMoveCustomOverlay.setPosition(mousePosition);
        mouseMoveCustomOverlay.setMap(map.current);
      }
    } else if (circleDrawStart) {
      tempCircle?.setMap(null);
      drawingLine?.setMap(null);
      circle?.setMap(null);
      if (centerPosition) {
        const linePath = [centerPosition, mousePosition];
        drawingCircleLine?.setPath(linePath);
        length = drawingCircleLine?.getDistance();
        const info = `<div class ='drawing_circleinfo'><span>${setDistance(
          length
        )}\n우측클릭으로 완성</span></div>`;
        if (length >= 0) {
          const circleOptions = {
            center: centerPosition,
            radius: length,
          };
          drawingCircle.setOptions(circleOptions);
          drawingCircleLine.setMap(map.current);
          drawingCircle.setMap(map.current);
          mouseMoveCustomOverlay.setContent(info);
          mouseMoveCustomOverlay.setPosition(mousePosition);
          mouseMoveCustomOverlay.setMap(map.current);
        }
      } else {
        drawingCircleInfo.setPosition(mousePosition);
        drawingCircleInfo.setMap(map.current);
      }
    } else {
      mouseMoveCustomOverlay.setContent(radiusContent);
      mouseMoveCustomOverlay.setPosition(mousePosition);
      mouseMoveCustomOverlay.setMap(map.current);
      drawingCircleLine?.setMap(null);
      drawingCircle?.setMap(null);
    }
  };
  const rightClickEvent = (evt: any) => {
    if (drawingStart) {
      length = 0;
      mouseMoveCustomOverlay.setMap(null);
      drawingLine.setPath([centerPosition, evt.coord]);
      tempCircle.setRadius(drawingLine?.getDistance());
      tempCircleInfoRef.current = {
        point: [centerPosition.y, centerPosition.x],
        radius: drawingLine?.getDistance(),
      };
      drawingLine?.setMap(null);
      tempCircle?.setMap(null);
      setMarkerStart(false);
      drawingStart = false;
    } else if (circleDrawStart) {
      radiusRef.current = [centerPosition, evt.coord];
      circleDrawStart = false;
      setCircleStart(false);
      centerPosition = null;
      drawingCircleLine?.setMap(null);
      drawingCircle?.setMap(null);
    }
  };
  const mouseoutEvent = (evt: any) => {
    mouseMoveCustomOverlay.close();
    tempCircle?.setMap(null);
    drawingStart = false;
    drawingLine?.setMap(null);
    centerPosition = tempPosition;
    circleDrawStart = false;
    drawingCircleLine?.setMap(null);
    drawingCircle?.setMap(null);
  };

  const keydownEvent = (evt: any) => {
    if (evt.keyboardEvent.key === 'Escape') {
      drawingStart = false;
      drawingLine?.setMap(null);
      circleDrawStart = false;
      drawingLine?.setMap(null);
      drawingCircle?.setMap(null);
    }
  };
  const drawingMap = () => {
    if (maps) {
      naver.maps.Event.addListener(map.current, 'mouseout', mouseoutEvent);
      naver.maps.Event.addListener(map.current, 'keydown', keydownEvent);
    }
  };

  const getCurrentPosition = () => {
    const handleGeoSucces = (position: any) => {
      setMapCenter([position.coords.latitude, position.coords.longitude]);
    };
    const handleGeoError = () => {
      console.log('access geo location error>>>>>>>>>>>>>');
    };
    return navigator.geolocation.getCurrentPosition(
      handleGeoSucces,
      handleGeoError
    );
  };

  const deletePoint = (
    evt: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    marker?.map((m: any) => m?.setMap(null));
    stampsCircle?.map((stamp: any) => stamp?.setMap(null));
    const newStampsCircle = stampsCircle.filter(
      (_point: any, i: number) => i !== Number(evt.currentTarget.id)
    );
    const newMarker = marker.filter(
      (_m: any, i: number) => i !== Number(evt.currentTarget.id)
    );
    const newStamps = stamps.filter(
      (_m: any, i: number) => i !== Number(evt.currentTarget.id)
    );
    marker[Number(evt.currentTarget.id)]?.setMap(null);
    newStampsCircle?.map((stamp: any) => stamp?.setMap(map.current));
    newMarker?.map((m: any, idx: number) =>
      m?.setIcon({
        content: `<div class='stamp_marker'><span class='stamp_marker_index'>${
          idx + 1
        }</span></div>`,
        anchor: new naver.maps.Point(18, 38),
      })
    );
    newMarker?.map((m: any) => m?.setMap(map.current));
    setStamps(newStamps);
    setStampsCircle(newStampsCircle);
    setMarker(newMarker);
  };

  const changePoint = (
    evt: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    checkDirection: boolean
  ) => {
    const index = Number(evt.currentTarget.id);
    const checkIndex = checkDirection
      ? [index - 1, index, index - 1, index + 1]
      : [index, index + 1, index, index + 2];
    console.log('index: ', index, ', checkIndex: ', checkIndex);
    marker?.map((m: any) => m?.setMap(null));
    const newStamps: any = [
      ...stamps.slice(0, checkIndex[0]),
      stamps[checkIndex[1]],
      stamps[checkIndex[2]],
      ...stamps.slice(checkIndex[3], stamps.length),
    ];
    const newStampsCircle: any = [
      ...stampsCircle.slice(0, checkIndex[0]),
      stampsCircle[checkIndex[1]],
      stampsCircle[checkIndex[2]],
      ...stampsCircle.slice(checkIndex[3], stampsCircle.length),
    ];
    const newMarker: any = [
      ...marker.slice(0, checkIndex[0]),
      marker[checkIndex[1]],
      marker[checkIndex[2]],
      ...marker.slice(checkIndex[3], marker.length),
    ];
    newMarker[checkIndex[1]].setIcon({
      content: newStamps[checkIndex[1]].name
        ? `<div class='stamp_marker'><span class='stamp_marker_index'>${
            checkIndex[1] + 1
          }</span><span> ${newStamps[checkIndex[1]].name}</span></div>`
        : `<div class='stamp_marker'><span class='stamp_marker_index'>${
            checkIndex[1] + 1
          }</span></div>`,
      anchor: new naver.maps.Point(18, 38),
    });
    newMarker[checkIndex[2]].setIcon({
      content: newStamps[checkIndex[2]].name
        ? `<div class='stamp_marker'><span class='stamp_marker_index'>${
            checkIndex[2] + 1
          }</span><span> ${newStamps[checkIndex[2]].name}</span></div>`
        : `<div class='stamp_marker'><span class='stamp_marker_index'>${
            checkIndex[2] + 1
          }</span></div>`,
      anchor: new naver.maps.Point(18, 38),
    });
    setStamps(newStamps);
    setStampCircle(newStampsCircle);
    setMarker(newMarker);
    newMarker?.map((m: any) => m?.setMap(map.current));
  };

  const onChangeStampInfo = (idx: number, stamp: any) => {
    console.log('onChangeStampInfo :, ', stamp);
    marker?.map((m: any) => m?.setMap(null));
    const newStamps = stamps;
    const newMarker = marker;
    const newStampsCircle = stampsCircle;
    newStamps[idx] = { point: [stamp.lat, stamp.lng], ...stamp };
    const tempMarker = new naver.maps.Marker({
      position: new naver.maps.LatLng(stamp.lat, stamp.lng),
      map: map.current,
      icon: {
        content: newStamps[idx].name
          ? `<div class='stamp_marker'><span class='stamp_marker_index'>${
              idx + 1
            }</span><span> ${newStamps[idx].name}</span></div>`
          : `<div class='stamp_marker'><span class='stamp_marker_index'>${
              idx + 1
            }</span></div>`,
        anchor: new naver.maps.Point(18, 38),
      },
      clickable: false,
      draggable: false,
    });
    newMarker[idx] = tempMarker;
    const newCircle = new naver.maps.Circle({
      map: map.current,
      center: new naver.maps.LatLng(stamp.lat, stamp.lng),
      radius: stamp.radius,
      fillColor: '#6C65FF',
      fillOpacity: 0.1,
      strokeColor: '#6C65FF',
      strokeOpacity: 0.3,
    });
    marker?.map((m: any) => m?.setMap(null)); // cleawr preview marker
    stampsCircle?.map((item: any) => item?.setMap(null)); // cleawr preview cirlces
    newStampsCircle[idx] = newCircle;
    newStampsCircle?.map((item: any) => item?.setMap(map.current));
    newMarker?.map((m: any) => m?.setMap(map.current));
    setMarker(newMarker);
    setStamps(newStamps);
    setStampsCircle(newStampsCircle);
    setOpenCreateStamp(false);
    setStampInfoIndex(0);
    setStampInfo({});
  };

  const onAddStampInfo = (idx: number, stamp: any) => {
    marker?.map((m: any) => m?.setMap(null));
    const newStamps = stamps;
    const newMarker = marker;
    const newCircle = new naver.maps.Circle({
      map: map.current,
      center: new naver.maps.LatLng(stamp.lat, stamp.lng),
      radius: stamp.radius,
      fillColor: '#6C65FF',
      fillOpacity: 0.1,
      strokeColor: '#6C65FF',
      strokeOpacity: 0.3,
    });
    newStamps[idx] = { point: [stamp.lat, stamp.lng], ...stamp };
    newMarker[idx] = new naver.maps.Marker({
      position: new naver.maps.LatLng(stamp.lat, stamp.lng),
      map: map.current,
      icon: {
        content: newStamps[idx].name
          ? `<div class='stamp_marker'><span class='stamp_marker_index'>${
              idx + 1
            }</span><span> ${newStamps[idx].name}</span></div>`
          : `<div class='stamp_marker'><span class='stamp_marker_index'>${
              idx + 1
            }</span></div>`,
        anchor: new naver.maps.Point(18, 38),
      },
      clickable: false,
      draggable: false,
    });
    newMarker?.map((m: any) => m?.setMap(map.current));
    setMarker(newMarker);
    setStamps(newStamps);
    setStampsCircle([...stampsCircle, newCircle]);
    setOpenCreateStamp(false);
    setStampInfoIndex(0);
    setStampInfo({});
  };

  const onClickStampInfo = (
    evt: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    const newIndex = Number(evt.currentTarget.id);
    setStampInfoIndex(newIndex);
    setStampInfo(stamps[newIndex]);
    setOpenCreateStamp(true);
  };

  const showRadiusCircle = () => {
    const newRediusCenter = {
      y: circleData?.lat,
      _lat: circleData?.lat,
      x: circleData?.lng,
      _lng: circleData?.lng,
    };
    const newCircle = new naver.maps.Circle({
      map: map.current,
      center: newRediusCenter,
      radius: circleData?.radius,
      strokeWeight: 1,
      fillColor: '#f0a293',
      fillOpacity: 0.15,
      strokeColor: '#f0a293',
      strokeOpacity: 0.3,
    });
    setCircle(newCircle);
  };

  const updateStamps = (checkedStamps: any[]) => {
    const newStamps = stamps.map((stamp: any) => {
      const newStamp = stamp;
      newStamp.checked = checkedStamps.includes(stamp.id);
      return newStamp;
    });
    const newMapInfo = {
      stamps: newStamps,
    };
    setMapInfo(newMapInfo);
  };

  const changeHandler = (checked: boolean, id: string) => {
    if (checked) {
      if (checkedButtons.length + 1 === stamps.length) {
        setOpenSnackbar(true);
        setDialogMessage(
          '모든 모든 스탬프를 필수 스탬프로 설정할 수 없습니다.'
        );
        return;
      }
      const newCheckedButtons = [...checkedButtons, id];
      updateStamps(newCheckedButtons);
      setCheckedButtons(newCheckedButtons);
    } else {
      const newCheckedButtons = checkedButtons.filter(button => button !== id);
      updateStamps(newCheckedButtons);
      setCheckedButtons(newCheckedButtons);
    }
  };

  useMemo(() => {
    if (
      map.current !== null &&
      map.current !== undefined &&
      map.current.updateBy !== undefined
    ) {
      map.current.updateBy(
        new naver.maps.LatLng(mapCenter[0], mapCenter[1]),
        15
      );
    }
  }, [mapCenter]);

  useEffect(() => {
    console.log('maps>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
    initMap();
    drawingMap();
    return () => {
      map.current = null;
      drawingPointsRef.current = null;
      tempCourseRef.current = null;
      radiusRef.current = null;
      reloadRef.current = null;
      tempPointsRef.current = [];
      stampCircleRef.current = 150;
    };
  }, [maps]);

  // 스탬프 코스 포인트 설정
  useEffect(() => {
    if (tempCircleInfoRef.current) {
      console.log('markerStart>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
      if (tempCircleInfoRef.current.radius < 50) {
        console.log(
          '원활한 GPS 인식을 위해 반경 200m를 권장합니다. 반경을 다시 그려주세요.'
        );
        return;
      }
      if (tempCircleInfoRef.current.radius >= STAMP_RADIUS) {
        console.log(`반경은 ${STAMP_RADIUS}m 이하로 지정해주세요.`);
        return;
      }
      if (stamps.length > 0) {
        stamps.forEach((stamp: any) => {
          const interval = stamp.radius + tempCircleInfoRef.current.radius;
          const distance = new naver.maps.Polyline({
            path: [stamp.point, tempCircleInfoRef.current.point],
            map: map.current,
          });
          if (interval - distance.getDistance() >= 50) {
            console.log(
              '원활한 참여를 위해 다른 스탬프와 반경이 너무 겹치지 않게 그려주세요.'
            );
            return;
          }
          distance.setMap(null);
          console.log('success');
        });
      }

      const newStamps = [...stamps, tempCircleInfoRef.current];
      const newCircle = new naver.maps.Circle({
        map: map.current,
        center: new naver.maps.LatLng(
          tempCircleInfoRef.current.point[0],
          tempCircleInfoRef.current.point[1]
        ),
        radius: tempCircleInfoRef.current.radius,
        fillColor: '#6C65FF',
        fillOpacity: 0.1,
        strokeColor: '#6C65FF',
        strokeOpacity: 0.3,
      });
      const newMarker = new naver.maps.Marker({
        position: new naver.maps.LatLng(
          tempCircleInfoRef.current.point[0],
          tempCircleInfoRef.current.point[1]
        ),
        map: map.current,
        icon: {
          content: `<div class='stamp_marker'><span class='stamp_marker_index'>${newStamps.length}</span></div>`,
          anchor: new naver.maps.Point(18, 38),
        },
        clickable: false,
        draggable: false,
      });
      setStamps(newStamps);
      setMarker([...marker, newMarker]);
      setStampsCircle([...stampsCircle, newCircle]);
      tempCircleInfoRef.current = null;
    }
  }, [markerStart]);

  // 전체 반경 설정
  useEffect(() => {
    console.log('circleStart>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
    circle?.setMap(null);
    if (radiusRef.current) {
      const radius = new naver.maps.Polyline({
        map: map.current,
        path: radiusRef.current,
        strokeWeight: 1,
        strokeColor: '#00a0e9',
        strokeOpacity: 0.1,
        strokeStyle: 'solid',
      });
      const newCircle = new naver.maps.Circle({
        map: map.current,
        center: radiusRef.current[0],
        radius: radius.getDistance(),
        strokeWeight: 1,
        fillColor: '#FF8A65',
        fillOpacity: 0.15,
        strokeColor: '#FF8A65',
        strokeOpacity: 0.3,
      });
      setCircle(newCircle);
      radius.setMap(null);
      newCircle.setMap(null);
      radiusRef.current = null;
    }
  }, [circleStart]);

  useEffect(() => {
    circle?.setMap(map.current);
    const newCircle = {
      lat: circle?.center.y,
      lng: circle?.center.x,
      radius: circle?.radius,
    };
    const newMapInfo = {
      stamps: mapInfo?.stamps,
      circle: newCircle,
    };
    setCircleData(newCircle);
    setMapInfo(newMapInfo);
  }, [circle]);

  useMemo(() => {
    if (tempCourseRef.current && pointChange) {
      const tempTime: any = [];
      const tempDistance: any = [];
      tempPointsRef.current?.map((q: any, idx: number) => {
        tempPolyline = new naver.maps.Polyline({
          path: [
            new naver.maps.LatLng(
              tempPointsRef.current[idx - 1]?.lat,
              tempPointsRef.current[idx - 1]?.lng
            ),
            new naver.maps.LatLng(q.lat, q.lng),
          ],
          map: map.current,
          strokeOpacity: 0,
        });
        tempPolyline?.getDistance() &&
          tempTime.push(tempPolyline.getDistance() / 67);
        tempDistance.push(tempPolyline?.getDistance());
      });
      console.log(
        'erer>>>>>>>>>>>>>',
        new naver.maps.LatLng(
          tempPointsRef.current[-1]?.lat,
          tempPointsRef.current[-1]?.lng
        )
      );
      tempPolyline?.setMap(null);
      setPointChange(false);
    }
  }, [pointChange]);

  useEffect(() => {
    if (tempPoints.length > 0) {
      marker.map((m: any) => m?.setMap(null));
      stampsCircle.map((m: any) => m?.setMap(null));
      const tempstime: any = [];
      const tempsdistance: any = [];

      const temps: any = [];
      const newCircles: any = [];
      tempPoints?.map((q: any, idx: number) => {
        temps.push(
          new naver.maps.Marker({
            position: new naver.maps.LatLng(q?.lat, q?.lng),
            map: map.current,
            icon: {
              content: q.name
                ? `<div class='stamp_marker'><span class='stamp_marker_index'>${
                    idx + 1
                  }</span><span> ${q.name}</span></div>`
                : `<div class='stamp_marker'><span class='stamp_marker_index'>${
                    idx + 1
                  }</span></div>`,
              anchor: new naver.maps.Point(13, 33),
            },
            clickable: false,
            draggable: false,
          })
        );
        newCircles.push(
          new naver.maps.Circle({
            map: map.current,
            center: new naver.maps.LatLng(q?.lat, q?.lng),
            radius: q?.radius,
            fillColor: '#6C65FF',
            fillOpacity: 0.1,
            strokeColor: '#6C65FF',
            strokeOpacity: 0.3,
          })
        );
      });
      const checkedStamps: any[] = [];
      const newStamps = tempPoints?.map((q: any, idx: number) => {
        const tmpStamp = {
          point: [q.lat, q.lng],
          radius: q.radius,
          name: q.name,
          description: q.description,
          image: q.image,
          id: q.id,
          className: q.className,
          link: q.link,
          phoneNo: q.phoneNo,
          address: q.address,
          stampAddress: q.stampAddress,
          checked: q.checked === 1,
        };
        if (q.checked === 1) checkedStamps.push(q.id);
        return tmpStamp;
      });
      setCheckedButtons(checkedStamps);
      setStamps(newStamps);
      setMarker(temps);
      setStampsCircle(newCircles);
      setMapCenter([newStamps[0]?.point[0], newStamps[0]?.point[1]]);
      setTempPoints([]);
    }
  }, [tempPoints]);

  useEffect(() => {
    const newMapInfo = {
      stamps: stamps,
    };
    setMapInfo(newMapInfo);
  }, [stamps]);

  useEffect(() => {
    setSelectDrawCircle(false);
    setCheckedButtons([]);
    if (stamptourData && stamptourData.gpsData.length > 0) {
      console.log('gps Data: ', stamptourData.gpsData);
      const newTempPoints = stamptourData.gpsData;
      setTempPoints(newTempPoints);
    } else {
      setMapCenter([37.5, 127.012]);
    }

    if (stamptourData && stamptourData.circleData) {
      const newCircle = {
        lat: stamptourData.circleData.lat,
        lng: stamptourData.circleData.lng,
        radius: stamptourData.circleData.radius,
      };
      const newMapInfo = {
        stamps: stamps,
        circle: newCircle,
      };
      setMapInfo(newMapInfo);
      circle?.setMap(null);
      setCircleData(stamptourData.circleData);
    }
  }, [stamptourData]);

  return (
    <div style={{ margin: '1rem 0' }}>
      <Box className='flex_center' sx={{ position: 'relative' }}>
        <div
          id={elementId}
          ref={map}
          style={{
            width: '70%',
            height: '70vh',
            borderRadius: '10px',
            minWidth: '900px',
          }}
          onContextMenu={e => {
            e.preventDefault();
          }}
        >
          <OverlayView>
            <Paper>
              <IconButton size='small' onClick={getCurrentPosition}>
                <GpsFixedIcon />
              </IconButton>
            </Paper>
          </OverlayView>
        </div>
        <ContentsPaper ref={testRef}>
          <Box className='walkcourse_content_header'>스탬프 정보</Box>
          <Box sx={{ p: '1rem', overflowY: 'scroll', height: '60vh' }}>
            {stamps.map((stamp: any, idx: number) => (
              <div key={idx.toString()} style={{ padding: '0 1rem 1rem' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    mb: '1rem',
                  }}
                  key={idx.toString()}
                >
                  <Box className='flex_center'>
                    <div
                      className='stamp_info_marker'
                      aria-hidden
                      id={idx.toString()}
                      onClick={() =>
                        setMapCenter([stamp?.point[0], stamp?.point[1]])
                      }
                      style={{ cursor: 'pointer' }}
                    >
                      <span className='stamp_info'>★</span>
                    </div>
                    <Typography sx={{ ml: '.5rem', fontSize: '1rem' }}>
                      {idx + 1}번 스탬프
                    </Typography>
                  </Box>
                  <Box className='flex_start'>
                    <Checkbox
                      onChange={e => {
                        changeHandler(e.currentTarget.checked, stamp.id);
                      }}
                      checked={checkedButtons.includes(stamp.id)}
                    />
                    <Typography sx={{ fontSize: '1rem' }}>
                      필수 스탬프
                    </Typography>
                  </Box>
                </Box>
                <Typography sx={{ fontSize: '1.06rem', mb: '1em' }}>
                  {stamp.name ? stamp.name : '이름 넣기'}
                </Typography>
                <Divider sx={{ p: 0, m: 0 }} />
              </div>
            ))}
          </Box>
        </ContentsPaper>
        <SwSnackbar
          open={openSnackbar}
          onClose={() => setOpenSnackbar(false)}
          contents={dialogMessage}
        />
      </Box>
    </div>
  );
};
