import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';

import Grid from '@mui/material/Unstable_Grid2';
import SearchIcon from '@mui/icons-material/Search';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwSearchDataPortal } from '../../commonComponent/SwSearchDataPortal';

import {
  getServiceCouponInfo,
  postServiceCouponRegister,
} from '../../../common/api/ApiContract';
import { IServiceCoupon } from '../../../models/service_coupon';
import { KEY_SERVICE_COUPON_INFO } from '../../../common/key';
import { checkNaN, commaFormat } from '../../../common/helper';
import { RESULT_OK } from '../../../common/resultCode';

interface Props {}

const communityCouponType = [
  { title: '제한 없음', value: '0' },
  // { title: '100명 제한', value: '1' },
  // { title: '300명 제한', value: '2' },
  // { title: '500명 제한', value: '3' },
  // { title: '1000명 제한', value: '4' },
  // { title: '1000명 이상', value: '5' },
];

export const challengeCouponType = [
  { title: '일반 이용권(공개대상 커뮤니티/그룹 필수)', value: '0' },
  // { title: '지역 단위 lv 1 ', value: '1' },
  // { title: '지역 단위 lv 2 ', value: '2' },
  // { title: '지역 단위 lv 3 ', value: '3' },
  { title: '자유 이용권(공개대상 자유선택)', value: '4' },
];

const programCouponType = [{ title: '제한 없음', value: '0' }];

const messagingCouponType = [
  { title: '문자 이용 금액 충전권', value: '0' },
  { title: '문자 쿠폰', value: '1' },
];

const COMMUNTY_COUPON_MAX_COUNT = 10;
const CHALLENGE_COUPON_MAX_COUNT = 10;

export const GivePlan: React.FC<Props> = () => {
  const searchRef = useRef<any>();
  const [openAlert, setOpenAlert] = useState<number>(0);
  const [openPortal, setOpenPortal] = useState<boolean>(false);
  const [communityPortalId, setCommunityPortalId] = useState('1231');
  const [challengePortalId, setChallengePortalId] = useState('1231');
  const [communityUseStatus, setCommunityUseStatus] = useState<string>('');
  const [challengeUseStatus, setChallengeUseStatus] = useState<string>('');
  const [programUseStatus, setProgramUseStatus] = useState<string>('');
  const [messagingUseStatus, setMessagingUseStatus] = useState<string>('');
  const [communityPlan, setCommunityPlan] = useState<any>('0');
  const [challengePlan, setChallengePlan] = useState<any>('0');
  const [programPlan, setProgramPlan] = useState<any>('0');
  const [messagingPlan, setMessagingPlan] = useState<any>('0');
  const [communityPlanCount, setCommunityPlanCount] = useState<number>(1);
  const [challengePlanCount, setChallengePlanCount] = useState<number>(1);
  const [programPlanCount, setProgramPlanCount] = useState<number>(1);
  const [messagingPlanCount, setMessagingPlanCount] = useState<string>('');
  const [contents, setContents] = useState('');
  const [searchPortal, setSearchPortal] = useState<string>('');
  const [title, setTitle] = useState<any>('');
  const [searchKey, setSearchKey] = useState<string>('all');
  const [searchWord, setSearchWord] = useState<string>('');
  const [searchPortalAccount, setSearchPortalAccount] = useState<any>();
  const [type, setType] = useState<any>('');
  const [couponType, setCouponType] = useState<any>('');
  const [couponName, setCouponName] = useState<any>('');

  const {
    data,
    isLoading,
    refetch: serviceCouponDataRefetch,
  } = useQuery(
    KEY_SERVICE_COUPON_INFO,
    () => {
      if (searchPortalAccount !== undefined && searchPortalAccount !== null) {
        const newData: IServiceCoupon = {
          portalId: searchPortalAccount.portalId,
        };
        return getServiceCouponInfo(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        if (res !== null && res.serviceCoupon !== null) {
          const newCommunityData = res.serviceCoupon
            .map((row: any) => {
              const newData =
                row.type === 'community'
                  ? `${row.couponName}(${row.couponCount - row.couponUseCount})`
                  : '';
              return newData;
            })
            .filter((row: any) => row !== '');
          const newChallengeData = res.serviceCoupon.map((row: any) => {
            const newData =
              row.type === 'challenge'
                ? `${row.couponName}(${row.couponCount - row.couponUseCount})`
                : '';
            return newData;
          });
          const newProgramData = res.serviceCoupon
            .map((row: any) => {
              const newData =
                row.type === 'program'
                  ? `${row.couponName}(${row.couponCount - row.couponUseCount})`
                  : '';
              return newData;
            })
            .filter((row: any) => row !== '');
          const newMessagingData = res.serviceCoupon
            .map((row: any) => {
              const newData =
                row.type === 'messaging'
                  ? `${row.couponName}(${commaFormat(
                      String(row.couponCount - row.couponUseCount)
                    )})`
                  : '';
              return newData;
            })
            .filter((row: any) => row !== '');
          setCommunityUseStatus(newCommunityData.join('/'));
          setChallengeUseStatus(newChallengeData.join('/'));
          setProgramUseStatus(newProgramData.join('/'));
          setMessagingUseStatus(newMessagingData.join('/'));
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { mutate: postServiceCouponMutate, isError: isMutateError } =
    useMutation(postServiceCouponRegister, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setOpenAlert(2);
          setContents(`${type} 서비스 이용권을 등록했습니다.`);
        } else {
          setOpenAlert(2);
          setContents(
            `${type} 서비스 이용권을 등록하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
          );
        }
      },
      onError: error => {
        setOpenAlert(2);
        setContents(
          `${type} 서비스 이용권 저장이 실패했습니다. 다시 시도해주시기 바랍니다.`
        );
      },
    });

  const registerServiceCoupon = () => {
    const newData: IServiceCoupon = {
      type: type,
      portalId: searchPortalAccount.portalId,
      couponCount:
        type === 'community'
          ? communityPlanCount
          : type === 'challenge'
          ? challengePlanCount
          : type === 'program'
          ? programPlanCount
          : Number(messagingPlanCount.replaceAll(',', '')),
      couponName: couponName,
      couponType:
        type === 'community'
          ? communityPlan
          : type === 'challenge'
          ? challengePlan
          : type === 'program'
          ? programPlan
          : messagingPlan,
    };

    console.log('registerServiceCoupon : ', newData);
    postServiceCouponMutate(newData);
  };

  const giveCommunityPlan = () => {
    const newData = communityCouponType.filter(
      (item: any) => item.value === communityPlan
    );

    if (
      searchPortal === null ||
      searchPortal.length === 0 ||
      searchPortalAccount === undefined ||
      searchPortalAccount === null
    ) {
      setOpenAlert(2);
      setContents(`포탈 아이디를 검색 후 선택해주세요`);
      return;
    }
    setCouponName(newData[0].title);
    setContents(
      `${searchPortal}에게 '${newData[0].title}'이용권 ${communityPlanCount}개를 지급하시겠습니까?`
    );
    setType('community');
    setOpenAlert(1);
  };
  const giveChallengePlan = () => {
    const newData = challengeCouponType.filter(
      (item: any) => item.value === challengePlan
    );

    console.log('searchPortalAccount :', searchPortalAccount);
    if (
      searchPortal === null ||
      searchPortal.length === 0 ||
      searchPortalAccount === undefined ||
      searchPortalAccount === null
    ) {
      setOpenAlert(2);
      setContents(`포탈 아이디를 검색 후 선택해주세요`);
      return;
    }

    setCouponName(newData[0].title);
    setContents(
      `${searchPortal}에게 '${newData[0].title}'이용권 ${challengePlanCount}개를 지급하시겠습니까?`
    );
    setType('challenge');
    setOpenAlert(1);
  };
  const giveProgramPlan = () => {
    const newData = programCouponType.filter(
      (item: any) => item.value === programPlan
    );

    console.log('searchPortalAccount :', searchPortalAccount);
    if (
      searchPortal === null ||
      searchPortal.length === 0 ||
      searchPortalAccount === undefined ||
      searchPortalAccount === null
    ) {
      setOpenAlert(2);
      setContents(`포탈 아이디를 검색 후 선택해주세요`);
      return;
    }

    setCouponName(newData[0].title);
    setContents(
      `${searchPortal}에게 '${newData[0].title}'이용권 ${programPlanCount}개를 지급하시겠습니까?`
    );
    setType('program');
    setOpenAlert(1);
  };
  const giveMessagingPlan = () => {
    const newData = messagingCouponType.filter(
      (item: any) => item.value === messagingPlan
    );

    console.log('searchPortalAccount :', searchPortalAccount);
    if (
      searchPortal === null ||
      searchPortal.length === 0 ||
      searchPortalAccount === undefined ||
      searchPortalAccount === null
    ) {
      setOpenAlert(2);
      setContents(`포탈 아이디를 검색 후 선택해주세요`);
      return;
    }

    setCouponName(newData[0].title);
    setContents(
      `${searchPortal}에게 '${newData[0].title}'이용권 ${commaFormat(
        messagingPlanCount
      )}원을 지급하시겠습니까?`
    );
    setType('messaging');
    setOpenAlert(1);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      setOpenPortal(true);
    }
  };

  useEffect(() => {
    if (searchPortalAccount) {
      console.log('searchPortalAccount : ', searchPortalAccount);
      setSearchPortal(searchPortalAccount.type);
      serviceCouponDataRefetch();
    }
  }, [searchPortalAccount]);

  return (
    <>
      <Box sx={{ m: '2rem 0' }}>
        {searchPortalAccount !== undefined && searchPortalAccount !== null && (
          <Paper sx={{ width: '100%' }}>
            <Grid spacing={8} sx={{ m: '1rem' }}>
              <Typography fontWeight={700} fontSize='1rem' sx={{ mb: '.5rem' }}>
                {`${searchPortalAccount.portalId} 잔여 이용권 개수`}
              </Typography>
              <Typography fontWeight={500} fontSize='1rem' sx={{ mb: '.5rem' }}>
                {`* 커뮤니티: ${communityUseStatus}`}
              </Typography>
              <Typography fontWeight={500} fontSize='1rem' sx={{ mb: '.5rem' }}>
                {`* 챌린지: ${challengeUseStatus}`}
              </Typography>
              <Typography fontWeight={500} fontSize='1rem'>
                {`* 프로그램: ${programUseStatus}`}
              </Typography>
              <Typography fontWeight={500} fontSize='1rem'>
                {`* 문자: ${messagingUseStatus}`}
              </Typography>
            </Grid>
          </Paper>
        )}
        <Grid container spacing={4}>
          <Grid xs={4}>
            <Paper sx={{ p: '2rem' }}>
              <Typography fontWeight={500} fontSize='1rem'>
                커뮤니티 이용권
              </Typography>
              <Divider />
              <Box>
                <Typography className='title'>이용권 종류</Typography>
                <TextField
                  select
                  fullWidth
                  value={communityPlan}
                  onChange={evt => setCommunityPlan(evt.target.value)}
                >
                  {communityCouponType.map((item: any, idx: number) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.title}
                    </MenuItem>
                  ))}
                </TextField>
                <Divider />
              </Box>
              <Box>
                <Typography className='title'>지급 포탈</Typography>
                <Box>
                  <TextField
                    sx={{ width: '30rem' }}
                    placeholder='포탈 아이디를 검색해 주세요.'
                    inputRef={searchRef}
                    value={searchPortal}
                    onKeyDown={evt => {
                      handleKeyDown(evt);
                    }}
                    onChange={(
                      evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                    ) => {
                      setSearchPortal(evt.target.value);
                      setSearchPortalAccount(null);
                    }}
                    helperText=''
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton onClick={() => setOpenPortal(true)}>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Divider />
              </Box>
              <Box sx={{ mb: '1rem' }}>
                <Typography className='title'>이용권 개수</Typography>
                <TextField
                  select
                  fullWidth
                  value={communityPlanCount}
                  onChange={evt =>
                    setCommunityPlanCount(Number(evt.target.value))
                  }
                >
                  {[...Array(COMMUNTY_COUPON_MAX_COUNT)].map(
                    (x: any, i: number) => (
                      <MenuItem key={i + 1} value={i + 1}>
                        {i + 1}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </Box>
              <Button variant='contained' fullWidth onClick={giveCommunityPlan}>
                지급
              </Button>
            </Paper>
          </Grid>
          <Grid xs={4}>
            <Paper sx={{ p: '2rem' }}>
              <Typography fontWeight={500} fontSize='1rem'>
                챌린지 이용권
              </Typography>
              <Divider />
              <Box>
                <Typography className='title'>이용권 종류</Typography>
                <TextField
                  select
                  fullWidth
                  value={challengePlan}
                  onChange={evt => setChallengePlan(evt.target.value)}
                >
                  {challengeCouponType.map((item: any, idx: number) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.title}
                    </MenuItem>
                  ))}
                </TextField>
                <Divider />
              </Box>
              <Box>
                <Typography className='title'>지급 포탈</Typography>
                <Box>
                  <TextField
                    sx={{ width: '30rem' }}
                    placeholder='포탈 아이디를 검색해 주세요.'
                    inputRef={searchRef}
                    value={searchPortal}
                    onChange={(
                      evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                    ) => {
                      setSearchPortal(evt.target.value);
                      setSearchPortalAccount(null);
                    }}
                    helperText=''
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton onClick={() => setOpenPortal(true)}>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Divider />
              </Box>
              <Box sx={{ mb: '1rem' }}>
                <Typography className='title'>이용권 개수</Typography>
                <TextField
                  select
                  fullWidth
                  value={challengePlanCount}
                  onChange={evt =>
                    setChallengePlanCount(Number(evt.target.value))
                  }
                >
                  {[...Array(CHALLENGE_COUPON_MAX_COUNT)].map(
                    (x: any, i: number) => (
                      <MenuItem key={i + 1} value={i + 1}>
                        {i + 1}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </Box>
              <Button variant='contained' fullWidth onClick={giveChallengePlan}>
                지급
              </Button>
            </Paper>
          </Grid>
          <Grid xs={4}>
            <Paper sx={{ p: '2rem' }}>
              <Typography fontWeight={500} fontSize='1rem'>
                프로그램 이용권
              </Typography>
              <Divider />
              <Box>
                <Typography className='title'>이용권 종류</Typography>
                <TextField
                  select
                  fullWidth
                  value={programPlan}
                  onChange={evt => setProgramPlan(evt.target.value)}
                >
                  {programCouponType.map((item: any, idx: number) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.title}
                    </MenuItem>
                  ))}
                </TextField>
                <Divider />
              </Box>
              <Box>
                <Typography className='title'>지급 포탈</Typography>
                <Box>
                  <TextField
                    sx={{ width: '30rem' }}
                    placeholder='포탈 아이디를 검색해 주세요.'
                    inputRef={searchRef}
                    value={searchPortal}
                    onChange={(
                      evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                    ) => {
                      setSearchPortal(evt.target.value);
                      setSearchPortalAccount(null);
                    }}
                    helperText=''
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton onClick={() => setOpenPortal(true)}>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Divider />
              </Box>
              <Box sx={{ mb: '1rem' }}>
                <Typography className='title'>이용권 개수</Typography>
                <TextField
                  select
                  fullWidth
                  value={programPlanCount}
                  onChange={evt =>
                    setProgramPlanCount(Number(evt.target.value))
                  }
                >
                  {[...Array(CHALLENGE_COUPON_MAX_COUNT)].map(
                    (x: any, i: number) => (
                      <MenuItem key={i + 1} value={i + 1}>
                        {i + 1}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </Box>
              <Button variant='contained' fullWidth onClick={giveProgramPlan}>
                지급
              </Button>
            </Paper>
          </Grid>
          <Grid xs={4}>
            <Paper sx={{ p: '2rem' }}>
              <Typography fontWeight={500} fontSize='1rem'>
                문자 이용권
              </Typography>
              <Divider />
              <Box>
                <Typography className='title'>이용권 종류</Typography>
                <TextField
                  select
                  fullWidth
                  value={messagingPlan}
                  onChange={evt => setMessagingPlan(evt.target.value)}
                >
                  {messagingCouponType.map((item: any, idx: number) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.title}
                    </MenuItem>
                  ))}
                </TextField>
                <Divider />
              </Box>
              <Box>
                <Typography className='title'>지급 포탈</Typography>
                <Box>
                  <TextField
                    sx={{ width: '30rem' }}
                    placeholder='포탈 아이디를 검색해 주세요.'
                    inputRef={searchRef}
                    value={searchPortal}
                    onChange={(
                      evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                    ) => {
                      setSearchPortal(evt.target.value);
                      setSearchPortalAccount(null);
                    }}
                    helperText=''
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton onClick={() => setOpenPortal(true)}>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Divider />
              </Box>
              <Box sx={{ mb: '1rem' }}>
                <Typography className='title'>충전 금액</Typography>
                <TextField
                  fullWidth
                  value={messagingPlanCount}
                  onChange={evt => {
                    const newValue = checkNaN(
                      evt.target.value.replaceAll(',', ''),
                      Number(messagingPlanCount.replaceAll(',', ''))
                    );
                    setMessagingPlanCount(
                      commaFormat(String(newValue).substring(0, 7))
                    );
                  }}
                />
              </Box>
              <Button variant='contained' fullWidth onClick={giveMessagingPlan}>
                지급
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <SwSearchDataPortal
        title={title !== '' ? title : '포털아이디'}
        searchKey={searchKey}
        searchWord={searchRef.current?.value}
        isDetail={true}
        open={openPortal}
        onClose={() => {
          setOpenPortal(false);
        }}
        setTarget={setSearchPortalAccount}
      />
      <SwAlert
        contents={contents}
        open={!!openAlert}
        onConfirm={() => {
          if (openAlert === 1) {
            registerServiceCoupon();
          }
          setOpenAlert(0);
        }}
        onClose={() => setOpenAlert(0)}
        confirm={1}
      />
    </>
  );
};
