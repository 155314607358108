import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState, Dispatch } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { getProgramTemplateCodeList } from '../../../common/api/ApiProgram';
import { KEY_PROGRAM_TEMPLATE_CODE_LIST } from '../../../common/key';

interface Props {
  title: string;
  searchKey: string;
  searchWord: string;
  isDetail?: boolean;
  filterName?: boolean;
  open: boolean;
  onClose: () => void;
  setTarget: Dispatch<React.SetStateAction<any[]>>;
}

const columns = ['템플릿 코드 이름', '템플릿 코드'];

export const SwSearchTemplateCode: React.FC<Props> = ({
  title,
  open,
  onClose,
  searchKey,
  searchWord,
  isDetail = false,
  filterName = false,
  setTarget,
}) => {
  const queryClient = useQueryClient();
  const [templateCodeList, setTemplateCodeList] = useState<any>([]);
  const [selected, setSelected] = useState<any[]>([]);
  const [checkedButtons, setCheckedButtons] = useState<string[]>([]);

  const {
    data,
    isLoading,
    refetch: programTemplateCodeRefetch,
  } = useQuery(
    KEY_PROGRAM_TEMPLATE_CODE_LIST,
    () => {
      const newRequest = {
        status: 2, // 1: close, 2: open, 3: all
        page: 0,
        rowsPerPage: 0,
        searchKey: searchKey,
        searchWord: searchWord,
      };
      if (open === true && searchKey !== '' && isDetail === false)
        return getProgramTemplateCodeList(newRequest);
      return null;
    },
    {
      onSuccess: res => {
        if (res !== null && res.templateCode !== undefined) {
          console.log('res:', res);
          setTemplateCodeList(res.templateCode);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const clickItem = (row: any) => {
    if (checkedButtons.includes(row.templateCode)) {
      setCheckedButtons(
        checkedButtons.filter(button => button !== row.templateCode)
      );
    } else {
      setCheckedButtons([...checkedButtons, row.templateCode]);
    }
  };

  const changeHandler = (checked: boolean, id: string) => {
    if (checked) {
      setCheckedButtons([...checkedButtons, id]);
    } else {
      setCheckedButtons(checkedButtons.filter(button => button !== id));
    }
  };

  const saveTarget = () => {
    const newTarget = templateCodeList.filter((item: any) =>
      checkedButtons.includes(item.templateCode)
    );
    console.log('target:', newTarget);
    setTarget(newTarget);
    onClose();
  };

  useEffect(() => {
    console.log('open:', open, ', searchWord:', searchWord);
    if (open === true) {
      setCheckedButtons([]);
      programTemplateCodeRefetch();
    }
    return () => {};
  }, [open]);

  return (
    <Dialog
      open={open}
      fullScreen
      sx={{
        '& .MuiDialog-paper': {
          height: '66vh',
          margin: '20rem',
        },
      }}
    >
      <DialogTitle>
        <Box className='flex_between'>
          <span>
            {searchWord && searchWord.length > 0 && `'${searchWord}'`} {title}{' '}
            검색결과
          </span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogContent>
        <TableContainer>
          <Table stickyHeader>
            <TableHead sx={{ bgcolor: '#F9FAFC' }}>
              <TableRow>
                <TableCell key='template_code_head_0' padding='checkbox'>
                  선택
                </TableCell>
                {columns.map((column, idx) => (
                  <TableCell
                    key={`template_code_head_${idx + 1}`}
                    sx={{ p: '.6rem' }}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {templateCodeList?.map((row: any, idx: number) => (
                <TableRow
                  key={`template_code_${idx}`}
                  onClick={() => clickItem(row)}
                >
                  <TableCell padding='checkbox'>
                    <Checkbox
                      id={row.communityId}
                      onChange={e => {
                        changeHandler(
                          e.currentTarget.checked,
                          row.templateCode
                        );
                      }}
                      checked={checkedButtons.includes(row.templateCode)}
                    />
                  </TableCell>
                  <TableCell key={row.na} sx={{ p: '.3rem .6rem' }}>
                    {row.templateName}
                  </TableCell>
                  <TableCell sx={{ p: '.3rem .6rem' }}>
                    {row.templateCode}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <Divider sx={{ m: 0 }} />
      <Box className='flex_between' sx={{ m: '1rem 2rem' }}>
        <Box>
          <Typography>{`${checkedButtons.length}개가 선택되었습니다.`}</Typography>
        </Box>
        <Button
          variant='contained'
          color='info'
          sx={{ fontSize: '.87rem' }}
          onClick={saveTarget}
        >
          추가
        </Button>
      </Box>
      {/* <DialogActions sx={{ p: '1rem' }}>
        <Box className='flex_between'>
          <Box>
            <Typography>커뮤니티</Typography>
          </Box>
          <Button
            variant='contained'
            color='info'
            sx={{ fontSize: '.87rem' }}
            onClick={saveTarget}
          >
            추가
          </Button>
        </Box>
      </DialogActions> */}
    </Dialog>
  );
};
